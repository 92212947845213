import { ReactNode } from 'react';

import { Trans, t } from '@lingui/macro';
import { Link } from '@shared/typography';
import { Space } from 'antd';
import Chevron from 'assets/svg/chevron-left.svg?react';

import styles from './ReturnToLink.module.scss';

type RouteProps = {
  route: string;
  routeName: ReactNode;
  previousPage?: never;
};

type PreviousPageProps = {
  previousPage: boolean;
  route?: never;
  routeName?: never;
};

type Props = RouteProps | PreviousPageProps;

export const ReturnToLink = ({ previousPage, route, routeName }: Props) => {
  const contents = (
    <Space className={styles.container} size={8}>
      <Chevron />
      <Trans>Return to {previousPage ? t`previous page` : routeName}</Trans>
    </Space>
  );

  if (previousPage) {
    return (
      <Link className={styles.link} previousPage>
        {contents}
      </Link>
    );
  }

  return (
    <Link className={styles.link} to={route!}>
      {contents}
    </Link>
  );
};
