export const GREEN_100 = '#E2F2BD';
export const GREEN_200 = '#D3EC9B';
export const GREEN_300 = '#C5E57A';
export const GREEN_400 = '#B6DF59';
export const GREEN_500 = '#92B247';
export const GREEN_600 = '#6D8635';
export const GREEN_700 = '#495924';
export const GREEN_800 = '#242D12';

export const ALL_GREENS = [
  GREEN_100,
  GREEN_200,
  GREEN_300,
  GREEN_400,
  GREEN_500,
  GREEN_600,
  GREEN_700,
  GREEN_800,
];

export const ALL_SEMANTIC_GREENS = [
  GREEN_300,
  GREEN_400,
  GREEN_500,
  GREEN_600,
  GREEN_700,
  GREEN_800,
];
