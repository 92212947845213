import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PermissionSettingInfo from '@pages/users/:user/PermissionSettingInfo';
import UserDetailInfoFields from '@pages/users/:user/UserDetailInfoFields';
import { USER_FORM_FIELD, UserFormType } from '@pages/users/:user/UserFormType';
import Form, { setFieldErrors } from '@shared/Form';
import { Page } from '@shared/page';
import { Panel } from '@shared/panels';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { Col, Row, Space } from 'antd';
import { getStytchAPIErrorMessage } from 'error/StytchError';
import { useNotification } from 'providers/Notification';
import { useMe } from 'providers/User';

import ProfileHeader from './ProfileHeader';

const Profile = () => {
  const user = useMe();
  const stytch = useStytchB2BClient();
  const { pushNotification } = useNotification();
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleFinish = async (values: UserFormType) => {
    if (user?.id) {
      try {
        setSaving(true);
        const response = await stytch.self.update({
          name: values[USER_FORM_FIELD.NAME],
        });

        if (!response.member.name.length) {
          throw new Error();
        }
      } catch (e) {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e),
        });

        setFieldErrors(form, USER_FORM_FIELD.NAME, [
          t`An error occurred while trying to save your name. Please refresh the page and try again.`,
        ]);
      } finally {
        setSaving(false);
        setIsEditing(false);
      }
    }
  };

  return (
    <Page title={t`Your Profile`} pageName={user ? user.name : undefined}>
      <Form form={form} onFinish={handleFinish}>
        <Space size={24} direction="vertical" style={{ width: '100%' }}>
          <ProfileHeader
            isC99Superuser={!!user?.isC99Superuser}
            isEditing={isEditing}
            isSaving={isSaving}
            onEditChange={setIsEditing}
          />
          <Row gutter={[24, 24]}>
            <Col xxl={7} xl={9} md={24} sm={24} xs={24}>
              <Panel size="L" title={<Trans>User Details</Trans>} style={{ height: '100%' }}>
                <UserDetailInfoFields
                  isEditing={isEditing}
                  name={user?.name || ''}
                  email={user?.email}
                  isEmailEditable={false}
                />
              </Panel>
            </Col>
            <Col xxl={17} xl={15} lg={24}>
              <Panel size="L" title={<Trans>Permission Setting</Trans>} style={{ height: '100%' }}>
                <PermissionSettingInfo role={user?.isAdmin ? 'admin' : 'user'} isEditing={false} />
              </Panel>
            </Col>
          </Row>
        </Space>
      </Form>
    </Page>
  );
};

export default Profile;
