import { useMemo } from 'react';

import { t } from '@lingui/macro';
import Flare, { Donut, FlareSeriesOptions, Legend, Tooltip } from '@shared/Flare';
import { AudienceOptionType } from 'api/audience';
import { WebTrafficVisitSummaryDataType } from 'api/webTraffic';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  VISITS_PRIMARY,
  WHITE,
} from 'constants/colors';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  data?: WebTrafficVisitSummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error?: SolGraphQLError;
};

const WebTrafficTotalVisitsChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'visits',
        name: t`Visits`,
        data: data.map((slice) => ({
          name: slice.name,
          data: slice.percentOfTotal,
          custom: {
            typeId: slice.id,
            value: slice.count,
            companyCount: slice.engagedCompanies,
            companyPercent: slice.percentOfTotalCompanies,
          },
        })),
      },
    ];
  }, [data]);

  const totalVisits = data && data.length > 0 ? data[0].total : 0;

  return (
    <Flare
      data={seriesData}
      title={`<b>${numberFormat(totalVisits)}</b><br/>${t`Total Visits`}`}
      width="auto"
      height="auto"
      colors={[audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY, VISITS_PRIMARY, BOT_PRIMARY]}
      isLoading={isLoading}
      error={error}
    >
      <Legend showByCategory />
      <Donut
        y="data"
        dataLabelColor={WHITE}
        dataLabelFormat={(label) => {
          if (label.y && label.y >= 0.02) {
            return numberFormat(label.y, { isPercent: true, precision: 0 });
          }
          return '';
        }}
        hideConnector
        hideDataLabels={false}
        hiddenDataLabelThreshold={0}
      />
      <Tooltip
        titleFormat={(item) => item?.point.name}
        rowLabelFormat={(item) => `${item?.series.name}<br/>${t`Companies`}`}
        rowSecondaryValueFormat={(item) =>
          `(${numberFormat(item?.point.y, { isPercent: true })})<br/>(${numberFormat(item?.point.custom?.companyPercent, { isPercent: true })})`
        }
        rowValueFormat={(item) =>
          `${numberFormat(item?.point.custom?.value)}<br/>${numberFormat(item?.point.custom?.companyCount)}`
        }
        valueLink={(item) => {
          if (item?.point.custom?.typeId === 'audience') {
            return ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              'metrics.visits': '[1,]',
            });
          }
          if (item?.point.custom?.typeId === 'tam') {
            return ROUTES.webTrafficActivityWithParams({
              inTam: 'true',
              'metrics.visits': '[1,]',
            });
          }
          return null;
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficTotalVisitsChart;
