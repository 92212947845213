import { Trans, t } from '@lingui/macro';
import { MultiSelect, MultiSelectProps } from '@shared/Form';
import { RevenueRangeType } from 'api/common';
import { useRevenueRanges } from 'api/settings';

const RevRangeMultiSelect = (props: MultiSelectProps<RevenueRangeType>) => {
  const { revRanges, isLoading } = useRevenueRanges();

  return (
    <MultiSelect
      placeholder={t`Select Company Sizes...`}
      notFoundContent={<Trans>No company sizes were found...</Trans>}
      {...props}
      options={revRanges}
      isLoading={isLoading}
    />
  );
};

export default RevRangeMultiSelect;
