import { msg } from '@lingui/macro';

import { ErrorByCodeType } from './types';

export enum USER_ERROR_CODES {
  ALREADY_IN_ORG = 'err:sol.users.already-in-org',
}

export const USER_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [USER_ERROR_CODES.ALREADY_IN_ORG]: msg`The email address of the user you tried to invite already belongs to a member of this account.`,
};
