import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import ChannelMultiSelect from '@pages/pixels/ChannelMultiSelect';
import VendorSelect from '@pages/pixels/VendorSelect';
import { Button } from '@shared/buttons';
import { ConfirmModal } from '@shared/modals';
import { Title } from '@shared/typography';

import styles from './RuleFilterModal.module.scss';

type Props = {
  open: boolean;
  channels: string[];
  vendors: string[];
  onFiltersChange: (channels: string[], vendors: string[]) => void;
  onClose: () => void;
};

const RuleFilterModal = ({ open, channels, vendors, onFiltersChange, onClose }: Props) => {
  const [selectedChannels, setSelectedChannels] = useState<string[]>(channels);
  const [selectedVendors, setSelectedVendors] = useState<string[]>(vendors);

  const handleFiltersReset = () => {
    setSelectedChannels([]);
    setSelectedVendors([]);
  };

  const handleClose = () => {
    setSelectedChannels(channels);
    setSelectedVendors(vendors);
    onClose();
  };

  const handleChannelChange = (channels: string[]) => {
    setSelectedChannels(channels);
  };

  const handleVendorChange = (vendors: string[]) => {
    setSelectedVendors(vendors);
  };

  const handleApplyFilters = () => {
    onFiltersChange(selectedChannels, selectedVendors);
  };

  return (
    <ConfirmModal
      open={open}
      okText={t`Apply Filter`}
      onOk={handleApplyFilters}
      onCancel={handleClose}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          <Title level={2} weight="light">
            <Trans>Filter</Trans>
          </Title>
          <Button color="black" variant="secondary" onClick={handleFiltersReset}>
            <Trans>Clear All Filters</Trans>
          </Button>
        </div>
        <ChannelMultiSelect
          value={selectedChannels}
          placeholder={t`Add Channel...`}
          onChange={handleChannelChange}
          size="large"
        />
        <VendorSelect
          mode="multiple"
          value={selectedVendors}
          placeholder={t`Add Vendor...`}
          onChange={handleVendorChange}
        />
      </div>
    </ConfirmModal>
  );
};

export default RuleFilterModal;
