import { PropsWithChildren } from 'react';

import { Flex } from '@shared/Flex';

import styles from './FlareEmptyMessage.module.scss';

const FlareEmptyMessage = ({ children }: PropsWithChildren) => {
  return (
    <Flex className={styles.text} justify="center" align="center">
      {children}
    </Flex>
  );
};

export default FlareEmptyMessage;
