export const GRAY_100 = '#F5F5F6';
export const GRAY_200 = '#E8E8E8';
export const GRAY_300 = '#E3E4E5';
export const GRAY_400 = '#DADADA';
export const GRAY_500 = '#BFBFBF';
export const GRAY_600 = '#3C3C3C';
export const GRAY_700 = '#808080';
export const GRAY_800 = '#191919';

export const ALL_GRAYS = [
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_800,
];
