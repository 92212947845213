import { Trans, t } from '@lingui/macro';
import { getPeriodTime } from '@shared/Dashboard/Filters/util/date-filter-util';
import { Legend, LegendItem } from '@shared/Legend';
import DonutContainer from '@shared/charts/DonutContainer';
import MultiDonut from '@shared/charts/MultiDonut';
import { Panel } from '@shared/panels';
import { AudienceGroupedOptionType } from 'api/audience';
import { usePixelTargetingEfficiency } from 'api/pixel';
import { ALL_PRIMARY, AUDIENCE_PRIMARY, TAM_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';
import { WebTrafficSearchParamType } from 'router/searchParams/webTrafficSearchParams';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  startDate?: string;
  endDate?: string;
  isLoading: boolean;
};

const PixelTargetingEfficiencyChart = ({
  pixelIds,
  startDate,
  endDate,
  audience,
  isLoading,
}: Props) => {
  const audienceIds = audience?.audienceIds;

  // TODO: Eventually we will support multiple audiences and we can delete this line
  const audienceId = audienceIds?.[0];

  const {
    pixelTargetingEfficiencyData: data,
    isLoading: isDonutDataLoading,
    error,
  } = usePixelTargetingEfficiency(pixelIds, startDate, endDate, audienceId);

  const dateRangeLabel = getPeriodTime(startDate, endDate);

  return (
    <Panel
      size="L"
      title={<Trans>Targeting Efficiency</Trans>}
      infoPopover={{
        body: <Trans>% of Impressions served to your audience.</Trans>,
      }}
      style={{ height: 465 }}
      noPadding
    >
      <Legend>
        {audience && <LegendItem color={AUDIENCE_PRIMARY} name={audience.name} />}
        <LegendItem color={TAM_PRIMARY} name={t`TAM`} />
        <LegendItem color={ALL_PRIMARY} name={t`ALL`} />
      </Legend>
      <DonutContainer error={error}>
        {audience && data?.impressions.all != null && (
          <MultiDonut
            data={[
              {
                value: data?.impressions.audience,
                secondaryValue: (data?.impressions.audience || 0) / data.impressions.all,
                seriesName: t`${audience.name} Impressions`,
                color: AUDIENCE_PRIMARY,
              },
              {
                value: data && data.impressions.all - data.impressions.audience,
                valueText: numberFormat(data.impressions.all),
                seriesName: t`ALL`,
                color: ALL_PRIMARY,
              },
            ]}
            metricName={t`Impressions`}
            titleValue={numberFormat(data?.impressions.audience)}
            tooltipTitle={dateRangeLabel}
            isLoading={isLoading || isDonutDataLoading}
            error={null}
            valueLink={(item) => {
              if (item?.colorIndex === 1) {
                return ROUTES.webTrafficActivityWithParams({
                  pixelIds,
                  'metrics.impressions': '[1,]',
                });
              } else {
                return ROUTES.webTrafficActivityWithParams({
                  pixelIds,
                  'metrics.impressions': '[1,]',
                  audienceIds,
                });
              }
            }}
          />
        )}
        {data?.impressions.all != null && (
          <MultiDonut
            data={[
              {
                value: data?.impressions.tam,
                secondaryValue: (data?.impressions.tam || 0) / data.impressions.all,
                seriesName: t`TAM Impressions`,
                color: TAM_PRIMARY,
              },
              {
                value: data && data.impressions.all - data.impressions.tam,
                valueText: numberFormat(data.impressions.all),
                seriesName: t`ALL`,
                color: ALL_PRIMARY,
              },
            ]}
            metricName={t`Impressions`}
            titleValue={numberFormat(data?.impressions.tam)}
            tooltipTitle={dateRangeLabel}
            isLoading={isLoading || isDonutDataLoading}
            error={null}
            valueLink={(item) => {
              const defaultFilters: Partial<WebTrafficSearchParamType> = {
                pixelIds,
                'metrics.impressions': '[1,]',
              };

              if (item?.colorIndex === 1) {
                return ROUTES.webTrafficActivityWithParams({
                  ...defaultFilters,
                  audienceIds,
                });
              } else {
                return ROUTES.webTrafficActivityWithParams({
                  ...defaultFilters,
                  inTam: 'true',
                });
              }
            }}
          />
        )}
      </DonutContainer>
    </Panel>
  );
};

export default PixelTargetingEfficiencyChart;
