const generatePixelMailToLink = (channelName: string, vendorName: string, pixelURL: string) => {
  const subject = `New Channel99 Pixel for ${vendorName} - ${channelName}`;
  const body = `Hello,

You've successfully created a new Channel99 Pixel for ${vendorName} - ${channelName}. Please use the Pixel code and directions below to put the pixel into action.

${pixelURL}

Step-by-step instructions for placing your Pixel can be found HERE: https://support.channel99.com/hc/en-us/sections/18811026107803-Pixel

Thank you,
`;

  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};

export default generatePixelMailToLink;
