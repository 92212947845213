import { useState } from 'react';

import { Trans } from '@lingui/macro';
import { AISearchInput } from '@shared/Form';
import { Button } from '@shared/buttons';
import { AIMessageType, mutateAIChat } from 'api/ai';
import AIStarsIcon from 'assets/svg/ai-stars.svg?react';

import AIDrawer from './AIDrawer';
import styles from './AISearch.module.scss';
import Markdown from './Markdown';

const AISearch = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [messages, setMessages] = useState<AIMessageType[]>([]);
  const [isChatLoading, setChatLoading] = useState(false);

  const sendMessage = async (message: string) => {
    setMessages((prev) => [...prev, { content: message, isUser: true }]);
    setChatLoading(true);

    const aiResponse = await mutateAIChat(message);

    setMessages((prev) => [
      ...prev,
      {
        content: <Markdown>{aiResponse}</Markdown>,
        isUser: false,
      },
    ]);
    setChatLoading(false);
  };

  const handleSearch = (value: string) => {
    setDrawerOpen(true);
    sendMessage(value);
  };

  const handleSendMessage = (message: string) => {
    sendMessage(message);
  };

  return (
    <div className={styles.container}>
      {messages.length === 0 ? (
        <AISearchInput className={styles.searchInput} size="medium" round onSearch={handleSearch} />
      ) : (
        <Button
          className={styles.openButton}
          variant="secondary"
          color="black"
          icon={<AIStarsIcon />}
          onClick={() => setDrawerOpen(true)}
        >
          <Trans>Open Conversation</Trans>
        </Button>
      )}
      <AIDrawer
        open={isDrawerOpen}
        messages={messages}
        isChatLoading={isChatLoading}
        onClose={() => setDrawerOpen(false)}
        onSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default AISearch;
