import { PropsWithChildren, useState } from 'react';

import { NotesPanel } from '@shared/panels';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './DashboardWidget.module.scss';
import DashboardWidgetActions from './DashboardWidgetActions';
import { DashboardFiltersType } from './Filters/types';
import { DashboardWidgetLibraryType, DashboardWidgetType } from './types';

const isLibraryWidget = (widget: DashboardWidgetType): widget is DashboardWidgetLibraryType => {
  return 'library' in widget;
};

type Props = {
  widget: DashboardWidgetType;
  dashboardFilters: DashboardFiltersType;
  canFullScreen?: boolean;
  isFullScreen?: boolean;
  draggableHandleSelector?: string | false;
  draggableIgnoreSelector?: string | false;
  onWidgetExpand: (isExpanded: boolean, widget: DashboardWidgetType) => void;
};

const renderLibraryWidget = (
  widget: DashboardWidgetLibraryType,
  dashboardFilters: DashboardFiltersType,
) => {
  const { library: LibraryComponent } = widget;

  return <LibraryComponent dashboardFilters={dashboardFilters} />;
};

const DashboardWidget = ({
  widget,
  dashboardFilters,
  canFullScreen = true,
  isFullScreen = false,
  draggableHandleSelector,
  draggableIgnoreSelector,
  onWidgetExpand,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState<boolean>(false);

  const handleLinkExternal = (link: string) => {
    navigate(link);
  };

  const handleWidgetExpand = (isExpanded: boolean) => {
    onWidgetExpand(isExpanded, widget);
  };

  return (
    <NotesPanel
      data-testid={`dashboard-widget-${widget.id}`}
      startDate={dashboardFilters.start_date}
      endDate={dashboardFilters.end_date}
      className={classNames(styles.widgetPanel, { 'c99-pull-front': isNotesDrawerOpen })}
      headerClassName={draggableHandleSelector}
      actionsClassName={draggableIgnoreSelector}
      title={widget.title}
      hideNotes={!widget.showNotes}
      actions={
        <DashboardWidgetActions
          link={widget.link}
          canExpand={canFullScreen}
          isExpanded={isFullScreen}
          onExpandToggle={handleWidgetExpand}
          onLinkExternal={handleLinkExternal}
        />
      }
      noPadding
      verifyC99Tag={widget.verifyC99Tag}
      onDrawerOpenChange={setIsNotesDrawerOpen}
    >
      {isLibraryWidget(widget) ? (
        renderLibraryWidget(widget, dashboardFilters)
      ) : (
        <div>Dynamic widgets coming soon</div>
      )}
    </NotesPanel>
  );
};

export default DashboardWidget;
