import { Trans } from '@lingui/macro';
import { ConfirmModal } from '@shared/modals';
import { Title } from '@shared/typography';
import { VendorSpendDataType } from 'api/vendorSpend';

type Props = {
  vendorSpendRow?: VendorSpendDataType;
  onDeleteVendorRow: (deletedVendorSpendRow: VendorSpendDataType) => void;
  onCancel: () => void;
};

const ConfirmDeleteRowModal = ({ vendorSpendRow, onDeleteVendorRow, onCancel }: Props) => {
  return (
    <ConfirmModal
      open={vendorSpendRow != null}
      okText={<Trans>Yes, Delete</Trans>}
      onCancel={onCancel}
      onOk={() => onDeleteVendorRow(vendorSpendRow!)}
    >
      <div>
        <Title level="4" weight="light">
          <Trans>
            Deleting will remove all spend data for{' '}
            <Title level="4" weight="semi-bold" inline>
              {vendorSpendRow?.vendor.name}
            </Title>{' '}
            <Title level="4" weight="semi-bold" inline>
              {vendorSpendRow?.vendor.channel?.name}.
            </Title>{' '}
            Are you sure you want to delete?
          </Trans>
        </Title>
      </div>
    </ConfirmModal>
  );
};

export default ConfirmDeleteRowModal;
