import { Trans, t } from '@lingui/macro';
import PageSpinner from '@shared/PageSpinner';
import PageError from '@shared/errors/PageError';
import { Page } from '@shared/page';
import { useIntegrationProviders } from 'api/integration';

import IntegrationCard from './IntegrationCard';
import styles from './IntegrationsList.module.scss';
import { getProviderLogoById, getProviderShortDescriptionById } from './providerUtil';

const IntegrationsList = () => {
  const { providers, isLoading, error } = useIntegrationProviders();

  return (
    <Page title={t`Settings`} pageName={t`Integrations`}>
      {isLoading && <PageSpinner />}
      {error ? (
        <PageError
          message={<Trans>An error occurred while loading integration providers</Trans>}
          detail={<Trans>Please refresh the page and try again</Trans>}
        />
      ) : (
        <div className={styles.cardList}>
          {providers?.map((provider) => (
            <IntegrationCard
              key={provider.id}
              id={provider.id}
              name={provider.name}
              status={
                provider.connections.length
                  ? provider.connections[0].status === 'active'
                    ? 'active'
                    : 'broken'
                  : 'notConnected'
              }
              description={getProviderShortDescriptionById(provider.id)}
              icon={getProviderLogoById(provider.id)}
            />
          ))}
        </div>
      )}
    </Page>
  );
};

export default IntegrationsList;
