import { UserProfileType } from 'api/user';
import { LDClient, LDContext } from 'launchdarkly-js-client-sdk';

export const getLDContext = (user: UserProfileType): LDContext => {
  return {
    key: user?.id,
    kind: 'user',
    ...user,
    name: user?.email || '',
    environment: import.meta.env.VITE_ENVIRONMENT,
  };
};

export const updateUserContext = (user: UserProfileType, ldClient?: LDClient) => {
  if (ldClient && user && window.USER_ID !== user.id) {
    const context = getLDContext(user);
    ldClient.identify(context);
    window.USER_ID = user.id;
  }
};
