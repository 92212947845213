export const PINK_100 = '#F1C4E2';
export const PINK_200 = '#E9A6D3';
export const PINK_300 = '#E490C8';
export const PINK_400 = '#DB6BB6';
export const PINK_500 = '#AF5692';
export const PINK_600 = '#83406D';
export const PINK_700 = '#582B49';
export const PINK_800 = '#2C1524';

export const ALL_PINKS = [
  PINK_100,
  PINK_200,
  PINK_300,
  PINK_400,
  PINK_500,
  PINK_600,
  PINK_700,
  PINK_800,
];

export const ALL_SEMANTIC_PINKS = [PINK_300, PINK_400, PINK_500, PINK_600, PINK_700, PINK_800];
