import ConfettiFireworks from 'react-canvas-confetti/dist/presets/fireworks';

const Fireworks = () => {
  return (
    <ConfettiFireworks
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 3,
      }}
      decorateOptions={(options) => ({
        ...options,
        particleCount: 100,
        startVelocity: 30,
        ticks: 60,
        zIndex: 3,
      })}
      autorun={{ speed: 1.25, delay: 1500, duration: 3000 }}
    />
  );
};

export default Fireworks;
