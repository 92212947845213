import { ReactNode } from 'react';

import { t } from '@lingui/macro';
import Tooltip from '@shared/Tooltip';
import { Link } from '@shared/typography';
import { NumberFormatOptionsType, numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

import {
  EventLogParamPrimitiveType,
  EventLogParamReferenceType,
  EventLogParamReferenceValues,
  EventLogParamType,
} from './eventLogType';

const isEventLogParamPrimitive = (param: EventLogParamType): param is EventLogParamPrimitiveType =>
  param.type === 'strn' || param.type === 'nmbr';

const typeToPath: Record<EventLogParamReferenceValues, (id?: string) => string | undefined> = {
  user: ROUTES.userById,
  oprv: ROUTES.integrationById,
  pixl: ROUTES.pixelById,
  audi: ROUTES.audienceById,
  vndr: ROUTES.vendorById,
  chnl: ROUTES.channelById,
  vspd: () => ROUTES.configureVendors.path,
  cmap: () => ROUTES.configureChannels.path,
};

const reactReplaceText = (
  text: string,
  regex: RegExp,
  formatToken: (token: string) => ReactNode,
) => {
  const textArray = text.split(regex);
  return textArray.map((text) => {
    if (text.match(regex)) {
      return formatToken(text.slice(1, -1));
    }
    return text;
  });
};

const createMessageLink = (
  param: EventLogParamReferenceType,
  getPathById: (id?: string) => string | undefined,
) => {
  if (param.isDeleted) {
    return (
      <Tooltip title={t`Deleted object`} body={t`This object has been deleted`}>
        <Link variant="caption1" color="static-red" isDisabled>
          {param.value}
        </Link>
      </Tooltip>
    );
  } else {
    return (
      <Link variant="caption1" to={getPathById(param.id)}>
        {param.value}
      </Link>
    );
  }
};

const formatNumberValue = (value: string | number, format?: string) => {
  const formatOptions: NumberFormatOptionsType = {
    isPercent: false,
    precision: 0,
    isCurrency: false,
    isMultiplier: false,
    compact: false,
  };

  if (format) {
    const numberType = format[0];
    const precisionStr = format.substring(1);

    if (numberType === '$') {
      formatOptions.isCurrency = true;
    }

    if (numberType === '%') {
      formatOptions.isPercent = true;
    }

    if (numberType === '#') {
      formatOptions.isCurrency = false;
      formatOptions.isPercent = false;
    }

    if (precisionStr.length) {
      formatOptions.precision = +precisionStr;
    }
  }

  return numberFormat(value, formatOptions);
};

export const hydrateEventLogMessage = (
  message: string,
  params: Record<string, EventLogParamType>,
  linkify: boolean = true,
): string | ReactNode => {
  const replacedText = reactReplaceText(message, /({[^}]+})/g, (token) => {
    const param = params[token];

    // If the param is not found or is null, return the key.
    if (!param || param.value === null) {
      return `{${token}}`;
    }

    if (isEventLogParamPrimitive(param)) {
      if (param.type === 'nmbr') {
        return formatNumberValue(param.value, param.format);
      }

      if (param.type === 'strn') {
        return param.value;
      }
    } else {
      // Else it's a reference and we'll be displaying a link to the object.
      const getPathById = typeToPath[param.type];
      if (!getPathById) {
        return param.value;
      }

      if (linkify) {
        return createMessageLink(param, getPathById);
      } else {
        return param.value;
      }
    }
  });

  if (!linkify) {
    return replacedText.join('');
  }
  return replacedText;
};
