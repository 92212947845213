export default [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'yandex.com',
  'gmx.com',
  'live.com',
  'comcast.net',
  'verizon.net',
  'rocketmail.com',
  'inbox.com',
  'att.net',
  'zoho.com',
  'me.com',
  'sina.com',
  'sina.cn',
  'naver.com',
  'daum.net',
  'rediffmail.com',
];
