import { useMemo, useRef, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import {
  InstantSearchTable,
  SolQueryParamsNew,
  useInstantSearchState,
} from '@shared/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@shared/InstantSearch/util/search-util';
import { Legend, LegendItem } from '@shared/Legend';
import { ColumnsType, ExpandableConfig } from '@shared/Table';
import { Panel } from '@shared/panels';
import { Link } from '@shared/typography';
import { TableRef } from 'antd/es/table';
import { AudienceOptionType } from 'api/audience';
import { VendorActivityDataType, useVendorActivity } from 'api/vendor';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from 'constants/colors';
import { FEATURE_FLAG, useFeatureFlag } from 'constants/featureFlags';
import didClickOnAntTooltip from 'helper/didClickOnAntTooltip';
import { numberFormat } from 'helper/numberFormatter';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { ROUTES } from 'router';

import styles from './VendorActivityTable.module.scss';
import VendorActivityTableBarCell from './VendorActivityTableBarCell';
import VendorTop5CampaignTable from './VendorTop5CampaignTable';
import { SERIES_TYPE } from './types';

type Props = {
  tableParams?: SolQueryParamsNew;
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
};

const VendorActivityTable = ({ startDate, endDate, audience }: Props) => {
  const hasCampaignsFlag = useFeatureFlag(FEATURE_FLAG.campaigns);
  const tableRef = useRef<TableRef>(null);
  const { queryState } = useInstantSearchState();
  const [enabledSeries, setEnabledSeries] = useState<SERIES_TYPE[]>([
    SERIES_TYPE.AUDIENCE,
    SERIES_TYPE.TAM,
    SERIES_TYPE.ALL,
  ]);
  const [hoveredRow, setHoveredRow] = useState<VendorActivityDataType | null>(null);
  const [stickyRow, setStickyRow] = useState<{
    element: HTMLElement;
    record: VendorActivityDataType;
  } | null>(null);
  const {
    vendorActivity: data,
    totalResults,
    isLoading,
    error,
  } = useVendorActivity(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
    audience?.id,
  );
  useOnClickOutside(stickyRow?.element, (event) => {
    if (!didClickOnAntTooltip(event.target)) {
      setStickyRow(null);
    }
  });

  const sortFields = ROUTES.vendors.searchParams.sortBy.values;

  const handleSeriesToggle = (type: SERIES_TYPE, selected: boolean) => {
    setEnabledSeries((seriesList) => {
      if (selected) {
        return seriesList.concat(type);
      }
      return seriesList.filter((d) => d !== type);
    });
  };

  const handleScrollChange = () => {
    if (tableRef.current) {
      const scrollLeft =
        tableRef.current.nativeElement.querySelector('.ant-table-body')?.scrollLeft;
      tableRef.current.nativeElement
        .querySelectorAll('.ant-table-expanded-row-fixed .ant-table-content')
        .forEach((el) => {
          el.scrollLeft = scrollLeft ?? 0;
        });
    }
  };

  const handleNestedScrollChange = (e: React.UIEvent<HTMLDivElement>) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current.nativeElement.querySelector('.ant-table-body');
      if (scrollContainer) {
        scrollContainer.scrollLeft = e.currentTarget.scrollLeft;
      }
    }
  };

  // We use this to calculate bar percentage for each row.
  const maxVisits = useMemo(
    () =>
      data?.reduce((max, d) => {
        if (enabledSeries.indexOf(SERIES_TYPE.ALL) >= 0) {
          return Math.max(max, d.visits);
        }
        if (enabledSeries.indexOf(SERIES_TYPE.TAM) >= 0) {
          return Math.max(max, d.tamVisits);
        }
        if (enabledSeries.indexOf(SERIES_TYPE.AUDIENCE) >= 0) {
          return Math.max(max, d.audienceVisits);
        }
        return 0;
      }, 0),
    [data, enabledSeries],
  );

  const expandable = useMemo<ExpandableConfig<VendorActivityDataType> | undefined>(() => {
    return hasCampaignsFlag
      ? {
          mode: 'accordion',
          rowExpandable: (record) =>
            record.vendor.channel?.id !== 'chnl_direct' &&
            record.vendor.channel?.id !== 'chnl_referral',
          expandedRowRender: (record) => {
            setTimeout(() => {
              handleScrollChange();
            }, 0);
            return (
              <VendorTop5CampaignTable
                vendor={record.vendor}
                audience={audience}
                enabledSeries={enabledSeries}
                onScroll={handleNestedScrollChange}
              />
            );
          },
          expandRowByClick: true,
          durationMs: 300,
        }
      : undefined;
  }, [
    hasCampaignsFlag,
    audience,
    enabledSeries,
    handleNestedScrollChange,
    handleScrollChange,
    tableRef,
  ]);

  const columns: ColumnsType<VendorActivityDataType> = useMemo(
    () => [
      {
        title: t`Channel`,
        key: 'vendor.channel.name',
        fixed: 'left',
        width: 120,
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.channelById(field.vendor.channel?.id)}>
            {field.vendor.channel?.name}
          </Link>
        ),
      },
      {
        title: t`Vendor`,
        key: 'vendor.name',
        fixed: 'left',
        width: 120,
        render: (text, field) => (
          <Link variant="caption1" weight="bold" to={ROUTES.vendorById(field.vendor.id)}>
            {field.vendor.name}
          </Link>
        ),
      },
      {
        title: t`Spend`,
        key: 'spend',
        width: 110,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Impressions`,
        key: 'adImpressions',
        width: 100,
        align: 'right',
        render: (text) => numberFormat(text),
      },
      {
        title: t`Clicks`,
        key: 'adClicks',
        width: 90,
        align: 'right',
        render: (text) => numberFormat(text),
      },
      {
        title: t`Visits`,
        key: 'visits',
        width: 300,
        sorter: sortFields.indexOf('visits') >= 0,
        defaultSortOrder: 'descend',
        render: (text, field) => (
          <VendorActivityTableBarCell
            data={field}
            audience={audience}
            maxVisits={maxVisits}
            hoveredRow={hoveredRow}
            stickyRow={stickyRow?.record}
            enabledSeries={enabledSeries}
            onStickyTooltipClose={() => setStickyRow(null)}
          />
        ),
        onCell: (record) => ({
          style: { cursor: 'pointer' },
          onMouseEnter: () => setHoveredRow(record),
          onMouseLeave: () => setHoveredRow(null),
          onClick: (e) => {
            if (didClickOnAntTooltip(e.target)) {
              return;
            }

            // clicking on the same row again will close the tooltip
            if (stickyRow?.record === record) {
              setStickyRow(null);
            } else {
              setStickyRow({ element: e.currentTarget as HTMLElement, record });
            }
          },
        }),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        width: 110,
        align: 'right',
        render: (text, field) =>
          +text > 0 ? (
            <Link
              variant="caption1"
              to={ROUTES.webTrafficActivityWithParams({
                vendorIds: field.vendor.id,
                audienceIds: audience?.id,
                'metrics.visits': '[1,]',
              })}
            >
              {numberFormat(text)}
            </Link>
          ) : (
            0
          ),
      },
      {
        title: t`Pipeline Influenced`,
        key: 'pipelineInfluenced',
        sorter: sortFields.indexOf('pipelineInfluenced') >= 0,
        width: 120,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Closed Won Influenced`,
        key: 'closedWonInfluenced',
        sorter: sortFields.indexOf('closedWonInfluenced') >= 0,
        width: 120,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [data, maxVisits, audience, hoveredRow, enabledSeries, stickyRow],
  );

  return (
    <Panel size="L" title={<Trans>Visits By Vendor</Trans>} verifyC99Tag isFullHeight>
      <Legend style={{ marginInline: -32, marginBlockStart: -20, marginBlockEnd: 16 }}>
        {audience?.name && (
          <LegendItem
            color={AUDIENCE_PRIMARY}
            name={audience?.name}
            onChange={(selected) => handleSeriesToggle(SERIES_TYPE.AUDIENCE, selected)}
          />
        )}
        <LegendItem
          color={TAM_PRIMARY}
          name={t`TAM`}
          onChange={(selected) => handleSeriesToggle(SERIES_TYPE.TAM, selected)}
        />
        <LegendItem
          color={VISITS_PRIMARY}
          name={t`Visits`}
          onChange={(selected) => handleSeriesToggle(SERIES_TYPE.ALL, selected)}
        />
      </Legend>
      <InstantSearchTable<VendorActivityDataType>
        data-testid="vendors-table"
        className={styles.table}
        ref={tableRef}
        totalResults={totalResults}
        loading={isLoading}
        error={error}
        columns={columns}
        dataSource={data}
        emptyMessage={<Trans>No vendors found</Trans>}
        pagination={false}
        expandable={expandable}
        rowKey={(record) => `${record.vendor.channel?.id}-${record.vendor.id}`}
        sticky
        scroll={{ x: 1200 }}
        onScroll={handleScrollChange}
      />
    </Panel>
  );
};

export default VendorActivityTable;
