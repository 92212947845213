import { useContext } from 'react';

import { UserContext } from './User';

const useMe = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useMe must be used within a UserProvider');
  }
  return context.user;
};

export default useMe;
