import { useMemo } from 'react';

import { FilterOperatorMulti, FilterOperatorValue } from '@shared/InstantSearch';
import { CategoryFilter } from '@shared/filters';
import { useRegions } from 'api/settings';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const RegionFilter = ({ onChange, ...rest }: Props) => {
  const { regions, isLoading, error } = useRegions({ includeBlanks: true });

  const validRegions = useMemo(() => regions?.filter((region) => region.id !== '#N/A'), [regions]);

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={validRegions}
      onChange={onChange}
    />
  );
};

export default RegionFilter;
