import { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { ConfirmModal } from '@shared/modals';
import { Title } from '@shared/typography';

type Props = {
  name?: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const UserDeleteModal = ({ name, open, onClose, onDelete }: Props) => {
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = () => {
    setDeleting(true);
    onDelete();
  };

  useEffect(() => {
    if (open) {
      setDeleting(false);
    }
  }, [open]);

  return (
    <ConfirmModal
      open={open}
      okText={<Trans>Yes, Delete</Trans>}
      isLoading={isDeleting}
      onOk={handleDelete}
      onCancel={onClose}
    >
      <Trans>
        Are you sure you want to delete{' '}
        <Title level="4" weight="bold" inline>
          {name}
        </Title>
        ?
      </Trans>
    </ConfirmModal>
  );
};

export default UserDeleteModal;
