import { ReactNode, useRef } from 'react';

import Spin from '@shared/Spin';
import { Link, Text } from '@shared/typography';
import Warning from 'assets/svg/warning.svg?react';
import classNames from 'classnames';

import DeltaLabel from './DeltaLabel';
import styles from './KpiMetric.module.scss';
import KpiMetricLoader from './KpiMetricLoader';

type Props = {
  className?: string;
  color?: string;
  label: ReactNode;
  value?: number | string | null;
  valueLink?: string | false | 0;
  percentChange?: number | null | false;
  caption?: ReactNode;
  captionLink?: string | false;
  isLoading?: boolean;
  error: Error | undefined | null;
};

const KpiMetric = ({
  className = '',
  color = undefined,
  label,
  value,
  valueLink,
  percentChange,
  caption,
  captionLink,
  isLoading,
  error,
}: Props) => {
  const stickyValue = useRef<number | string | null>(null);

  if (value != null) {
    stickyValue.current = value;
  }

  const showSkeletonLoader = isLoading && stickyValue.current == null;
  const showSpinnerLoader = isLoading && stickyValue.current != null;

  return (
    <Spin wrapperClassName={styles.spinner} spinning={showSpinnerLoader}>
      <div
        className={classNames(styles.metric, className)}
        style={{ backgroundColor: color, borderColor: color }}
      >
        <Text className={styles.title} variant="footnote" color="semidark-grey">
          {label}
        </Text>
        {error ? (
          <Warning className={styles.warning} />
        ) : showSkeletonLoader ? (
          <KpiMetricLoader />
        ) : (
          <>
            <div className={styles.valueContainer}>
              {valueLink ? (
                <Link
                  to={valueLink}
                  className={styles.value}
                  variant="kpi"
                  weight="semi-bold"
                  title={stickyValue.current + ''}
                >
                  {stickyValue.current}
                </Link>
              ) : (
                <Text className={styles.value} variant="kpi" title={stickyValue.current + ''}>
                  {stickyValue.current}
                </Text>
              )}
              {percentChange !== false && percentChange !== undefined && (
                <DeltaLabel className={styles.deltaLabel} percentChange={percentChange} />
              )}
            </div>
            {
              <Text className={styles.caption} variant="caption1">
                {captionLink ? (
                  <Link to={captionLink} variant="caption1" color="static-black">
                    {caption ? caption : '\u00A0'}
                  </Link>
                ) : caption ? (
                  caption
                ) : (
                  '\u00A0'
                )}
              </Text>
            }
          </>
        )}
      </div>
    </Spin>
  );
};

export default KpiMetric;
