const WEB_STORAGE = {
  LAST_VISITED_ORG: `channel99-last_visited_org-${import.meta.env.VITE_ENVIRONMENT}`,
  FOREIGN_SUPERUSER_INSTANCE: `channel99-foreign_superuser_instance-${
    import.meta.env.VITE_ENVIRONMENT
  }`,
  DEFAULT_DATE_RANGE: `channel99-default-date-range-${import.meta.env.VITE_ENVIRONMENT}`,
};

export const getLastVisitedOrgSlug = () => localStorage.getItem(WEB_STORAGE.LAST_VISITED_ORG);
export const setLastVisitedOrgSlug = (orgSlug: string) =>
  localStorage.setItem(WEB_STORAGE.LAST_VISITED_ORG, orgSlug);
export const clearLastVisitedOrgSlug = () => localStorage.removeItem(WEB_STORAGE.LAST_VISITED_ORG);

export const getForeignSuperuserInstance = () =>
  localStorage.getItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);
export const setForeignSuperuserInstance = (instanceId: string) =>
  localStorage.setItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE, instanceId);
export const clearForeignSuperuserInstance = () =>
  localStorage.removeItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);

export const getDefaultDateRange = () => {
  const dateRange = sessionStorage.getItem(WEB_STORAGE.DEFAULT_DATE_RANGE);
  if (dateRange) {
    return JSON.parse(dateRange) as { start_date: string; end_date: string };
  }
  return null;
};
export const setDefaultDateRange = (dateRange: { start_date: string; end_date: string }) =>
  sessionStorage.setItem(WEB_STORAGE.DEFAULT_DATE_RANGE, JSON.stringify(dateRange));
export const removeDefaultDateRange = () =>
  sessionStorage.removeItem(WEB_STORAGE.DEFAULT_DATE_RANGE);

export const clearSessionStorage = () => sessionStorage.clear();
