import { t } from '@lingui/macro';
import { Page } from '@shared/page';

const CampaignOverview = () => {
  return (
    <Page title={t`Campaigns`} pageName={t`All Campaigns`}>
      WIP
    </Page>
  );
};

export default CampaignOverview;
