import { SKELETON_BACKGROUND_COLOR, SKELETON_FOREGROUND_COLOR } from 'constants/colors';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const SkeletonLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    foregroundColor={SKELETON_FOREGROUND_COLOR}
    backgroundColor={SKELETON_BACKGROUND_COLOR}
    speed={1.5}
    {...props}
  />
);

export default SkeletonLoader;
