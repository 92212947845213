import {
  BenchmarksWidget,
  CompanyActivityTopVisitsWidget,
  PipelineTrendsWidget,
  RecentDealsWidget,
  WebTrafficBreakdownByCompanySizeWidget,
  WebTrafficBreakdownByIndustryWidget,
  WebTrafficVisitByTypeTrendWidget,
  WebTrafficVisitsByTypeWidget,
} from './widgets';

const WidgetLibrary = {
  BENCHMARKS_WIDGET: BenchmarksWidget,
  COMPANY_ACTIVITY_TOP_VISITS_WIDGET: CompanyActivityTopVisitsWidget,
  PIPELINE_TRENDS_WIDGET: PipelineTrendsWidget,
  RECENT_DEALS_WIDGET: RecentDealsWidget,
  TRAFFIC_BREAKDOWN_BY_TYPE_WIDGET: WebTrafficVisitsByTypeWidget,
  TRAFFIC_BREAKDOWN_BY_INDUSTRY_WIDGET: WebTrafficBreakdownByIndustryWidget,
  TRAFFIC_BREAKDOWN_BY_COMPANY_SIZE_WIDGET: WebTrafficBreakdownByCompanySizeWidget,
  TRAFFIC_TRENDS_WIDGET: WebTrafficVisitByTypeTrendWidget,
};

export default WidgetLibrary;
