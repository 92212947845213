export const root = {
  path: '/',
  description: 'The root path of the application.',
  navigable: true,
};

export const app = {
  path: '/app',
  description: 'The main application page.',
  navigable: true,
};
