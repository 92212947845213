import { forwardRef, useLayoutEffect } from 'react';

import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useForwardedRef from 'hooks/useForwardedRef';

import { ChartCallbackFunction } from './types';

type Props = {
  callback?: ChartCallbackFunction;
  options: Options | null | undefined;
  measuredWidth?: number;
  measuredHeight?: number;
};

const HighchartsRenderer = forwardRef<HighchartsReact.RefObject, Props>(
  ({ callback, options, measuredWidth, measuredHeight }, ref) => {
    const chartRef = useForwardedRef<HighchartsReact.RefObject>(ref);

    useLayoutEffect(() => {
      const chart = chartRef.current?.chart;
      if (chart && measuredWidth && measuredHeight) {
        chart.setSize(measuredWidth, measuredHeight, false);
      }
    }, [measuredWidth, measuredHeight, chartRef.current?.chart]);

    return (
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        containerProps={{
          style: {
            width: '100%',
            height: '100%',
          },
        }}
        options={options}
        callback={callback}
      />
    );
  },
);

export default HighchartsRenderer;
