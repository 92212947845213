import { PropsWithChildren, ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import { Title } from '@shared/typography';
import { Space } from 'antd';
import Warning from 'assets/svg/warning.svg?react';
import classNames from 'classnames';

import styles from './PageError.module.scss';

type Props = {
  message?: ReactNode;
  detail?: ReactNode;
};

const PageError = ({ message, detail, children }: PropsWithChildren<Props>) => {
  return (
    <Space align="center" direction="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" className={styles.content} align="center" wrap size="large">
        <Warning className={styles.icon} />
        <Title level={1} weight="light">
          {message ? message : <Trans>An unknown error occurred.</Trans>}
        </Title>
        {detail != null && (
          <Title level={4} weight="light" className={classNames(styles.detail)}>
            {detail}
          </Title>
        )}
        {children}
      </Space>
    </Space>
  );
};

export default PageError;
