import { useMemo } from 'react';

import { t } from '@lingui/macro';
import Flare, { Donut, FlareSeriesOptions, Legend, Tooltip } from '@shared/Flare';
import { AudienceOptionType } from 'api/audience';
import { WebTrafficRevRangeSummaryDataType } from 'api/webTraffic';
import { WHITE } from 'constants/colors';
import { GRAY_600 } from 'constants/colors/semantic/gray';
import { GREEN_400, GREEN_500, GREEN_600 } from 'constants/colors/semantic/green';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  data?: WebTrafficRevRangeSummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error?: SolGraphQLError;
};

const WebTrafficCompanySizeChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'companies',
        name: t`Companies`,
        data: data.map((slice) => ({
          name: slice.revRange.shortName,
          data: slice.percentOfTotalCompanies,
          custom: {
            revRangeId: slice.revRange.id,
            visitCount: slice.count,
            companyCount: slice.engagedCompanies,
            visitPercent: slice.percentOfTotal,
          },
        })),
      },
    ];
  }, [data]);

  const totalCompanies = data && data.length > 0 ? data[0].companies : 0;

  return (
    <Flare
      data={seriesData}
      title={`<b>${numberFormat(totalCompanies)}</b><br/>${t`Companies`}`}
      width="auto"
      height="auto"
      colors={[GREEN_400, GREEN_600, GREEN_500, GRAY_600]}
      isLoading={isLoading}
      error={error}
    >
      <Legend showByCategory />
      <Donut
        y="data"
        dataLabelColor={WHITE}
        dataLabelFormat={(label) => {
          if (label.y && label.y >= 0.02) {
            return numberFormat(label.y, { isPercent: true, precision: 0 });
          }
          return '';
        }}
        hideConnector
        hiddenDataLabelThreshold={0}
      />
      <Tooltip
        titleFormat={(item) => item?.point.name}
        rowLabelFormat={(item) => `${item?.series.name}<br/>${t`Visits`}`}
        rowSecondaryValueFormat={(item) =>
          `(${numberFormat(item?.point.y, { isPercent: true })})<br/>(${numberFormat(item?.point.custom?.visitPercent, { isPercent: true })})`
        }
        rowValueFormat={(item) =>
          `${numberFormat(item?.point.custom?.companyCount)}<br/>${numberFormat(item?.point.custom?.visitCount)}`
        }
        valueLink={(item) => {
          return ROUTES.webTrafficActivityWithParams({
            'revRange.id': item?.point.custom?.revRangeId,
            'metrics.visits': '[1,]',
            audienceIds: audience?.id,
          });
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficCompanySizeChart;
