import { PropsWithChildren } from 'react';

import { t } from '@lingui/macro';
import { ActionIcon } from '@shared/buttons';
import { Text } from '@shared/typography';
import MenuCloseIcon from 'assets/svg/menu-close-icon.svg?react';
import classNames from 'classnames';

import styles from './MenuGroup.module.scss';

type Props = {
  className?: string;
  onMenuClose: () => void;
};

const MenuGroup = ({ className, children, onMenuClose, ...rest }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.group, className)} {...rest}>
      <ActionIcon
        className={styles.closeButton}
        color="black"
        size="small"
        icon={<MenuCloseIcon />}
        aria-label={t`Close Menu`}
        onClick={onMenuClose}
      />
      <Text variant="subhead" weight="bold">
        {children}
      </Text>
    </div>
  );
};

export default MenuGroup;
