import { plural, t } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { AudienceGroupedOptionType } from 'api/audience';
import {
  usePixelImpressionTrend,
  usePixelOpportunitiesInfluenced,
  usePixelReachEngagementTrend,
} from 'api/pixel';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';
import { WebTrafficSearchParamType } from 'router/searchParams/webTrafficSearchParams';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  startDate?: string;
  endDate?: string;
  isLoading: boolean;
};

const PixelDetailKPIMetrics = ({
  pixelIds,
  audience,
  startDate,
  endDate,
  isLoading: isPixelLoading,
}: Props) => {
  // TODO: Eventually we will support multiple audiences
  const audienceId = audience?.audienceIds?.[0];

  const {
    pixelReachEngagementTrend,
    isLoading: isReachEngagementLoading,
    error: reachEngagementError,
  } = usePixelReachEngagementTrend(pixelIds, startDate, endDate, audienceId);
  const {
    pixelImpressionTrend,
    isLoading: isImpressionTrendLoading,
    error: pixelImpressionError,
  } = usePixelImpressionTrend(pixelIds, startDate, endDate, audienceId);
  const {
    pixelOpportunitiesInfluenced,
    isLoading: isOpportunitiesInfluencedLoading,
    error: oppInfluencedError,
  } = usePixelOpportunitiesInfluenced(pixelIds, startDate, endDate, audienceId);

  const lastTrendItem = pixelReachEngagementTrend?.at(-1);
  const reached = lastTrendItem?.reach;
  const engaged = lastTrendItem?.engagement;
  const totalSize = lastTrendItem?.audienceSize;

  const defaultFilters: Partial<WebTrafficSearchParamType> = {
    pixelIds,
    audienceIds: audience?.id,
  };

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={t`Total Impressions`}
        value={pixelImpressionTrend && numberFormat(pixelImpressionTrend.totalImpressions)}
        isLoading={isImpressionTrendLoading || isPixelLoading}
        error={pixelImpressionError}
      />
      <KpiMetric
        label={t`Reached Companies`}
        value={reached && numberFormat(reached)}
        valueLink={
          !!reached &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'metrics.impressions': '[1,]',
          })
        }
        caption={
          reached &&
          totalSize &&
          numberFormat(reached / totalSize, { isPercent: true, precision: 1 })
        }
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Engaged Companies`}
        value={engaged && numberFormat(engaged)}
        valueLink={
          !!engaged &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'metrics.impressions': '[1,]',
            isEngaged: 'true',
          })
        }
        caption={
          engaged &&
          totalSize &&
          numberFormat(engaged / totalSize, { isPercent: true, precision: 1 })
        }
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Pipeline Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.pipeline.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.pipeline.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
      <KpiMetric
        label={t`Closed Won Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.recentDeals.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.recentDeals.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
    </KpiMetricGroup>
  );
};

export default PixelDetailKPIMetrics;
