import { Trans } from '@lingui/macro';
import { Text } from '@shared/typography';
import { Row } from 'antd';

import styles from './GlobalLoader.module.scss';

type Props = {
  isLoggingOut?: boolean;
};

const GlobalLoader = ({ isLoggingOut }: Props) => {
  return (
    <Row align="middle" justify="center" gutter={[0, 0]} className={styles.container}>
      <div className={styles.loader}>
        <svg
          width="204"
          height="204"
          viewBox="-19.25 -19.25 40.5 40.5"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#CDFD5F"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="0.5">
              <circle
                strokeOpacity=".5"
                cx="0"
                cy="0"
                r="20"
                stroke="rgba(255, 255, 255, 0.3)"
                strokeWidth="0.5"
              ></circle>
              <path d="M20 0c0-9.94-8.06-20-20-20">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 0 0"
                  to="360 0 0"
                  dur="1s"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </g>
          </g>
        </svg>
        <div className={styles.text}>
          <Text variant="subhead">
            {isLoggingOut ? (
              <Trans>Logging you out</Trans>
            ) : (
              <Trans>Loading up your C99 experience</Trans>
            )}
          </Text>
        </div>
      </div>
    </Row>
  );
};

export default GlobalLoader;
