import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Flare, { Axis, Column, Legend, Tooltip, linkifyAxisLabels } from '@shared/Flare';
import AxisLabel from '@shared/Flare/guides/AxisLabel';
import { Panel } from '@shared/panels';
import { AudienceOptionType } from 'api/audience';
import { useChannelsTrafficBreakdown } from 'api/channel';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';
import { WebTrafficSearchParamType } from 'router/searchParams/webTrafficSearchParams';

type Props = {
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
};

const ChannelsTrafficBreakdownChart = ({ startDate, endDate, audience }: Props) => {
  const navigate = useNavigate();
  const {
    channelsTrafficBreakdown: data,
    isLoading,
    error,
  } = useChannelsTrafficBreakdown(startDate, endDate, audience?.id);

  const filteredData = useMemo(
    () =>
      data
        ?.filter((d) => d.visits.all > 0 || d.visits.audience > 0 || d.visits.tam > 0)
        .sort((a, b) => b.visits.all - a.visits.all),
    [data],
  );

  const sanitizedData = useMemo(() => {
    if (!filteredData) {
      return undefined;
    }

    const standardColumns = [
      {
        id: 'visits',
        name: t`Visits`,
        zIndex: 1,
        data: filteredData.map((d) => ({ amount: d.visits.all })),
      },
      {
        id: 'tam',
        name: t`TAM`,
        zIndex: 2,
        data: filteredData.map((d) => ({ amount: d.visits.tam, custom: { all: d.visits.all } })),
      },
    ];

    if (audience?.id) {
      return standardColumns.concat({
        id: 'audience',
        name: audience?.name,
        zIndex: 3,
        data: filteredData.map((d) => ({
          amount: d.visits.audience,
          custom: { all: d.visits.all },
        })),
      });
    } else {
      return standardColumns;
    }
  }, [filteredData, audience]);

  return (
    <Panel
      size="L"
      title={<Trans>Visits By Channel</Trans>}
      noPadding
      style={{ height: 485 }}
      verifyC99Tag
    >
      <Flare
        data-testid="traffic-breakdown-chart"
        data={sanitizedData}
        description={t`Number of total visits, TAM visits, and selected audience visits`}
        colors={[VISITS_PRIMARY, TAM_PRIMARY, AUDIENCE_PRIMARY]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend reversed />
        <Column
          y="amount"
          position="overlap"
          skeletonLoaderOptions={{
            numCategories: 7,
            numSeries: audience?.id ? 3 : 2,
            sortDescending: true,
          }}
        />
        <Axis position="left" labelFormat={(item) => numberFormat(item.value)} />
        <Axis
          position="bottom"
          categories={filteredData?.map((d) => d.channel.name)}
          labelFormat={(item) => {
            const { channel } = filteredData?.[item.pos] || {};
            if (!channel?.id) {
              return '';
            }
            return renderToStaticMarkup(
              <AxisLabel label={channel.name} linkUrl={ROUTES.channelById(channel.id)} />,
            );
          }}
          useHTML
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => item?.key}
          rowValueFormat={(item) => numberFormat(item?.y)}
          rowSecondaryValueFormat={(item) =>
            item?.colorIndex !== 0
              ? `(${numberFormat((item?.y ?? 0) / item?.point.custom?.all, {
                  isPercent: true,
                  precision: 1,
                })})`
              : ''
          }
          valueLink={(item) => {
            if (!item?.y || item?.point.x == null || !filteredData) {
              return null;
            }

            const channel = filteredData[item.point.x];

            const linkParams: Partial<WebTrafficSearchParamType> = {
              channelIds: channel.channel.id,
              'metrics.visits': '[1,]',
            };

            switch (item?.colorIndex) {
              case 0:
                return ROUTES.webTrafficActivityWithParams(linkParams);
              case 1:
                return ROUTES.webTrafficActivityWithParams({
                  ...linkParams,
                  inTam: 'true',
                });
              case 2:
                return ROUTES.webTrafficActivityWithParams({
                  ...linkParams,
                  audienceIds: audience?.id,
                });
              default:
                return null;
            }
          }}
          unstable_hasRouterContext={false}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default ChannelsTrafficBreakdownChart;
