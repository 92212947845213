import { FilterOperatorMulti, FilterOperatorValue } from '@shared/InstantSearch';
import { CategoryFilter } from '@shared/filters';
import { useSectors } from 'api/settings';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const SectorFilter = ({ onChange, ...rest }: Props) => {
  const { sectors, isLoading, error } = useSectors({ includeBlanks: true });

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={sectors}
      onChange={onChange}
    />
  );
};

export default SectorFilter;
