import { HEADER_HEIGHT, PAGE_WIDTH_NO_SIDER, SIDER_WIDTH } from 'constants/dimensions';
import Highcharts, { ChartPositionObject, Pointer, PositionObject, Tooltip } from 'highcharts';

export const adjustPositionByContainer = (positionObj: PositionObject): PositionObject => {
  const scrollParent = document.getElementById('pageRoot')?.parentElement;
  const scrollTop = scrollParent?.scrollTop || 0;
  const scrollLeft = scrollParent?.scrollLeft || 0;
  const isSiderVisible = document.body.offsetWidth >= PAGE_WIDTH_NO_SIDER;

  return {
    x: positionObj.x - (isSiderVisible ? SIDER_WIDTH : 0) + scrollLeft,
    y: positionObj.y - HEADER_HEIGHT + scrollTop,
  };
};

/**
 * By default, Highcharts places the tooltip container at the end of `body`. This function moves it
 * to the #pageRoot element instead so it is inside the scrollable main content area. Without this
 * override, the tooltip will stay in a fixed position when the page is scrolled.
 */
Highcharts.wrap(Highcharts.Tooltip.prototype, 'getLabel', function (this: Tooltip, proceed) {
  // eslint-disable-next-line prefer-rest-params
  const result = proceed.apply(this, Array.prototype.slice.call(arguments, 1));

  // Add the tooltip container to the #pageRoot element instead of `body`.
  if (this.container?.parentElement === document.body) {
    document.getElementById('pageRoot')?.appendChild(this.container);
  }
  return result;
});

/**
 * Since we are placing the tooltip container inside the #pageRoot element, we need to adjust the
 * position of the tooltip to account for the header and sider. Highcharts assumes the parent
 * always starts at (0, 0) coordinates. In our case, the parent div is right of the Sider and below
 * the Header so we need to adjust where Highcharts thinks the chart is positioned to account for
 * that.
 */
Highcharts.wrap(Highcharts.Tooltip.prototype, 'getPosition', function (this: Tooltip, proceed) {
  const originalGetChartPosition = this.chart.pointer.getChartPosition;

  // A function which overrides where Highcharts thinks the chart is positioned to account for the
  // header and sider.
  this.chart.pointer.getChartPosition = function (this: Pointer): ChartPositionObject {
    const chartPosition = originalGetChartPosition.call(this);
    const newPosition = adjustPositionByContainer({ x: chartPosition.left, y: chartPosition.top });

    return {
      ...chartPosition,
      left: newPosition.x,
      top: newPosition.y,
    };
  };

  // When we call the original `getPosition` function, it will call our overridden
  // `getChartPosition` instead of the original one.
  // eslint-disable-next-line prefer-rest-params
  const result = proceed.apply(this, Array.prototype.slice.call(arguments, 1));

  // Put the function back so other Highcharts components can use it.
  this.chart.pointer.getChartPosition = originalGetChartPosition;

  return result;
});
