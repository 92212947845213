import { Trans } from '@lingui/macro';
import { PipelineTrendsWidget } from '@shared/Dashboard/WidgetLibrary';
import { NotesPanel } from '@shared/panels';

type Props = {
  startDate?: string;
  endDate?: string;
};

const PipelineTrendsChart = ({ startDate, endDate }: Props) => {
  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      title={<Trans>Trends</Trans>}
      size="L"
      style={{ height: 415 }}
      noPadding
    >
      <PipelineTrendsWidget dashboardFilters={{ start_date: startDate, end_date: endDate }} />
    </NotesPanel>
  );
};

export default PipelineTrendsChart;
