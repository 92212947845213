import { PropsWithChildren, ReactNode } from 'react';

import { Text } from '@shared/typography';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import classNames from 'classnames';

import styles from './Tooltip.module.scss';

type Props = {
  title?: ReactNode;
  body: ReactNode;
  placement?: TooltipPlacement;
  xOffset?: number;
  yOffset?: number;
  pointerEvents?: boolean;
};

const Tooltip = ({
  title,
  body,
  placement = 'bottom',
  xOffset,
  yOffset,
  pointerEvents = true,
  children,
}: PropsWithChildren<Props>) => {
  const offset = xOffset != null || yOffset != null ? [xOffset || 0, yOffset || 0] : undefined;

  return (
    <AntdTooltip
      overlayClassName={classNames(styles.overlay, {
        [styles.noPointerEvents]: pointerEvents === false,
      })}
      placement={placement}
      align={offset && { offset }}
      showArrow={false}
      title={
        <div className={styles.tooltip}>
          {title && (
            <Text variant="caption1" color="light-green">
              {title}
            </Text>
          )}
          <Text variant="body2" color="white">
            {body}
          </Text>
        </div>
      }
    >
      {children}
    </AntdTooltip>
  );
};

export default Tooltip;
