export const AQUA_100 = '#BDE5E8';
export const AQUA_200 = '#9BD7DD';
export const AQUA_300 = '#7ACAD1';
export const AQUA_400 = '#59BDC6';
export const AQUA_500 = '#47979E';
export const AQUA_600 = '#357677';
export const AQUA_700 = '#244C4F';
export const AQUA_800 = '#122628';

export const ALL_AQUAS = [
  AQUA_100,
  AQUA_200,
  AQUA_300,
  AQUA_400,
  AQUA_500,
  AQUA_600,
  AQUA_700,
  AQUA_800,
];

export const ALL_SEMANTIC_AQUAS = [AQUA_300, AQUA_400, AQUA_500, AQUA_600, AQUA_700, AQUA_800];
