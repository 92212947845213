import { Column } from '@silevis/reactgrid';

const CHANNEL_COLUMN_ID = 'channel';
const VENDOR_COLUMN_ID = 'vendor';

const FIXED_COLUMNS_WIDTH = 116;
const EDITABLE_COLUMN_MIN_WIDTH = 92;

// TODO: rethink this in the future
const COL_PADDING = 62;
const AVERAGE_LETTER_SIZE = 7;

export const fixedTableColumns = (width?: number): Column[] => {
  const colWidth = width && width > FIXED_COLUMNS_WIDTH ? width : FIXED_COLUMNS_WIDTH;
  return [
    { columnId: CHANNEL_COLUMN_ID, width: colWidth },
    { columnId: VENDOR_COLUMN_ID, width: colWidth },
  ];
};

export const getColumnItem = (columnId: number, isEOY = false, width?: number): Column => {
  const colWidth = width && width > EDITABLE_COLUMN_MIN_WIDTH ? width : EDITABLE_COLUMN_MIN_WIDTH;
  return {
    columnId,
    width: isEOY ? colWidth + 2 : colWidth,
  };
};

export const getColumnWidth = (data: string): number =>
  data.length * AVERAGE_LETTER_SIZE + COL_PADDING;
