import { useEffect, useMemo } from 'react';

import { t } from '@lingui/macro';
import closeIcon from 'assets/svg/bx-x.svg';
import { adjustPositionByContainer } from 'helper/highcharts-tooltip-extension';
import { numberFormat } from 'helper/numberFormatter';
import {
  TooltipPositionerCallbackFunction as DefaultPositionerCallbackFunction,
  Point,
  TooltipFormatterContextObject,
} from 'highcharts';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

import { useFlareContext } from '../FlareContext';
import {
  FlareChart,
  SanitizedFlareProps,
  TooltipFormatFunction,
  TooltipLinkFunction,
  TooltipPositionerCallbackFunction,
} from '../types';
import styles from './Tooltip.module.scss';
import TooltipRow from './TooltipRow';
import TooltipTitle from './TooltipTitle';

type TooltipProps = {
  shared?: boolean;
  rowLabelFormat?: TooltipFormatFunction;
  rowValueFormat?: TooltipFormatFunction;
  valueLink?: TooltipLinkFunction;
  unstable_hasRouterContext?: boolean;
  rowSecondaryValueFormat?: TooltipFormatFunction;
  titleFormat?: TooltipFormatFunction;
  positioner?: TooltipPositionerCallbackFunction;
  showTotalRow?: boolean;
  reversed?: boolean;
  stickyOnClick?: boolean;
};

const stickyTooltipRemove = (chart: FlareChart, showHoverTooltips = false) => {
  const tooltipContainer = chart?.tooltip?.container;

  if (!chart || !chart.stickyTooltip || !tooltipContainer) {
    return;
  }

  chart.stickyTooltip?.element.remove();
  chart.stickyTooltip = undefined;

  chart.getSelectedPoints().forEach((selectedPoint) => {
    selectedPoint.select(false, true);
    chart.getSelectedPoints().forEach((selectedPoint) => {
      selectedPoint.setState('hover');
    });
  });

  if (showHoverTooltips) {
    tooltipContainer.classList.remove(styles.highchartsTooltipContainerHidden);
  }
};

const drawTooltipOnPointX = (
  chart: FlareChart,
  xValue: number | string,
  onLinkClick: (url: string) => void,
) => {
  const tooltipContainer = chart?.tooltip?.container;

  if (!chart || !tooltipContainer) {
    return;
  }

  const clonedTooltipContainer = tooltipContainer.cloneNode(true) as HTMLElement;
  clonedTooltipContainer.classList.remove(
    'highcharts-tooltip-container', // use our class instead
    styles.highchartsTooltipContainerHidden,
  );
  clonedTooltipContainer.classList.add(styles.stickyTooltipContainer);

  const linkedLabels = clonedTooltipContainer.querySelectorAll<HTMLSpanElement>('span[id]');

  linkedLabels.forEach((label) => {
    const url = label.getAttribute('id');
    label.style.cursor = 'pointer';
    if (label && url) {
      // turn it into a link
      label.onclick = () => onLinkClick(url);
    }
  });

  const tooltipCloseButtonParent = clonedTooltipContainer.querySelector(
    '.highcharts-tooltip > span',
  ) as HTMLElement;

  const closeButton = document.createElement('button');
  closeButton.classList.add(styles.closeTooltipButton);
  closeButton.onclick = () => {
    stickyTooltipRemove(chart, true);
  };
  const closeButtonIcon = document.createElement('img');
  closeButtonIcon.src = closeIcon;
  closeButtonIcon.alt = t`Close`;
  closeButtonIcon.classList.add(styles.closeTooltipButtonIcon);
  closeButton.append(closeButtonIcon);
  tooltipCloseButtonParent.appendChild(closeButton);

  // Insert the sticky tooltip in the scrollable main content container. `body` is not scrollable.
  (document.getElementById('pageRoot') ?? document.body).appendChild(clonedTooltipContainer);

  // save tooltip object
  chart.stickyTooltip = {
    xValue,
    element: clonedTooltipContainer,
  };

  tooltipContainer.classList.add(styles.highchartsTooltipContainerHidden);

  chart.hoverPoints?.forEach((hoverPoint, index) => {
    hoverPoint.select(true, index > 0);
  });
};

const stickyTooltipAdd = (point: Point | null, onLinkClick: (url: string) => void) => {
  const chart = point?.series.chart as FlareChart;
  if (!point || !chart) {
    return;
  }

  if (chart.stickyTooltip) {
    const isSamePoint = chart.stickyTooltip.xValue === point.x;
    stickyTooltipRemove(chart, isSamePoint);
    if (isSamePoint) {
      chart.hoverPoints?.forEach((hoverPoint) => {
        hoverPoint.setState('hover');
      });
      // don't redraw another sticky tooltip if the user clicked on the same x position twice.
      // Effectively makes this a toggle.
      return;
    }
  }

  drawTooltipOnPointX(chart, point.x, onLinkClick);
};

const buildTooltipOptions = (
  options: Highcharts.Options,
  parentProps: SanitizedFlareProps,
  props: TooltipProps,
  onLinkClick: (url: string) => void,
) => {
  const { colors } = parentProps;
  const {
    shared,
    rowLabelFormat,
    rowValueFormat,
    valueLink,
    unstable_hasRouterContext = true,
    rowSecondaryValueFormat = null,
    titleFormat = null,
    positioner,
    showTotalRow = false,
    reversed = false,
    stickyOnClick = true,
  } = props;

  // Avoid using `this` context variable which arrow functions cannot utilize. The `positioner`
  // function should be an arrow function, therefore pass `this` as first param.
  const defaultTooltipPositioner: DefaultPositionerCallbackFunction | undefined = positioner
    ? function (labelWidth, labelHeight, point) {
        return adjustPositionByContainer(positioner(this, labelWidth, labelHeight, point));
      }
    : undefined;

  const config: Highcharts.Options = {
    ...options,
    tooltip: {
      enabled: true,
      className: styles.tooltipContainer,
      useHTML: true,
      shared,
      outside: true,
      followPointer: false,
      positioner: defaultTooltipPositioner,
      style: {
        pointerEvent: 'none',
        zIndex: 1000,
      },
      backgroundColor: 'none',
      borderColor: 'none',
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      formatter: shared
        ? function (this: TooltipFormatterContextObject) {
            // Some marks don't have `points` on Tooltip (Donut). We'll recreate those Tooltip
            // point objects here if that's the case.
            const points =
              this.points ??
              this.series.points.map((point) => ({ ...this, ...point, key: point.name, point }));
            const dataPoints = reversed ? points.reverse() : points;

            // Tooltip showing all data point values
            return renderToStaticMarkup(
              <table className={styles.tooltip}>
                {titleFormat && (
                  <thead>
                    <TooltipTitle>{titleFormat(this)}</TooltipTitle>
                  </thead>
                )}
                {rowValueFormat && (
                  <tbody>
                    {dataPoints?.map((item, index) => (
                      <TooltipRow
                        key={index}
                        name={rowLabelFormat ? rowLabelFormat(item) + '' : item.series.name}
                        value={rowValueFormat(item)}
                        valueLink={valueLink?.(item)}
                        unstable_hasRouterContext={unstable_hasRouterContext}
                        secondaryValue={rowSecondaryValueFormat?.(item)}
                        color={(item.color as string) || colors[item.colorIndex]}
                      />
                    ))}
                    {showTotalRow && (
                      <TooltipRow
                        name={t`Total`}
                        value={numberFormat(
                          dataPoints?.reduce<number>((acc, item) => acc + (item?.y ?? 0), 0) ?? 0,
                        )}
                      />
                    )}
                  </tbody>
                )}
              </table>,
            );
          }
        : function (this: TooltipFormatterContextObject) {
            // Tooltip showing only the hovered data point's value
            return renderToStaticMarkup(
              <table className={styles.tooltip}>
                {titleFormat && (
                  <thead>
                    <TooltipTitle>{titleFormat(this)}</TooltipTitle>
                  </thead>
                )}
                {rowValueFormat && (
                  <tbody>
                    <TooltipRow
                      name={rowLabelFormat ? rowLabelFormat(this) + '' : this.series.name}
                      value={rowValueFormat(this)}
                      valueLink={valueLink?.(this)}
                      unstable_hasRouterContext={unstable_hasRouterContext}
                      secondaryValue={rowSecondaryValueFormat?.(this)}
                      color={(this.color as string) || colors[this.colorIndex]}
                    />
                  </tbody>
                )}
              </table>,
            );
          },
    },
  };

  if (stickyOnClick) {
    config.plotOptions = {
      ...config.plotOptions,
      series: {
        ...config.plotOptions?.series,
        cursor: 'pointer',
        point: {
          ...config.plotOptions?.series?.point,
          events: {
            ...config.plotOptions?.series?.point?.events,
            click: function (event) {
              if (options.plotOptions?.series?.point?.events?.click) {
                options.plotOptions?.series?.point?.events?.click.call(this, event);
              }

              stickyTooltipAdd(this, onLinkClick);
            },
          },
        },
      },
    };

    // Don't add this if we're dealing with a donut chart. Donut charts shouldn't click background.
    config.chart = {
      ...options.chart,
      className: styles.stickyTooltipChart,
      events: {
        ...options.chart?.events,
        click: function (event) {
          if (options.chart?.events?.click) {
            options.chart.events.click.call(this, event);
          }

          if (this.hoverPoint) {
            stickyTooltipAdd(this.hoverPoint, onLinkClick);
          } else {
            stickyTooltipRemove(this as FlareChart, true);
          }
        },
      },
    };
  }

  return config;
};

const Tooltip = (props: TooltipProps) => {
  const navigate = useNavigate();
  const { id, registerChild, chart } = useFlareContext();
  const stickyOnClick = props.stickyOnClick == null || props.stickyOnClick;

  props = useMemo(() => {
    return {
      ...props,
      stickyOnClick,
    };
  }, [props, stickyOnClick]);

  useOnClickOutside(chart?.container, (event) => {
    if (chart?.stickyTooltip && !chart.stickyTooltip.element.contains(event.target as Node)) {
      stickyTooltipRemove(chart as FlareChart, true);
    }
  });

  useEffect(() => {
    registerChild(id, (options: Highcharts.Options, parentProps: SanitizedFlareProps) =>
      buildTooltipOptions(options, parentProps, props, (url: string) => navigate(url)),
    );
  }, [props]);

  useEffect(() => {
    return () => {
      stickyTooltipRemove(chart as FlareChart);
      document.querySelectorAll(`.${styles.stickyTooltipContainer}`).forEach((el) => el.remove());
    };
  }, [chart]);

  return null;
};

export default Tooltip;
