import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { ReturnToLink } from '@shared/ReturnToLink';
import Error404 from '@shared/errors/Error404';
import { Page } from '@shared/page';
import { Space } from 'antd';
import { useMultiplePixelTargetingEfficiency } from 'api/pixel';
import SolGraphQLError from 'error/SolGraphQLError';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'router';

import PixelMultiCostPerVisitChart from './PixelMultiCostPerVisitChart';
import PixelMultiKPIMetrics from './PixelMultiKPIMetrics';
import PixelMultiOpportunityInfluenceChart from './PixelMultiOpportunityInfluenceChart';
import PixelMultiReachEngagementChart from './PixelMultiReachEngagementChart';
import PixelMultiTargetingEfficiencyChart from './PixelMultiTargetingEfficiencyChart';

const PixelCompare = () => {
  const { pixels: pixelIdString } = useParams();
  const pixelIds = pixelIdString?.split(',');
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});
  const { error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    filters.start_date,
    filters.end_date,
  );

  return (
    <Page title={t`Pixel Compare`}>
      <Space direction="vertical" size="large">
        <ReturnToLink route={ROUTES.pixels.path} routeName={<Trans>Pixel Overview</Trans>} />
        {error instanceof SolGraphQLError && error.is404() ? (
          <Error404
            message={<Trans>One or more of the pixels you're looking for do not exist</Trans>}
          />
        ) : (
          <>
            <DashboardFilterProvider
              dashboardFilters={filters}
              onDashboardFiltersChange={setDashboardFilters}
            >
              <DashboardDateRangeFilter />
            </DashboardFilterProvider>
            <PixelMultiKPIMetrics
              pixelIds={pixelIds}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <PixelMultiReachEngagementChart
              pixelIds={pixelIds}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <PixelMultiOpportunityInfluenceChart
              pixelIds={pixelIds}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <PixelMultiTargetingEfficiencyChart
              pixelIds={pixelIds}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <PixelMultiCostPerVisitChart
              pixelIds={pixelIds}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
          </>
        )}
      </Space>
    </Page>
  );
};

export default PixelCompare;
