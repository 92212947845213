import { useState } from 'react';

import { t } from '@lingui/macro';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { Page } from '@shared/page';
import { Space } from 'antd';

import BenchmarksByChannel from './BenchmarksByChannel';

const BenchmarksOverview = () => {
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Web Traffic`} pageName={t`Benchmarks`}>
      <Space direction="vertical" size="large">
        <DashboardFilterProvider
          dashboardFilters={dashboardFilters}
          onDashboardFiltersChange={setDashboardFilters}
        >
          <DashboardDateRangeFilter />
        </DashboardFilterProvider>
        <BenchmarksByChannel dashboardFilters={dashboardFilters} />
      </Space>
    </Page>
  );
};

export default BenchmarksOverview;
