import { PropsWithChildren } from 'react';

import NotificationWrapper from '@pages/Home/NotificationWrapper';
import { Row } from 'antd';
import HeaderSetter from 'hooks/header/HeaderSetter';

import styles from './FullPage.module.scss';
import Layout from './Layout';
import { PageHeader } from './PageHeader';

const { Content } = Layout;

type Props = {
  title?: string;
};

/**
 * Use this component for pages outside of /app which take up the full screen and do not use header
 * or sidebar.
 */
const FullPage = ({ title, children }: PropsWithChildren<Props>) => {
  return (
    <Layout>
      {title && <HeaderSetter hideHeaderTitle>{title}</HeaderSetter>}
      <PageHeader blackLogo />
      <Content>
        <NotificationWrapper />
        <Row align="middle" justify="center" gutter={[0, 0]}>
          <div className={styles.page}>{children}</div>
        </Row>
      </Content>
    </Layout>
  );
};

export default FullPage;
