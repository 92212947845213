import { useState } from 'react';

import { DefaultOptionType } from 'antd/es/select';
import { OptionType } from 'api/common';

import { Option, Select, SelectProps } from '../Select';

export interface MultiSelectProps<T> extends Omit<SelectProps, 'mode' | 'options'> {
  defaultValue?: T[];
  value?: T[];
  isLoading?: boolean;
  options?: T[];
}

const MultiSelect = <T extends OptionType>({
  defaultValue,
  value: valueFromProps,
  children,
  options,
  isLoading,
  onChange,
  ...rest
}: MultiSelectProps<T>) => {
  const isControlled = typeof valueFromProps !== 'undefined';
  const hasDefaultValue = typeof defaultValue !== 'undefined';

  const [internalValue, setInternalValue] = useState<T[] | undefined>(
    hasDefaultValue ? defaultValue : undefined,
  );

  const value = isControlled ? valueFromProps : internalValue;

  const handleChange = (
    val: DefaultOptionType,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (Array.isArray(option)) {
      const mappedOption = option
        .filter((opt) => typeof opt.children === 'string')
        .map((opt) => ({ id: opt.value + '', name: opt.children + '' }) as T);

      onChange?.(mappedOption, option);

      if (!isControlled) {
        setInternalValue(mappedOption);
      }
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={(value || [])?.map((val) => ({ value: val?.id, label: val?.name }))}
      mode="multiple"
      allowClear
      onChange={handleChange}
      labelInValue
      showSearch
      {...rest}
    >
      {options
        ? (options || []).map((option) => (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ))
        : children}
    </Select>
  );
};

export default MultiSelect;
