import { Trans, t } from '@lingui/macro';
import { MultiSelect, MultiSelectProps } from '@shared/Form';
import { RegionType } from 'api/common';
import { useRegions } from 'api/settings';

const RegionMultiSelect = (props: MultiSelectProps<RegionType>) => {
  const { regions, isLoading } = useRegions();

  return (
    <MultiSelect
      placeholder={t`Select Regions...`}
      notFoundContent={<Trans>No regions were found...</Trans>}
      {...props}
      options={regions}
      isLoading={isLoading}
    />
  );
};

export default RegionMultiSelect;
