import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';

import { fetcherSol } from '../swr-fetcher';
import { HealthCenterGQLResponse } from './healthCenterType';

export const useHealthCenter = () => {
  const { data, error, isLoading } = useSWR<HealthCenterGQLResponse, SolGraphQLError>(
    {
      query: gql`
        query HealthCenterStatus {
          healthCenter {
            status {
              channelMapping {
                isAvailable
                isComplete
                isFree
                label
                updatedAt
                updatedBy {
                  id
                  name
                }
              }
              customAudience {
                createdAt
                createdBy {
                  id
                  name
                }
                isAvailable
                isComplete
                isFree
                label
              }
              onboardingProgress
              salesforce {
                isAvailable
                isComplete
                isFree
                label
                lastSynced
              }
              tag {
                isAvailable
                isComplete
                isFree
                label
                lastEventAt
                tagInstalledAt
              }
              tam {
                isAvailable
                isComplete
                isFree
                label
                updatedAt
                updatedBy {
                  id
                  name
                }
              }
              universalPixel {
                createdBy {
                  id
                  name
                }
                isAvailable
                isComplete
                isFree
                label
                lastCreatedAt
              }
              userAdmin {
                isAvailable
                isComplete
                isFree
                label
                lastUserAddedAt
              }
              vendorSpend {
                isAvailable
                isComplete
                isFree
                label
                spendInputThroughAt
                lastUpdatedBy {
                  id
                  name
                }
              }
            }
          }
        }
      `,
    },
    fetcherSol,
    {},
  );

  return {
    healthCenterData: data?.healthCenter.status,
    isLoading,
    error,
  };
};
