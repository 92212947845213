import { PropsWithChildren } from 'react';

import { ConfigProvider } from 'antd';
import { AQUA_400 } from 'constants/colors/semantic/aqua';
import { GREEN_400, GREEN_500 } from 'constants/colors/semantic/green';
import { RED_400 } from 'constants/colors/semantic/red';
import { YELLOW_400 } from 'constants/colors/semantic/yellow';

const AntDConfigProvider = (props: PropsWithChildren) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: GREEN_500,
          fontFamily: `'Inter', sans-serif`,

          colorSuccess: GREEN_400,
          colorError: RED_400,
          colorWarning: YELLOW_400,
          colorInfo: AQUA_400,
          colorLink: AQUA_400,
        },
      }}
      {...props}
    />
  );
};

export default AntDConfigProvider;
