import {
  ALL_WEB_TRAFFIC_SEARCH_PARAM_KEYS,
  WEB_TRAFFIC_ACTIVITY_SORT_KEYS,
  WebTrafficSearchParamType,
} from 'router/searchParams/webTrafficSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from 'router/types';

export const webTraffic = {
  path: '/app/web-traffic',
  description:
    "Shows web traffic for the company's tagged website. Overall traffic over time, visits broken down by traffic type, industry, and company size, and a list of the most frequently visited companies",
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the channels by',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const closedWon = {
  path: '/app/closed-won',
  description:
    'Shows an individual closed-won opportunity details page. If no opportunityId is provided, the most recent closed-won opportunity is displayed. Visits are shown throughout the lifecycle of the opportunity up until the closed date when the opportunity was closed as won. Metrics such as unique visitors and vendors who contributed to the traffic are also shown.',
  navigable: true,
};

export const closedWonDetail = {
  path: '/app/closed-won/:opportunityId?',
  description: 'Shows an individual closed-won opportunity details page.',
  navigable: true,
};

export const pipeline = {
  path: '/app/pipeline',
  description:
    'Shows statistics on all pipeline opportunities such as return on spend, cost per opportunity, pipeline influenced revenue, pipeline spend, channel return on spend, and vendor influence and return on spend. All from a pipeline opportunities point of view.',
  navigable: true,
};

export const webTrafficBenchmarks = {
  path: '/app/web-traffic-benchmarks',
  description:
    'Shows web traffic benchmarks for the company broken down by channels. The benchmarks are based on the company’s industry and size. The benchmarks are compared to traffic from other companies in the same industry to show marketers how they are performing relative to other companies in the same space.',
  navigable: true,
};

export const webTrafficActivity = {
  path: '/app/web-traffic-activity',
  description:
    "Shows a list of all companies that have visited the company's website during a specific date range. The list shows the name of the company, region, industry, revenue range (size), audiences it belongs to, number of impressions, number of visits, and a timestamp for the last visit. The list can be filtered and sorted.",
  navigable: true,
  searchParams: {
    audienceIds: {
      type: 'string[]',
      description: 'A comma-separated string of audience IDs',
    },
    vendorIds: {
      type: 'string[]',
      description: 'A comma-separated string of vendor IDs',
    },
    channelIds: {
      type: 'string[]',
      description: 'A comma-separated string of channel IDs',
    },
    pixelIds: {
      type: 'string[]',
      description: 'A comma-separated string of pixel IDs',
    },
    search: {
      type: 'string',
      description: 'A string to search for in the company name',
    },
    ['region.id']: {
      type: 'string[]',
      description: 'The comma-separated string of region IDs',
    },
    ['sector.id']: {
      type: 'string[]',
      description: 'The comma-separated string of sector IDs',
    },
    ['revRange.id']: {
      type: 'string[]',
      description: 'The comma-separated string of revenue range IDs',
    },
    ['metrics.impressions']: {
      type: 'range',
      description:
        'A range of impressions from min to max. Both are inclusive. At least one must be provided. Use square brackets. Example: [0,10] means 0 <= impressions <= 10 or [10,] means impressions >= 10',
    },
    ['metrics.visits']: {
      type: 'range',
      description:
        'A range of visits from min to max. Both are inclusive. At least one must be provided. Use square brackets. Example: [0,10] means 0 <= visits <= 10 or [10,] means visits >= 10',
    },
    inTam: {
      type: 'boolean',
      description: 'Whether the company is in the TAM',
    },
    isEngaged: {
      type: 'boolean',
      description:
        'To only be used with a pixelIds filter. Indicates whether a company is engaged.',
    },
    sortBy: {
      type: 'enum',
      values: [
        'name',
        'region.id',
        'sector.id',
        'revRange.id',
        'metrics.impressions',
        'metrics.visits',
        'metrics.lastActivityDate',
      ],
      default: 'metrics.lastActivityDate',
      description: 'The field to sort by. Only one field can be sorted at a time.',
    } satisfies RouteSearchParamEnumType<WEB_TRAFFIC_ACTIVITY_SORT_KEYS>,
    sortOrder: {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description:
        'The sort order. Fields with type `number` or `date` are usually best sorted in descending order. While string fields are best sorted in ascending order.',
    },
    page: {
      type: 'string',
      description: 'The page number to display',
    },
    size: {
      type: 'string',
      description: 'The number of items to display per page',
    },
  },
} satisfies NavigableRouteType<ALL_WEB_TRAFFIC_SEARCH_PARAM_KEYS>;

export const closedWonById = (opportunityId: string) => `${closedWon.path}/${opportunityId}`;

/**
 * Returns a URL string for the web traffic activity route with the given search params.
 *
 * @param params An object with keys that match the search params for the web traffic activity
 * route. Undefined values are skipped.
 * @returns A URL string with the search params appended as query params
 */
export const webTrafficActivityWithParams = (params: Partial<WebTrafficSearchParamType>) => {
  const nonNullFilters = Object.entries(params).reduce(
    (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
    {},
  );
  const query = new URLSearchParams(nonNullFilters as Record<string, string>);
  return `${webTrafficActivity.path}?${query.toString()}`;
};
