import { NavigableRouteType } from 'router/types';

export const campaigns = {
  path: '/app/campaigns',
  description:
    'Displays metrics for each campaign. An overview of each campaign is shown with the channel and vendor it belongs to, total spend, pixel impressions, pixel click-throughs, visits, companies who have engaged with the campaign, pipeline opportunities influenced, and closed-won opportunities influenced. The campaign can be broken down by the audiences that are targeted through that campaign. A fit score visualization is also displayed which shows how well the campaign is performing relative to other campaigns in the same space.',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the channels by',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const vendorDetail = {
  path: '/app/campaigns/:campaign',
  description:
    'Display metrics for a single campaign within a marketing channel. Total visits over time, return on marketing spend (ROMS), efficiency, and campaign influence on pipeline and closed-won opportunities. The campaign can be broken down by the audiences that are targeted through that campaign.',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the campaign metrics by',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const campaignById = (campaignId?: string) =>
  campaignId && `${campaigns.path}/${encodeURIComponent(campaignId)}`;
