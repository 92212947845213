import { Layout } from 'antd';
import { SIDER_WIDTH } from 'constants/dimensions';

import { Menu } from '../Menu';
import styles from './Sider.module.scss';

const { Sider: SiderAntd } = Layout;

export const Sider = () => {
  return (
    <SiderAntd className={styles.sider} breakpoint="lg" collapsedWidth="0" width={SIDER_WIDTH}>
      <Menu />
    </SiderAntd>
  );
};
