import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { InstantSearchTable } from '@shared/InstantSearch';
import LetteredAvatar from '@shared/LetteredAvatar';
import { ColumnsType } from '@shared/Table';
import { Button } from '@shared/buttons';
import { Tag } from '@shared/typography';
import { Link } from '@shared/typography';
import { Space } from 'antd';
import { UserType, mutateUserInvite } from 'api/user';
import { useNotification } from 'providers/Notification';
import { useMe } from 'providers/User';
import { ROUTES } from 'router';

import styles from './UsersListTable.module.scss';

type Props = {
  data?: UserType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: Error;
};

const UsersListTable = ({ data, totalResults, isLoading, error }: Props) => {
  const user = useMe();
  const { pushNotification, removeNotification } = useNotification();
  const [inviteUserPendingId, setInviteUserPendingId] = useState<string | undefined>();

  const sortFields = ROUTES.users.searchParams.sortBy.values;

  const handleResendInvite = async (user: UserType) => {
    try {
      setInviteUserPendingId(user.id);
      const { success } = await mutateUserInvite(user.name, user.email, user.role!);

      if (!success) {
        throw new Error();
      }

      removeNotification({ type: 'error' });
      pushNotification({
        type: 'success',
        message: t`You have successfully resent the invitation`,
      });
    } catch {
      pushNotification({
        type: 'error',
        message: t`Apologies, but something went wrong. Please try to resend the invite again.`,
      });
    } finally {
      setInviteUserPendingId(undefined);
    }
  };

  const columns: ColumnsType<UserType> = [
    {
      title: t`User Name`,
      key: 'name',
      fixed: 'left',
      sorter: sortFields.indexOf('name') >= 0,
      render: (text, field) => {
        const userIsMe = user?.id === field.id;

        return (
          <Space>
            <LetteredAvatar name={text} shape="circle" size="small" />
            {
              <Link
                variant="caption1"
                to={userIsMe ? ROUTES.profile.path : ROUTES.userById(field.id)}
              >
                {text}
              </Link>
            }
            {userIsMe && <Trans>(you)</Trans>}
          </Space>
        );
      },
    },
    {
      title: t`User Email`,
      key: 'email',
      sorter: sortFields.indexOf('email') >= 0,
      render: (text) => text,
    },
    {
      title: t`Status`,
      key: 'status',
      sorter: sortFields.indexOf('status') >= 0,
      render: (text, field) =>
        text === 'active' ? (
          <Tag color="success">
            <Trans>Active</Trans>
          </Tag>
        ) : (
          <div className={styles.inviteContainer}>
            <Tag color="error">
              <Trans>Pending</Trans>
            </Tag>
            <Button
              color="black"
              variant="secondary"
              isDisabled={!!inviteUserPendingId && inviteUserPendingId !== field.id}
              isLoading={inviteUserPendingId === field.id}
              onClick={() => {
                handleResendInvite(field);
              }}
            >
              <Trans>Resend Invite</Trans>
            </Button>
          </div>
        ),
    },
    {
      title: t`User Role`,
      key: 'role',
      sorter: sortFields.indexOf('role') >= 0,
      render: (text) => (text === 'admin' ? t`Admin` : t`User`),
    },
  ];

  return (
    <InstantSearchTable<UserType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No users found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      sticky
    />
  );
};

export default UsersListTable;
