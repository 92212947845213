import { useMemo } from 'react';

import { t } from '@lingui/macro';
import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import { getRevenueRangeName } from 'messages/revenue-range-name-messages';
import { ROUTES } from 'router';
import useSWR from 'swr';

import { AudienceOptionType } from '../audience';
import { REVENUE_RANGE_ID, WEB_TRAFFIC_TYPES } from '../common';
import { fetcherSol } from '../swr-fetcher';
import {
  WebTrafficBreakdownByTypeGQLResponse,
  WebTrafficIndustrySummaryGQLResponse,
  WebTrafficKPIGQLResponse,
  WebTrafficRevRangeSummaryDataType,
  WebTrafficRevRangeSummaryGQLResponse,
  WebTrafficVisitSummaryDataType,
  WebTrafficVisitTrendByTypeGQLResponse,
} from './webTrafficType';

export const useWebTrafficKPIs = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficKPIGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficKPIs($startDate: DateTime!, $endDate: DateTime!, $audienceId: String) {
            visualization {
              trafficKpi(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
                visits
                visitsLastPeriod
                visitsPercentChange
                tamVisits
                tamVisitsLastPeriod
                tamVisitsPercentChange
                audienceVisits
                audienceVisitsLastPeriod
                audienceVisitsPercentChange
                engagedCompanies
                engagedCompaniesLastPeriod
                engagedCompaniesPercentChange
                pipelineInfluenced
                pipelineInfluencedLastPeriod
                pipelineInfluencedPercentChange
                closedWonInfluenced
                closedWonInfluencedLastPeriod
                closedWonInfluencedPercentChange
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId: audience?.id,
      },
    },
    fetcherSol,
    {},
  );

  const webTrafficKPIs = useMemo(() => {
    const {
      visits,
      visitsLastPeriod,
      visitsPercentChange,
      tamVisits,
      tamVisitsLastPeriod,
      tamVisitsPercentChange,
      audienceVisits,
      audienceVisitsLastPeriod,
      audienceVisitsPercentChange,
      engagedCompanies,
      engagedCompaniesLastPeriod,
      engagedCompaniesPercentChange,
      pipelineInfluenced,
      pipelineInfluencedLastPeriod,
      pipelineInfluencedPercentChange,
      closedWonInfluenced,
      closedWonInfluencedLastPeriod,
      closedWonInfluencedPercentChange,
    } = data?.visualization.trafficKpi ?? {};

    const secondItem = audience?.id
      ? {
          id: 'scopedVisits',
          label: t`${audience.name} Visits`,
          value: audienceVisits,
          lastPeriod: audienceVisitsLastPeriod,
          percentChange: audienceVisitsPercentChange,
          valueFormat: {},
        }
      : {
          id: 'scopedVisits',
          label: t`TAM Visits`,
          value: tamVisits,
          lastPeriod: tamVisitsLastPeriod,
          percentChange: tamVisitsPercentChange,
          valueFormat: {},
        };

    return [
      {
        id: 'visits',
        label: t`Total Visits`,
        value: visits,
        lastPeriod: visitsLastPeriod,
        percentChange: visitsPercentChange,
        valueFormat: {},
      },
      secondItem,
      {
        id: 'engagedCompanies',
        label: audience?.name ? t`${audience.name} Engaged Companies` : t`TAM Engaged Companies`,
        value: engagedCompanies,
        valueLink:
          engagedCompanies != null &&
          engagedCompanies > 0 &&
          ROUTES.webTrafficActivityWithParams({
            audienceIds: audience?.id,
            inTam: audience?.id ? undefined : 'true',
            'metrics.visits': '[1,]',
          }),
        lastPeriod: engagedCompaniesLastPeriod,
        percentChange: engagedCompaniesPercentChange,
        valueFormat: {},
      },
      {
        id: 'pipeline',
        label: t`Pipeline Influence`,
        value: pipelineInfluenced,
        lastPeriod: pipelineInfluencedLastPeriod,
        percentChange: pipelineInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
      {
        id: 'closedWon',
        label: t`Closed Won Influence`,
        value: closedWonInfluenced,
        lastPeriod: closedWonInfluencedLastPeriod,
        percentChange: closedWonInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
    ];
  }, [data, audience]);

  return {
    webTrafficKPIs,
    isLoading,
    error,
  };
};

export const useWebTrafficVisitTrendByType = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficVisitTrendByTypeGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficVisitTrendByType(
            $startDate: DateTime!
            $endDate: DateTime!
            $audienceId: String!
          ) {
            visualization {
              trafficVisitTrend(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
                audience
                bot
                other
                sundayOfWeek
                tam
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId: audienceId ?? '', // TODO: remove empty string once Sol makes `audienceId` optional
      },
    },
    fetcherSol,
    {},
  );

  return {
    trafficTrendData: data?.visualization.trafficVisitTrend,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownByTrafficType = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficBreakdownByTypeGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficBreakdownByTrafficType(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping!
            $audienceId: String
          ) {
            visualization {
              trafficVisitBreakdown(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceId: $audienceId
              ) {
                groupingField
                count
                total
                percentOfTotal
                engagedCompanies
                companies
                percentOfTotalCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId: audience?.id,
        groupingField: 'traffic_type',
      },
    },
    fetcherSol,
    {},
  );

  const trafficGroupingName: Record<string, string | undefined> = {
    [WEB_TRAFFIC_TYPES.AUDIENCE]: `${audience?.name} Visits`,
    [WEB_TRAFFIC_TYPES.TAM]: t`TAM Visits`,
    [WEB_TRAFFIC_TYPES.OTHER]: t`Other Visits`,
    [WEB_TRAFFIC_TYPES.BOT]: t`Bot Visits`,
  };

  const trafficOrder = [
    WEB_TRAFFIC_TYPES.AUDIENCE,
    WEB_TRAFFIC_TYPES.TAM,
    WEB_TRAFFIC_TYPES.OTHER,
    WEB_TRAFFIC_TYPES.BOT,
  ];

  const webTrafficSummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown) {
      return undefined;
    }

    return data.visualization.trafficVisitBreakdown
      .sort(
        (a, b) =>
          trafficOrder.indexOf(a.groupingField as WEB_TRAFFIC_TYPES) -
          trafficOrder.indexOf(b.groupingField as WEB_TRAFFIC_TYPES),
      )
      .map(({ groupingField, ...rest }) => ({
        id: groupingField,
        name: trafficGroupingName[groupingField],
        ...rest,
      })) as WebTrafficVisitSummaryDataType[];
  }, [data]);

  return {
    webTrafficSummary,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownBySector = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficIndustrySummaryGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficBreakdownBySector(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping!
            $audienceId: String
          ) {
            visualization {
              trafficVisitBreakdown(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceId: $audienceId
              ) {
                sector {
                  id
                  name
                }
                count
                total
                percentOfTotal
                engagedCompanies
                companies
                percentOfTotalCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
        groupingField: 'sector',
      },
    },
    fetcherSol,
    {},
  );

  const webTrafficIndustrySummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown) {
      return undefined;
    }

    return data.visualization.trafficVisitBreakdown.sort(
      (a, b) => b.engagedCompanies - a.engagedCompanies,
    );
  }, [data]);

  return {
    webTrafficIndustrySummary,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownByCompanySize = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficRevRangeSummaryGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficBreakdownByRevRange(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping!
            $audienceId: String
          ) {
            visualization {
              trafficVisitBreakdown(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceId: $audienceId
              ) {
                revRange {
                  id
                  name
                }
                count
                total
                percentOfTotal
                engagedCompanies
                companies
                percentOfTotalCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
        groupingField: 'revrange',
      },
    },
    fetcherSol,
    {},
  );

  const trafficOrder = [
    REVENUE_RANGE_ID.ENTERPRISE,
    REVENUE_RANGE_ID.MID_MARKET,
    REVENUE_RANGE_ID.SMALL_MEDIUM,
    REVENUE_RANGE_ID.VERY_SMALL,
  ];

  const webTrafficRevRangeSummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown) {
      return undefined;
    }

    return data.visualization.trafficVisitBreakdown
      .sort(
        (a, b) =>
          trafficOrder.indexOf(a.revRange.id as REVENUE_RANGE_ID) -
          trafficOrder.indexOf(b.revRange.id as REVENUE_RANGE_ID),
      )
      .map(({ revRange, ...rest }) => ({
        revRange: {
          ...revRange,
          shortName: getRevenueRangeName(revRange.id),
        },
        ...rest,
      })) as WebTrafficRevRangeSummaryDataType[];
  }, [data]);

  return {
    webTrafficRevRangeSummary,
    isLoading,
    error,
  };
};
