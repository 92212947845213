export const PURPLE_100 = '#DDC4EB';
export const PURPLE_200 = '#CBA6E2';
export const PURPLE_300 = '#BF90DA';
export const PURPLE_400 = '#A96BCE';
export const PURPLE_500 = '#8756A5';
export const PURPLE_600 = '#65407C';
export const PURPLE_700 = '#442B52';
export const PURPLE_800 = '#221529';

export const ALL_PURPLES = [
  PURPLE_100,
  PURPLE_200,
  PURPLE_300,
  PURPLE_400,
  PURPLE_500,
  PURPLE_600,
  PURPLE_700,
  PURPLE_800,
];

export const ALL_SEMANTIC_PURPLES = [
  PURPLE_300,
  PURPLE_400,
  PURPLE_500,
  PURPLE_600,
  PURPLE_700,
  PURPLE_800,
];
