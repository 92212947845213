import { PropsWithChildren, UIEvent, useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import { CheckboxValueType, ListBox } from '@shared/Form';
import { FilterOperatorMulti, FilterOperatorValue } from '@shared/InstantSearch';
import { Link } from '@shared/typography';
import { OptionType } from 'api/common';

type Props = {
  options: OptionType[] | undefined;
  selectionMode: 'none' | 'single' | 'multiple';
  value?: FilterOperatorMulti;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  error?: Error | null;
  onChange?: (value: FilterOperatorValue) => void;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
};

const CategoryFilter = ({
  children,
  options,
  value,
  onChange,
  ...rest
}: PropsWithChildren<Props>) => {
  const handleSelectAll = () => {
    onChange?.({ operator: 'notIn', operand: [] });
  };

  const handleClear = () => {
    onChange?.({ operator: 'in', operand: [] });
  };

  const handleChange = (val: CheckboxValueType[]) => {
    const op = value?.operator ?? 'notIn';

    let newOperand: string[] = [];
    let newOperator = op;
    if (op === 'notIn') {
      newOperator = 'notIn';
      const notCheckedValues =
        options?.filter((option) => !val.includes(option.id)).map((option) => option.id) ?? [];
      newOperand = notCheckedValues;
    } else {
      newOperator = 'in';
      newOperand = val as string[];
    }
    onChange?.({ operator: newOperator, operand: [...new Set(newOperand)] });
  };

  const checkedValues = useMemo(() => {
    if (!value?.operator || value?.operator === 'notIn') {
      const operand = value?.operand ?? [];
      return (
        options?.filter((option) => !operand.includes(option.id)).map((option) => option.id) ?? []
      );
    } else {
      return value?.operand ?? [];
    }
  }, [value, options]);

  return (
    <Flex vertical gap="small">
      <Flex gap="small">
        <Link variant="caption1" onClick={handleSelectAll}>
          <Trans>Select All</Trans>
        </Link>
        <Link variant="caption1" onClick={handleClear}>
          <Trans>Clear</Trans>
        </Link>
      </Flex>
      {children}
      <ListBox {...rest} value={checkedValues} options={options} onChange={handleChange} />
    </Flex>
  );
};

export default CategoryFilter;
