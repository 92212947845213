import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { DateRangeType } from '@shared/Dashboard/Filters/types';
import { Radio } from '@shared/Form';
import { Panel } from '@shared/panels';
import { AudienceGroupedOptionType } from 'api/audience';
import { OPPORTUNITY_TYPE } from 'api/opportunity';
import { usePixelOpportunitiesInfluenced } from 'api/pixel';

import PixelOpportunityInfluenceChart from './PixelOpportunityInfluenceChart';
import PixelOpportunityInfluenceTable from './PixelOpportunityInfluenceTable';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  startDate?: string;
  endDate?: string;
  isLoading: boolean;
};

const PixelOpportunityInfluenceChartTable = ({
  pixelIds,
  audience,
  startDate,
  endDate,
  isLoading: isPixelLoading,
}: Props) => {
  const audienceIds = audience?.audienceIds;

  // TODO: Eventually we will support multiple audiences and we can delete this line
  const audienceId = audienceIds?.[0];

  const [opportunityType, setOpportunityType] = useState<OPPORTUNITY_TYPE>(
    OPPORTUNITY_TYPE.PIPELINE,
  );
  const [dateRangeOverride, setDateRangeOverride] = useState<DateRangeType>();
  const {
    pixelOpportunitiesInfluenced: data,
    isLoading: isPixelOpportunitiesInfluencedLoading,
    error,
  } = usePixelOpportunitiesInfluenced(pixelIds, startDate, endDate, audienceId);

  const isLoading = isPixelLoading || isPixelOpportunitiesInfluencedLoading;

  const options = [
    { value: OPPORTUNITY_TYPE.PIPELINE, label: t`Pipeline` },
    { value: OPPORTUNITY_TYPE.CLOSED_WON, label: t`Closed Won` },
  ];

  return (
    <Panel
      size="L"
      title={<Trans>Opportunity Influence</Trans>}
      noPadding
      actions={
        <Radio.Group
          value={opportunityType}
          optionType="button"
          onChange={(e) => setOpportunityType(e.target.value)}
          options={options}
        />
      }
    >
      <PixelOpportunityInfluenceChart
        data={
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON
            ? data?.recentDeals.data
            : data?.pipeline.data
        }
        opportunityType={opportunityType}
        isLoading={isLoading}
        error={error}
        onSelectedDateRangeChange={setDateRangeOverride}
      />
      <PixelOpportunityInfluenceTable
        pixelIds={pixelIds}
        startDate={dateRangeOverride?.startDate ?? startDate}
        endDate={dateRangeOverride?.endDate ?? endDate}
        audience={audience}
        opportunityType={opportunityType}
        isLoading={isLoading}
      />
    </Panel>
  );
};

export default PixelOpportunityInfluenceChartTable;
