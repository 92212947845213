import { VendorSpendCellData, VendorSpendDataType } from 'api/vendorSpend';
import moment from 'moment';

export const calculateBeginningDate = (vendorSpendRows: VendorSpendDataType[]): string => {
  const beginningDate = vendorSpendRows.reduce<moment.Moment>((acc, row) => {
    const date = moment(row.data[0].firstDayOfMonth).date(1);
    return date && acc.isAfter(date) ? date : acc;
  }, moment().date(1));

  return beginningDate.toISOString();
};

export const addMissingDates = (beginningDate?: string, data: VendorSpendCellData[] = []) => {
  if (!beginningDate) {
    beginningDate = moment().startOf('month').toISOString();
  }

  const endingDate = moment().add(1, 'year').date(1);
  let dateCursor = moment(beginningDate).startOf('month');
  const newData: VendorSpendCellData[] = [];
  const isUserSupplied = data?.[0]?.userSupplied === false ? false : true;

  while (dateCursor.isSameOrBefore(endingDate, 'month')) {
    const firstDayOfMonthCursor = dateCursor.toISOString();
    const existingItem = data.find((item) => item.firstDayOfMonth === firstDayOfMonthCursor);
    if (existingItem) {
      newData.push(existingItem);
    } else {
      newData.push({
        amount: 0,
        firstDayOfMonth: firstDayOfMonthCursor,
        userSupplied: isUserSupplied,
      });
    }
    dateCursor = moment(dateCursor).utc().add(1, 'month').startOf('month');
  }

  return newData;
};
