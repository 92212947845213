import { useEffect, useMemo, useRef, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { Flex } from '@shared/Flex';
import PageSpinner from '@shared/PageSpinner';
import { ReturnToLink } from '@shared/ReturnToLink';
import Error404 from '@shared/errors/Error404';
import { Page } from '@shared/page';
import { Col, Row, Space } from 'antd';
import { AudienceGroupedOptionType } from 'api/audience';
import { usePixelsByIds } from 'api/pixel';
import SolGraphQLError from 'error/SolGraphQLError';
import { useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'router';

import PixelDetailKPIMetrics from './PixelDetailKPIMetrics';
import PixelImpressionTrendChart from './PixelImpressionTrendChart';
import PixelOpportunityInfluenceChartTable from './PixelOpportunityInfluenceChartTable';
import PixelReachAndEngagementTrendChart from './PixelReachAndEngagementTrendChart';
import PixelTagList from './PixelTagList';
import PixelTargetingEfficiencyChart from './PixelTargetingEfficiencyChart';

const GROUPED_AUDIENCE_ID = 'GROUPED_AUDIENCE';

const PixelDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pixels: pixelIdString } = useParams();
  const pixelIds = pixelIdString?.split(',');
  const { pixels, isLoading, error: pixelError } = usePixelsByIds(pixelIds);
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});
  const hadAudience = useRef(false);
  const isGrouped = !!(pixelIds && pixelIds?.length > 1);

  useEffect(() => {
    if (pixels?.length && !searchParams.has('audience')) {
      // Put the default audience into the url if it's not already there
      if (isGrouped) {
        searchParams.set('audience', GROUPED_AUDIENCE_ID);
      } else {
        const audienceId = pixels[0].audience?.id;
        searchParams.set('audience', audienceId);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [pixels, isGrouped]);

  const audience = useMemo<AudienceGroupedOptionType | undefined>(() => {
    const aud = filters.audience;

    if (aud) {
      if (aud.id === GROUPED_AUDIENCE_ID) {
        const audienceIds = [
          ...new Set(pixels?.map((pixel) => pixel.audience?.id).filter(Boolean)),
        ];
        return {
          ...aud,
          audienceIds,
        };
      } else {
        return {
          ...aud,
          audienceIds: [aud.id],
        };
      }
    }

    return undefined;
  }, [filters.audience, pixels]);

  const prependedAudienceOptions = useMemo(
    () =>
      isGrouped
        ? [{ id: GROUPED_AUDIENCE_ID, name: t`Grouped Audience (${audience?.audienceIds.length})` }]
        : undefined,
    [isGrouped, audience],
  );

  // Did we load an audience before? By default we should load an audience before we make the first
  // query. Then, if audience is null, it's because the user specifically removed it. This check
  // makes sure we don't quickly load null audience queries before audienceId is retrieved.
  if (audience) {
    hadAudience.current = true;
  }

  const pageTitle = isGrouped ? t`Grouped (${pixelIds?.length})` : pixels?.[0].name;

  return (
    <Page title={t`Pixel Reporting`} pageName={pageTitle}>
      <Space direction="vertical" size="large">
        <Flex justify="space-between" align="start" gap="xlarge">
          <ReturnToLink route={ROUTES.pixels.path} routeName={<Trans>Pixel Overview</Trans>} />
          <PixelTagList pixels={pixels} />
        </Flex>
        {pixelError instanceof SolGraphQLError && pixelError.is404() ? (
          <Error404 message={<Trans>The pixel you're looking for does not exist</Trans>} />
        ) : (
          <>
            <DashboardFilterProvider
              dashboardFilters={filters}
              onDashboardFiltersChange={setDashboardFilters}
            >
              <DashboardDateRangeFilter />
              <DashboardAudienceFilter
                isDisabled={!hadAudience.current}
                prependedOptions={prependedAudienceOptions}
              />
            </DashboardFilterProvider>
            {hadAudience.current ? (
              <>
                <PixelDetailKPIMetrics
                  pixelIds={pixelIds}
                  audience={audience}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  isLoading={isLoading}
                />
                <PixelReachAndEngagementTrendChart
                  pixelIds={pixelIds}
                  audience={audience}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  isLoading={isLoading}
                />
                <Row gutter={[24, 24]}>
                  <Col md={10} sm={24} xs={24}>
                    <PixelImpressionTrendChart
                      pixelIds={pixelIds}
                      audience={audience}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      isLoading={isLoading}
                    />
                  </Col>
                  <Col md={14} sm={24} xs={24}>
                    <PixelTargetingEfficiencyChart
                      pixelIds={pixelIds}
                      audience={audience}
                      startDate={filters.start_date}
                      endDate={filters.end_date}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
                <PixelOpportunityInfluenceChartTable
                  pixelIds={pixelIds}
                  audience={audience}
                  startDate={filters.start_date}
                  endDate={filters.end_date}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <PageSpinner />
            )}
          </>
        )}
      </Space>
    </Page>
  );
};

export default PixelDetail;
