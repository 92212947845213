import { CSSProperties } from 'react';

import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  style: CSSProperties;
};

const DonutLoader = ({ style }: Props) => (
  <SkeletonLoader style={{ ...style, margin: 32 }} viewBox="0 0 260 260">
    <path
      fillRule="evenodd"
      d="m181.494 249.395-19.67-46.001c28.341-11.534 48.168-40.533 48.168-73.4 0-44.183-35.817-80-80-80-.31 0-.616.146-.332.176L128.465.2c.512-.06 1.022-.1 1.635-.1 71.698 0 130 58.104 130 130 0 53.404-32.424 99.35-78.606 119.295ZM59.712 20.631C78.496 8.536 100.597 1.147 124.349.131l2.197 50.679c-14.554-.112-28.099 4.397-39.628 11.777L59.712 20.631ZM50.27 127.768.48 126.99C1.3 83.818 23.52 45.85 56.269 22.935L84.81 64.2c-20.447 14.02-34.058 37.077-34.54 63.568Zm79.722 82.226a79.746 79.746 0 0 0 29.429-5.612l18.188 47.218c-14.743 5.211-30.803 8.5-47.509 8.5C58.592 260.1.639 202.422.15 131.161l49.95-.827c.086 44.026 35.824 79.66 79.892 79.66Z"
    />
  </SkeletonLoader>
);

export default DonutLoader;
