export const BLUE_100 = '#9ABDE3';
export const BLUE_200 = '#679CD6';
export const BLUE_300 = '#4183CB';
export const BLUE_400 = '#025ABA';
export const BLUE_500 = '#024895';
export const BLUE_600 = '#013670';
export const BLUE_700 = '#01244A';
export const BLUE_800 = '#001225';

export const ALL_BLUES = [
  BLUE_100,
  BLUE_200,
  BLUE_300,
  BLUE_400,
  BLUE_500,
  BLUE_600,
  BLUE_700,
  BLUE_800,
];

export const ALL_SEMANTIC_BLUES = [BLUE_300, BLUE_400, BLUE_500, BLUE_600, BLUE_700, BLUE_800];
