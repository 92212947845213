import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Table, { ColumnsType, TableSummary } from '@shared/Table';
import { Panel } from '@shared/panels';
import { AudienceOptionType } from 'api/audience';
import { ChannelVisitsOverviewRowDataType, useChannelVisitsOverview } from 'api/channel';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  channelId?: string;
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
  isLoading?: boolean;
};

const ChannelVisitsOverviewTable = ({ channelId, startDate, endDate, audience }: Props) => {
  const { channelVisitsData, channelVisitsSummary, isLoading, error } = useChannelVisitsOverview(
    channelId,
    startDate,
    endDate,
    audience?.id,
  );

  const showAudience = !!audience?.id;

  const columns = useMemo(() => {
    const cols: ColumnsType<ChannelVisitsOverviewRowDataType> = [];

    cols.push({
      title: t`Vendor`,
      key: 'vendor.name',
      fixed: 'left',
      sorter: (a, b) => a.vendor.name.toLowerCase().localeCompare(b.vendor.name.toLowerCase()),
      render: (text, field) => field.vendor?.name ?? '',
    });

    if (audience?.id) {
      cols.push({
        title: audience?.name,
        key: 'visits.audience',
        align: 'right',
        sorter: (a, b) => a.visits.audience - b.visits.audience,
        render: (text, field) => field.visits.audience,
      });
    }

    return cols.concat([
      {
        title: t`Visits`,
        key: 'visits.all',
        align: 'right',
        sorter: (a, b) => a.visits.all - b.visits.all,
        render: (text, field) => numberFormat(field.visits.all),
        defaultSortOrder: 'descend',
      },
      {
        title: t`TAM Visits`,
        key: 'visits.tam',
        align: 'right',
        sorter: (a, b) => a.visits.tam - b.visits.tam,
        render: (text, field) => numberFormat(field.visits.tam),
      },
      {
        title: t`$/Visit`,
        key: 'spendPerVisit.all',
        align: 'right',
        sorter: (a, b) => a.spendPerVisit.all - b.spendPerVisit.all,
        render: (text, field) => numberFormat(field.spendPerVisit.all, { isCurrency: true }),
      },
      {
        title: t`$/TAM Visit`,
        key: 'spendPerVisit.tam',
        align: 'right',
        sorter: (a, b) => a.spendPerVisit.tam - b.spendPerVisit.tam,
        render: (text, field) => numberFormat(field.spendPerVisit.tam, { isCurrency: true }),
      },
    ]);
  }, [audience]);

  return (
    <Panel size="L" title={<Trans>Visits Overview</Trans>} verifyC99Tag>
      <Table<ChannelVisitsOverviewRowDataType>
        loading={isLoading}
        error={error}
        emptyMessage={<Trans>No overview data was found</Trans>}
        columns={columns}
        dataSource={channelVisitsData}
        pagination={false}
        rowKey={(record) => record.vendor.id}
        summary={() => (
          <TableSummary fixed>
            <TableSummary.Row>
              <TableSummary.Cell>
                <Trans>Total</Trans>
              </TableSummary.Cell>
              {showAudience && (
                <TableSummary.Cell align="right">
                  {numberFormat(channelVisitsSummary?.visits.audience)}
                </TableSummary.Cell>
              )}
              <TableSummary.Cell align="right">
                {numberFormat(channelVisitsSummary?.visits.all)}
              </TableSummary.Cell>
              <TableSummary.Cell align="right">
                {numberFormat(channelVisitsSummary?.visits.tam)}
              </TableSummary.Cell>
              <TableSummary.Cell align="right">
                {numberFormat(channelVisitsSummary?.spendPerVisit.all, { isCurrency: true })}
              </TableSummary.Cell>
              <TableSummary.Cell align="right">
                {numberFormat(channelVisitsSummary?.spendPerVisit.tam, { isCurrency: true })}
              </TableSummary.Cell>
            </TableSummary.Row>
          </TableSummary>
        )}
      />
    </Panel>
  );
};

export default ChannelVisitsOverviewTable;
