/* eslint-disable no-restricted-imports */
import { Table as AntDTable } from 'antd';

import styles from './TableSummary.module.scss';

export interface SummaryProps {
  fixed?: boolean | 'top' | 'bottom';
  children?: React.ReactNode;
}

export interface FooterRowProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

type SummaryCellProps = {
  className?: string;
  children?: React.ReactNode;
  index?: number;
  colSpan?: number;
  rowSpan?: number;
  align?: 'left' | 'center' | 'right';
};

const TableSummary = (props: SummaryProps) => <AntDTable.Summary {...props} />;

TableSummary.Row = (props: FooterRowProps) => (
  <AntDTable.Summary.Row {...props} className={styles.summaryRow} />
);

TableSummary.Cell = ({ index, ...rest }: SummaryCellProps) => (
  // By default, `index` refers to the index of a row in the table body. When that row index is
  // hovered, this summary cell would be highlighted. There isn't a way to turn off this behavior.
  // Setting to -1 always highlights the cell when hovered over any row, so that didn't serve the
  // purpose either. Also, `null` and `undefined` are unallowed values. I wound up discovering that
  // an index of -2 keeps the cell from highlighting no matter which body row is hovered so I made
  // this the default while still allowing an override in case we want the default cell
  // highlighting behavior.
  <AntDTable.Summary.Cell
    {...rest}
    className={styles.summaryCell}
    index={index == null ? -2 : index}
  />
);

export default TableSummary;
