import Highcharts, { Options } from 'highcharts';

import { FlareChart, FlareProps } from '../types';

const initializeOptions = (
  defaultOptions: Options | undefined,
  { title, subtitle, description, postRender }: Partial<FlareProps>,
): Highcharts.Options => {
  return {
    ...defaultOptions,
    chart: {
      backgroundColor: 'transparent',
      events: {
        render: postRender && ((event) => postRender?.(event.target as unknown as FlareChart)),
      },
      style: {
        ...defaultOptions?.chart?.style,
        fontFamily: '"Inter", sans-serif',
      },
      ...defaultOptions?.chart,
    },
    title: {
      ...defaultOptions?.title,
      text: title,
      style: {
        ...defaultOptions?.title?.style,
        fontSize: '16px',
      },
    },
    subtitle: {
      ...defaultOptions?.subtitle,
      text: subtitle,
    },
    accessibility: {
      ...defaultOptions?.accessibility,
      description,
    },
    time: {
      useUTC: false,
      ...defaultOptions?.time,
    },
    plotOptions: {
      ...defaultOptions?.plotOptions,
      series: {
        ...defaultOptions?.plotOptions?.series,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
};

export default initializeOptions;
