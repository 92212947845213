import { Trans } from '@lingui/macro';
import { Rule } from 'antd/es/form';

import { isBusinessEmail, isEmailValid } from './validator';

export const EMAIL_STANDARD_RULES: Rule[] = [
  {
    validator: (_, value) => (isEmailValid(value) ? Promise.resolve() : Promise.reject()),
    message: <Trans>Please use a valid email address</Trans>,
  },
  {
    validator: (_, value) => (isBusinessEmail(value) ? Promise.resolve() : Promise.reject()),
    message: <Trans>Please use a work email, not a personal one</Trans>,
  },
];
