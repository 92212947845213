import { CSSProperties } from 'react';

import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  style?: CSSProperties;
};

const KpiMetricLoader = ({ style = {} }: Props) => (
  <SkeletonLoader style={style} width="100%" height="36">
    <rect x="0" y="2" rx="5" ry="5" width="60%" height="32" />
  </SkeletonLoader>
);

export default KpiMetricLoader;
