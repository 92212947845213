import { msg } from '@lingui/macro';

import { ErrorByCodeType } from './types';

export enum CORE_ERROR_CODES {
  UNKNOWN = 'UNKNOWN_SOL_ERROR',
  NOT_FOUND = 'err:sol.data.not-found',
  UPDATE_FAILURE = 'err:sol.data.update-failure',
  FIELD_DOES_NOT_EXIST = 'err:sol.data.field-does-not-exist',
  MISSING_REQUIRED_FIELD = 'err:sol.data.missing-required-field',
  TOO_LONG = 'err:sol.data.too-long',
  NON_UNIQUE_ID = 'err:sol.core.nonUniqueId',

  ISE = 'err:core.ise',
  NO_INSTANCE = 'err:sol.core.no-instance',
  INSTANCE_ACCESS = 'err:sol.core.instance-access',
  RESPONSE_TOO_LARGE = 'err:sol.core.response-too-large',
  REQUEST_TOO_LARGE = 'err:sol.core.request-too-large',
  TOO_MANY_ERRORS = 'err:sol.core.too-many-errors',
  BAD_ID_PREFIX = 'err:sol.core.bad-id-prefix',
  MISSING_AUTHENTICATION = 'err:sol.core.missing-authentication',
  TIMEOUT = 'err:sol.core.timeout',
  OPERATION_LOCKED = 'err:sol.core.operationLocked',
}

export const INTERNAL_CORE_ERROR = msg`A system error occurred. Please contact support for assistance.`;

export const CORE_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [CORE_ERROR_CODES.UNKNOWN]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.NOT_FOUND]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.UPDATE_FAILURE]: msg`Unable to save changes. Please contact support for assistance.`,
  [CORE_ERROR_CODES.FIELD_DOES_NOT_EXIST]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.MISSING_REQUIRED_FIELD]: msg`This field is required.`,
  [CORE_ERROR_CODES.TOO_LONG]: msg`The text supplied for the field is too long. Please shorten it and try again.`,
  [CORE_ERROR_CODES.NON_UNIQUE_ID]: INTERNAL_CORE_ERROR,

  [CORE_ERROR_CODES.ISE]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.NO_INSTANCE]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.INSTANCE_ACCESS]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.RESPONSE_TOO_LARGE]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.REQUEST_TOO_LARGE]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.TOO_MANY_ERRORS]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.BAD_ID_PREFIX]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.MISSING_AUTHENTICATION]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.TIMEOUT]: INTERNAL_CORE_ERROR,
  [CORE_ERROR_CODES.OPERATION_LOCKED]: msg`Vendor Spend is currently being edited by {lockedBy} and can only be edited by one user at a time.`,
};
