import { useMemo } from 'react';

import { InstantSearchSortOrder, QueryState, SolQueryParams } from '@shared/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@shared/InstantSearch/util/search-util';
import { AxiosProgressEvent } from 'axios';
import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import fetcher from '../Fetcher';
import { SolDeleteVariablesType } from '../solType';
import { fetcherSol } from '../swr-fetcher';
import getVariablesFromTableParams, {
  getVariablesFromTableParamsNew,
} from '../util/getVariablesFromTableParams';
import {
  AudienceCreateResponseType,
  AudienceCreateType,
  AudienceCreateVariablesType,
  AudienceDatasetResponse,
  AudienceDeleteResponseType,
  AudienceGQLResponse,
  AudienceListGQLResponse,
  AudienceMembershipGQLResponse,
  AudienceOptionGQLResponseType,
  AudienceOptionType,
  AudienceUpdateResponseType,
  AudienceUpdateType,
  AudienceUpdateVariablesType,
  UploadAudienceProgressCallback,
} from './audienceType';

const CSV_AUDIENCE_HEADER = 'domain';

export const uploadAudienceFile = async (
  file: File | Blob,
  onUploadProgress?: UploadAudienceProgressCallback,
): Promise<string> => {
  const name = file instanceof File ? file.name : 'audience.csv';

  const response = await fetcher.requestSol<File | Blob, AudienceDatasetResponse>({
    url: 'audiences/dataset',
    method: 'POST',
    data: file,
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${name}"`,
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (!onUploadProgress) {
        return;
      }

      if (progressEvent.total && progressEvent.total !== 0) {
        const percentComplete = Math.round(progressEvent.loaded / progressEvent.total);
        onUploadProgress(percentComplete);
      } else {
        onUploadProgress(1);
      }
    },
  });

  return response.data.dataset;
};

export const uploadAudienceDomains = (domains: string[]) => {
  const file = new Blob([[CSV_AUDIENCE_HEADER, ...domains].join('\n')], { type: 'text/csv' });
  return uploadAudienceFile(file);
};

const DEFAULT_FILTER_FIELDS = ['created_by.name', 'description', 'name'];

export const useAudienceOptions = ({
  search = '',
  pageSize = 50,
  prependedOptions = [],
}: {
  search?: string;
  pageSize?: number;
  prependedOptions?: AudienceOptionType[];
}) => {
  const { data, isLoading, error, size, setSize } = useSWRInfinite<
    AudienceOptionGQLResponseType,
    SolGraphQLError
  >(
    (pageIndex, previousPageData) => {
      // if the last result said we hit the end, don't make this request.
      if (previousPageData && !previousPageData.audiences.get.pageMeta.hasNext) {
        return null;
      }

      return {
        query: gql`
          query GetAudienceOptions(
            $page: Pagination!
            $sort: [SortParamInput]!
            $filter: [FilterParamInput]!
            $queryId: String
          ) {
            audiences {
              get(page: $page, sort: $sort, filter: $filter, queryId: $queryId) {
                edges {
                  node {
                    id
                    name
                    metrics {
                      size
                    }
                  }
                }
                pageMeta {
                  hasNext
                }
              }
            }
          }
        `,
        variables: getVariablesFromTableParams(['name'], {
          page: {
            offset: pageIndex * pageSize,
            limit: pageSize,
          },
          sort: {
            direction: InstantSearchSortOrder.ASC,
            field: 'name',
          },
          filter: search,
        }),
      };
    },
    fetcherSol,
    { revalidateFirstPage: false },
  );

  const hasMore = data?.[data.length - 1].audiences.get.pageMeta.hasNext;

  const audienceOptions = useMemo(() => {
    if (!data) {
      return prependedOptions;
    }

    return (prependedOptions || []).concat(
      data.map((d) => d.audiences.get.edges.map((edge) => edge.node)).flat(),
    );
  }, [data, prependedOptions]);

  return {
    audienceOptions,
    hasMore,
    isLoading,
    isLoadingMore: hasMore && size > 0 && data && typeof data[size - 1] === 'undefined',
    error,
    pageIndex: size,
    loadMore: setSize,
  };
};

export const useAudienceList = (tableParams?: SolQueryParams) => {
  const { data, error, isLoading, mutate } = useSWR<AudienceListGQLResponse, SolGraphQLError>(
    {
      query:
        tableParams &&
        gql`
          query GetAudienceList(
            $page: Pagination!
            $sort: [SortParamInput]!
            $filter: [FilterParamInput]!
          ) {
            audiences {
              get(page: $page, sort: $sort, filter: $filter) {
                edges {
                  node {
                    id
                    name
                    description
                    type
                    last_job {
                      status
                      errors
                    }
                    metrics {
                      size
                      visits
                    }
                    created_at
                    created_by {
                      id
                      name
                    }
                  }
                }
                totalEdges
              }
            }
          }
        `,
      variables: getVariablesFromTableParams(DEFAULT_FILTER_FIELDS, tableParams),
    },
    fetcherSol,
    {},
  );

  return {
    audienceListData: data?.audiences.get.edges.map((edge) => edge.node),
    totalResults: data?.audiences.get.totalEdges,
    isLoading,
    error,
    mutate,
  };
};

export const useAudienceById = (audienceId?: string) => {
  const { data, error, isLoading, mutate } = useSWR<AudienceGQLResponse, SolGraphQLError>(
    {
      query:
        audienceId &&
        gql`
          query GetAudience($getByIdId: String) {
            audiences {
              getById(id: $getByIdId) {
                id
                description
                last_job {
                  status
                }
                metrics {
                  size
                  visits
                }
                name
                salesforce_url
                type
              }
            }
          }
        `,
      variables: {
        getByIdId: audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    audience: data?.audiences.getById,
    error,
    isLoading,
    mutate,
  };
};

export const mutateAudienceCreate = async (audience: AudienceCreateType) => {
  const variables: AudienceCreateVariablesType = {
    audience: {
      description: audience.description,
      name: audience.name,
      type: audience.type,
    },
  };

  if (audience.type === 'salesforce') {
    variables.audience.salesforce_url = audience.salesforce_url;
  }

  if (audience.type === 'upload') {
    variables.audience.dataset_id = audience.dataset_id;
  }

  return await fetcherSol<AudienceCreateVariablesType, AudienceCreateResponseType>({
    query: gql`
      mutation CreateAudience($audience: CreateAudienceInput!) {
        audiences {
          create(audience: $audience) {
            id
            description
            last_job {
              status
            }
            metrics {
              size
              visits
            }
            name
            salesforce_url
            type
          }
        }
      }
    `,
    variables,
  });
};

export const mutateAudienceUpdate = async (audience: AudienceUpdateType) => {
  return await fetcherSol<AudienceUpdateVariablesType, AudienceUpdateResponseType>({
    query: gql`
      mutation UpdateAudience($audience: UpdateAudienceInput!) {
        audiences {
          update(audience: $audience) {
            id
            description
            last_job {
              status
            }
            metrics {
              size
              visits
            }
            name
            salesforce_url
            type
          }
        }
      }
    `,
    variables: {
      audience: {
        id: audience.id,
        description: audience.description,
        name: audience.name,
      },
    },
  });
};

export const mutateAudienceDelete = async (audienceId: string) => {
  const response = await fetcherSol<SolDeleteVariablesType, AudienceDeleteResponseType>({
    query: gql`
      mutation AudienceDelete($deleteId: String!) {
        audiences {
          delete(id: $deleteId) {
            id
            success
          }
        }
      }
    `,
    variables: {
      deleteId: audienceId,
    },
  });

  return response.audiences.delete.success;
};

export const useAudienceMembership = (
  audienceId: string | undefined,
  membershipQueryState: QueryState | undefined,
) => {
  const tableParams = getSolQueryParamsNewFromQueryState(membershipQueryState);

  const { data, error, isLoading } = useSWR<AudienceMembershipGQLResponse, SolGraphQLError>(
    {
      query:
        tableParams &&
        gql`
          query GetByAudienceId(
            $audienceId: String!
            $sort: [SortParamInput]!
            $page: Pagination!
            $searchQuery: String
          ) {
            companies {
              getByAudienceId(
                audienceId: $audienceId
                sort: $sort
                page: $page
                searchQuery: $searchQuery
              ) {
                edges {
                  node {
                    id
                    name
                    tld
                    metrics {
                      visits
                      lastActivityDate
                    }
                  }
                }
                totalEdges
              }
            }
          }
        `,
      variables: {
        audienceId,
        ...getVariablesFromTableParamsNew(tableParams),
      },
    },
    fetcherSol,
    {},
  );

  return {
    audienceMembership: data?.companies.getByAudienceId.edges.map((edge) => edge.node),
    totalResults: data?.companies.getByAudienceId.totalEdges,
    isLoading,
    error,
  };
};
