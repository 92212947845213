import { PropsWithChildren, ReactNode } from 'react';

import { t } from '@lingui/macro';
import { Button } from '@shared/buttons';
import { Title } from '@shared/typography';

import styles from './ConfirmModal.module.scss';
import Modal from './Modal';
import { ModalProps } from './types';

type Props = ModalProps & {
  okText?: ReactNode;
  cancelText?: ReactNode;
  isLoading?: boolean;
};

const ConfirmModal = ({
  open,
  width = 667,
  okText = t`Ok`,
  cancelText = t`Cancel`,
  children,
  isLoading = false,
  onOk,
  onCancel,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      {...rest}
      className={styles.confirm}
      open={open}
      width={width}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancelButton"
          color="black"
          variant="secondary"
          size="large"
          isDisabled={isLoading}
          onClick={onCancel}
        >
          {cancelText}
        </Button>,
        <Button
          key="okButton"
          color="black"
          variant="primary"
          size="large"
          isLoading={isLoading}
          onClick={onOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <Title level={4} weight="light">
        {children}
      </Title>
    </Modal>
  );
};

export default ConfirmModal;
