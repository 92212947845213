import LetteredAvatar from '@shared/LetteredAvatar';
import classNames from 'classnames';

import styles from './CompanyLogo.module.scss';

type Props = {
  name?: string;
  domain?: string;
  size?: 'small' | 'medium';
};

const CompanyLogo = ({ name, domain, size = 'small' }: Props) => {
  const displayName = name ?? domain;

  if (domain == null) {
    return <LetteredAvatar name={displayName} shape="square" size={size} />;
  }

  return (
    <object
      className={classNames(styles.logoIcon, styles[`size-${size}`])}
      data={`https://logo.clearbit.com/${domain}`}
      type="image/jpeg"
    >
      <LetteredAvatar name={displayName} shape="square" size={size} />
    </object>
  );
};

export default CompanyLogo;
