import { SolQueryParamsNew } from '@shared/InstantSearch';
import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { fetcherSol } from '../swr-fetcher';
import {
  VendorActivityGQLResponse,
  VendorActivityKPIsGQLResponse,
  VendorFitScoringGQLResponse,
  VendorListByChannelIdGQLResponse,
  VendorListGQLResponse,
  VendorTrafficOverviewKPIsGQLResponse,
  VendorWeeklyVisitsGQLResponse,
} from './vendorType';

export const useVendorList = () => {
  const { data, error, isLoading } = useSWRImmutable<VendorListGQLResponse, SolGraphQLError>(
    {
      query: gql`
        query GetVendorList {
          vendors {
            getAll {
              id
              name
              channel {
                id
                name
              }
            }
          }
        }
      `,
    },
    fetcherSol,
    {},
  );

  const vendors = data?.vendors.getAll.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return {
    vendors,
    error,
    isLoading,
  };
};

export const useVendorById = (vendorId?: string) => {
  const { vendors, isLoading, error } = useVendorList();
  const vendor = vendors?.find((item) => item.id === vendorId);

  return {
    vendor,
    error,
    isLoading,
  };
};

export const useVendorListByChannelId = (channelId?: string) => {
  const { data, error, isLoading } = useSWRImmutable<
    VendorListByChannelIdGQLResponse,
    SolGraphQLError
  >(
    {
      query:
        channelId &&
        gql`
          query GetVendorListByChannelId($channelId: String) {
            vendors {
              getByChannelId(channelId: $channelId) {
                id
                name
              }
            }
          }
        `,
      variables: {
        channelId,
      },
    },
    fetcherSol,
    {},
  );

  const vendors = data?.vendors.getByChannelId.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return {
    vendors,
    error,
    isLoading,
  };
};

export const useVendorActivityKPIs = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSWR<VendorActivityKPIsGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query VendorTrafficKPIs($startDate: DateTime!, $endDate: DateTime!, $audienceId: String) {
            visualization {
              trafficKpi(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
                audienceVisits
                closedWonInfluenced
                engagedCompanies
                impressions
                pipelineInfluenced
                reachedCompanies
                tamVisits
                viewThroughVisits
                visits
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    vendorActivityKpis: data?.visualization.trafficKpi,
    isLoading,
    error,
  };
};

export const useVendorActivity = (
  tableParams?: SolQueryParamsNew,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = tableParams && startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSWR<VendorActivityGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query VendorActivity(
            $page: Pagination!
            $sort: [SortParamInput]!
            $startDate: DateTime!
            $endDate: DateTime!
            $audienceId: String
          ) {
            visualization {
              vendorActivity(
                page: $page
                sort: $sort
                startDate: $startDate
                endDate: $endDate
                audienceId: $audienceId
              ) {
                edges {
                  node {
                    adClicks
                    adImpressions
                    audienceVisits
                    closedWonInfluenced
                    engagedCompanies
                    id
                    pipelineInfluenced
                    spend
                    tamVisits
                    vendor {
                      channel {
                        id
                        name
                      }
                      id
                      name
                    }
                    visits
                  }
                }
                totalEdges
              }
            }
          }
        `,
      variables: {
        page: tableParams?.page,
        sort: tableParams?.sort,
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    vendorActivity: data?.visualization.vendorActivity.edges.map((edge) => edge.node),
    totalResults: data?.visualization.vendorActivity.totalEdges,
    isLoading,
    error,
  };
};

export const useVendorFitScoring = (startDate?: string, endDate?: string, audienceId?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;
  const { data, isLoading, error } = useSWR<VendorFitScoringGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query VendorFitScoring(
            $page: Pagination!
            $sort: [SortParamInput]!
            $startDate: DateTime!
            $endDate: DateTime!
            $audienceId: String
          ) {
            visualization {
              vendorFitScoring(
                page: $page
                sort: $sort
                startDate: $startDate
                endDate: $endDate
                audienceId: $audienceId
              ) {
                edges {
                  node {
                    countBaseAccounts
                    vendor {
                      id
                      name
                      channel {
                        id
                        name
                      }
                    }
                    visits {
                      count
                      best
                      worst
                    }
                    engagedCompanies {
                      count
                      percent
                      best
                      worst
                    }
                    spendPerVisit {
                      total
                      best
                      worst
                    }
                    pipelineInfluenced {
                      perSpend
                      best
                      worst
                    }
                    opportunityInfluenced {
                      total
                      percent
                      best
                      worst
                    }
                    fitScore {
                      score
                      best
                      worst
                    }
                  }
                }
              }
            }
          }
        `,
      variables: {
        page: {
          offset: 0,
          limit: 100,
        },
        sort: [
          {
            field: 'fitScore.score',
            direction: 'desc',
          },
        ],
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    vendorFitScores: data?.visualization.vendorFitScoring.edges.map((edge) => edge.node),
    isLoading,
    error,
  };
};

export const useVendorTrafficOverviewKPIs = (
  vendorId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = !!(vendorId && startDate?.length && endDate?.length);

  const { data, isLoading, error } = useSWR<VendorTrafficOverviewKPIsGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query VendorTrafficOverviewKpis(
            $vendorId: String
            $startDate: DateTime
            $endDate: DateTime
            $audienceId: String
          ) {
            visualization {
              trafficOverviewKpis(
                vendorId: $vendorId
                startDate: $startDate
                endDate: $endDate
                audienceId: $audienceId
              ) {
                audienceVisits
                spend
                spendPerAudienceVisit
                spendPerTamVisit
                spendPerVisit
                tamVisits
                visits
              }
            }
          }
        `,
      variables: {
        vendorId,
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    vendorTrafficOverviewKPIs: data?.visualization.trafficOverviewKpis,
    isLoading,
    error,
  };
};

export const useVendorWeeklyVisits = (
  vendorId?: string,
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = vendorId && startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<VendorWeeklyVisitsGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query VendorWeeklyTraffic(
            $vendorId: String
            $startDate: DateTime
            $endDate: DateTime
            $audienceId: String
          ) {
            visualization {
              weeklyTraffic(
                vendorId: $vendorId
                startDate: $startDate
                endDate: $endDate
                audienceId: $audienceId
              ) {
                data {
                  sundayOfWeek
                  visits {
                    all
                    audience
                    tam
                  }
                }
              }
            }
          }
        `,
      variables: {
        vendorId,
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
      },
    },
    fetcherSol,
    {},
  );

  return {
    vendorWeeklyVisits: data?.visualization.weeklyTraffic[0]?.data,
    isLoading,
    error,
  };
};
