import { SVGPathArray } from 'highcharts';

import { AxisExtended, BarPoint, ColumnPoint, FlareChart } from '../types';

type Props = {
  muteClassName: string;
};

export const pointStyleHelper =
  ({ muteClassName }: Props) =>
  (point: ColumnPoint | BarPoint, { mute }: { mute?: boolean }) => {
    if (mute === true) {
      point.graphic?.element.classList.add(muteClassName);
      point.dataLabel?.element.classList.add(muteClassName);
    }

    if (mute === false) {
      point.graphic?.element.classList.remove(muteClassName);
      point.dataLabel?.element.classList.remove(muteClassName);
    }
  };

export const getSelectionRect = (chart: FlareChart) => {
  const xAxis = chart.xAxis[0] as AxisExtended;
  const selectedPoint = chart.getSelectedPoints()?.[0];

  if (chart.inverted) {
    return {
      x: chart.plotLeft,
      y: chart.plotTop + (chart.plotHeight - (selectedPoint.plotX ?? 0) - xAxis.transA / 2),
      width: chart.plotWidth,
      height: xAxis.transA,
    };
  }
  return {
    x: chart.plotLeft + (selectedPoint.plotX ?? 0) - xAxis.transA / 2,
    y: chart.plotTop,
    width: xAxis.transA,
    height: chart.plotHeight,
  };
};

export const getSelectionLine = (chart: FlareChart) => {
  const svgPath: SVGPathArray = [];
  const selectedPoint = chart.getSelectedPoints()?.[0];

  if (chart.inverted) {
    svgPath.push(['M', chart.plotLeft, selectedPoint.plotX ?? 0]);
    svgPath.push(['L', chart.plotLeft + chart.plotWidth, selectedPoint.plotX ?? 0]);
  } else {
    svgPath.push([
      'M',
      Math.round(chart.plotLeft + (selectedPoint.plotX ?? 0)) - 0.5,
      chart.plotTop,
    ]);
    svgPath.push([
      'L',
      Math.round(chart.plotLeft + (selectedPoint.plotX ?? 0)) - 0.5,
      chart.plotTop + chart.plotHeight,
    ]);
  }

  return svgPath;
};
