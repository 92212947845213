import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useLocationState = <T extends object>() => {
  const { state } = useLocation();
  const obj = (state || {}) as T;
  return useMemo(() => obj, []);
};

export default useLocationState;
