/* eslint-disable no-restricted-imports */
import { Drawer as AntDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import Cross from 'assets/svg/cross.svg?react';
import classNames from 'classnames';

import styles from './Drawer.module.scss';

export type DrawerProps = AntdDrawerProps;

const Drawer = (props: DrawerProps) => {
  return (
    <AntDrawer
      {...props}
      className={classNames(styles.drawer, props.className)}
      closeIcon={<Cross />}
      getContainer={document.getElementById('pageRoot') || undefined}
    />
  );
};

export default Drawer;
