import { percentToHex } from 'helper/number-util';

import { ALL_SEMANTIC_AQUAS, AQUA_100, AQUA_200, AQUA_400, AQUA_600 } from './semantic/aqua';
import { ALL_SEMANTIC_BLUES } from './semantic/blue';
import { ALL_GRAYS, GRAY_200, GRAY_400, GRAY_500, GRAY_700 } from './semantic/gray';
import { ALL_SEMANTIC_GREENS, GREEN_100, GREEN_400 } from './semantic/green';
import { ALL_SEMANTIC_ORANGES } from './semantic/orange';
import { ALL_SEMANTIC_PINKS } from './semantic/pink';
import { ALL_SEMANTIC_PURPLES } from './semantic/purple';
import { ALL_SEMANTIC_REDS } from './semantic/red';
import { ALL_SEMANTIC_TEALS } from './semantic/teal';
import { ALL_SEMANTIC_VIOLETS } from './semantic/violet';
import { ALL_SEMANTIC_YELLOWS } from './semantic/yellow';

export const ALL_SEMANTIC_COLORS = [
  ...ALL_SEMANTIC_GREENS,
  ...ALL_SEMANTIC_AQUAS,
  ...ALL_SEMANTIC_TEALS,
  ...ALL_SEMANTIC_VIOLETS,
  ...ALL_SEMANTIC_ORANGES,
  ...ALL_SEMANTIC_YELLOWS,
  ...ALL_SEMANTIC_PINKS,
  ...ALL_SEMANTIC_PURPLES,
  ...ALL_SEMANTIC_BLUES,
  ...ALL_SEMANTIC_REDS,
];

export const ALL_COLORS = [...ALL_SEMANTIC_COLORS, ...ALL_GRAYS];

export const TAM_PRIMARY = GREEN_400;
export const TAM_SECONDARY = GREEN_100;
export const VISITS_PRIMARY = AQUA_400;
export const VISITS_SECONDARY = AQUA_100;
export const AUDIENCE_PRIMARY = AQUA_600;
export const AUDIENCE_SECONDARY = AQUA_200;
export const ALL_PRIMARY = GRAY_500;
export const ALL_SECONDARY = GRAY_400;
export const BOT_PRIMARY = GRAY_700;

export const colorWithOpacity = (hexColorString: string, opacity: number) => {
  return `${hexColorString}${percentToHex(opacity)}`;
};

// Supporting colors
export const WHITE = '#FFFFFF';

export const MANGO = '#F1A673';

export const GRID_LINE_COLOR = '#0000001A';
export const CROSSHAIR_LINE_COLOR = '#808080';
export const CROSSHAIR_BACKGROUND = 'rgba(245, 245, 246, 0.8)';
const GREEN_AXIS_LABEL_COLOR = '#90B143';
const BLUE_AXIS_LABEL_COLOR = AQUA_400;

export const VISUALISATION_TEXT_COLORS = [GREEN_AXIS_LABEL_COLOR, BLUE_AXIS_LABEL_COLOR];

export const SKELETON_FOREGROUND_COLOR = GRAY_500;
export const SKELETON_BACKGROUND_COLOR = GRAY_200;

// DEPRECATED color system

/**
 * @deprecated
 */
export const VISUALISATION_CHART_COLORS = [
  '#B6DF59',
  '#59BDC6',
  '#9162FA',
  '#E45858',
  '#D2C92A',
  '#808080',
];

/**
 * @deprecated
 */
export const VISUALISATION_AREA_CHART_COLORS = [
  '#B6DF59',
  '#59BDC6',
  '#DB6BB6',
  '#D2C92A',
  '#F1A673',
];
