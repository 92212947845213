import { Trans, t } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import { Text } from '@shared/typography';
import SolGraphQLError from 'error/SolGraphQLError';

import styles from './ReportName.module.scss';

type Props = {
  reportName?: string;
  isLoading: boolean;
  error?: SolGraphQLError;
};

const ReportName = ({ reportName, isLoading, error }: Props) => {
  if (!isLoading && !reportName && !error) {
    return null;
  }

  if (error) {
    return (
      <Flex className={styles.reportName} gap="small">
        <Text variant="body1" color="red">
          {error?.getFirstError ? error.getFirstError().message : t`Error loading report.`}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex className={styles.reportName} gap="small">
      <Text className={styles.reportNameLabel} variant="body1" color="grey">
        <Trans>Report Name:</Trans>
      </Text>
      {reportName ? (
        <Text variant="body1" color="grey">
          {reportName}
        </Text>
      ) : (
        <Text variant="body1" color="grey" italic>
          <Trans>Loading...</Trans>
        </Text>
      )}
    </Flex>
  );
};

export default ReportName;
