import { Trans } from '@lingui/macro';
import ProgressBar, { ProgressBarGoal } from '@shared/ProgressBar';
import classNames from 'classnames';

import styles from './OnboardingProgressBar.module.scss';

type Props = {
  isLoading: boolean;
  value?: number;
};

const OnboardingProgressBar = ({ isLoading, value }: Props) => {
  value = isLoading || !value ? 0 : Math.floor(value * 100) / 100;
  const valuePercent = `${Math.floor(value * 100)}%`;

  return (
    <div className={styles.container}>
      <ProgressBar
        className={classNames(styles.progressBar, { [styles.isLoading]: isLoading })}
        data-testid="onboarding-progress-bar"
        value={value}
        red={value < 0.6}
        yellow={value >= 0.6 && value < 0.8}
        green={value >= 0.8}
      >
        {value < 1 && (
          <div
            className={classNames(styles.valueLabel, { [styles.isLoading]: isLoading })}
            style={{ left: valuePercent }}
          >
            {valuePercent}
          </div>
        )}
        <ProgressBarGoal value={1} label={<Trans>100% Complete</Trans>} orientation="below" />
      </ProgressBar>
    </div>
  );
};

export default OnboardingProgressBar;
