import { UIEvent, useEffect, useRef } from 'react';

import { SearchInput } from '@shared/Form';
import { FilterOperatorMulti, FilterOperatorValue } from '@shared/InstantSearch';
import { InputRef } from 'antd';
import { AudienceOptionType } from 'api/audience';

import { CategoryFilter } from '../CategoryFilter';

// We display a loading option as the last item in the list (if there are more pages to fetch).
// If the user sees this loading option, load the next page. Don't wait until the user scrolls all
// the way to the bottom.
const LOADING_OPTION_OFFSET = 30;

type Props = {
  value?: FilterOperatorMulti;
  options?: AudienceOptionType[];
  loadMore?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  error?: Error | null;
  searchText?: string;
  onChange?: (value: FilterOperatorValue) => void;
  onSearch: (search: string) => void;
};

const InfiniteScrollCategoryFilter = ({
  loadMore,
  hasMore,
  isLoading,
  isLoadingMore,
  error,
  options,
  searchText,
  onChange,
  onSearch,
  ...rest
}: Props) => {
  const searchRef = useRef<InputRef | null>(null);
  const loading = isLoading || isLoadingMore;

  useEffect(() => {
    searchRef.current?.focus();
  }, [searchRef]);

  const handleScroll = (event: UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (
      loadMore &&
      hasMore &&
      !loading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - LOADING_OPTION_OFFSET
    ) {
      loadMore();
    }
  };

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      error={error}
      options={options}
      onScroll={handleScroll}
      onChange={onChange}
    >
      <SearchInput ref={searchRef} size="medium" value={searchText} onChange={onSearch} autoFocus />
    </CategoryFilter>
  );
};

export default InfiniteScrollCategoryFilter;
