import { OptionsStackingValue } from 'highcharts';

import { FlareOptionsPositionValue } from '../types';

export const getStackingFromPosition = (
  position: FlareOptionsPositionValue,
): OptionsStackingValue | undefined => {
  switch (position) {
    case 'percent':
      return 'percent';
    case 'stack':
      return 'normal';
    case 'stream':
      return 'stream';
    case 'overlap':
    case 'dodge':
    default:
      return undefined;
  }
};

export const getGroupingFromPosition = (position: FlareOptionsPositionValue): boolean => {
  return position === 'dodge';
};
