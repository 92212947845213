import { useMemo } from 'react';

import { plural, t } from '@lingui/macro';
import { DateRangeType } from '@shared/Dashboard/Filters/types';
import Flare, { Axis, Column, FlareError, Legend, Tooltip } from '@shared/Flare';
import { OPPORTUNITY_TYPE } from 'api/opportunity';
import { PixelOpportunitiesTrend } from 'api/pixel';
import { AUDIENCE_PRIMARY, AUDIENCE_SECONDARY } from 'constants/colors';
import { DateFormatMD, MonthDateYearFormat } from 'constants/formats';
import { formatDate } from 'helper/dateHelper';
import { numberFormat } from 'helper/numberFormatter';
import { Point } from 'highcharts';
import moment from 'moment';

type Props = {
  data?: PixelOpportunitiesTrend[];
  opportunityType: OPPORTUNITY_TYPE;
  isLoading: boolean;
  error: FlareError;
  onSelectedDateRangeChange: (dateRange?: DateRangeType) => void;
};

const PixelOpportunityInfluenceChart = ({
  data,
  opportunityType,
  isLoading,
  error,
  onSelectedDateRangeChange,
}: Props) => {
  const seriesName = opportunityType === OPPORTUNITY_TYPE.CLOSED_WON ? t`Closed Won` : t`Pipeline`;

  const seriesData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'recentDeals',
        name: seriesName,
        zIndex: 1,
        data: data.map((d) => ({
          amount: d.revenue,
          custom: { date: d.sundayOfWeek, count: d.opportunities },
        })),
      },
    ];
  }, [data, seriesName]);

  const handleSelectionChange = (point: Point | null) => {
    const startDate = point?.options.custom?.date;
    if (startDate) {
      const endDate = moment(startDate).add(7, 'd').format('YYYY-MM-DD');
      onSelectedDateRangeChange?.({ startDate, endDate });
    } else {
      onSelectedDateRangeChange?.();
    }
  };

  return (
    <div style={{ height: 400 }}>
      <Flare
        data={seriesData}
        description={t`A chart showing number of opportunities each week over the select time period`}
        colors={[
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON ? AUDIENCE_SECONDARY : AUDIENCE_PRIMARY,
        ]}
        isLoading={isLoading}
        error={error}
      >
        <Legend noToggleVisibility />
        <Column
          y="amount"
          position="stack"
          skeletonLoaderOptions={{ numCategories: 12, numSeries: 1 }}
          onPointSelectionChange={handleSelectionChange}
        />
        <Axis
          position="left"
          labelFormat={(item) =>
            numberFormat(item.value, { isCurrency: true, compact: true, precision: 0 })
          }
          title={t`${seriesName} Amount`}
        />
        <Axis
          position="bottom"
          categories={data?.map((d) => d.sundayOfWeek)}
          labelFormat={(item) => formatDate(item.value, DateFormatMD)}
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => formatDate(item?.x, MonthDateYearFormat)}
          rowSecondaryValueFormat={(item) =>
            `(${plural(item?.point.custom?.count, {
              one: '# Opportunity',
              other: '# Opportunities',
            })})`
          }
          rowValueFormat={(item) => numberFormat(item?.y, { isCurrency: true, precision: 0 })}
          shared
          stickyOnClick={false}
        />
      </Flare>
    </div>
  );
};

export default PixelOpportunityInfluenceChart;
