import { ForwardedRef, forwardRef, useCallback, useMemo, useRef } from 'react';

import { Trans } from '@lingui/macro';
import {
  FilterOperatorValue,
  QueryState,
  QueryStateFilter,
  useInstantSearchState,
} from '@shared/InstantSearch';
import { getAntDSortOrder, getQueryStateSortOrder } from '@shared/InstantSearch/util/search-util';
import Table, { ColumnsType, SortResult, TableProps } from '@shared/Table';
import { FilterDetails } from '@shared/filters';
import { TablePaginationConfig } from 'antd';
import { TableRef } from 'antd/es/table';

import styles from './InstantSearchTable.module.scss';
import { isFilterOperatorRange } from './util/filter-util';

interface InstantSearchTableProps<T> extends TableProps<T> {
  totalResults?: number;
  getFieldName?: (fieldId: string) => string;
}

const defaultPagination: TablePaginationConfig = {
  current: 1,
};

const InstantSearchTable = <T extends object>(
  {
    columns,
    pagination = defaultPagination,
    totalResults,
    getFieldName,
    ...rest
  }: InstantSearchTableProps<T>,
  ref: ForwardedRef<TableRef>,
) => {
  // Hang on to the last successful response and don't reset the table when we go to `loading`
  // state and `data == null`. Instead, just use the last known total results.
  const stickyTotalResults = useRef<number>();
  const { queryState, updateQueryState } = useInstantSearchState();

  const handleChange = (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterOperatorValue>,
    sorter?: SortResult<T>,
  ) => {
    const newQueryState: Partial<QueryState> = {};

    if (sorter && !Array.isArray(sorter) && sorter.field) {
      newQueryState.sortBy = sorter.field + '';
      newQueryState.sortOrder = getQueryStateSortOrder(sorter.order);
    }

    if (pagination && pagination.current) {
      newQueryState.page = pagination.current;
      newQueryState.size = pagination.pageSize;
    }

    if (tableFilters) {
      // Keep any filters which aren't being used by the table
      const globalFilters = queryState.filters.filter((filter) => !(filter.field in tableFilters));

      const additionalFilters = Object.keys(tableFilters)
        .filter((key) => tableFilters[key] != null)
        .map((key: string) => {
          const filterValue = tableFilters[key];
          if (
            !isFilterOperatorRange(filterValue) &&
            Array.isArray(filterValue?.operand) &&
            filterValue.operand.length === 0
          ) {
            return null;
          }
          return {
            field: key,
            ...filterValue,
          };
        })
        .filter((filter) => filter !== null) as QueryStateFilter[];

      newQueryState.filters = globalFilters.concat(additionalFilters);
    }

    updateQueryState(newQueryState);
  };

  // Set sort and filters programmatically when queryState changes.
  const updatedColumns: ColumnsType<T> | undefined = useMemo(() => {
    const { sortBy, sortOrder, filters } = queryState;

    if (columns) {
      return columns.map((col) => {
        const filter = filters.find((filter) => filter.field === col.key);
        return {
          ...col,
          sortOrder: col.key === sortBy ? getAntDSortOrder(sortOrder) : null,
          filteredValue: filter ?? null,
        };
      });
    }
  }, [queryState, columns]);

  if (totalResults != null) {
    stickyTotalResults.current = totalResults;
  }

  // Set pagination programmatically when queryState changes.
  const updatedPagination: TablePaginationConfig | undefined | false = useMemo(() => {
    const { page, size } = queryState;

    if (pagination && page) {
      if (!stickyTotalResults.current || stickyTotalResults.current < (size || 10)) {
        return false;
      }

      return {
        ...pagination,
        current: page || 1,
        pageSize: size || 10,
        total: stickyTotalResults.current,
      };
    }
  }, [queryState, stickyTotalResults.current, pagination]);

  const getFieldNameDefault = useCallback(
    (fieldId: string) => updatedColumns?.find((col) => col.key === fieldId)?.title as string,
    [updatedColumns],
  );

  return (
    <div data-testid="instant-search-table" className={styles.tableContainer}>
      <FilterDetails
        className={styles.filterDetails}
        getFieldName={getFieldName ?? getFieldNameDefault}
      />
      <Table<T>
        ref={ref}
        columns={updatedColumns}
        pagination={updatedPagination}
        onChange={handleChange}
        {...rest}
        emptyMessage={<Trans>No results found</Trans>}
      />
    </div>
  );
};

export default forwardRef(InstantSearchTable) as <T>(
  props: InstantSearchTableProps<T> & { ref?: ForwardedRef<TableRef> },
) => ReturnType<typeof InstantSearchTable>;
