import React from 'react';

import { SwitchOptionType } from './SwitchGroup';

export interface SwitchGroupContextType {
  name?: string;
  toggleOption?: (option: SwitchOptionType) => void;
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isDisabled?: boolean;
  registerValue: (val: string) => void;
  cancelValue: (val: string) => void;
}

const SwitchGroupContext = React.createContext<SwitchGroupContextType | null>(null);

export default SwitchGroupContext;
