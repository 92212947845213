import { MouseEventHandler, ReactElement, cloneElement, useState } from 'react';

import { ButtonProps } from '@shared/buttons';

type Props = {
  children: ReactElement<ButtonProps>;
};

/**
 * A helper component which wraps a button. When the user clicks the button we will disable it
 * temporarily. The delay increases the more times the user clicks the button.
 */
const RateLimit = ({ children }: Props) => {
  const [retryCount, setRetryCount] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const handleClick: MouseEventHandler<HTMLElement> | undefined = (e) => {
    setDisabled(true);

    const backoffTime = Math.pow(2, retryCount) * 250;

    setRetryCount(retryCount + 1);

    setTimeout(() => {
      setDisabled(false);
    }, backoffTime);

    if (children.props.onClick) {
      children.props.onClick(e);
    }
  };

  return cloneElement(children, { onClick: handleClick, isLoading: disabled });
};

export default RateLimit;
