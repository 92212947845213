import { useMemo } from 'react';

import { t } from '@lingui/macro';
import Flare, { Axis, FlareSeriesOptions, Tooltip } from '@shared/Flare';
import Bar from '@shared/Flare/marks/Bar';
import { AudienceOptionType } from 'api/audience';
import { WebTrafficIndustrySummaryDataType } from 'api/webTraffic';
import { GREEN_400 } from 'constants/colors/semantic/green';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  data?: WebTrafficIndustrySummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error?: SolGraphQLError;
};

const WebTrafficIndustriesChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'companies',
        name: t`Companies`,
        data: data.map((slice) => ({
          name: slice.sector.name,
          amount: slice.engagedCompanies,
          custom: {
            sectorId: slice.sector.id,
            companyPercent: slice.percentOfTotalCompanies,
            visits: slice.count,
            visitsPercent: slice.percentOfTotal,
          },
        })),
      },
    ];
  }, [data]);

  return (
    <Flare
      data={seriesData}
      description={t`Top industries by number of companies and visits`}
      colors={[GREEN_400]}
      isLoading={isLoading}
      error={error}
      marginTop={5}
      marginBottom={5}
      marginLeft={0}
      marginRight={0}
      paddingLeft={5}
      paddingRight={5}
    >
      <Bar
        y="amount"
        position="dodge"
        minBarHeight={40}
        groupPadding={0.2}
        showDataLabels
        dataLabelFormat={(label) => (label.y === 0 ? '' : numberFormat(label.y))}
        skeletonLoaderOptions={{
          numSeries: audience?.id ? 3 : 2,
          numCategories: 3,
          sortDescending: true,
        }}
      />
      <Axis
        position="right"
        labelFormat={(item) => numberFormat(item.value)}
        gridlineWidth={0}
        baselineWidth={1}
        tickAmount={5}
      />
      <Axis
        position="bottom"
        categories={data?.map((d) => d.sector.name) || []}
        crosshair="rect"
        baselineWidth={0}
        labelFormat={(item) =>
          `<div style="text-align: right; overflow: hidden; text-overflow: ellipsis;">${item.value}</div>`
        }
        useHTML
      />
      <Tooltip
        titleFormat={(item) => item?.key}
        rowLabelFormat={(item) => `${item?.series.name}<br/>${t`Visits`}`}
        rowSecondaryValueFormat={(item) =>
          `(${numberFormat(item?.point.custom?.companyPercent, { isPercent: true })})<br/>(${numberFormat(item?.point.custom?.visitsPercent, { isPercent: true })})`
        }
        rowValueFormat={(item) =>
          `${numberFormat(item?.point.y)}<br/>${numberFormat(item?.point.custom?.visits)}`
        }
        valueLink={(item) => {
          return ROUTES.webTrafficActivityWithParams({
            'sector.id': item?.point.custom?.sectorId,
            'metrics.visits': '[1,]',
            audienceIds: audience?.id,
          });
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficIndustriesChart;
