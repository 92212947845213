import { THEME_KEY } from '../constants';
import { THEMES } from '../types';

/* Uncomment when implement dark mode
const isBrowserDefaultDark = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches
*/

export const getInitialTheme = (): THEMES => {
  const localStorageTheme = localStorage.getItem(THEME_KEY) as THEMES;
  // const browserDefault = isBrowserDefaultDark() ? THEMES.DARK : THEMES.LIGHT TODO: uncomment when implement dark mode
  const browserDefault = THEMES.LIGHT;
  return localStorageTheme || browserDefault;
};
