import { MouseEvent, useMemo } from 'react';

import { Plural, Trans } from '@lingui/macro';
import { InstantSearchTable } from '@shared/InstantSearch';
import { ColumnsType, FilterDropdown } from '@shared/Table';
import { ActionIcon } from '@shared/buttons';
import { RangeFilter } from '@shared/filters';
import { Link, Tag } from '@shared/typography';
import { Space } from 'antd';
import { CompanyDetailType } from 'api/activity';
import { AUDIENCE_PRIMARY, TAM_PRIMARY } from 'constants/colors';
import { USDateViewFormat } from 'constants/formats';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';
import { getRevenueRangeDescription } from 'messages/revenue-range-name-messages';
import moment from 'moment';
import { ROUTES } from 'router';

import AudienceFilter from './AudienceFilter';
import CompanyLogo from './CompanyLogo';
import RegionFilter from './RegionFilter';
import RevenueRangeFilter from './RevenueRangeFilter';
import SectorFilter from './SectorFilter';
import { type ALL_WEB_TRAFFIC_FIELDS, getWebTrafficFieldName } from './webTrafficFields';

type Props = {
  data?: CompanyDetailType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: SolGraphQLError;
  onSelectedRowChange?: (row: CompanyDetailType | null) => void;
};

const WebTrafficActivityTable = ({
  data,
  totalResults,
  isLoading,
  error,
  onSelectedRowChange,
}: Props) => {
  const sortFields = ROUTES.webTrafficActivity.searchParams.sortBy.values;

  const columns = useMemo<ColumnsType<CompanyDetailType>>(
    () => [
      {
        title: getWebTrafficFieldName('name'),
        key: 'name',
        fixed: 'left',
        sorter: sortFields.indexOf('name') >= 0,
        width: 150,
        render: (text, field) => (
          <Space>
            <CompanyLogo size="small" name={field.name} domain={field.tld} />
            <Link variant="caption1">{field.name || field.tld}</Link>
          </Space>
        ),
      },
      {
        title: getWebTrafficFieldName('region.id'),
        key: 'region.id',
        sorter: sortFields.indexOf('region.id') >= 0,
        align: 'right',
        width: 120,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RegionFilter />
          </FilterDropdown>
        ),
        render: (text, field) => field.region?.name,
      },
      {
        title: getWebTrafficFieldName('sector.id'),
        key: 'sector.id',
        sorter: sortFields.indexOf('sector.id') >= 0,
        width: 110,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <SectorFilter />
          </FilterDropdown>
        ),
        render: (text, field) => field.sector?.name,
      },
      {
        title: getWebTrafficFieldName('revRange.id'),
        key: 'revRange.id',
        sorter: sortFields.indexOf('revRange.id') >= 0,
        align: 'right',
        width: 110,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RevenueRangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => getRevenueRangeDescription(field.revRange?.id),
      },
      {
        title: getWebTrafficFieldName('audienceIds'),
        key: 'audienceIds',
        align: 'right',
        width: 130,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <AudienceFilter />
          </FilterDropdown>
        ),
        render: (text, field) => (
          <Space size={4}>
            {field.audiences.length > 0 && (
              <Tag size="medium" color={AUDIENCE_PRIMARY} noMargin>
                <Plural value={field.audiences.length} one="# Audience" other="# Audiences" />
              </Tag>
            )}
            {field.inTam && (
              <Tag size="medium" color={TAM_PRIMARY} noMargin>
                <Trans>TAM</Trans>
              </Tag>
            )}
          </Space>
        ),
      },
      {
        title: getWebTrafficFieldName('metrics.impressions'),
        key: 'metrics.impressions',
        sorter: sortFields.indexOf('metrics.impressions') >= 0,
        align: 'right',
        width: 95,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => numberFormat(field.metrics?.impressions),
      },
      {
        title: getWebTrafficFieldName('metrics.visits'),
        key: 'metrics.visits',
        sorter: sortFields.indexOf('metrics.visits') >= 0,
        align: 'right',
        width: 65,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) => numberFormat(field.metrics?.visits),
      },
      {
        title: getWebTrafficFieldName('metrics.lastActivityDate'),
        key: 'metrics.lastActivityDate',
        align: 'right',
        sorter: sortFields.indexOf('metrics.lastActivityDate') >= 0,
        width: 80,
        defaultSortOrder: 'descend',
        render: (text, field) =>
          field.metrics?.lastActivityDate
            ? moment(field.metrics?.lastActivityDate).format(USDateViewFormat)
            : `\u2013`,
      },
      {
        title: '',
        key: 'actions',
        align: 'right',
        width: 40,
        render: (text, field) => (
          <ActionIcon
            color="black"
            icon={<img src="/assets/images/linkedin-icon.png" />}
            to={`https://www.linkedin.com/search/results/companies?keywords=${field.name ?? field.tld}`}
            target="_blank"
            onClick={(e?: MouseEvent) => e?.stopPropagation()}
          />
        ),
      },
    ],
    [],
  );

  return (
    <InstantSearchTable<CompanyDetailType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No web traffic activity found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      rowCursor="pointer"
      sticky
      scroll={{ x: 1200 }}
      getFieldName={(fieldId: string) =>
        getWebTrafficFieldName(fieldId as keyof typeof ALL_WEB_TRAFFIC_FIELDS)
      }
      onRow={(record) => ({
        onClick: () => onSelectedRowChange?.(record),
      })}
    />
  );
};

export default WebTrafficActivityTable;
