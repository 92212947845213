import { PropsWithChildren } from 'react';

import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import fetcher from 'api/Fetcher';
import { useLogOut } from 'hooks/useLogOut';
import { useMe } from 'providers/User';
import { Navigate } from 'react-router-dom';

import { ROUTES } from './routes';

const AuthenticatedRoute = ({ children }: PropsWithChildren) => {
  const stytch = useStytchB2BClient();
  const user = useMe();
  const { logOut } = useLogOut();

  if (!user) {
    return <Navigate to={ROUTES.signIn.path} replace />;
  }

  fetcher.setUserData(user, stytch, logOut);
  return <>{children}</>;
};

export default AuthenticatedRoute;
