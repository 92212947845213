import { msg } from '@lingui/macro';

import { ErrorByCodeType } from './types';

export enum PIXEL_ERROR_CODES {
  INVALID_PIXEL_CODE = 'err:sol.pixel.invalid-code',
  TAG_CREATION_FAILURE = 'err:sol.pixel.tag-creation-failure',
  TAG_DUPLICATE = 'err:sol.pixel.tag-duplicate',
}

const INTERNAL_PIXEL_ERROR = msg`An error occurred with this Pixel. Please contact support for assistance.`;

export const PIXEL_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [PIXEL_ERROR_CODES.INVALID_PIXEL_CODE]: INTERNAL_PIXEL_ERROR,
  [PIXEL_ERROR_CODES.TAG_CREATION_FAILURE]: msg`An error occurred creating the Pixel. Please contact support for assistance.`,
  [PIXEL_ERROR_CODES.TAG_DUPLICATE]: msg`An error occurred creating the Pixel. Please refresh the page and try again. If the issue persists, please contact support.`,
};
