import { Trans, t } from '@lingui/macro';
import TooltipRow from '@shared/Flare/guides/TooltipRow';
import TooltipTitle from '@shared/Flare/guides/TooltipTitle';
import { QueryStateFilterOperator, useInstantSearchState } from '@shared/InstantSearch';
import { isFilterRange } from '@shared/InstantSearch/util/filter-util';
import Popover from '@shared/Popover';
import Spin from '@shared/Spin';
import { Link } from '@shared/typography';
import { Space } from 'antd';
import { useFilterNames } from 'api/common';

type Props = {
  className?: string;
  getFieldName?: (fieldId: string) => string;
};

const FilterDetails = ({ className, getFieldName }: Props) => {
  const {
    queryState: { filters },
    updateQueryState,
  } = useInstantSearchState();

  const { filtersWithNames, isLoading, error } = useFilterNames(filters);

  // Don't render one if there are no filters
  if (!filters?.length) {
    return null;
  }

  const handleFiltersClear = () => {
    updateQueryState({ filters: [], page: 1 });
  };

  const getFilterText = (operator: QueryStateFilterOperator, operand: string) => {
    const operandText = operand === '' ? t`(Blanks)` : operand;
    if (operator === 'notIn') {
      return t`NOT ${operandText}`;
    }
    return operandText;
  };

  return (
    <Space className={className} size="middle">
      <Link variant="caption1" onClick={handleFiltersClear}>
        <Trans>Clear Filters</Trans>
      </Link>
      <Popover
        variant="dark"
        trigger="click"
        placement="left"
        arrow
        title={
          <table data-testid="filter-details-popover-content">
            <thead>
              <TooltipTitle>
                <Trans>View Currently Filtered by:</Trans>
              </TooltipTitle>
            </thead>
            <tbody>
              {isLoading && <Spin />}
              {!isLoading &&
                (error ? filters : filtersWithNames).map((filter) => {
                  let filterValueText = '';
                  if (isFilterRange(filter)) {
                    if (filter.min) {
                      filterValueText = filter.min.inclusive ? '>=' : '>';
                      filterValueText += ` ${filter.min.operand}`;
                    }
                    if (filter.max) {
                      if (filterValueText.length) {
                        filterValueText += '<br/>';
                      }
                      filterValueText += filter.max.inclusive ? '<=' : '<';
                      filterValueText += ` ${filter.max.operand}`;
                    }
                  } else {
                    if (Array.isArray(filter.operand)) {
                      filterValueText = filter.operand
                        .map((operand) => getFilterText(filter.operator, operand))
                        .join('<br/>');
                    } else {
                      filterValueText = getFilterText(filter.operator, filter.operand);
                    }
                  }
                  return (
                    <TooltipRow
                      key={filter.field}
                      name={getFieldName?.(filter.field) ?? filter.field}
                      value={filterValueText}
                      valueOverflow
                    />
                  );
                })}
            </tbody>
          </table>
        }
      >
        <Link variant="caption1">
          <Trans>Filter Details ({filters.length})</Trans>
        </Link>
      </Popover>
    </Space>
  );
};

export default FilterDetails;
