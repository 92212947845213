import { PropsWithChildren } from 'react';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import excel from 'react-syntax-highlighter/dist/esm/languages/prism/excel-formula';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import markdown from 'react-syntax-highlighter/dist/esm/languages/prism/markdown';
import markup from 'react-syntax-highlighter/dist/esm/languages/prism/markup';
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import tsx from 'react-syntax-highlighter/dist/esm/languages/prism/tsx';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Text } from '../Text';
import styles from './Code.module.scss';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('excelFormula', excel);
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('markup', markup);
SyntaxHighlighter.registerLanguage('sql', sql);
SyntaxHighlighter.registerLanguage('tsx', tsx);

export type SyntaxLanguagage =
  | 'bash'
  | 'csv'
  | 'excel'
  | 'javascript'
  | 'json'
  | 'html'
  | 'markdown'
  | 'sql'
  | 'text'
  | 'typescript';

type Props = {
  title?: string;
  language?: SyntaxLanguagage | string | false;
};

const getSyntaxLanguage = (language: string) => {
  switch (language) {
    case 'bash':
    case 'csv':
    case 'javascript':
    case 'json':
    case 'markdown':
    case 'sql':
    case 'text':
    case 'typescript':
      return language;
    case 'excel':
      return 'excelFormula';
    case 'html':
      return 'markup';
    default:
      return undefined;
  }
};

const Code = ({ language, children }: PropsWithChildren<Props>) => {
  const syntaxLang = getSyntaxLanguage(language as string);

  return (
    <>
      {syntaxLang ? (
        <div className={styles.container}>
          <div className={styles.title}>
            <Text variant="caption1" color="white">
              {syntaxLang}
            </Text>
          </div>
          <SyntaxHighlighter
            className={styles.codeBlock}
            PreTag="div"
            language={syntaxLang}
            style={prism}
          >
            {String(children)}
          </SyntaxHighlighter>
        </div>
      ) : (
        <code className={styles.code}>{children}</code>
      )}
    </>
  );
};

export default Code;
