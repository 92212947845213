import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { ConfirmModal } from '@shared/modals';
import { Link, Text, Title } from '@shared/typography';
import { Tooltip } from 'antd';
import { IntegrationConnectionType, mutateIntegrationConnectionDelete } from 'api/integration';
import moment from 'moment';

import ConnectionOrg from './ConnectionOrg';
import styles from './IntegrationConnectionListItem.module.scss';

type Props = {
  providerName: string;
  connection: IntegrationConnectionType;
  onDisconnect: (connection: IntegrationConnectionType) => void;
};

const IntegrationConnectionListItem = ({ providerName, connection, onDisconnect }: Props) => {
  const [confirmDisconnect, setConfirmDisconnect] = useState<boolean>(false);
  const [disconnectLoading, setDisconnectLoading] = useState<boolean>(false);
  const author = connection.createdBy;
  const orgName = connection?.organizationName;
  const orgId = connection?.organizationId;

  const handleDisconnect = async () => {
    // don't worry about closing the modal. This component (including the modal) will be unmounted
    // when the refresh happens and the connection no longer exists.
    setDisconnectLoading(true);
    await mutateIntegrationConnectionDelete(connection.id!);
    onDisconnect(connection);
    setDisconnectLoading(false);
    setConfirmDisconnect(false);
  };

  return (
    <div data-testid="integration-detail-connectionItem" className={styles.container}>
      <Text variant="label" color="dark-grey">
        {author ? author.name : t`Unknown user`}
      </Text>
      <Tooltip title={author?.email}>
        <Text variant="body1" className={styles.email}>
          {author ? author.email : t`Unknown email address`}
        </Text>
      </Tooltip>
      <ConnectionOrg orgId={orgId} orgName={orgName} />
      {connection.status === 'active' ? (
        <Text variant="body2" color="grey">
          {connection.lastSuccessfulJob ? (
            <Trans>
              Last sync completed {moment(connection.lastSuccessfulJob.created_at).fromNow()}.
            </Trans>
          ) : (
            <Trans>Sync in process.</Trans>
          )}{' '}
          <Trans>{providerName} sync attempts to update every 6 hours.</Trans>
        </Text>
      ) : (
        <Text variant="footnote" color="red" weight="regular">
          <Trans>There is a problem with this account, please refresh.</Trans>
        </Text>
      )}
      <Link variant="body2" color="static-grey" onClick={() => setConfirmDisconnect(true)}>
        <Trans>Disconnect {providerName}</Trans>
      </Link>
      <ConfirmModal
        open={confirmDisconnect}
        okText={t`Yes, Disconnect`}
        isLoading={disconnectLoading}
        onCancel={() => setConfirmDisconnect(false)}
        onOk={handleDisconnect}
      >
        <Trans>
          Do you really want to disconnect{' '}
          <Title level="4" weight="medium" inline>
            {author?.email || t`this account`}
          </Title>
          ? {providerName} data will not be available if you continue.
        </Trans>
      </ConfirmModal>
    </div>
  );
};

export default IntegrationConnectionListItem;
