import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  size?: 'small' | 'medium' | 'large';
  hideHeader?: boolean;
  numRows?: number;
  numColumns?: number;
};

const TableLoader = ({
  size = 'medium',
  hideHeader = false,
  numRows = 5,
  numColumns = 3,
}: Props) => {
  const theadHeight = size === 'small' ? 20 : 30;
  const rowHeight = 2;
  const vGap = size === 'small' ? 24 : 48;
  const hGap = 3;

  const colWidth = 120 / numColumns - hGap;

  let xCursor = 0;
  let yCursor = 0;

  const renderColumn = (rowIndex: number, colIndex: number, height: number) => {
    // First row is a touch longer
    const width = xCursor === 0 ? colWidth + hGap : colWidth;
    const col = (
      <rect key={`${rowIndex}-${colIndex}`} x={xCursor} y={yCursor} width={width} height={height} />
    );

    xCursor += width + hGap;
    return col;
  };

  const renderRow = (rowIndex: number, height: number) => {
    const row = [...Array(numColumns)].map((d, i) => renderColumn(rowIndex, i, height));

    xCursor = 0;
    yCursor += height + vGap;
    return row;
  };

  const calculatedHeight =
    (hideHeader ? 0 : theadHeight + vGap) + (numRows * (rowHeight + vGap) - vGap);

  return (
    <SkeletonLoader
      viewBox={`0 0 120 ${calculatedHeight}`}
      height={calculatedHeight}
      width="100%"
      preserveAspectRatio="none"
    >
      {!hideHeader && renderRow(-1, theadHeight)}
      {[...Array(numRows)].map((d, i) => renderRow(i, rowHeight))}
    </SkeletonLoader>
  );
};

export default TableLoader;
