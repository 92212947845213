import { Trans } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import Notification from '@shared/Notification';
import { Button } from '@shared/buttons';
import { clearForeignSuperuserInstance } from 'helper/common';
import { useLogOut } from 'hooks/useLogOut';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';

import styles from './LoginAsNotification.module.scss';

type Props = {
  instanceId: string;
};

const LoginAsNotification = ({ instanceId }: Props) => {
  const navigate = useNavigate();
  const { clearCacheOnly } = useLogOut();

  const handleReturnToHomeOrg = () => {
    clearForeignSuperuserInstance();
    clearCacheOnly();
    navigate(ROUTES.app.path);
  };

  return (
    <Notification className={styles.notification} type="info" size="large">
      <Flex justify="space-between" align="center">
        <Trans>REMINDER! You are currently an Admin in instance: "{instanceId}"</Trans>
        <Button color="white" onClick={handleReturnToHomeOrg}>
          <Trans>Return To Your Org</Trans>
        </Button>
      </Flex>
    </Notification>
  );
};

export default LoginAsNotification;
