/* eslint-disable no-restricted-imports */
import { PropsWithChildren } from 'react';

import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';
import classNames from 'classnames';
import { isLowLuminance } from 'helper/colorHelper';

import styles from './Tag.module.scss';

export type TagProps = Omit<AntdTagProps, 'color'> & {
  size?: 'small' | 'medium' | 'large';
  color?: 'success' | 'error' | 'info' | 'default' | string;
  variant?: 'outlined' | 'solid';
  noMargin?: boolean;
  isDisabled?: boolean;
};

const Tag = ({
  children,
  className = '',
  size = 'medium',
  color = 'default',
  variant = 'outlined',
  noMargin = false,
  closable = false,
  isDisabled = false,
  ...props
}: PropsWithChildren<TagProps>) => {
  const isCustomColor =
    color !== 'success' && color !== 'error' && color !== 'info' && color !== 'default';
  const colorThemeClassName = isCustomColor ? 'default' : color;
  const textColor = isCustomColor && isLowLuminance(color) ? 'light' : 'dark';

  return (
    <AntdTag
      {...props}
      closable={!isDisabled && closable}
      className={classNames(
        styles.tag,
        className,
        styles[`size-${size}`],
        styles[`variant-${variant}`],
        styles[`text-color-${textColor}`],
        {
          [styles[`color-${colorThemeClassName}`]]: color != null,
          [styles.closable]: closable,
          [styles.noMargin]: noMargin,
          [styles.disabled]: isDisabled,
        },
      )}
      style={{
        backgroundColor: isCustomColor ? color : undefined,
        borderColor: isCustomColor && variant === 'outlined' ? color : undefined,
      }}
    >
      <span className={styles.content} title={typeof children === 'string' ? children : undefined}>
        {children}
      </span>
    </AntdTag>
  );
};

export default Tag;
