import { PropsWithChildren } from 'react';

import { ActionIcon } from '@shared/buttons';
import { Text } from '@shared/typography';
import ClearIcon from 'assets/svg/bx-x.svg?react';
import classNames from 'classnames';

import styles from './Notification.module.scss';

type Props = {
  className?: string;
  type?: 'default' | 'success' | 'error' | 'info';
  size?: 'medium' | 'large';
  onClose?: () => void;
};

const Notification = ({
  className,
  type = 'default',
  size = 'medium',
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      data-testid="notification"
      className={classNames(
        styles.container,
        className,
        styles[`type-${type}`],
        styles[`size-${size}`],
      )}
    >
      <Text className={styles.content} variant="caption1" weight="semi-bold">
        {children}
      </Text>
      {onClose && (
        <ActionIcon
          color={type === 'error' || type === 'info' ? 'white' : 'black'}
          size="small"
          icon={<ClearIcon />}
          onClick={onClose}
        />
      )}
    </div>
  );
};

export default Notification;
