export const ORANGE_100 = '#F4D1B7';
export const ORANGE_200 = '#EEBB94';
export const ORANGE_300 = '#E9A470';
export const ORANGE_400 = '#E38D4C';
export const ORANGE_500 = '#B6713D';
export const ORANGE_600 = '#88552E';
export const ORANGE_700 = '#5B381E';
export const ORANGE_800 = '#2D1C0F';

export const ALL_ORANGES = [
  ORANGE_100,
  ORANGE_200,
  ORANGE_300,
  ORANGE_400,
  ORANGE_500,
  ORANGE_600,
  ORANGE_700,
  ORANGE_800,
];

export const ALL_SEMANTIC_ORANGES = [
  ORANGE_300,
  ORANGE_400,
  ORANGE_500,
  ORANGE_600,
  ORANGE_700,
  ORANGE_800,
];
