import { useCallback, useEffect } from 'react';

/**
 * Registers a click event listener on the document to check if the click is outside of the
 * supplied element(s). If true, the callback function is called.
 *
 * @param el One or more elements to check if the click is outside of
 * @param onClickOutside Callback function to call when the click is outside of the element(s)
 */
const useOnClickOutside = <T extends HTMLElement | undefined | null>(
  el: T | T[],
  onClickOutside: (event: MouseEvent) => void,
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;

      // Do nothing if the target is not contained in the document
      if (!target || !target.isConnected) {
        return;
      }

      // Do nothing if the target is part of a modal or a dropdown
      if (
        target instanceof Element &&
        (target.closest('.ant-modal-root') || target.closest('.ant-picker-dropdown'))
      ) {
        return;
      }

      const isOutside = Array.isArray(el)
        ? el.filter((element) => Boolean(element)).every((element) => !element?.contains(target))
        : !el?.contains(target);

      if (isOutside) {
        onClickOutside(event);
      }
    },
    Array.isArray(el) ? el : [el],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, { capture: true });

    return () => document.removeEventListener('mousedown', handleClickOutside, { capture: true });
  }, [handleClickOutside]);
};

export default useOnClickOutside;
