import { PAGINATION_PARAM_KEYS } from 'router/searchParams/defaultPaginationParams';
import { USER_SORT_KEYS } from 'router/searchParams/userSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from 'router/types';

export const users = {
  path: '/app/users',
  description:
    'A list of users for the company. Each user is shown with their name, email, role, and status. New users can also be invited from this page.',
  navigable: true,
  searchParams: {
    page: {
      type: 'string',
      description: 'The page number to display',
    },
    size: {
      type: 'string',
      description: 'The number of items to display per page',
    },
    sortBy: {
      type: 'enum',
      values: ['email', 'name', 'role', 'status'],
      default: 'name',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<USER_SORT_KEYS>,
    sortOrder: {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'asc',
      description: 'The sort order',
    },
    search: {
      type: 'string',
      description: 'A string to search for in the user name, email, or role',
    },
  },
} satisfies NavigableRouteType<PAGINATION_PARAM_KEYS>;

export const userInvite = {
  path: '/app/users/invite',
  description:
    'A form where an admin can fill out the name, email and desired permission level for a new user (user or admin).',
  navigable: true,
};

export const userDetail = {
  path: '/app/users/:user',
  description:
    "A page where an admin can view and edit a user's name, email, and permission level. The admin can also delete the user from Channel99 here.",
  navigable: true,
};

export const profile = {
  path: '/app/profile',
  description:
    "The logged-in user's profile page. The user can change their name or log out from this page.",
  navigable: true,
};

export const loginAs = {
  path: '/app/profile/login-as',
  navigable: false,
};

export const userById = (userId?: string) => `${users.path}/${userId}`;
