import { useEffect } from 'react';

import { LegendItem, Legend as SharedLegend } from '@shared/Legend';
import { Series, SeriesOptionsType } from 'highcharts';

import { useFlareContext } from '../FlareContext';

type Props = {
  reversed?: boolean;
  noToggleVisibility?: boolean;
  showByCategory?: boolean;
};

type SeriesOptionsTypeData = {
  name: string;
  color: string;
};

type SeriesOptionsTypeEnhanced = {
  data: SeriesOptionsTypeData[];
  color: string;
} & SeriesOptionsType;

const Legend = ({ reversed = false, noToggleVisibility = false, showByCategory }: Props) => {
  const { id, options, registerChild, chart } = useFlareContext();
  const canToggle = !noToggleVisibility;

  useEffect(() => {
    registerChild(id, (options: Highcharts.Options) => {
      return {
        ...options,
        // turn off legend since we're rendering an HTML one below
        legend: {
          enabled: false,
        },
      };
    });
  }, []);

  const handleSeriesToggle = (seriesOptions: SeriesOptionsType, selected: boolean) => {
    if (chart && seriesOptions.id) {
      const series = chart.get(seriesOptions.id) as Series;
      series?.setVisible(selected);
    }
  };

  // Wait until Flare has processed options merging and have Legend updates.
  if (!options?.legend) {
    return null;
  }

  let updatedSeries = (options?.series || []) as SeriesOptionsTypeEnhanced[];
  if (reversed) {
    updatedSeries = updatedSeries.slice().reverse();
  }

  return (
    <SharedLegend>
      {showByCategory
        ? updatedSeries
            .reduce<SeriesOptionsTypeData[]>((memo, series) => memo.concat(series?.data), [])
            .map((d, index) => (
              <LegendItem key={(d.name || '') + index} color={d.color} name={d.name} />
            ))
        : updatedSeries.map(
            (series, index) =>
              series.showInLegend !== false && (
                <LegendItem
                  key={(id || '') + index}
                  color={typeof series.color === 'string' ? series.color : null}
                  name={series.name ? series.name : `Series ${index + 1}`}
                  onChange={
                    canToggle ? (selected) => handleSeriesToggle(series, selected) : undefined
                  }
                />
              ),
          )}
    </SharedLegend>
  );
};

export default Legend;
