import { createContext, useContext } from 'react';

import { EventLogType } from 'api/eventLog';

export type NotesDrawerContextType = {
  eventLog?: EventLogType[];
  selectedNote?: EventLogType;
  onSelectedNoteChange: (note?: EventLogType) => void;
  isNotesDrawerOpen: boolean;
  onNotesDrawerOpenChange: (isOpen: boolean) => void;
};

const NotesDrawerContext = createContext<NotesDrawerContextType | null>(null);

export const useNotesDrawerContext = () => {
  const context = useContext(NotesDrawerContext);
  if (!context) {
    throw new Error('useNotesDrawerContext must be used within a NotesDrawerContext Provider');
  }
  return context;
};

export default NotesDrawerContext;
