import { useMemo } from 'react';

import { Trans } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import { Button } from '@shared/buttons';
import { Tag } from '@shared/typography';
import { Space } from 'antd';
import { PixelType } from 'api/pixel';
import { FEATURE_FLAG, useFeatureFlag } from 'constants/featureFlags';
import { ROUTES } from 'router';

type Props = {
  selectedPixels: PixelType[];
  onSelectedPixelClose: (pixel: PixelType) => void;
};

const PixelTableActionContent = ({ selectedPixels, onSelectedPixelClose }: Props) => {
  const hasGroupedPixelFeat = useFeatureFlag(FEATURE_FLAG.groupedPixel);
  const selectedPixelIds = useMemo(() => selectedPixels.map((pixel) => pixel.id), [selectedPixels]);

  return (
    <Space direction="horizontal" size="middle" align="start">
      <Flex vertical gap="small">
        <Button
          color="black"
          variant="secondary"
          isDisabled={selectedPixels.length < 2}
          to={ROUTES.pixelCompareByIds(selectedPixelIds)}
        >
          <Trans>Compare{selectedPixels.length > 0 ? ` (${selectedPixels.length})` : ''}</Trans>
        </Button>
        {hasGroupedPixelFeat && (
          <Button
            color="black"
            variant="secondary"
            isDisabled={selectedPixels.length < 2}
            to={ROUTES.pixelGroupByIds(selectedPixelIds)}
          >
            <Trans>Group{selectedPixels.length > 0 ? ` (${selectedPixels.length})` : ''}</Trans>
          </Button>
        )}
      </Flex>
      <Space direction="horizontal" size={[0, 12]} wrap>
        {selectedPixels.map((pixel) => (
          <Tag key={pixel.id} closable onClose={() => onSelectedPixelClose(pixel)}>
            {pixel.name}
          </Tag>
        ))}
      </Space>
    </Space>
  );
};

export default PixelTableActionContent;
