import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import Form, { PasswordInput } from '@shared/Form';
import { ConfirmModal } from '@shared/modals';
import { Title } from '@shared/typography';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { maxPasswordLength } from 'constants/numbers';
import { getStytchAPIErrorMessage } from 'error/StytchError';
import useUpdatePassword from 'hooks/useUpdatePassword';
import { useNotification } from 'providers/Notification';

import styles from './UpdatePasswordModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

enum FORM_FIELD {
  NEW_PASSWORD = 'newPassword',
  NEW_PASSWORD_CONFIRM = 'newPasswordConfirm',
}

type FormType = {
  [FORM_FIELD.NEW_PASSWORD]: string;
  [FORM_FIELD.NEW_PASSWORD_CONFIRM]: string;
};

const UpdatePasswordModal = ({ open, onClose }: Props) => {
  const { pushNotification } = useNotification();
  const [form] = Form.useForm();
  const stytch = useStytchB2BClient();
  const { validatePassword, validateConfirmPassword } = useUpdatePassword({
    form,
    newPasswordFieldName: FORM_FIELD.NEW_PASSWORD,
    confirmPasswordFieldName: FORM_FIELD.NEW_PASSWORD_CONFIRM,
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFinish = async (values: FormType) => {
    setIsSaving(true);

    try {
      await stytch.passwords.resetBySession({
        password: values[FORM_FIELD.NEW_PASSWORD],
      });

      pushNotification({
        type: 'success',
        message: t`Your password has been updated. You have been logged out of all other devices.`,
      });

      handleClose();
    } catch (e) {
      pushNotification({
        type: 'error',
        message: getStytchAPIErrorMessage(e),
      });
    }

    setIsSaving(false);
  };

  const handleUpdatePassword = () => {
    form.submit();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === 'Enter') {
      form.submit();
    }
  };

  return (
    <ConfirmModal
      open={open}
      okText={<Trans>Update</Trans>}
      isLoading={isSaving}
      onCancel={handleClose}
      onOk={handleUpdatePassword}
    >
      <Form
        className={styles.form}
        form={form}
        size="large"
        validateTrigger={['onSubmit', 'onBlur']}
        onFinish={handleFinish}
        onKeyDown={handleKeyDown}
      >
        <Title level="2" weight="light">
          <Trans>Change Password</Trans>
        </Title>
        <div className={styles.formBody}>
          <Form.Item
            label={<Trans>New Password</Trans>}
            name={FORM_FIELD.NEW_PASSWORD}
            initialValue=""
            required={false}
            rules={[{ validator: validatePassword }]}
          >
            <PasswordInput autoComplete="new-password" maxLength={maxPasswordLength} />
          </Form.Item>
          <Form.Item
            label={<Trans>Confirm Password</Trans>}
            name={FORM_FIELD.NEW_PASSWORD_CONFIRM}
            initialValue=""
            required={false}
            rules={[{ validator: validateConfirmPassword }]}
          >
            <PasswordInput autoComplete="new-password" maxLength={maxPasswordLength} />
          </Form.Item>
        </div>
      </Form>
    </ConfirmModal>
  );
};

export default UpdatePasswordModal;
