import { useEffect } from 'react';

import { GRAY_800 } from 'constants/colors/semantic/gray';
import {
  DataLabelsOptions,
  Options,
  PointLabelObject,
  PointOptionsObject,
  SeriesPieOptions,
} from 'highcharts';

import { useFlareContext } from '../FlareContext';
import DonutLoader from '../loaders/DonutLoader';
import { FlareDataPointOptions, FlareSeriesOptions, SanitizedFlareProps } from '../types';

type DonutProps = {
  innerSize?: number;
  y?: string;
  dataLabelFormat?: (
    label: PointLabelObject,
    options: DataLabelsOptions,
  ) => number | string | null | undefined;
  hideDataLabels?: boolean;
  noBorder?: boolean;
  noHoverEffect?: boolean;
  hideConnector?: boolean;
  dataLabelColor?: string;
  hiddenDataLabelThreshold?: number;
};

const buildDonutOptions = (
  options: Options,
  parentProps: SanitizedFlareProps,
  props: DonutProps,
): Options => {
  const { data, colors } = parentProps;
  const {
    y = 'y',
    innerSize = 0.67,
    dataLabelFormat,
    hideDataLabels = false,
    noBorder = false,
    noHoverEffect = false,
    hideConnector = false,
    dataLabelColor,
    hiddenDataLabelThreshold = 0.05,
  } = props;

  const series: SeriesPieOptions[] | undefined = data?.map((series: FlareSeriesOptions) => {
    const { name, data: seriesData } = series;

    const totalYVal = seriesData.reduce(
      (memo: number, item: FlareDataPointOptions) => memo + item[y],
      0,
    );

    const newSeriesData = seriesData.map((item: FlareDataPointOptions, pointIndex: number) => {
      const yVal = item[y];
      const yValPercentage = yVal / totalYVal;

      const newItem: PointOptionsObject = {
        ...item,
        y: yVal,
        color: colors[pointIndex],
      };
      delete newItem[y as keyof PointOptionsObject];

      if (yValPercentage <= hiddenDataLabelThreshold && !hideDataLabels) {
        newItem.dataLabels = {
          enabled: true,
          distance: 35,
        };
      }

      return newItem;
    });

    return {
      type: 'pie',
      name,
      data: newSeriesData,
    };
  });

  return {
    ...options,
    chart: {
      ...options.chart,
      type: 'pie',
    },
    series,
    title: {
      ...options.title,
      verticalAlign: 'middle',
    },
    plotOptions: {
      ...options.plotOptions,
      pie: {
        borderColor: noBorder ? 'transparent' : '#fff',
        borderRadius: 0,
        innerSize: innerSize * 100 + '%',
        dataLabels: {
          style: {
            color: dataLabelColor ?? GRAY_800,
          },
          enabled: !hideDataLabels,
          useHTML: true,
          distance: (-(1 - innerSize) / 2) * 100 + '%',
          overflow: 'allow',
          crop: false,
          formatter: function (options) {
            return dataLabelFormat ? dataLabelFormat(this, options) : this.key;
          },
          connectorWidth: hideConnector ? 0 : 1,
        },
        slicedOffset: 6,
        states: {
          hover: {
            enabled: !noHoverEffect,
            animation: {
              duration: 200,
            },
          },
          normal: {
            animation: {
              duration: 200,
            },
          },
          inactive: {
            enabled: !noHoverEffect,
            animation: {
              duration: 200,
            },
          },
        },
      },
    },
  };
};

const Donut = (props: DonutProps) => {
  const { id, registerChild, isLoading } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options: Options, parentProps: SanitizedFlareProps) =>
      buildDonutOptions(options, parentProps, props),
    );
  }, [props]);

  if (isLoading) {
    return <DonutLoader style={{ flex: 1 }} />;
  }

  return null;
};

export default Donut;
