import { ReactNode } from 'react';

import { SearchInput } from '@shared/Form';
import { useInstantSearchState } from '@shared/InstantSearch';
import classNames from 'classnames';

import styles from './TableActions.module.scss';

type Props = {
  className?: string;
  size?: 'small' | 'medium' | 'large';
  actions?: ReactNode | ReactNode[];
  hideSearch?: boolean;
};

const TableActions = ({ className, size = 'large', actions, hideSearch = false }: Props) => {
  const { queryState, updateQueryState } = useInstantSearchState();

  const handleSearch = (search: string) => {
    updateQueryState({ search, page: 1 });
  };

  return (
    <div className={classNames(styles.container, className)}>
      {<div className={classNames(styles.actionsContainer)}>{actions && actions}</div>}
      {!hideSearch && (
        <SearchInput
          className={styles.search}
          size={size}
          value={queryState.search}
          onChange={handleSearch}
        />
      )}
    </div>
  );
};

export default TableActions;
