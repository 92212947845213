import { ChannelMappingRuleDataType } from 'api/channelMappingRules';
import isEqual from 'lodash/isEqual';

export const isRuleMissingRequiredFields = (item: ChannelMappingRuleDataType) => {
  return (
    item.vendor?.id == null ||
    item.vendor?.channel?.id == null ||
    (item.customParameters.length === 0 &&
      item.media.length === 0 &&
      item.referralDomains.length === 0 &&
      item.sources.length === 0)
  );
};

/**
 * Checks to see if a row exactly matches another row in the channel mapping table. This is an
 * error condition
 */
export const getMatchingRulesForRule = (
  item: ChannelMappingRuleDataType,
  data: ChannelMappingRuleDataType[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { priority, id: itemId, ...propsToCompare } = item;

  return (data || []).filter((otherItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { priority, id: otherItemId, ...otherPropsToCompare } = otherItem;
    return (
      itemId !== otherItemId && !otherItem.isC99 && isEqual(propsToCompare, otherPropsToCompare)
    );
  });
};
