import { DashboardWidgetComponentProps } from '@shared/Dashboard/types';
import PipelineTrendsChart from '@shared/charts/PipelineTrendsChart';
import { usePipelineWeeklyTrends } from 'api/pipeline';

const PipelineTrendsWidget = ({ dashboardFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date } = dashboardFilters;
  const { pipelineWeeklyTrends, isLoading, error } = usePipelineWeeklyTrends(start_date, end_date);

  return <PipelineTrendsChart isLoading={isLoading} data={pipelineWeeklyTrends} error={error} />;
};

export default PipelineTrendsWidget;
