import { CSSProperties } from 'react';

import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  style?: CSSProperties;
};

const AreaLoader = ({ style = {} }: Props) => (
  <SkeletonLoader style={style} viewBox="18.294 22.759 406.259 410.26" preserveAspectRatio="none">
    <path d="m164.069 267.576 33.633-77.953 42.32 63.479 54.382-46.826 37.333 38.886 75.645-114.701V51.965L331 167.318l-37.412-38.971-56.117 48.323-37.412-56.117-34.294 79.5-63.912-67.029-67.029 29.617v77.655l69.724-32.254 59.521 59.534zm243.307-114.817-76.382 115.354-37.412-38.971-56.117 48.323-37.412-56.117-34.294 79.5-63.912-73.265L34.818 257.2v145.5h372.559V152.759z" />
  </SkeletonLoader>
);

export default AreaLoader;
