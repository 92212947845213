import { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Results } from '@shared/Results';
import { Button } from '@shared/buttons';
import { ConfirmModal } from '@shared/modals';
import { Link } from '@shared/typography';
import {
  EventLogDataType,
  EventLogInputType,
  EventLogType,
  mutateNoteCreate,
  mutateNoteDelete,
  mutateNoteUpdate,
} from 'api/eventLog';
import { useNotification } from 'providers/Notification';

import styles from './NotesPanelDrawer.module.scss';
import NotesPanelDrawerEdit from './NotesPanelDrawerEdit';
import NotesPanelDrawerTable from './NotesPanelDrawerTable';

type Props = {
  isOpen?: boolean;
  data?: EventLogType[];
  isLoading?: boolean;
  error?: Error;
  selectedItem?: EventLogType;
  onSelectionChange?: (note?: EventLogType) => void;
  onNoteCreate?: (note: EventLogDataType) => void;
  onNoteUpdate?: (note: EventLogDataType) => void;
  onNoteDelete?: (noteId: string) => void;
};

const NotesPanelDrawer = ({
  data,
  isLoading,
  error,
  selectedItem,
  onSelectionChange,
  onNoteCreate,
  onNoteUpdate,
  onNoteDelete,
  isOpen,
}: Props) => {
  const { pushNotification, removeNotification } = useNotification();
  const [showAddNote, setShowAddNote] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState<EventLogType | undefined>(undefined);
  const [noteToDelete, setNoteToDelete] = useState<EventLogType | undefined>(undefined);
  const [isSavingNote, setSavingNote] = useState(false);

  const noNotes = data?.length === 0;

  useEffect(() => {
    if (!isOpen) {
      resetNoteForm();
    }
  }, [isOpen]);

  const resetNoteForm = () => {
    setShowAddNote(false);
    setNoteToEdit(undefined);
  };

  const handleSaveNote = async (note: EventLogInputType) => {
    setSavingNote(true);
    removeNotification({ type: 'error' });

    try {
      if (note.id) {
        const newNote = await mutateNoteUpdate(note.id, note.rawDetails.toString(), note.date);
        onNoteCreate?.(newNote);
      } else {
        const updatedNote = await mutateNoteCreate(note.rawDetails.toString(), note.date);
        onNoteUpdate?.(updatedNote);
      }
    } catch {
      pushNotification({
        type: 'error',
        message: t`Failed to save note. Please try again or contact support if the issue persists.`,
      });
    }
    setSavingNote(false);
    resetNoteForm();
  };

  const handleEditNote = (note: EventLogType) => {
    setShowAddNote(false);
    setNoteToEdit(note);
  };

  const handleDeleteNote = async () => {
    try {
      if (noteToDelete) {
        await mutateNoteDelete(noteToDelete.id);
        onNoteDelete?.(noteToDelete.id);
      }
    } catch {
      pushNotification({
        type: 'error',
        message: t`Failed to delete note. Please try again or contact support if the issue persists.`,
      });
    }
    setNoteToDelete(undefined);
  };

  return (
    <div className={styles.drawer}>
      {showAddNote || noteToEdit ? (
        <NotesPanelDrawerEdit
          defaultNote={noteToEdit}
          isSaving={isSavingNote}
          onSave={handleSaveNote}
          onCancel={resetNoteForm}
        />
      ) : noNotes ? (
        <Results
          className={styles.results}
          subtitle={
            <Trans>
              You have no notes during this time period. Add a note if anything interesting
              happened.
            </Trans>
          }
          extra={
            <Button color="green" onClick={() => setShowAddNote(true)}>
              <Trans>Add Note</Trans>
            </Button>
          }
        />
      ) : (
        <>
          <Link
            className={styles.actionLink}
            variant="caption1"
            onClick={() => setShowAddNote(true)}
          >
            <Trans>Add Note</Trans>
          </Link>
          <NotesPanelDrawerTable
            data={data}
            isLoading={isLoading}
            error={error}
            selectedItem={selectedItem}
            onSelectionChange={onSelectionChange}
            onNoteEdit={handleEditNote}
            onNoteDelete={setNoteToDelete}
          />
        </>
      )}
      <ConfirmModal
        open={noteToDelete != null}
        okText={<Trans>Delete</Trans>}
        onCancel={() => setNoteToDelete(undefined)}
        onOk={handleDeleteNote}
      >
        <Trans>Are you sure you want to delete this note?</Trans>
      </ConfirmModal>
    </div>
  );
};

export default NotesPanelDrawer;
