import { useMemo } from 'react';

import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';

import { fetcherSol } from '../swr-fetcher';
import { RecentDealVisitTrendsGQLResponse, RecentDealsGQLResponse } from './opportunityType';

export const useRecentDeals = () => {
  const { data, error, isLoading } = useSWR<RecentDealsGQLResponse>(
    {
      query: gql`
        query Opportunities {
          opportunities {
            recentDeals {
              id
              name
              account {
                company {
                  name
                }
                visits
                people
                vendors
              }
              openDate
              closeDate
              daysToClose
              amount
            }
          }
        }
      `,
    },
    fetcherSol,
    {},
  );

  return {
    recentDeals: data?.opportunities.recentDeals,
    isLoading,
    error,
  };
};

export const useRecentDealVisitTrends = (opportunityId?: string) => {
  const { data, error, isLoading } = useSWR<RecentDealVisitTrendsGQLResponse, SolGraphQLError>(
    {
      query:
        opportunityId &&
        gql`
          query RecentDealVisitTrends($opportunityId: String) {
            visualization {
              recentDealsTrends(opportunityId: $opportunityId) {
                opportunity {
                  id
                  name
                  openDate
                  closeDate
                  amount
                }
                channelData {
                  channel {
                    id
                    name
                  }
                  data {
                    sundayOfWeek
                    runningTotalVisits
                  }
                }
              }
            }
          }
        `,
      variables: {
        opportunityId,
      },
    },
    fetcherSol,
    {},
  );

  useMemo(() => {
    const { recentDealsTrends } = data?.visualization ?? {};

    if (!recentDealsTrends) {
      return;
    }

    recentDealsTrends.channelData.sort((a, b) => {
      if (a.channel.name < b.channel.name) {
        return -1;
      }
      if (a.channel.name > b.channel.name) {
        return 1;
      }
      return 0;
    });

    return recentDealsTrends;
  }, [data]);

  return {
    recentDealVisitTrends: data?.visualization.recentDealsTrends,
    isLoading,
    error,
  };
};
