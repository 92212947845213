import { Fragment, ReactNode } from 'react';

import { Divider } from '@shared/Divider';
import { ActionIcon } from '@shared/buttons';
import { Tooltip } from 'antd';
import { To } from 'react-router-dom';

import styles from './MoreActionsCell.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
  menuItems: {
    key: string;
    label: string;
    color?: 'black' | 'white' | 'red';
    icon: ReactNode;
    to?: To | false;
  }[];
  onSelect?: (key: string) => void;
};

const MoreActionsCell = ({ size = 'medium', menuItems, onSelect }: Props) => {
  return (
    <div className={styles.container}>
      {menuItems.map((menuItem, i) => (
        <Fragment key={menuItem.key}>
          <Tooltip title={menuItem.label} placement="top">
            <ActionIcon
              aria-label={menuItem.label}
              color={menuItem.color ?? 'black'}
              size={size}
              icon={menuItem.icon}
              to={menuItem.to}
              onClick={() => {
                onSelect?.(menuItem.key);
              }}
            />
          </Tooltip>
          {i < menuItems.length - 1 && (
            <Divider className={styles.divider} color="black" type="vertical" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MoreActionsCell;
