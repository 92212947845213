import { Trans, t } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { usePipelineKPIs } from 'api/pipeline';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  startDate?: string;
  endDate?: string;
};

const PipelineKPIMetrics = ({ startDate, endDate }: Props) => {
  const { pipelineKPIs, isLoading, error } = usePipelineKPIs(startDate, endDate);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>Pipeline</Trans>}
        value={
          pipelineKPIs && numberFormat(pipelineKPIs.amount, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Total Spend</Trans>}
        value={
          pipelineKPIs && numberFormat(pipelineKPIs.totalSpend, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Return on Spend</Trans>}
        value={pipelineKPIs && t`${numberFormat(pipelineKPIs.returnOnSpend, { precision: 1 })}x`}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Opportunities</Trans>}
        value={pipelineKPIs && numberFormat(pipelineKPIs.opportunities, { precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Cost per Opportunity</Trans>}
        value={
          pipelineKPIs &&
          numberFormat(pipelineKPIs.costPerOpportunity, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default PipelineKPIMetrics;
