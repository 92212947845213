import { PropsWithChildren } from 'react';

import { Text } from '@shared/typography';

import styles from './TooltipTitle.module.scss';

const TooltipTitle = ({ children }: PropsWithChildren) => (
  <tr>
    <th className={styles.tooltipHeading} colSpan={3}>
      <Text variant="caption1" color="light-green">
        {children || ''}
      </Text>
    </th>
  </tr>
);

export default TooltipTitle;
