import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import Notification from '@shared/Notification';
import { NotificationType, useNotification } from 'providers/Notification';
import { useLocation } from 'react-router-dom';

const getNotificationType = (type: NotificationType): 'default' | 'success' | 'error' | 'info' => {
  switch (type) {
    case 'success':
      return 'success';
    case 'error':
      return 'error';
    default:
      return 'default';
  }
};

const NotificationWrapper = () => {
  const { notifications, removeNotification, removeAllNotifications } = useNotification();
  const { pathname } = useLocation();

  useEffect(() => {
    removeAllNotifications();
  }, [pathname]);

  const getSimpleNotification = useCallback(
    (key: string | undefined, type: NotificationType, message: ReactNode) => (
      <Notification
        key={key}
        type={getNotificationType(type)}
        size="medium"
        onClose={() => removeNotification({ key })}
      >
        {message}
      </Notification>
    ),
    [notifications],
  );

  const items = useMemo(
    () => notifications.map((val) => getSimpleNotification(val.key, val.type, val.message)),
    [notifications],
  );

  return notifications.length ? <>{items}</> : <></>;
};

export default NotificationWrapper;
