import * as React from 'react';

import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from '@silevis/reactgrid';

export interface HeaderCellInterface extends Cell, Span {
  type: 'header';
  text: string;
  onCellClick?: () => void;
}

class HeaderCell implements CellTemplate<HeaderCellInterface> {
  getCompatibleCell(
    uncertainCell: Uncertain<HeaderCellInterface>,
  ): Compatible<HeaderCellInterface> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  isFocusable = (): boolean => false;

  getClassName(cell: Compatible<HeaderCellInterface>): string {
    return cell.className ? cell.className : '';
  }

  render(cell: Compatible<HeaderCellInterface>): React.ReactNode {
    return <div onClick={cell?.onCellClick}>{cell.text}</div>;
  }
}

export default HeaderCell;
