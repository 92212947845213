import { useMemo } from 'react';

import { t } from '@lingui/macro';
import Flare, { Donut, FlareSeriesOptions, Tooltip } from '@shared/Flare';
import { WebTrafficCompanyBreakdownType } from 'api/activity';
import { WHITE } from 'constants/colors';
import { getChannelColor } from 'constants/colors/channel';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  data?: WebTrafficCompanyBreakdownType[];
  isLoading: boolean;
  error?: SolGraphQLError;
};

const CompanyVisitDonutChart = ({ data, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'percent',
        name: t`Total visits`,
        data: data.map((d) => ({
          name: d.vendor.displayName,
          data: d.percentOfTotal,
          custom: { visits: d.count },
        })),
      },
    ];
  }, [data]);

  let totalVisits = 0;
  if (data && data.length > 0) {
    totalVisits = data[0].total;
  }

  return (
    <div style={{ width: 314, height: 290 }}>
      <Flare
        data={seriesData}
        title={`<b>${numberFormat(totalVisits)}</b><br/>${t`Total Visits`}`}
        width="auto"
        height="auto"
        colors={data?.map((d) => getChannelColor(d.vendor.channel?.id))}
        isLoading={isLoading}
        error={error}
      >
        <Donut
          y="data"
          dataLabelFormat={(label) => {
            if (label.y && label.y >= 0.03) {
              return numberFormat(label.y, { isPercent: true, precision: 0 });
            }
            return '';
          }}
          hideConnector
          noBorder
          dataLabelColor={WHITE}
          hiddenDataLabelThreshold={0}
        />
        <Tooltip
          titleFormat={(item) => item?.key}
          rowValueFormat={(item) => numberFormat(item?.point?.custom?.visits)}
          rowSecondaryValueFormat={(item) => {
            const percent = item?.point?.y;
            return percent ? `(${numberFormat(percent, { isPercent: true, precision: 0 })})` : '';
          }}
        />
      </Flare>
    </div>
  );
};

export default CompanyVisitDonutChart;
