import { AxiosRequestConfig } from 'axios';
import SolGraphQLError from 'error/SolGraphQLError';
import { print } from 'graphql';

import fetch from './Fetcher';
import { ISolConfig, ISolRequest, SolResponse } from './solType';

export interface IRequest extends AxiosRequestConfig {
  showErrorPopup?: boolean;
}

export const fetcherSol = async <VariablesType, JSONResponse>(
  config: ISolConfig<VariablesType>,
): Promise<JSONResponse> => {
  const { query, variables, showErrorPopup = false } = config;

  if (!query) {
    return null as JSONResponse;
  }

  const result = await fetch.requestSol<ISolRequest<VariablesType>, SolResponse<JSONResponse>>({
    url: 'graphql',
    method: 'POST',
    data: {
      query: print(query), // turn AST into string
      variables,
    },
    showErrorPopup,
  });

  const { data, errors } = result.data;

  if (errors) {
    throw new SolGraphQLError(errors);
  }

  return data;
};
