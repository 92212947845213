import { Trans, t } from '@lingui/macro';
import { MultiSelect, MultiSelectProps } from '@shared/Form';
import { SectorType } from 'api/common';
import { useSectors } from 'api/settings';

const IndustryMultiSelect = (props: MultiSelectProps<SectorType>) => {
  const { sectors, isLoading } = useSectors();

  return (
    <MultiSelect
      placeholder={t`Select Industries...`}
      notFoundContent={<Trans>No industries were found...</Trans>}
      {...props}
      options={sectors}
      isLoading={isLoading}
    />
  );
};

export default IndustryMultiSelect;
