import { UserProfileType } from 'api/user';

export const updatePendoUser = (user?: UserProfileType) => {
  document.dispatchEvent(
    new CustomEvent('pendoInitialize', {
      detail: {
        user_email: user?.email,
        user_id: user?.id,
        user_role: user?.isAdmin ? 'admin' : 'standard',
        luna_id: user?.currentOrg.id,
      },
    }),
  );
};
