import { ChannelMappingCustomParameterType } from 'api/channelMappingRules';

export const displayCommaSeparatedValuesAsText = (csv: string[]) => csv.join(', ');

export const displayCustomParamsAsText = (customParams: ChannelMappingCustomParameterType[]) =>
  customParams.map((param) => `${param.name}: ${param.values.join(', ')}`).join('; ');

export const parseCommaSeparatedValues = (csv: string) => {
  return csv
    .split(',')
    .map((str) => str.trim())
    .filter((str) => str.length);
};

export const parseCustomParameters = (customParamsStr: string) => {
  const newParamGroups = customParamsStr.split(';');
  const newCustomParams = newParamGroups
    .map((str) => str.trim())
    .filter((str) => str.length)
    .map<ChannelMappingCustomParameterType>((paramGroup) => {
      const [name, values] = paramGroup.trim().split(':');
      return {
        name: name.trim(),
        values: (values || '').split(',').map((str) => str.trim()),
      };
    });

  return newCustomParams;
};
