import { useState } from 'react';

import { t } from '@lingui/macro';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import InstantSearch, { InstantSearchSortOrder, QueryState } from '@shared/InstantSearch';
import { Page } from '@shared/page';
import { Space } from 'antd';

import VendorActivityKPIs from './VendorActivityKPIs';
import VendorActivityTable from './VendorActivityTable';
import VendorFitScoringTable from './VendorFitScoringTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'visits',
  sortOrder: InstantSearchSortOrder.DESC,
};

const VendorOverview = () => {
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Vendors`} pageName={t`All Vendors`}>
      <InstantSearch defaultQueryState={DEFAULT_QUERY_STATE} ignoredFields={['filters']}>
        <Space direction="vertical" size="large">
          <DashboardFilterProvider
            dashboardFilters={filters}
            onDashboardFiltersChange={setDashboardFilters}
          >
            <DashboardDateRangeFilter />
            <DashboardAudienceFilter />
          </DashboardFilterProvider>
          <VendorActivityKPIs
            audience={filters.audience}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />
          <VendorActivityTable
            audience={filters.audience}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />
          <VendorFitScoringTable
            audience={filters.audience}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default VendorOverview;
