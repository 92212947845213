import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ErrorBoundary } from '@sentry/react';
import { useFlags } from 'constants/featureFlags';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { HeaderProvider } from 'hooks/header/HeaderContext';
import { AntDConfigProvider } from 'providers/AntD';
import { NotificationProvider } from 'providers/Notification';
import { ThemeProvider } from 'providers/Theme';
import { UserProvider } from 'providers/User';
import { RouterProvider } from 'react-router-dom';
import getRouter from 'router/Router';

import { messages } from './locales/en/messages.mjs';

if (typeof Highcharts === 'object') {
  HighchartsAccessibility(Highcharts);
  HighchartsMore(Highcharts);
}

i18n.load('en', messages);
i18n.activate('en');

function App() {
  const flags = useFlags();
  const router = getRouter(flags);

  return (
    <UserProvider>
      <I18nProvider i18n={i18n}>
        <AntDConfigProvider>
          <ErrorBoundary>
            <ThemeProvider>
              <NotificationProvider>
                <HeaderProvider>
                  <RouterProvider router={router} />
                </HeaderProvider>
              </NotificationProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </AntDConfigProvider>
      </I18nProvider>
    </UserProvider>
  );
}

export default App;
