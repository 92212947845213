import { ProfileAvatar } from '@shared/ProfileAvatar';
import { Title } from '@shared/typography';
import { Header } from 'antd/es/layout/layout';
import Chevron from 'assets/svg/chevron.left.svg?react';
import Logo from 'assets/svg/logo.svg?react';
import classNames from 'classnames';
import { HEADER_HEIGHT } from 'constants/dimensions';
import { FEATURE_FLAG, useFeatureFlag } from 'constants/featureFlags';
import { useHeaderContext } from 'hooks/header/HeaderContext';
import { useMe } from 'providers/User';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router';

import AISearch from './AISearch';
import styles from './PageHeader.module.scss';

const DEFAULT_TITLE = 'DEFAULT_PAGE_TITLE';

type Props = {
  toggleMenu?: () => void;
  whiteLogo?: boolean;
  blackLogo?: boolean;
  expandedOnResponsive?: boolean;
  onClickCallback?: () => void;
};

const PageHeader = ({
  toggleMenu,
  whiteLogo,
  blackLogo,
  expandedOnResponsive,
  onClickCallback,
}: Props) => {
  const user = useMe();
  const { title: menuTitle } = useHeaderContext();
  const isAIChatEnabled = useFeatureFlag(FEATURE_FLAG.aiChat);

  return (
    <Header
      className={classNames(styles.header, !menuTitle && styles.transparent, {
        [styles.bigHeader]: expandedOnResponsive,
      })}
      style={{ height: HEADER_HEIGHT }}
    >
      <div
        className={classNames(styles.logoWrapper, {
          [styles.white]: whiteLogo,
          [styles.black]: blackLogo,
        })}
      >
        <Link
          className={classNames(styles.logoLink)}
          to={menuTitle ? ROUTES.dashboard.path : ROUTES.signIn.path}
          onClick={onClickCallback}
        >
          <Logo />
        </Link>
      </div>
      {!!menuTitle && (
        <div className={styles.titleWrapper}>
          <div className={styles.title} onClick={toggleMenu}>
            <Title className={styles.titleText} level="3" weight="semi-bold">
              {menuTitle || DEFAULT_TITLE}
            </Title>
            <Chevron className={styles.titleChevron} />
          </div>
          {isAIChatEnabled && <AISearch />}
          <Link className={styles.avatarLink} to={ROUTES.profile.path}>
            <ProfileAvatar name={user?.name} pictureUrl={user?.pictureUrl} />
          </Link>
        </div>
      )}
    </Header>
  );
};

export default PageHeader;
