import { createContext, useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { THEME_KEY } from './constants';
import { getInitialTheme } from './helpers/getInitialTheme';
import { THEMES, ThemeState } from './types';

const initialState: ThemeState = {
  theme: THEMES.LIGHT,
  setTheme: () => null,
  toggleTheme: () => null,
};

export const ThemeContext = createContext(initialState);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState(getInitialTheme());

  const toggleTheme = useCallback(() => {
    setTheme((prev) => (prev === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT));
  }, []);

  useEffect(() => {
    localStorage.setItem(THEME_KEY, theme);
    document.body.classList.add(`theme-${theme}`);
    return () => document.body.classList.remove(`theme-${theme}`);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
