export const VIOLET_100 = '#CBC8F2';
export const VIOLET_200 = '#B1ACEC';
export const VIOLET_300 = '#9791E5';
export const VIOLET_400 = '#7D75DF';
export const VIOLET_500 = '#645EB2';
export const VIOLET_600 = '#4B4686';
export const VIOLET_700 = '#322F59';
export const VIOLET_800 = '#19172D';

export const ALL_VIOLETS = [
  VIOLET_100,
  VIOLET_200,
  VIOLET_300,
  VIOLET_400,
  VIOLET_500,
  VIOLET_600,
  VIOLET_700,
  VIOLET_800,
];

export const ALL_SEMANTIC_VIOLETS = [
  VIOLET_300,
  VIOLET_400,
  VIOLET_500,
  VIOLET_600,
  VIOLET_700,
  VIOLET_800,
];
