import { useRef, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import InstantSearch, { QueryState } from '@shared/InstantSearch';
import Prompt from '@shared/Prompt';
import TableActions from '@shared/TableActions/TableActions';
import Tooltip from '@shared/Tooltip';
import { ActionButton, Button } from '@shared/buttons';
import { Page } from '@shared/page';
import {
  ChannelMappingRuleDataType,
  endSession,
  mutateChannelMappingRules,
  startOrContinueSession,
  useChannelMappingRules,
} from 'api/channelMappingRules';
import EditAddIcon from 'assets/svg/edit-add-box.svg?react';
import PlusIcon from 'assets/svg/plus.svg?react';
import useSessionLock from 'hooks/useSessionLock';
import { useNotification } from 'providers/Notification';

import styles from './ChannelMappingRules.module.scss';
import ChannelMappingRulesTable, { ChannelMappingRulesTableRef } from './ChannelMappingRulesTable';
import RuleFilter from './RuleFilter';
import { getMatchingRulesForRule, isRuleMissingRequiredFields } from './validator';

export const NEW_ROW_ID_PREFIX = 'new-row-';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  page: 1,
  size: 500,
};
const IGNORED_QUERY_STATE_FIELDS: (keyof QueryState)[] = ['page', 'size', 'sortBy', 'sortOrder'];

enum RuleErrorType {
  EMPTY,
  NON_UNIQUE,
}
type RuleError = {
  type: RuleErrorType;
  key: string;
};

// 30 seconds
const REFRESH_SESSION_LOCK_TIME = 30000;

const ChannelMappingRules = () => {
  const tableRef = useRef<ChannelMappingRulesTableRef>(null);
  const { pushNotification, removeNotification } = useNotification();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { data, isLoading, error, mutate } = useChannelMappingRules(isEditMode);
  const [invalidRowKeys, setInvalidRowKeys] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [changedData, setChangedData] = useState<ChannelMappingRuleDataType[] | undefined>(
    undefined,
  );
  const { startSessionLock, endSessionLock } = useSessionLock({
    startSession: startOrContinueSession,
    refreshSession: startOrContinueSession,
    endSession,
    keepAlivePingInterval: REFRESH_SESSION_LOCK_TIME,
  });

  const handleAddRule = () => {
    const newKey = `${NEW_ROW_ID_PREFIX}${crypto.randomUUID()}`;
    const newData = (
      [
        {
          id: newKey,
          customParameters: [],
          isC99: false,
          media: [],
          priority: 1,
          referralDomains: [],
          sources: [],
          vendor: undefined,
        },
      ] as ChannelMappingRuleDataType[]
    ).concat(changedData || data || []);

    setChangedData(newData.map((d, index) => ({ ...d, priority: index })));
    tableRef.current?.highlightRows([newKey]);
    tableRef.current?.scrollToRow(newKey);
  };

  const changeEditMode = async (desiresEditMode: boolean): Promise<boolean> => {
    if (desiresEditMode) {
      const success = await startSessionLock();
      if (success) {
        removeNotification({ type: 'edit' });
        pushNotification({
          type: 'edit',
          message: t`You are currently editing your Channel Mapping Rules, please click Save and Run to process changes`,
        });
        setIsEditMode(true);
      }
      return success;
    } else {
      // we don't care about errors on a lock release, Sol releases locks after 10 mins.
      endSessionLock();
      removeNotification({ type: 'edit' });
      setIsEditMode(false);
      return true;
    }
  };

  const handleBeforeUnload = () => {
    endSessionLock();
  };

  const handleCancel = () => {
    changeEditMode(false);
    setChangedData(undefined);
  };

  /**
   * Validates each mapping rule to ensure it has been filled out and doesn't match any other rules
   */
  const validateData = (dataToSave: ChannelMappingRuleDataType[]) => {
    const errors = dataToSave.reduce<RuleError[]>((memo, item) => {
      // `isC99` rows can't be edited by the user and won't be validated.
      if (item.isC99) {
        return memo;
      }
      if (isRuleMissingRequiredFields(item)) {
        memo.push({ type: RuleErrorType.EMPTY, key: item.id });
      } else {
        const matchingRules = getMatchingRulesForRule(item, dataToSave);

        if (matchingRules.length) {
          memo.push({
            type: RuleErrorType.NON_UNIQUE,
            key: item.id,
          });
        }
      }
      return memo;
    }, []);

    if (errors?.length) {
      setInvalidRowKeys(errors.map((e) => e.key));
      tableRef.current?.scrollToRow(errors[0].key);

      const errorMessage = errors.some((e) => e.type === RuleErrorType.EMPTY)
        ? t`Please enter channel, vendor and at least one of the parameters to create a rule.`
        : t`One or more rules match other rules. Please provide unique parameters for each rule.`;
      pushNotification({ type: 'error', message: errorMessage });
    } else {
      removeNotification({ type: 'error' });
      setInvalidRowKeys([]);
    }

    return errors;
  };

  const handleSave = async () => {
    if (changedData != null) {
      setIsSaving(true);

      const errors = validateData(changedData);
      if (!errors?.length) {
        try {
          const response = await mutateChannelMappingRules(changedData);
          if (response.success) {
            pushNotification({
              type: 'success',
              message: t`Success! Channel Mapping Rules have been saved.`,
            });
          } else {
            throw new Error();
          }
        } catch {
          pushNotification({
            type: 'error',
            message: t`Apologies, but something went wrong. Please try to Save again.`,
          });
        }
      }

      setIsSaving(false);
      changeEditMode(false);

      await mutate();
      setChangedData(undefined);
    } else {
      changeEditMode(false);
    }
  };

  const handleDataChange = (newData: ChannelMappingRuleDataType[]) => {
    setChangedData(newData);
  };

  return (
    <Page title={t`Configure`} pageName={t`Channel Mapping Rules`}>
      <InstantSearch
        defaultQueryState={DEFAULT_QUERY_STATE}
        ignoredFields={IGNORED_QUERY_STATE_FIELDS}
      >
        <div className={styles.content}>
          <Prompt
            message={t`Are you sure you want to exit without saving your Channel Mapping Rules changes?`}
            shouldPreventNavigation={changedData != null}
            onBeforeUnload={handleBeforeUnload}
          />
          <div className={styles.actionBar}>
            <div>
              {isEditMode && (
                <Button
                  color="black"
                  variant="secondary"
                  icon={<PlusIcon />}
                  onClick={handleAddRule}
                >
                  <Trans>New Rule</Trans>
                </Button>
              )}
            </div>
            <div className={styles.primaryActionBar}>
              {isEditMode && (
                <>
                  <Button
                    color="black"
                    variant="secondary"
                    isDisabled={isSaving}
                    onClick={handleCancel}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    color="black"
                    variant="secondary"
                    isDisabled={changedData == null}
                    isLoading={isSaving}
                    onClick={handleSave}
                  >
                    <Trans>Save & Run</Trans>
                  </Button>
                </>
              )}
              <Tooltip
                placement="topRight"
                title={<Trans>Add/Edit</Trans>}
                body={<Trans>Add or Edit Channel Mapping Rules</Trans>}
              >
                <ActionButton
                  color="black"
                  icon={<EditAddIcon />}
                  isSelected={isEditMode}
                  isDisabled={isSaving}
                  onClick={() => changeEditMode(!isEditMode)}
                />
              </Tooltip>
              <RuleFilter />
              <TableActions size="medium" />
            </div>
          </div>
          <ChannelMappingRulesTable
            ref={tableRef}
            data={changedData || data || []}
            invalidRowKeys={invalidRowKeys}
            isLoading={isLoading}
            error={error}
            isEditMode={isEditMode}
            onDataChange={handleDataChange}
            onValidate={validateData}
          />
        </div>
      </InstantSearch>
    </Page>
  );
};

export default ChannelMappingRules;
