import { PropsWithChildren } from 'react';

import { Text } from '@shared/typography';
import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

type Props = {
  className?: string;
  value: number;
  label?: string;
  'data-testid'?: string;
  green?: boolean;
  red?: boolean;
  yellow?: boolean;
  size?: 'M' | 'S';
};

const ProgressBar = ({
  children,
  className = '',
  value,
  label,
  'data-testid': dataTestId,
  green = false,
  red = false,
  yellow = false,
  size = 'M',
}: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.trackContainer, className, { [styles.small]: size === 'S' })}>
      <div
        data-testid={dataTestId}
        className={classNames(styles.filled, className, {
          [styles.red]: red,
          [styles.yellow]: yellow,
          [styles.green]: green,
          [styles.small]: size === 'S',
        })}
        style={{ width: `${value * 100}%` }}
      >
        {label != null && (
          <Text className={styles.label} variant="caption2">
            {label}
          </Text>
        )}
      </div>
      {children}
    </div>
  );
};

export default ProgressBar;
