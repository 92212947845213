import { ReactNode, useMemo, useState } from 'react';

import { Text } from '@shared/typography';
import CheckableTag from 'antd/es/tag/CheckableTag';
import classNames from 'classnames';

import styles from './LegendItem.module.scss';

type Props = {
  className?: string;
  name?: string;
  color?: string | null;
  onChange?: (checked: boolean) => void;
};

const LegendItem = ({ className = '', color, name, onChange, ...rest }: Props) => {
  const [selected, setSelected] = useState(true);
  const interactive = !!onChange;

  const handleChange = (isChecked: boolean) => {
    setSelected(isChecked);
    onChange?.(isChecked);
  };

  const children = useMemo<ReactNode>(() => {
    return (
      <>
        {color && <div className={styles.symbol} style={{ backgroundColor: color }} />}
        <Text className={styles.label} variant="caption1">
          {name}
        </Text>
      </>
    );
  }, [color, name]);

  if (onChange) {
    return (
      <CheckableTag
        {...rest}
        className={classNames(
          styles.legendItem,
          styles.legendItemToggle,
          { [styles.selected]: selected, [styles.interactive]: interactive },
          className,
        )}
        checked={selected}
        onChange={handleChange}
      >
        {children}
      </CheckableTag>
    );
  } else {
    return <div className={classNames(styles.legendItem, className)}>{children}</div>;
  }
};

export default LegendItem;
