import { CSSProperties, useMemo } from 'react';

import AutoSizer from '@shared/AutoSizer';
import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  numSeries?: number;
  numCategories?: number;
  sortDescending?: boolean;
  style?: CSSProperties;
};

const BarLoader = ({ numSeries = 2, numCategories = 3, sortDescending = false, style }: Props) => {
  const barHeight = 25;
  const categoryGap = 30;
  const seriesGap = 3;
  let yCursor = categoryGap / 2;
  let maxXValue = 0;

  // generate the fake data once and reuse it every time we load for the duration of this
  // component. This will ensure we don't have jumps when we re-query data.
  const fakeData = useMemo(
    () =>
      [...Array(numCategories)].map(() =>
        [...Array(numSeries)].map(() => Math.floor(Math.random() * 60 + 40)),
      ),
    [numSeries, numCategories],
  );

  const sortedFakeData = useMemo(() => {
    if (sortDescending) {
      return fakeData.sort(
        (a, b) => b.reduce((acc, val) => acc + val, 0) - a.reduce((acc, val) => acc + val, 0),
      );
    }
    return fakeData;
  }, [fakeData, sortDescending]);

  const renderBar = (value: number, categoryIndex: number, seriesIndex: number) => {
    const bar = (
      <rect
        key={`${categoryIndex}-${seriesIndex}`}
        x="0"
        y={yCursor}
        width={value}
        height={barHeight}
      />
    );
    yCursor += barHeight + seriesGap;
    maxXValue = Math.max(value, maxXValue);
    return bar;
  };

  const renderCategory = (data: number[], categoryIndex: number) => {
    const category = data.map((d, i) => renderBar(d, categoryIndex, i));

    yCursor -= seriesGap;
    yCursor += categoryGap;

    return category;
  };

  const children = sortedFakeData.map((d, i) => renderCategory(d, i));
  yCursor -= categoryGap / 2;

  return (
    <div style={{ margin: 32, flex: 1, overflow: 'hidden' }}>
      <AutoSizer>
        {({ height, width }) => (
          <SkeletonLoader
            style={style}
            viewBox={`0 0 ${maxXValue} ${yCursor}`}
            preserveAspectRatio="none"
            height={height}
            width={width}
          >
            {children}
          </SkeletonLoader>
        )}
      </AutoSizer>
    </div>
  );
};

export default BarLoader;
