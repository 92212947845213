import { t } from '@lingui/macro';
import TooltipRow from '@shared/Flare/guides/TooltipRow';
import MultiSeriesTooltip from '@shared/MultiSeriesTooltip';
import { PipelineVendorInfluenceDataType } from 'api/pipeline';
import { TAM_PRIMARY, VISITS_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  data: PipelineVendorInfluenceDataType;
};

const PipelineVendorInfluenceCellTooltip = ({ data }: Props) => {
  return (
    <MultiSeriesTooltip title={data.vendor.name}>
      <TooltipRow
        color={TAM_PRIMARY}
        name={t`Opportunity Influence`}
        secondaryValue={`(${numberFormat(data.opportunitiesInfluencedPercent, {
          isPercent: true,
          precision: 1,
        })})`}
        value={numberFormat(data.opportunitiesInfluenced)}
      />
      <TooltipRow
        color={VISITS_PRIMARY}
        name={t`Pipeline $ Influence`}
        secondaryValue={`(${numberFormat(data.pipelineRevenueInfluencePercent, {
          isPercent: true,
          precision: 1,
        })})`}
        value={numberFormat(data.pipelineRevenueInfluence, {
          isCurrency: true,
          precision: 0,
        })}
      />
    </MultiSeriesTooltip>
  );
};

export default PipelineVendorInfluenceCellTooltip;
