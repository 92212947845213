export const truncateMiddle = (fullStr = '', strLen = 16, separator = '...') => {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  const separatorLength = separator.length;
  const charsToShow = strLen - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
  );
};
