import { useMemo } from 'react';

import { QueryStateFilter } from '@shared/InstantSearch';
import { isFilterRange } from '@shared/InstantSearch/util/filter-util';
import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';

import { fetcherSol } from '../swr-fetcher';
import { DisplayNamesGQLResponse } from './commonType';

export const useDisplayName = (id?: string | false | null) => {
  const { displayNames, isLoading, error } = useDisplayNames(id ? [id] : undefined);

  return {
    displayName: displayNames?.[0].name,
    isLoading,
    error,
  };
};

export const useDisplayNames = (ids?: string[]) => {
  const { data, isLoading, error } = useSWR<DisplayNamesGQLResponse, SolGraphQLError>(
    {
      query:
        ids?.length &&
        gql`
          query DisplayNames($ids: [String!]!) {
            displayNames {
              get(ids: $ids) {
                id
                name
              }
            }
          }
        `,
      variables: {
        ids,
      },
    },
    fetcherSol,
    {},
  );

  return {
    displayNames: data?.displayNames.get,
    isLoading,
    error,
  };
};

export const useFilterNames = (filters: QueryStateFilter[]) => {
  const filterIds = filters.reduce((memo: string[], filter) => {
    if (isFilterRange(filter)) {
      return memo;
    }

    let ids = [];
    if (!Array.isArray(filter.operand)) {
      ids = [filter.operand];
    } else {
      ids = filter.operand;
    }

    return memo.concat(ids.filter((id) => id.includes('_') || id.includes(':')));
  }, []);

  const { displayNames, isLoading, error } = useDisplayNames(filterIds);

  const filtersWithNames = useMemo(() => {
    if (!displayNames) {
      return filters;
    }

    const idLookup = displayNames.reduce((memo: Record<string, string>, { id, name }) => {
      memo[id] = name;
      return memo;
    }, {});

    // replace filter with id operands with name operands
    return filters.map((filter) => {
      if (isFilterRange(filter)) {
        return filter;
      }

      return {
        ...filter,
        operand: filter.operand.map((id) => idLookup[id] || id),
      };
    });
  }, [displayNames, filters]);

  return {
    filtersWithNames,
    isLoading,
    error,
  };
};
