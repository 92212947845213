import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFlareContext } from '@shared/Flare/FlareContext';
import { AxisExtended, FlareChart } from '@shared/Flare/types';
import { useNotesDrawerContext } from '@shared/panels';
import classNames from 'classnames';
import { Options } from 'highcharts';
import clamp from 'lodash/clamp';
import { createPortal } from 'react-dom';

import styles from './Notes.module.scss';

const buildNotesOptions = (
  options: Options,
  onChartRedraw: (chart: FlareChart) => void,
): Options => {
  return {
    ...options,
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        redraw: function (event) {
          options.chart?.events?.redraw?.call(this, event);
          onChartRedraw(this as FlareChart);
        },
      },
    },
  };
};

const Notes = () => {
  const { id, registerChild, parentProps } = useFlareContext();
  const { eventLog, selectedNote, onSelectedNoteChange, onNotesDrawerOpenChange } =
    useNotesDrawerContext();
  const [xAxis, setXAxis] = useState<AxisExtended | undefined>();
  const [plotBounds, setPlotBounds] = useState<{
    plotTop: number;
    plotLeft: number;
    plotHeight: number;
    plotWidth: number;
  }>();
  const [container, setContainer] = useState<Element | null>(null);

  const handleRender = useCallback((chart: FlareChart) => {
    if (chart) {
      setXAxis(chart.xAxis[0]);
      setPlotBounds({
        plotTop: chart.plotTop,
        plotLeft: chart.plotLeft,
        plotHeight: chart.plotHeight,
        plotWidth: chart.plotWidth,
      });
      setContainer(chart.container?.parentNode as Element);
    }
  }, []);

  const sortedEventLog = useMemo(() => eventLog && [...eventLog].reverse(), [eventLog]);

  useEffect(() => {
    registerChild(id, (options: Options) => buildNotesOptions(options, handleRender));
  }, [eventLog, handleRender]);

  if (!container || !eventLog || !plotBounds || !xAxis) {
    return null;
  }

  const { parseX } = parentProps;
  const maxXPos = xAxis.toPixels(xAxis.dataMax, true);
  const minXPos = xAxis.toPixels(xAxis.dataMin, true);

  return createPortal(
    <div className={styles.noteContainer}>
      {sortedEventLog?.map((note) => {
        const date = parseX ? parseX(note.date) : note.date;

        // If the note happens in the future or past, snap it to the nearest edge of the chart.
        const xPos = clamp(xAxis.toPixels(date, true), minXPos, maxXPos);

        return (
          <div
            key={note.id}
            className={classNames(styles.noteWrapper, {
              [styles.selected]: note.id === selectedNote?.id,
            })}
            data-id={note.id}
            data-xpos={xPos}
            style={{
              left: `${Math.round(xPos + plotBounds.plotLeft)}px`,
              top: `${plotBounds?.plotTop}px`,
              height: `${plotBounds?.plotHeight}px`,
            }}
            onMouseEnter={() => onSelectedNoteChange?.(note)}
            onMouseLeave={() => onSelectedNoteChange?.(undefined)}
            onClick={() => {
              onNotesDrawerOpenChange?.(true);
              onSelectedNoteChange?.(note);
            }}
          >
            <div className={styles.crosshair} />
            <div className={styles.note} />
          </div>
        );
      })}
    </div>,
    container,
  );
};

export default Notes;
