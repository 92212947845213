import { useState } from 'react';

import { Plural, Trans, t } from '@lingui/macro';
import {
  QueryStateFilter,
  QueryStateFilterMulti,
  useInstantSearchState,
} from '@shared/InstantSearch';
import Tooltip from '@shared/Tooltip';
import { ActionButton } from '@shared/buttons';
import FilterIcon from 'assets/svg/filter.svg?react';

import RuleFilterModal from './RuleFilterModal';

const RuleFilter = () => {
  const { queryState, updateQueryState } = useInstantSearchState();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const channelFilter = queryState.filters.find((filter) => filter.field === 'channel.name') as
    | QueryStateFilterMulti
    | undefined;
  const vendorFilter = queryState.filters.find((filter) => filter.field === 'vendor.name') as
    | QueryStateFilterMulti
    | undefined;

  const handleFiltersChange = (channels: string[], vendors: string[]) => {
    const filters: QueryStateFilter[] = [];

    if (channels.length) {
      filters.push({
        field: 'channel.name',
        operator: 'in',
        operand: channels,
      });
    }
    if (vendors.length) {
      filters.push({
        field: 'vendor.name',
        operator: 'in',
        operand: vendors,
      });
    }

    updateQueryState({ filters });
    setIsFilterModalOpen(false);
  };

  return (
    <>
      <Tooltip
        placement="topRight"
        title={<Trans>Add/Remove Filters</Trans>}
        body={
          <Trans>
            Add or Remove filters. {queryState.filters.length}{' '}
            <Plural value={queryState.filters.length} one="filter" other="filters" /> applied.
          </Trans>
        }
      >
        <ActionButton
          aria-label={t`Add or remove filters`}
          color="black"
          icon={<FilterIcon />}
          isSelected={queryState.filters.length > 0}
          onClick={() => setIsFilterModalOpen(true)}
        />
      </Tooltip>
      <RuleFilterModal
        open={isFilterModalOpen}
        channels={channelFilter?.operand || []}
        vendors={vendorFilter?.operand || []}
        onClose={() => setIsFilterModalOpen(false)}
        onFiltersChange={handleFiltersChange}
      />
    </>
  );
};

export default RuleFilter;
