import { useMemo } from 'react';

const C99_PIXEL_TOKEN = `{{C99_PIXEL_CODE}}`;
const C99_TAG_PREFIX = `CT-`;
const C99_TAG_SLUG_LENGTH = 7;

const C99_TAG_POSSIBLE_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const generateCode = () => {
  let code = C99_TAG_PREFIX;

  for (let i = 0; i < C99_TAG_SLUG_LENGTH; i++) {
    code += C99_TAG_POSSIBLE_CHARS[Math.floor(Math.random() * C99_TAG_POSSIBLE_CHARS.length)];
  }

  return code;
};

const getUrlPattern = (vendorId: string) => {
  const defaultURL = `https://ping.channel99.io/?C99=${C99_PIXEL_TOKEN}`;

  switch (vendorId) {
    case 'vndr_google_display':
      return `${defaultURL}&rnd=%%CACHEBUSTER%%`;
    case 'vndr_demandbase':
      return `${defaultURL}&rnd={{CACHEBUSTER}}`;
    case 'vndr_bing_display':
      return `${defaultURL}&rnd=\${CACHEBUSTER}`;
    default:
      return defaultURL;
  }
};

/**
 * Returns a `code` and a `url` which contains the code. A new `code` will be generated if one
 * wasn't supplied. The `url` pattern depends on which `vendorId` the pixel is being used on.
 */
const useGeneratePixelURL = (vendorId: string, code?: string) => {
  return useMemo(() => {
    if (!code) {
      code = generateCode();
    }

    if (!vendorId) {
      return {};
    }

    return {
      code,
      url: getUrlPattern(vendorId).replace(C99_PIXEL_TOKEN, code),
    };
  }, [code, vendorId]);
};

export default useGeneratePixelURL;
