import { fetcherSol } from 'api/swr-fetcher';
import gql from 'graphql-tag';

import { AIChatGQLResponse, AIChatVariablesType } from './aiType';

export const mutateAIChat = async (message: string) => {
  const response = await fetcherSol<AIChatVariablesType, AIChatGQLResponse>({
    query:
      message.length > 0 &&
      gql`
        query AiChat($message: String!) {
          ai {
            chat(message: $message)
          }
        }
      `,
    variables: {
      message,
    },
  });

  return response.ai.chat;
};
