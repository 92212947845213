/* eslint-disable no-restricted-imports */
import { Dropdown as AntdDropdown, DropdownProps as AntdDropdownProps } from 'antd';
import classNames from 'classnames';

import styles from './Dropdown.module.scss';

export type DropdownProps = Omit<AntdDropdownProps, 'disabled'> & {
  isDisabled?: boolean;
};

const Dropdown = ({ isDisabled, className, overlayClassName, ...rest }: DropdownProps) => {
  return (
    <AntdDropdown
      {...rest}
      className={className}
      overlayClassName={classNames(styles.overlay, overlayClassName)}
      disabled={isDisabled}
    />
  );
};

export default Dropdown;
