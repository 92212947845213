import { useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { InstantSearchSortOrder, QueryState } from '@shared/InstantSearch';
import Table, { ColumnsType, TableProps } from '@shared/Table';
import { Link } from '@shared/typography';
import { AudienceGroupedOptionType } from 'api/audience';
import { OPPORTUNITY_TYPE, OpportunityType } from 'api/opportunity';
import { usePixelOpportunitiesInfluencedList } from 'api/pixel';
import { USDateViewFormat } from 'constants/formats';
import { numberFormat } from 'helper/numberFormatter';
import moment from 'moment';
import { ROUTES } from 'router';

type Props = {
  pixelIds?: string[];
  startDate?: string;
  endDate?: string;
  audience?: AudienceGroupedOptionType;
  opportunityType: OPPORTUNITY_TYPE;
  error?: Error;
  isLoading: boolean;
};

type TableState = Omit<QueryState, 'search' | 'filters'>;

const PixelOpportunityInfluenceTable = ({
  pixelIds,
  startDate,
  endDate,
  audience,
  opportunityType,
}: Props) => {
  const audienceIds = audience?.audienceIds;

  // TODO: Eventually we will support multiple audiences and we can delete this line
  const audienceId = audienceIds?.[0];

  const [tableState, setTableState] = useState<TableState>({
    page: 1,
    size: 10,
    sortBy: 'date',
    sortOrder: InstantSearchSortOrder.DESC,
  });

  const { opportunitiesInfluenced, totalResults, isLoading, error } =
    usePixelOpportunitiesInfluencedList(
      tableState,
      opportunityType,
      pixelIds,
      startDate,
      endDate,
      audienceId,
    );

  const columns: ColumnsType<OpportunityType> = useMemo(() => {
    return [
      {
        title: t`Company Name`,
        key: 'account.company.name',
        fixed: 'left',
        sorter: true,
        render: (text, field) =>
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON &&
          field.metrics &&
          field.metrics.visits > 0 ? (
            <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
              {field.account?.company.name ?? ''}
            </Link>
          ) : (
            field.account?.company.name
          ),
      },
      {
        title: t`Opportunity`,
        key: 'name',
        sorter: true,
        render: (text, field) =>
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON &&
          field.metrics &&
          field.metrics.visits > 0 ? (
            <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
              {text}
            </Link>
          ) : (
            text
          ),
      },
      {
        title: opportunityType === OPPORTUNITY_TYPE.PIPELINE ? t`Create Date` : t`Close Date`,
        key: 'date',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (text, field) =>
          moment(
            opportunityType === OPPORTUNITY_TYPE.PIPELINE ? field.openDate : field.closeDate,
          ).format(USDateViewFormat),
      },
      {
        title: t`Impressions`,
        key: 'metrics.impressions',
        sorter: true,
        render: (text, field) => numberFormat(field.metrics?.impressions),
      },
      {
        title: t`Visits`,
        key: 'metrics.visits',
        sorter: true,
        render: (text, field) => numberFormat(field.metrics?.visits),
      },
      {
        title: t`Opportunity Amount`,
        key: 'amount',
        sorter: true,
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ];
  }, [opportunityType]);

  const handleTableChange: TableProps<OpportunityType>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    const sort = Array.isArray(sorter) ? sorter[0] : sorter;

    setTableState({
      page: pagination.current || tableState.page,
      size: pagination.pageSize || tableState.size,
      sortBy: Array.isArray(sort?.field) ? sort?.field[0] : sort.field,
      sortOrder:
        sort.order === 'descend' ? InstantSearchSortOrder.DESC : InstantSearchSortOrder.ASC,
    });
  };

  return (
    <div style={{ padding: '20px 32px' }}>
      <Table<OpportunityType>
        columns={columns}
        dataSource={opportunitiesInfluenced}
        loading={isLoading}
        emptyMessage={
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON ? (
            <Trans>No Closed Won opportunities found</Trans>
          ) : (
            <Trans>No Pipeline opportunities found</Trans>
          )
        }
        error={error}
        pagination={{ current: tableState.page, pageSize: tableState.size, total: totalResults }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default PixelOpportunityInfluenceTable;
