import { useState } from 'react';

import { InstantSearchSortOrder } from '@shared/InstantSearch';
import { EventLogType, useEventLog } from 'api/eventLog';

import { Panel, PanelProps } from '../Panel';
import NotesDrawerContext from './NotesDrawerContext';
import NotesPanelDrawer from './NotesPanelDrawer';

export type NotePanelProps = PanelProps & {
  startDate?: string;
  endDate?: string;
  hideNotes?: boolean;
  onDrawerOpenChange?: (isOpen: boolean) => void;
};

const NotesPanel = ({
  startDate,
  endDate,
  hideNotes,
  children,
  onDrawerOpenChange,
  ...rest
}: NotePanelProps) => {
  const [isRefreshing, setRefreshing] = useState<boolean>(false);
  const { eventLog, isLoading, error, mutate } = useEventLog(
    {
      page: { offset: 0, limit: 1000 },
      sort: { field: 'occurredAt', direction: InstantSearchSortOrder.DESC },
      filter: [
        {
          field: 'typeString',
          operator: 'in',
          operand: ['Note', 'Campaign'],
        },
      ],
    },
    startDate,
    endDate,
  );
  const [selectedNote, setSelectedNote] = useState<EventLogType | undefined>();
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState<boolean>(false);

  const handleDataChange = async () => {
    setRefreshing(true);
    await mutate();
    setRefreshing(false);
  };

  if (hideNotes) {
    return <Panel {...rest}>{children}</Panel>;
  }

  return (
    <Panel
      {...rest}
      isDrawerOpen={isNotesDrawerOpen}
      drawerContent={
        <NotesPanelDrawer
          data={eventLog}
          isLoading={isLoading || isRefreshing}
          error={error}
          selectedItem={selectedNote}
          onSelectionChange={setSelectedNote}
          onNoteCreate={handleDataChange}
          onNoteUpdate={handleDataChange}
          onNoteDelete={handleDataChange}
        />
      }
      onDrawerOpenChange={(isOpen) => {
        setIsNotesDrawerOpen(isOpen);
        onDrawerOpenChange?.(isOpen);

        if (!isOpen) {
          setSelectedNote(undefined);
        }
      }}
    >
      <NotesDrawerContext.Provider
        value={{
          eventLog,
          selectedNote,
          onSelectedNoteChange: setSelectedNote,
          isNotesDrawerOpen,
          onNotesDrawerOpenChange: (isOpen) => {
            setIsNotesDrawerOpen(isOpen);
            onDrawerOpenChange?.(isOpen);
          },
        }}
      >
        {children}
      </NotesDrawerContext.Provider>
    </Panel>
  );
};

export default NotesPanel;
