import { PropsWithChildren, createContext, useCallback, useState } from 'react';

import { NotificationDataType } from './types';

type RemoveNotificationCallbackType = ({
  key,
  type,
}: {
  key?: string;
  type?: string | string[];
}) => void;

type NotificationState = {
  notifications: NotificationDataType[];
  pushNotification: (notification: NotificationDataType) => void;
  removeNotification: RemoveNotificationCallbackType;
  removeAllNotifications: () => void;
};

export const NotificationContext = createContext<NotificationState | undefined>(undefined);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<NotificationDataType[]>([]);

  const handlePushNotification = useCallback((notification: NotificationDataType) => {
    const { type, message } = notification;
    const key = new Date().getTime().toString();
    setNotifications((n) => [
      ...n.filter((item) => item.type !== type),
      {
        key,
        type,
        message,
      },
    ]);
  }, []);

  const handleRemoveNotification: RemoveNotificationCallbackType = useCallback(({ key, type }) => {
    if (key) {
      setNotifications((n) => n.filter((item) => item.key !== key));
    } else if (type) {
      if (Array.isArray(type)) {
        setNotifications((n) => n.filter((item) => type.indexOf(item.type) === -1));
      } else {
        setNotifications((n) => n.filter((item) => item.type !== type));
      }
    }
  }, []);

  const handleRemoveAllNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        pushNotification: handlePushNotification,
        removeNotification: handleRemoveNotification,
        removeAllNotifications: handleRemoveAllNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
