import { INTERNAL_CORE_ERROR } from './core-standard-errors';
import { ErrorByCodeType } from './types';

export enum PAGINATION_ERROR_CODES {
  BAD_LIMIT = 'err:sol.pagination.invalid-limit',
  BAD_PAGINATION = 'err:sol.pagination.invalid-pagination',
  FILTER_NOT_ALLOWED = 'err:sol.pagination.filter-not-allowed',
  FILTER_OP_NOT_ALLOWED = 'err:sol.pagination.filter-op-not-allowed',
  FILTER_BAD_DATE = 'err:sol.pagination.invalid-filter-data',
  SORT_NOT_ALLOWED = 'err:sol.pagination.sort-not-allowed',
  TOO_MANY_SORTS = 'err:sol.pagination.too-many-sorts',
}

export const PAGINATION_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [PAGINATION_ERROR_CODES.BAD_LIMIT]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.BAD_PAGINATION]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.FILTER_NOT_ALLOWED]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.FILTER_OP_NOT_ALLOWED]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.FILTER_BAD_DATE]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.SORT_NOT_ALLOWED]: INTERNAL_CORE_ERROR,
  [PAGINATION_ERROR_CODES.TOO_MANY_SORTS]: INTERNAL_CORE_ERROR,
};
