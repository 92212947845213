import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import {
  DashboardDateRangeFilter,
  DashboardFilterProvider,
  DashboardFiltersType,
} from '@shared/Dashboard';
import { InstantSearchSortOrder, QueryState } from '@shared/InstantSearch';
import InstantSearch from '@shared/InstantSearch/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@shared/InstantSearch/util/search-util';
import TableActions from '@shared/TableActions/TableActions';
import { Page } from '@shared/page';
import { Panel } from '@shared/panels';
import { Space } from 'antd';
import { useEventLog } from 'api/eventLog';

import EventLogTable from './EventLogTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'occurredAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const EventLog = () => {
  const [queryState, setQueryState] = useState<QueryState>();
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFiltersType>({});
  const { eventLog, totalResults, isLoading, error } = useEventLog(
    getSolQueryParamsNewFromQueryState(queryState),
    dashboardFilters.start_date,
    dashboardFilters.end_date,
  );

  // const handleDownloadCSV = async () => {
  //   TODO: Implement download CSV
  //   const source = axios.CancelToken.source();
  // };

  return (
    <Page title={t`Settings`} pageName={t`Event Log`}>
      <InstantSearch defaultQueryState={DEFAULT_QUERY_STATE} onQueryStateChange={setQueryState}>
        <Space direction="vertical" size="large">
          <DashboardFilterProvider
            dashboardFilters={dashboardFilters}
            onDashboardFiltersChange={setDashboardFilters}
          >
            <DashboardDateRangeFilter />
            <TableActions />
          </DashboardFilterProvider>
          <Panel
            title={<Trans>Event Log Entries</Trans>}
            // actions={
            //   <EventLogActionMenu
            //     totalResults={totalResults}
            //     isLoading={isLoading}
            //     isDownloadingCSV={false}
            //     onDownloadCSV={handleDownloadCSV}
            //   />
            // }
          >
            <EventLogTable
              data={eventLog}
              totalResults={totalResults}
              isLoading={isLoading}
              error={error}
              startDate={dashboardFilters.start_date}
              endDate={dashboardFilters.end_date}
            />
          </Panel>
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default EventLog;
