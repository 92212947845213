import { URLSearchParams } from 'url';

import {
  FilterOperatorRange,
  FilterOperatorValue,
  QueryStateFilter,
  QueryStateFilterRange,
  SolQueryFilter,
} from '../types';

export const isFilterRange = (
  e: QueryStateFilterRange | QueryStateFilter,
): e is QueryStateFilterRange =>
  (e as QueryStateFilterRange).min != null || (e as QueryStateFilterRange).max != null;

export const isFilterOperatorRange = (
  e: FilterOperatorRange | FilterOperatorValue,
): e is FilterOperatorRange =>
  (e as FilterOperatorRange)?.min != null || (e as FilterOperatorRange)?.max != null;

const parseFilterFromQS = (field: string, operand: string): QueryStateFilter => {
  const isNegated = field.endsWith('!');
  field = field.replace(/!$/, '');
  const isRangeValue =
    (operand.startsWith('[') || operand.startsWith('(')) &&
    (operand.endsWith(']') || operand.endsWith(')'));

  if (isRangeValue) {
    const firstChar = operand[0];
    const lastChar = operand[operand.length - 1];
    const rangeValuesStr = operand.slice(1, operand.length - 1);
    const [minOperand, maxOperand] = rangeValuesStr.split(',');

    return {
      field,
      min:
        minOperand.length === 0 ? undefined : { inclusive: firstChar === '[', operand: minOperand },
      max:
        maxOperand.length === 0 ? undefined : { inclusive: lastChar === ']', operand: maxOperand },
    } as QueryStateFilterRange;
  } else {
    const values = operand.split(',');

    return {
      field,
      operator: isNegated ? 'notIn' : 'in',
      operand: values,
    };
  }
};

export const getFiltersFromURLSearchParams = (
  searchParams: URLSearchParams,
  initialFilters: QueryStateFilter[],
) => {
  const filterEntries = [...searchParams.entries()];

  // Get any default filters which haven't been overridden by search params.
  const remainingInitialFilters = initialFilters.filter(
    (filter) =>
      filterEntries.some(
        ([field]) =>
          filter.field === field ||
          (field.endsWith('!') && field.substring(0, field.length - 1)) === field,
      ) === false,
  );

  // Get any overriding filters from query params.
  const filtersFromQueryParams = filterEntries.map(([field, operand]) =>
    parseFilterFromQS(field, operand),
  );

  return [...remainingInitialFilters, ...filtersFromQueryParams];
};

const stringifyFilterRange = (filter: QueryStateFilterRange) => {
  let minString;
  let maxString;

  if (filter.min?.operand != null) {
    minString = `${filter.min.inclusive ? '[' : '('}${filter.min.operand}`;
  } else {
    minString = '[';
  }

  if (filter.max?.operand != null) {
    maxString = `${filter.max.operand}${filter.max.inclusive ? ']' : ')'}`;
  } else {
    maxString = ']';
  }

  return `${minString},${maxString}`;
};

export const stringifyFilter = (filter: QueryStateFilter) => {
  if (isFilterRange(filter)) {
    return stringifyFilterRange(filter);
  } else {
    const { operand } = filter;
    return Array.isArray(operand) ? operand.join(',') : operand;
  }
};

export const addURLSearchParamsFromFilters = (
  searchParams: URLSearchParams,
  filters: QueryStateFilter[],
) => {
  filters.forEach((filter) => {
    let fieldName = filter.field;
    if (!isFilterRange(filter) && filter.operator === 'notIn') {
      fieldName += '!';
    }
    searchParams.set(fieldName, stringifyFilter(filter));
  });
};

export const getSolQueryFiltersFromQueryParamsFilters = (
  filters: QueryStateFilter[],
): SolQueryFilter[] => {
  return filters.reduce<SolQueryFilter[]>((memo, filter) => {
    if (isFilterRange(filter)) {
      // Convert a single FilterRange filter into 2 Sol filters
      const rangeFilters: SolQueryFilter[] = [];

      if (filter.min != null) {
        rangeFilters.push({
          field: filter.field,
          operator: filter.min.inclusive ? 'gte' : 'gt',
          operand: filter.min.operand,
        });
      }

      if (filter.max != null) {
        rangeFilters.push({
          field: filter.field,
          operator: filter.max.inclusive ? 'lte' : 'lt',
          operand: filter.max.operand,
        });
      }

      return memo.concat(rangeFilters);
    }

    return memo.concat(filter);
  }, []);
};
