import { Text } from '@shared/typography';
import { Tooltip } from 'antd';
import { PipelineVendorInfluenceDataType } from 'api/pipeline';
import classNames from 'classnames';
import { numberFormat } from 'helper/numberFormatter';

import PipelineVendorInfluenceCellTooltip from './PipelineVendorInfluenceCellTooltip';
import { SERIES_INFLUENCE_TYPE } from './PipelineVendorInfluenceTable';
import styles from './PipelineVendorInfluenceTableBarCell.module.scss';

const ALLOCATED_LABEL_WIDTH = 75;

type Props = {
  data: PipelineVendorInfluenceDataType;
  maxPercentage?: number;
  hoveredRow?: PipelineVendorInfluenceDataType | null;
  enabledSeries: SERIES_INFLUENCE_TYPE[];
};

const PipelineVendorInfluenceTableBarCell = ({
  data,
  maxPercentage = 1,
  hoveredRow = null,
  enabledSeries,
}: Props) => {
  const isOppEnabled = enabledSeries.indexOf(SERIES_INFLUENCE_TYPE.OPP_INFLUENCE) >= 0;
  const isPipeEnabled = enabledSeries.indexOf(SERIES_INFLUENCE_TYPE.PIPE_INFLUENCE) >= 0;
  const isNoneEnabled = !isOppEnabled && !isPipeEnabled;

  const oppWidthPercent = isNoneEnabled
    ? 0
    : Math.min(100, (data.opportunitiesInfluencedPercent / maxPercentage) * 100);
  const pipeWidthPercent = isNoneEnabled
    ? 0
    : Math.min(100, (data.pipelineRevenueInfluencePercent / maxPercentage) * 100);

  const isFaded = hoveredRow && hoveredRow !== data;

  return (
    <div
      className={classNames(styles.barGroup, { [styles.isFaded]: isFaded })}
      style={{ width: `calc(100% - ${ALLOCATED_LABEL_WIDTH}px)` }}
    >
      {
        <div
          className={classNames(styles.bar, styles.oppBar, { [styles.hidden]: !isOppEnabled })}
          style={{
            width: `${oppWidthPercent}%`,
            height: isOppEnabled ? (isPipeEnabled ? '50%' : '100%') : '0%',
          }}
        >
          <div className={styles.label}>
            <Text variant="caption1" className={classNames(styles.text, 'light')}>
              {numberFormat(data.opportunitiesInfluencedPercent, { isPercent: true, precision: 1 })}
            </Text>
          </div>
        </div>
      }
      {
        <div
          className={classNames(styles.bar, styles.pipeBar, { [styles.hidden]: !isPipeEnabled })}
          style={{
            width: `${pipeWidthPercent}%`,
            height: isPipeEnabled ? (isOppEnabled ? '50%' : '100%') : '0%',
          }}
        >
          <div className={styles.label}>
            <Text className={classNames(styles.text, 'light')} variant="caption1">
              {numberFormat(data.pipelineRevenueInfluencePercent, {
                isPercent: true,
                precision: 1,
              })}
            </Text>
          </div>
        </div>
      }
      <Tooltip
        overlayStyle={{
          pointerEvents: 'none',
          visibility: hoveredRow === data ? 'visible' : 'hidden',
          maxWidth: 600,
        }}
        placement="bottom"
        open={hoveredRow === data}
        title={<PipelineVendorInfluenceCellTooltip data={data} />}
      >
        {/* A hidden bar with the full width of the stacked series that tooltip can anchor to */}
        <div
          className={styles.tooltipAnchor}
          style={{
            width: `${Math.max(oppWidthPercent, pipeWidthPercent)}%`,
          }}
        />
      </Tooltip>
    </div>
  );
};

export default PipelineVendorInfluenceTableBarCell;
