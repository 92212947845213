import { Link, Text } from '@shared/typography';
import classNames from 'classnames';

import styles from './AxisLabel.module.scss';

type Props = {
  align?: 'center' | 'left' | 'right';
  label: string;
  linkUrl?: string | false;
  secondaryLabel?: string;
};

/**
 * Only use this component within a Highcharts Axis. Use with `linkifyAxisLabels` utility to turn
 * these fake links into real ones (sort of).
 */
const AxisLabel = ({ align = 'center', label, linkUrl, secondaryLabel }: Props) => {
  return (
    <div className={classNames(styles.label, [styles[`align-${align}`]])}>
      {/* Why do we put an `id` here? because highcharts doesn't support dynamic content in their
      axis label formatter. Any events will be stripped out and react-router <Link> components are
      rendered outside of React context which doesn't work. So, we put an `id` with the desired url
      on the link and add a click handler in post-process which navigates the user to that url */}
      {linkUrl ? (
        <Link id={linkUrl} variant="caption1" color="black" weight="semi-bold">
          {label}
        </Link>
      ) : (
        <Text variant="caption1" color="black" weight="semi-bold">
          {label}
        </Text>
      )}
      {secondaryLabel && <Text variant="caption2">{secondaryLabel}</Text>}
    </div>
  );
};

export default AxisLabel;
