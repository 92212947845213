import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Flare, { Area, Axis, Legend, Notes, Tooltip } from '@shared/Flare';
import { NotesPanel } from '@shared/panels';
import { AudienceGroupedOptionType } from 'api/audience';
import { usePixelImpressionTrend } from 'api/pixel';
import { ALL_PRIMARY } from 'constants/colors';
import { DateFormatMD, MonthDateYearFormat } from 'constants/formats';
import { getTime } from 'helper/dateHelper';
import { numberFormat } from 'helper/numberFormatter';
import moment from 'moment';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  startDate?: string;
  endDate?: string;
  isLoading: boolean;
};

const PixelImpressionTrendChart = ({
  pixelIds,
  audience,
  startDate,
  endDate,
  isLoading,
}: Props) => {
  // TODO: Eventually we will support multiple audiences
  const audienceId = audience?.audienceIds?.[0];

  const {
    pixelImpressionTrend,
    isLoading: isEfficiencyDataLoading,
    error,
  } = usePixelImpressionTrend(pixelIds, startDate, endDate, audienceId);

  const seriesData = useMemo(() => {
    if (!pixelImpressionTrend?.data) {
      return undefined;
    }

    return [
      {
        id: 'impressions',
        zIndex: 1,
        name: t`Total impressions`,
        data: pixelImpressionTrend.data.map((d) => ({
          amount: d.impressions,
          date: d.sundayOfWeek,
        })),
      },
    ];
  }, [pixelImpressionTrend]);

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      size="L"
      title={<Trans>Impression Trend</Trans>}
      noPadding
      style={{ height: 465 }}
    >
      <Flare
        data={seriesData}
        description={t`A chart showing total impressions over time for the pixel.`}
        parseX={getTime}
        colors={[ALL_PRIMARY]}
        isLoading={isLoading || isEfficiencyDataLoading}
        error={error}
      >
        <Legend reversed noToggleVisibility />
        <Area x="date" y="amount" position="overlap" />
        <Axis
          position="left"
          title={t`Impressions`}
          labelFormat={(item) => numberFormat(item.value, { compact: true })}
        />
        <Axis
          type="datetime"
          crosshair="line"
          position="bottom"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
        />
        <Tooltip
          titleFormat={(item) => moment(item?.x).format(MonthDateYearFormat)}
          rowValueFormat={(item) => numberFormat(item?.y)}
          rowSecondaryValueFormat={(item) =>
            item?.colorIndex !== 0
              ? `(${numberFormat((item?.y ?? 0) / item?.point.custom?.all, {
                  isPercent: true,
                  precision: 1,
                })})`
              : ''
          }
          shared
        />
        <Notes />
      </Flare>
    </NotesPanel>
  );
};

export default PixelImpressionTrendChart;
