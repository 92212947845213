import { Text } from '@shared/typography';
import { Tooltip } from 'antd';
import { AudienceOptionType } from 'api/audience';
import { VendorActivityDataType } from 'api/vendor';
import classNames from 'classnames';
import { numberFormat } from 'helper/numberFormatter';

import styles from './VendorActivityTableBarCell.module.scss';
import VendorTableCellTooltip from './VendorTableCellTooltip';
import { SERIES_TYPE } from './types';

const ALLOCATED_LABEL_WIDTH = 60;

type Props = {
  data: VendorActivityDataType;
  audience?: AudienceOptionType;
  maxVisits?: number;
  hoveredRow?: VendorActivityDataType | null;
  stickyRow?: VendorActivityDataType | null;
  enabledSeries: SERIES_TYPE[];
  onStickyTooltipClose?: () => void;
};

const VendorVisitBreakdownTableBarCell = ({
  data,
  audience,
  maxVisits = 0,
  hoveredRow = null,
  stickyRow = null,
  enabledSeries,
  onStickyTooltipClose,
}: Props) => {
  const isAudienceEnabled = audience?.name && enabledSeries.indexOf(SERIES_TYPE.AUDIENCE) >= 0;
  const isTAMEnabled = enabledSeries.indexOf(SERIES_TYPE.TAM) >= 0;
  const isAllEnabled = enabledSeries.indexOf(SERIES_TYPE.ALL) >= 0;
  const isTooltipSticky = stickyRow === data;
  const isRowHovered = hoveredRow === data;
  const showTooltip = !stickyRow || isTooltipSticky;
  const isTooltipOpen = isTooltipSticky || (showTooltip && isRowHovered);

  const getLabelValue = () => {
    if (isAllEnabled) {
      return data.visits;
    }

    if (isTAMEnabled) {
      return data.tamVisits;
    }

    if (isAudienceEnabled && audience?.name) {
      return data.audienceVisits;
    }

    return 0;
  };

  const audienceVisits = isAudienceEnabled ? data.audienceVisits : 0;
  const tamVisits = isTAMEnabled ? Math.max(data.tamVisits - audienceVisits, 0) : 0;
  const allVisits = Math.max(data.visits - tamVisits - audienceVisits, 0);

  const audiencePercent = +(isAudienceEnabled
    ? ((audienceVisits / maxVisits) * 100).toFixed(2)
    : 0);
  const tamPercent = +(isTAMEnabled ? ((tamVisits / maxVisits) * 100).toFixed(2) : 0);
  const allPercent = +(isAllEnabled ? ((allVisits / maxVisits) * 100).toFixed(2) : 0);
  const labelLeftPercent = (audiencePercent + tamPercent + allPercent).toFixed(2);

  const isNotSticky = stickyRow && !isTooltipSticky && !isRowHovered;
  const isNotHovered = !stickyRow && hoveredRow && !isRowHovered;
  const isFaded = isNotSticky || isNotHovered;
  const labelValue = getLabelValue();

  return (
    <div
      className={classNames(styles.barGroup, { [styles.isFaded]: isFaded })}
      style={{ width: `calc(100% - ${ALLOCATED_LABEL_WIDTH}px)` }}
    >
      <div
        className={classNames(styles.bar, styles.audienceBar)}
        style={{ width: `${audiencePercent}%` }}
      />
      <div className={classNames(styles.bar, styles.tamBar)} style={{ width: `${tamPercent}%` }} />
      <div className={classNames(styles.bar, styles.allBar)} style={{ width: `${allPercent}%` }} />
      <Tooltip
        overlayClassName={classNames(styles.tooltipOverlay, {
          [styles.visible]: isTooltipOpen,
          [styles.sticky]: isTooltipSticky,
        })}
        placement="bottom"
        open={isTooltipOpen}
        title={
          <VendorTableCellTooltip
            data={data}
            audience={audience}
            sticky={isTooltipSticky}
            onClose={onStickyTooltipClose}
          />
        }
      >
        {/* A hidden bar with the full width of the stacked series that tooltip can anchor to */}
        <div className={styles.tooltipAnchor} style={{ width: `${labelLeftPercent}%` }} />
      </Tooltip>
      <div className={styles.label} style={{ left: `${labelLeftPercent}%` }}>
        {labelValue > 0 && (
          <Text variant="caption1" className={classNames(styles.text, 'light')}>
            {numberFormat(labelValue)}
          </Text>
        )}
      </div>
    </div>
  );
};

export default VendorVisitBreakdownTableBarCell;
