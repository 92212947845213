import { DashboardWidgetComponentProps } from '@shared/Dashboard/types';
import BenchmarksChart from '@shared/charts/BenchmarksChart';
import { useBenchmarksByChannel } from 'api/benchmarks';

const BenchmarksWidget = ({ dashboardFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date } = dashboardFilters;
  const {
    benchmarksByChannel: data,
    isLoading,
    error,
  } = useBenchmarksByChannel(start_date, end_date);

  return <BenchmarksChart isLoading={isLoading} error={error} data={data} />;
};

export default BenchmarksWidget;
