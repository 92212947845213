import { PAGINATION_PARAM_KEYS } from 'router/searchParams/defaultPaginationParams';
import { PIXEL_SORT_KEYS } from 'router/searchParams/pixelSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from 'router/types';

export const pixels = {
  path: '/app/pixels',
  description:
    'A list of all pixels created by the company. Each pixel is shown with the status, updated date, the most recent impression date, and the number of impressions in the past 30 days.',
  navigable: true,
  searchParams: {
    page: {
      type: 'string',
      description: 'The page number to display',
    },
    size: {
      type: 'string',
      description: 'The number of items to display per page',
    },
    sortBy: {
      type: 'enum',
      values: [
        'name',
        'channel.name',
        'vendor.name',
        'audience.name',
        'status',
        'updated_at',
        'metrics.lastImpressionDate',
        'metrics.impressions',
      ],
      default: 'updated_at',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<PIXEL_SORT_KEYS>,
    sortOrder: {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description: 'The sort order',
    },
    search: {
      type: 'string',
      description: 'A string to search for in the pixel name',
    },
  },
} satisfies NavigableRouteType<PAGINATION_PARAM_KEYS>;

export const pixelCompare = {
  path: '/app/pixels/compare/:pixels?',
  description:
    'Allows the user to compare two or more pixels side by side (up to 5). Each pixel in the comparison is shown with a total count of impressions, reach and engagement metrics for potential customers, contribution of each pixel to pipeline or closed-won opportunities, targeting efficiency, CPM, and cost per visit.',
  navigable: true,
};

export const pixelCreate = {
  path: '/app/pixels/create',
  description:
    'A form to create a new pixel. The user can name the pixel, add a description, and also select a channel, vendor, and audience they plan to target with the pixel. Once the form is filled-out, a pixel code will be displayed for the user to copy and paste in their different marketing campaigns to measure performance.',
  navigable: true,
};

export const pixelDetail = {
  path: '/app/pixels/:pixels',
  description:
    'Shows metrics for a single pixel or a comma-separated list of pixel ids as a dynamic grouped pixel. The pixel is shown with the total number of impressions, visits, pipeline opportunities, and closed-won opportunities influenced by the pixel. ',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the channels by',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const pixelEdit = {
  path: '/app/pixels/:pixel/edit',
  description:
    'A form where the user can edit name, description, or audience (channel and vendor cannot be changed). The pixel code can also be seen and copied on this page.',
  navigable: true,
};

export const pixelById = (pixelId?: string) => `${pixels.path}/${pixelId}`;
export const pixelGroupByIds = (pixelIds?: string[]) => `${pixels.path}/${pixelIds?.join(',')}`;
export const pixelCompareByIds = (pixelIds?: string[]) =>
  `${pixels.path}/compare/${pixelIds?.join(',')}`;
export const pixelEditById = (pixelId?: string): string => `${pixelById(pixelId)}/edit`;
