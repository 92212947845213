import { C99FlagSet } from 'constants/featureFlags';

export const C99FeatureFlagEventType = 'featureFlagEvent';

type FFEventProps = {
  key: string;
  oldValue: C99FlagSet;
  newValue: C99FlagSet;
};

class C99FeatureFlagEvent extends CustomEvent<FFEventProps> {
  protected init: MyChoiceEventInit;

  constructor(typeName: string, init: MyChoiceEventInit) {
    super(typeName, init);
    this.init = init;
  }
}

interface MyChoiceEventInit extends CustomEventInit<FFEventProps> {
  bubbles?: boolean;
  cancelable?: boolean;
  composed?: boolean;
  detail: FFEventProps;
}

export default C99FeatureFlagEvent;
