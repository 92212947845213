import { Trans, t } from '@lingui/macro';
import { Option, Select, SelectProps } from '@shared/Form';
import { Text } from '@shared/typography';
import { Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { OpportunityType, useRecentDeals } from 'api/opportunity';

import styles from './ClosedWonSelect.module.scss';

interface Props extends SelectProps {
  value?: OpportunityType | null;
  onChange?: (deal: OpportunityType) => void;
}

const ClosedWonSelect = ({ value, onChange, ...rest }: Props) => {
  const { recentDeals, isLoading } = useRecentDeals();

  const handleChange = ({ value: dealId }: DefaultOptionType) => {
    const recentDeal = recentDeals?.find((deal) => deal.id === dealId);

    if (onChange && recentDeal) {
      onChange?.(recentDeal);
    }
  };

  return (
    <Select
      width={250}
      isLoading={isLoading}
      value={{ value: value?.id, label: value?.account?.company.name }}
      onChange={handleChange}
      placeholder={t`Select Opportunity...`}
      notFoundContent={<Trans>No opportunities were found...</Trans>}
      labelInValue
      showSearch
      {...rest}
    >
      {(recentDeals || [])?.map((deal) => (
        <Option key={deal.id} value={deal.id}>
          <Space direction="vertical" size={0}>
            <Text className={styles.dropdownLabel} variant="body1" color="grey" weight="bold">
              {deal.account?.company.name}
            </Text>
            <Text className={styles.dropdownLabel} variant="caption1" color="grey">
              {deal.name}
            </Text>
          </Space>
        </Option>
      ))}
    </Select>
  );
};

export default ClosedWonSelect;
