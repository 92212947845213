import { ReactNode } from 'react';

import { ReactGrid, ReactGridProps, TextCell } from '@silevis/reactgrid';
import { Cell, DefaultCellTypes, Span } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import DeleteIcon from 'assets/images/trash.png';
import classNames from 'classnames';

import CurrencyCell from './CurrencyCell';
import styles from './ExcelGrid.module.scss';
import HeaderCell from './HeaderCell';
import IconCell from './IconCell';

type Props = ReactGridProps & {
  className?: string;
};

export interface HeaderCellInterface extends Cell, Span {
  type: 'header';
  text: string;
}

export interface CurrencyCellInterface extends Cell {
  type: 'currency';
  text: string;
}

export interface IconCellInterface extends Cell, Span {
  type: 'icon';
  iconSrc?: string;
  onIconClick?: () => void;
}

type HeaderCellType = {
  text: string;
  isFixedHeader?: boolean;
  isSorted?: boolean;
  isEoY?: boolean;
  currentMonth?: boolean;
};

type CellType = {
  text: string;
  isFixedLeft?: boolean;
  isSorted?: boolean;
  nonEditable?: boolean;
  disabled?: boolean;
  isEoY?: boolean;
  highlighted?: boolean;
  currentMonth?: boolean;
  renderer?: (text: string) => ReactNode;
};

type TotalCellType = HeaderCellType & {
  isExpanded?: boolean;
};

type IconCellType = {
  disabled?: boolean;
  onIconClick?: () => void;
};

const getCommonCellClassNames = (data: CellType | HeaderCellType | TotalCellType) =>
  classNames({
    [styles.isCurrentMonth]: data.currentMonth,
    [styles.isEndOfYear]: data.isEoY,
    [styles.isSorted]: data.isSorted,
  });

const getBodyCellClassNames = (data: CellType) =>
  classNames(styles.bodyCell, getCommonCellClassNames(data), {
    [styles.isFixedLeft]: data.isFixedLeft,
    [styles.isHighlighted]: data.highlighted,
    [styles.isEditable]: !data.nonEditable && !data.disabled,
    [styles.disabled]: data.disabled,
  });

const getHeaderCellClassNames = (data: HeaderCellType) =>
  classNames(styles.headerCell, getCommonCellClassNames(data), {
    [styles.isFixedLeft]: data.isFixedHeader,
  });

const getTotalCellClassNames = (data: TotalCellType) =>
  classNames(styles.bodyCell, styles.totalCell, getCommonCellClassNames(data), {
    [styles.isFixedLeft]: data.isFixedHeader,
    [styles.noRightBorder]: data.isExpanded,
  });

const getIconCellClassNames = (data: IconCellType) =>
  classNames(styles.bodyCell, {
    [styles.totalCell]: !data.onIconClick,
    [styles.disabled]: data.disabled,
  });

export const getHeaderCell = (data: HeaderCellType): HeaderCellInterface => ({
  ...data,
  type: 'header',
  className: getHeaderCellClassNames(data),
});

export const getTotalCell = (data: TotalCellType): DefaultCellTypes | CurrencyCellInterface => ({
  type: data.isFixedHeader ? 'text' : 'currency',
  className: getTotalCellClassNames(data),
  text: data.text,
  nonEditable: true,
});

export const getIconCell = (data: IconCellType): IconCellInterface => ({
  type: 'icon',
  className: getIconCellClassNames(data),
  iconSrc: data.onIconClick && DeleteIcon,
  onIconClick: data.onIconClick,
});

export const getTextCell = (data: CellType): TextCell => ({
  type: 'text',
  className: getBodyCellClassNames(data),
  nonEditable: data.nonEditable,
  text: data.text,
  renderer: data.renderer,
});

export const getCell = (data: CellType): DefaultCellTypes | CurrencyCellInterface => ({
  type: data.isFixedLeft ? 'text' : 'currency',
  className: getBodyCellClassNames(data),
  nonEditable: data.nonEditable || data.disabled,
  text: data.text,
});

const ExcelGrid = ({ className, ...rest }: Props) => {
  return (
    <div className={classNames(styles.table, className)}>
      <ReactGrid
        customCellTemplates={{
          currency: new CurrencyCell(),
          header: new HeaderCell(),
          icon: new IconCell(),
        }}
        {...rest}
      />
    </div>
  );
};

export default ExcelGrid;
