import { PAGINATION_PARAM_KEYS } from 'router/searchParams/defaultPaginationParams';
import { VENDOR_SORT_KEYS } from 'router/searchParams/vendorSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from 'router/types';

export const vendors = {
  path: '/app/vendors',
  description:
    'Displays metrics for each vendor. An overview of each vendor is shown with total spend, pixel impressions, pixel click-throughs, visits, companies who have engaged with the company, pipeline opportunities influenced, and closed-won opportunities influenced. The vendor can be broken down by the audiences that are targeted through that vendor. A fit score visualization is also displayed which shows how well the vendor is performing relative to other vendors in the same space.',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the vendors by',
    },
    page: {
      type: 'string',
      description: 'The page number to display',
    },
    size: {
      type: 'string',
      description: 'The number of items to display per page',
    },
    sortBy: {
      type: 'enum',
      values: ['closedWonInfluenced', 'pipelineInfluenced', 'visits'],
      default: 'visits',
      description: 'The field to sort by',
    } satisfies RouteSearchParamEnumType<VENDOR_SORT_KEYS>,
    sortOrder: {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description: 'The sort order',
    },
  },
} satisfies NavigableRouteType<'audience' | Exclude<PAGINATION_PARAM_KEYS, 'search'>>;

export const vendorDetail = {
  path: '/app/vendors/:vendor',
  description:
    'Display metrics for an individual vendor within a marketing channel. Total visits, total spend, and spend per visit are shown. The vendor can be broken down by the audiences that are targeted through that vendor.',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the vendor metrics by',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const vendorById = (vendorId?: string) =>
  vendorId && `${vendors.path}/${encodeURIComponent(vendorId)}`;
