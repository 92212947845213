import { Text } from '@shared/typography';
import classNames from 'classnames';
import { numberFormat } from 'helper/numberFormatter';

import styles from './BenchmarksChartAxis.module.scss';

type Props = {
  width: number;
};

const BenchmarksChartAxis = ({ width }: Props) => {
  const isCompact = width < 400;
  const tickPositions = isCompact ? [0, 0.5, 1] : [0, 0.25, 0.5, 0.75, 1];

  return (
    <div className={classNames(styles.axis, { [styles.compact]: isCompact })}>
      <div className={styles.labelContainer}>
        {tickPositions.map((position) => (
          <Text
            key={position}
            className={styles.axisLabel}
            variant="caption1"
            style={{ left: `${position * 100}%` }}
          >
            {numberFormat(position, { isPercent: true, precision: 0 })}
          </Text>
        ))}
      </div>
    </div>
  );
};

export default BenchmarksChartAxis;
