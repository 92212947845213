import { useState } from 'react';

import { t } from '@lingui/macro';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { Page } from '@shared/page';
import { Space } from 'antd';

import ChannelOverviewKPIMetrics from './ChannelOverviewKPIMetrics';
import ChannelsSpendPerVisitChart from './ChannelsSpendPerVisitChart';
import ChannelsTrafficBreakdownChart from './ChannelsTrafficBreakdownChart';

const ChannelOverview = () => {
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Channels`} pageName={t`All Channels`}>
      <Space direction="vertical" size="large">
        <DashboardFilterProvider
          dashboardFilters={filters}
          onDashboardFiltersChange={setDashboardFilters}
        >
          <DashboardDateRangeFilter />
          <DashboardAudienceFilter />
        </DashboardFilterProvider>
        <ChannelOverviewKPIMetrics
          audience={filters.audience}
          startDate={filters.start_date}
          endDate={filters.end_date}
        />
        <ChannelsTrafficBreakdownChart
          audience={filters.audience}
          startDate={filters.start_date}
          endDate={filters.end_date}
        />
        <ChannelsSpendPerVisitChart
          audience={filters.audience}
          startDate={filters.start_date}
          endDate={filters.end_date}
        />
      </Space>
    </Page>
  );
};

export default ChannelOverview;
