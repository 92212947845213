import { useState } from 'react';

import { t } from '@lingui/macro';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { Page } from '@shared/page';
import { Col, Row, Space } from 'antd';

import PipelineInfluencedTable from './PipelineInfluencedTable';
import PipelineKPIMetrics from './PipelineKPIMetrics';
import PipelineReturnOnSpendChart from './PipelineReturnOnSpendChart';
import PipelineTrendsChart from './PipelineTrendsChart';
import PipelineVendorInfluenceTable from './PipelineVendorInfluenceTable';

const PipelineOverview = () => {
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Analytics`} pageName={t`Pipeline`}>
      <Space direction="vertical" size="large">
        <DashboardFilterProvider
          dashboardFilters={filters}
          onDashboardFiltersChange={setDashboardFilters}
        >
          <DashboardDateRangeFilter />
        </DashboardFilterProvider>
        <PipelineKPIMetrics startDate={filters.start_date} endDate={filters.end_date} />
        <PipelineTrendsChart startDate={filters.start_date} endDate={filters.end_date} />
        <Row gutter={[24, 24]}>
          <Col xl={12} lg={24} sm={24} xs={24}>
            <PipelineReturnOnSpendChart startDate={filters.start_date} endDate={filters.end_date} />
          </Col>
          <Col xl={12} lg={24} sm={24} xs={24}>
            <PipelineInfluencedTable startDate={filters.start_date} endDate={filters.end_date} />
          </Col>
        </Row>
        <PipelineVendorInfluenceTable startDate={filters.start_date} endDate={filters.end_date} />
      </Space>
    </Page>
  );
};

export default PipelineOverview;
