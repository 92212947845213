import { useEffect } from 'react';

import { t } from '@lingui/macro';

import { useHeaderContext } from './HeaderContext';

type Props = {
  children: string;
  hideHeaderTitle?: boolean;
};

const HeaderSetter = ({ children, hideHeaderTitle = false }: Props) => {
  const { setTitle } = useHeaderContext();

  useEffect(() => {
    if (!hideHeaderTitle) {
      setTitle(children);
    }

    if (children) {
      document.title = children;
    } else {
      document.title = t`Channel99`;
    }

    return () => {
      setTitle(null);
    };
  }, [children]);

  return null;
};

export default HeaderSetter;
