import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { ReturnToLink } from '@shared/ReturnToLink';
import Error404 from '@shared/errors/Error404';
import { Page } from '@shared/page';
import { Space } from 'antd';
import { useVendorById } from 'api/vendor';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'router';

import VendorDetailKPIMetrics from './VendorDetailKPIMetrics';
import VendorWeeklyVisitsChart from './VendorWeeklyVisitsChart';

const VendorDetail = () => {
  const { vendor: vendorId } = useParams();
  const { vendor, isLoading } = useVendorById(vendorId);
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Vendors`} pageName={vendor && `${vendor?.channel?.name} - ${vendor?.name}`}>
      <Space direction="vertical" size="large">
        <ReturnToLink route={ROUTES.vendors.path} routeName={<Trans>Vendors</Trans>} />
        {!isLoading && vendor == null ? (
          <Error404 message={<Trans>The vendor you're looking for does not exist</Trans>} />
        ) : (
          <>
            <DashboardFilterProvider
              dashboardFilters={filters}
              onDashboardFiltersChange={setDashboardFilters}
            >
              <DashboardDateRangeFilter />
              <DashboardAudienceFilter />
            </DashboardFilterProvider>
            <VendorDetailKPIMetrics
              vendorId={vendor?.id}
              audience={filters.audience}
              startDate={filters.start_date}
              endDate={filters.end_date}
              isLoading={isLoading}
            />
            <VendorWeeklyVisitsChart
              vendorId={vendor?.id}
              audience={filters.audience}
              startDate={filters.start_date}
              endDate={filters.end_date}
              isLoading={isLoading}
            />
          </>
        )}
      </Space>
    </Page>
  );
};

export default VendorDetail;
