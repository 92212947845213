import { useState } from 'react';

import { t } from '@lingui/macro';
import { ActionIcon } from '@shared/buttons';
import { Tooltip } from 'antd';
import CollapseIcon from 'assets/svg/collapse.svg?react';
import ExpandIcon from 'assets/svg/expand.svg?react';
import LinkExternalIcon from 'assets/svg/link-external.svg?react';

import { DashboardLink } from './types';

type Props = {
  link?: DashboardLink;
  isExpanded?: boolean;
  canExpand?: boolean;
  onLinkExternal: (link: string) => void;
  onExpandToggle: (isExpanded: boolean) => void;
};

const DashboardWidgetActions = ({
  link,
  isExpanded = false,
  canExpand = true,
  onLinkExternal,
  onExpandToggle,
}: Props) => {
  const [showExpandToggleTooltip, setShowExpandToggleTooltip] = useState(false);

  return (
    <>
      {link && (
        <Tooltip title={t`Take Me to ${link.title}`}>
          <ActionIcon
            aria-label={t`Visit page`}
            color="black"
            icon={<LinkExternalIcon />}
            size="large"
            onClick={() => onLinkExternal(link.url)}
          />
        </Tooltip>
      )}
      {canExpand && (
        <Tooltip
          title={isExpanded ? t`Collapse View` : t`Expand View`}
          open={showExpandToggleTooltip}
          onOpenChange={setShowExpandToggleTooltip}
        >
          {isExpanded ? (
            <ActionIcon
              aria-label={t`Collapse View`}
              color="black"
              icon={<CollapseIcon />}
              size="large"
              onClick={() => {
                setShowExpandToggleTooltip(false);
                onExpandToggle(false);
              }}
            />
          ) : (
            <ActionIcon
              aria-label={t`Expand View`}
              color="black"
              icon={<ExpandIcon />}
              size="large"
              onClick={() => {
                setShowExpandToggleTooltip(false);
                onExpandToggle(true);
              }}
            />
          )}
        </Tooltip>
      )}
    </>
  );
};

export default DashboardWidgetActions;
