import { Divider as DividerAntd, DividerProps } from 'antd';
import classNames from 'classnames';

import styles from './Divider.module.scss';

type Props = DividerProps & {
  color?: 'gray' | 'black';
};

const Divider = ({ className, color = 'gray', ...props }: Props) => {
  return (
    <DividerAntd
      plain
      className={classNames(styles.divider, styles[`color-${color}`], className)}
      {...props}
    />
  );
};

export default Divider;
