import { useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { DashboardLayout, DashboardType } from '@shared/Dashboard';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import WidgetLibrary from '@shared/Dashboard/WidgetLibrary';
import { Page } from '@shared/page';
import { ROUTES } from 'router';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const dashboard = useMemo<DashboardType>(
    () => ({
      id: 'dashboard',
      widgets: [
        {
          id: '0',
          type: 'cell',
          title: t`Pipeline Trend`,
          library: WidgetLibrary.PIPELINE_TRENDS_WIDGET,
          link: {
            url: ROUTES.pipeline.path,
            title: t`Pipeline`,
          },
          showNotes: true,
          gridPos: { x: 0, y: 0, w: 12, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '1',
          type: 'cell',
          title: t`Traffic Summary`,
          library: WidgetLibrary.TRAFFIC_TRENDS_WIDGET,
          link: {
            url: ROUTES.webTraffic.path,
            title: t`Web Traffic`,
          },
          showNotes: true,
          gridPos: { x: 12, y: 0, w: 12, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '3',
          type: 'cell',
          title: `${t`Benchmarks`}*`,
          library: WidgetLibrary.BENCHMARKS_WIDGET,
          link: {
            url: ROUTES.webTrafficBenchmarks.path,
            title: t`Benchmarks`,
          },
          gridPos: { x: 0, y: 16, w: 12, h: 10 },
        },
        {
          id: '4',
          type: 'cell',
          title: t`Closed Won`,
          library: WidgetLibrary.RECENT_DEALS_WIDGET,
          link: {
            url: ROUTES.closedWon.path,
            title: t`Closed Won`,
          },
          gridPos: { x: 12, y: 16, w: 12, h: 10 },
        },
      ],
    }),
    [],
  );
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Dashboard`} className={styles.dashboardPage}>
      <DashboardFilterProvider
        dashboardFilters={filters}
        onDashboardFiltersChange={setDashboardFilters}
      >
        <DashboardDateRangeFilter />
      </DashboardFilterProvider>
      <DashboardLayout dashboard={dashboard} dashboardFilters={filters} isEditable={false} />
    </Page>
  );
};

export default Dashboard;
