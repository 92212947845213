import { Trans } from '@lingui/macro';
import { Divider } from '@shared/Divider';
import { Button } from '@shared/buttons';
import { Text, Title } from '@shared/typography';
import { IntegrationProviderType, useIntegrationProviderDetails } from 'api/integration';
import PlusIcon from 'assets/svg/plus.svg?react';
import { Fragment } from 'react/jsx-runtime';

import IntegrationStatus from '../IntegrationStatus';
import { getProviderLogoById, getProviderShortDescriptionById } from '../providerUtil';
import IntegrationConnectionListItem from './IntegrationConnectionListItem';
import styles from './IntegrationDetailRail.module.scss';

type Props = {
  provider: IntegrationProviderType;
};

const IntegrationDetailRail = ({ provider }: Props) => {
  const { mutate } = useIntegrationProviderDetails(provider.id);

  const numConnections = provider?.connections.length ?? 0;
  const workingConnections = provider?.connections.filter((conn) => conn.status === 'active');
  const hasNoConnections = numConnections === 0;
  const numWorkingConnections = workingConnections?.length ?? 0;
  const numBrokenConnections = numConnections - numWorkingConnections;

  const handleDisconnect = async () => {
    mutate();
  };

  return (
    <div data-testid="integration-detail-rail" className={styles.rail}>
      <div className={styles.railHeading}>
        {getProviderLogoById(provider?.id)}
        <Title level="3" weight="semi-bold">
          {provider?.name}
        </Title>
      </div>
      {provider?.multiConnection ? (
        <>
          {numWorkingConnections > 0 && (
            <IntegrationStatus status="active" count={numWorkingConnections} />
          )}
          {numBrokenConnections > 0 && (
            <IntegrationStatus status="broken" count={numBrokenConnections} />
          )}
          {hasNoConnections && (
            <>
              <IntegrationStatus status="notConnected" />
              <Text variant="body2" color="semidark-grey">
                {getProviderShortDescriptionById(provider?.id)}
              </Text>
            </>
          )}
        </>
      ) : (
        <>
          <IntegrationStatus status={numWorkingConnections > 0 ? 'active' : 'notConnected'} />
          <Text variant="body2" color="semidark-grey">
            {getProviderShortDescriptionById(provider?.id)}
          </Text>
        </>
      )}
      {provider?.connections.map((conn) => (
        <Fragment key={conn.id}>
          <Divider type="horizontal" orientation="center" />
          <IntegrationConnectionListItem
            providerName={provider.name}
            connection={conn}
            onDisconnect={handleDisconnect}
          />
        </Fragment>
      ))}
      <Divider />
      {(hasNoConnections || provider?.multiConnection) && (
        <Button color="black" variant="secondary" icon={<PlusIcon />} to={provider?.startUrl}>
          <Trans>{provider?.name} Account</Trans>
        </Button>
      )}
    </div>
  );
};

export default IntegrationDetailRail;
