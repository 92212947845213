import WebTrafficCompanySizeChart from '@pages/web-traffic/WebTrafficCompanySizeChart';
import { DashboardWidgetComponentProps } from '@shared/Dashboard/types';
import { useWebTrafficBreakdownByCompanySize } from 'api/webTraffic';

const WebTrafficBreakdownByCompanySizeWidget = ({
  dashboardFilters,
}: DashboardWidgetComponentProps) => {
  const { start_date, end_date, audience } = dashboardFilters;
  const { webTrafficRevRangeSummary, isLoading, error } = useWebTrafficBreakdownByCompanySize(
    start_date,
    end_date,
    audience?.id,
  );

  return (
    <WebTrafficCompanySizeChart
      data={webTrafficRevRangeSummary}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficBreakdownByCompanySizeWidget;
