import { PropsWithChildren, createContext, useContext, useState } from 'react';

interface HeaderContextType {
  title: string | null;
  setTitle: (title: string | null) => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<string | null>(null);

  return <HeaderContext.Provider value={{ title, setTitle }}>{children}</HeaderContext.Provider>;
};
