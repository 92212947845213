import { useMemo } from 'react';

import { t } from '@lingui/macro';
import Flare, {
  Area,
  Axis,
  FlareError,
  FlareSeriesOptions,
  Legend,
  Notes,
  Tooltip,
} from '@shared/Flare';
import { AudienceOptionType } from 'api/audience';
import { WebTrafficVisitTrendByTypeDataType } from 'api/webTraffic';
import { AUDIENCE_PRIMARY, BOT_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from 'constants/colors';
import { DateFormatMD, MonthDateYearFormat } from 'constants/formats';
import { getUTCTime } from 'helper/dateHelper';
import { numberFormat } from 'helper/numberFormatter';
import moment from 'moment';
import { ROUTES } from 'router';

type Props = {
  data?: WebTrafficVisitTrendByTypeDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error: FlareError;
};

const WebTrafficVisitByTypeTrendChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'bot',
        name: t`Bot Visits`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.bot })),
      },
      {
        id: 'other',
        name: t`Other Visits`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.other })),
      },
      audience?.id
        ? {
            id: audience?.id,
            name: t`${audience?.name} Visits`,
            zIndex: 5,
            data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.audience })),
          }
        : {
            id: 'tam',
            name: t`TAM Visits`,
            zIndex: 5,
            data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.tam })),
          },
    ];
  }, [data]);

  return (
    <Flare
      data={seriesData}
      width="auto"
      height="auto"
      colors={[BOT_PRIMARY, VISITS_PRIMARY, audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY]}
      parseX={getUTCTime}
      description={t`A trend chart showing the number of visits by traffic type: Audience or TAM, Other, and Bot`}
      isLoading={isLoading}
      error={error}
    >
      <Legend reversed />
      <Area x="date" y="amount" position="stack" fillOpacity={0.85} />
      <Axis position="left" labelFormat={(item) => numberFormat(item.value)} />
      <Axis
        type="datetime"
        position="bottom"
        crosshair="line"
        labelFormat={(item) => moment(item.value).format(DateFormatMD)}
      />
      <Tooltip
        shared
        titleFormat={(item) => moment(item?.x).format(MonthDateYearFormat)}
        rowValueFormat={(item) => numberFormat(item?.y)}
        valueLink={(item) => {
          if (item?.colorIndex === 0) {
            return null;
          }
          if (item?.colorIndex === 1) {
            if (audience?.id) {
              return ROUTES.webTrafficActivityWithParams({
                audienceIds: audience?.id,
                'metrics.visits': '[1,]',
              });
            } else {
              return ROUTES.webTrafficActivityWithParams({
                inTam: 'false',
                'metrics.visits': '[1,]',
              });
            }
          } else {
            if (audience?.id) {
              return ROUTES.webTrafficActivityWithParams({
                audienceIds: audience?.id,
                'metrics.visits': '[1,]',
              });
            } else {
              return ROUTES.webTrafficActivityWithParams({
                inTam: 'true',
                'metrics.visits': '[1,]',
              });
            }
          }
        }}
        unstable_hasRouterContext={false}
      />
      <Notes />
    </Flare>
  );
};

export default WebTrafficVisitByTypeTrendChart;
