/* eslint-disable no-restricted-imports */
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from 'antd';
import classNames from 'classnames';

import styles from './Popover.module.scss';

export type PopoverProps = AntdPopoverProps & {
  noPadding?: boolean;
  variant?: 'default' | 'dark';
};

const Popover = ({
  overlayClassName,
  variant = 'default',
  noPadding = false,
  ...rest
}: PopoverProps) => {
  return (
    <AntdPopover
      overlayClassName={classNames(
        styles.overlay,
        overlayClassName,
        variant === 'dark' && styles.dark,
        {
          [styles.noPadding]: noPadding,
        },
      )}
      arrow
      {...rest}
    />
  );
};

export default Popover;
