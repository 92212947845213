import { Trans, t } from '@lingui/macro';
import { InstantSearchTable } from '@shared/InstantSearch';
import { ColumnsType } from '@shared/Table';
import { InstanceShortDataType } from 'api/organization';
import { MonthDateYearFormat } from 'constants/formats';
import { formatDate } from 'helper/dateHelper';

type Props = {
  selectedRow: string[];
  data?: InstanceShortDataType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: Error;
  onSelectedRowChange: (row: string[]) => void;
};

const LoginAsTable = ({
  selectedRow: selectedRowId,
  data,
  totalResults,
  isLoading,
  error,
  onSelectedRowChange,
}: Props) => {
  const columns: ColumnsType<InstanceShortDataType> = [
    {
      title: t`Customer Instance`,
      key: 'name',
      fixed: 'left',
      sorter: true,
    },
    {
      title: t`Domain`,
      key: 'domain',
    },
    {
      title: t`Instance Id`,
      key: 'id',
    },
    {
      title: t`Created On`,
      key: 'createdAt',
      sorter: true,
      render: (text) => formatDate(text, MonthDateYearFormat),
    },
  ];

  return (
    <InstantSearchTable<InstanceShortDataType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No customer instances found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      rowCursor="pointer"
      rowSelection={{
        type: 'radio',
        selectedRowKeys: selectedRowId,
        onSelect: (record) => onSelectedRowChange([record.id]),
      }}
      onRow={(record) => ({ onClick: () => onSelectedRowChange([record.id]) })}
    />
  );
};

export default LoginAsTable;
