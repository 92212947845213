import { useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import { ActionIcon } from '@shared/buttons';
import { Text, TextProps } from '@shared/typography';
import { Tooltip } from 'antd';
import Check from 'assets/svg/check.svg?react';
import Clone from 'assets/svg/clone-regular.svg?react';

const CopyToClipboardText = ({ value, ...rest }: TextProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleCopy = () => {
    if (value) {
      setCopied(true);
      navigator.clipboard.writeText(value + '');
    }
  };

  return (
    <Flex align="center" justify="center" gap="small" data-testid="clipboard-copy-text">
      <Text {...rest} value={value} />
      <Tooltip
        open={copied ? copied : undefined}
        title={copied ? t`Copied!` : t`Copy to clipboard`}
      >
        <ActionIcon
          color="black"
          size="small"
          aria-label={t`Copy text to clipboard`}
          icon={copied ? <Check /> : <Clone />}
          onClick={handleCopy}
        />
      </Tooltip>
    </Flex>
  );
};

export default CopyToClipboardText;
