import * as React from 'react';

import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  inNumericKey,
  isAlphaNumericKey,
  isNumpadNumericKey,
  keyCodes,
} from '@silevis/reactgrid';
import classNames from 'classnames';
import { maxVendorConfigFieldLength } from 'constants/numbers';
import { numberFormat } from 'helper/numberFormatter';

import styles from './CurrencyCell.module.scss';

export interface CurrencyCellType extends Cell {
  type: 'currency';
  text: string;
}

/**
 * These ignored keys will not be handled by the textfield. They will be caught by the Grid and
 * handled there.
 *
 * Note that DOWN_ARROW and UP_ARROW are handled by the grid and will "commit" a cell like the
 * enter key does. Since we don't have multi-line cell values this seems acceptable. If we ignore
 * those keys here, pressing them will increment or decrement the number by 1. This seems less
 * useful than just committing the cell.
 */
const isIgnoredKey = (keyCode: number): boolean =>
  keyCode === keyCodes.LEFT_ARROW ||
  keyCode === keyCodes.RIGHT_ARROW ||
  keyCode === keyCodes.END ||
  keyCode === keyCodes.HOME ||
  keyCode === keyCodes.BACKSPACE ||
  keyCode === keyCodes.DELETE;

class CurrencyCell implements CellTemplate<CurrencyCellType> {
  getCompatibleCell(uncertainCell: Uncertain<CurrencyCellType>): Compatible<CurrencyCellType> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Math.round(parseFloat(text));
    return { ...uncertainCell, text, value };
  }

  handleKeyDown(
    cell: Compatible<CurrencyCellType>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string,
  ): { cell: Compatible<CurrencyCellType>; enableEditMode: boolean } {
    if (!ctrl && !alt && (inNumericKey(keyCode) || isNumpadNumericKey(keyCode))) {
      return {
        cell: {
          ...cell,
          text: key,
        },
        enableEditMode: true,
      };
    }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<CurrencyCellType>,
    cellToMerge: UncertainCompatible<CurrencyCellType>,
  ): Compatible<CurrencyCellType> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  render(
    cell: Compatible<CurrencyCellType>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CurrencyCellType>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      return (
        <div className={classNames(styles.wrapper, cell.className)}>
          <div
            className={classNames(styles.cell, {
              [styles.editMode]: !cell.nonEditable,
            })}
          >
            {numberFormat(cell.value, { isCurrency: true, precision: 0 })}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.inputWrapper}>
        <input
          min={0}
          // max length of N symbols
          max={Number(
            Array.from(Array(maxVendorConfigFieldLength))
              .map(() => 9)
              .join(''),
          )}
          ref={(input) => {
            if (input) {
              input.focus();
            }
          }}
          defaultValue={+cell.text}
          onChange={(e) =>
            onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)
          }
          onBlur={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isNotEsc = (e as any).view?.event?.keyCode !== 27;
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              isNotEsc,
            );
          }}
          type="number"
          className={styles.input}
          onCopy={(e) => e.stopPropagation()}
          onCut={(e) => e.stopPropagation()}
          onPaste={(e) => {
            e.stopPropagation();
            const clipboardData = e.clipboardData;
            const pastedData = clipboardData.getData('Text');
            const numbers = Array.from(Array(maxVendorConfigFieldLength).keys()).map((val) =>
              val.toString(),
            );
            if (
              !pastedData
                .toString()
                .split('')
                .every((val) => numbers.includes(val)) ||
              e.currentTarget.value.length + pastedData.length >= 10
            ) {
              e.preventDefault();
            }
          }}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isIgnoredKey(e.keyCode)) {
              e.stopPropagation();
            }
            if (
              ['e', 'E', '+', '-', '.', ','].includes(e.key) ||
              (e.currentTarget.value.length >= maxVendorConfigFieldLength &&
                Array.from(Array(maxVendorConfigFieldLength).keys())
                  .map((val) => val.toString())
                  .includes(e.key))
            ) {
              e.preventDefault();
            }
          }}
        />
      </div>
    );
  }
}

export default CurrencyCell;
