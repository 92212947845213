import { PropsWithChildren, ReactNode } from 'react';

import { Text, Title } from '@shared/typography';
import classNames from 'classnames';

import styles from './SignInPanel.module.scss';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  showGap?: boolean;
  footer?: ReactNode;
};

const SignInPanel = ({
  title,
  description,
  children,
  showGap = false,
  footer,
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.panel}>
      <div className={styles.title}>
        <Title level={2} weight="light">
          {title}
        </Title>
      </div>
      {description && (
        <div className={styles.description}>
          <Text variant="body2">{description}</Text>
        </div>
      )}
      <div className={classNames(styles.content, { [styles.showGap]: showGap })}>{children}</div>
      <div className={classNames(styles.footer)}>{footer}</div>
    </div>
  );
};

SignInPanel.Instructions = ({ children }: PropsWithChildren) => {
  return (
    <Text variant="body1" className={styles.instructions}>
      {children}
    </Text>
  );
};

export default SignInPanel;
