import { CHANNEL_ID } from 'api/common';

import { GRAY_400 } from './semantic/gray';

const CHANNEL_COLOR_SCHEME: Record<CHANNEL_ID, string> = {
  [CHANNEL_ID.DISPLAY]: '#92B247',
  [CHANNEL_ID.PAID_SOCIAL]: '#59BDC6',
  [CHANNEL_ID.PAID_SEARCH]: '#E86D62',
  [CHANNEL_ID.PAID_VIDEO]: '#6D8635',
  [CHANNEL_ID.AR_INFLUENCER]: '#645EB2',
  [CHANNEL_ID.PR]: '#645EB2',
  [CHANNEL_ID.EMAIL]: '#DB6BB6',
  [CHANNEL_ID.ORGANIC_SEARCH]: '#34A686',
  [CHANNEL_ID.ORGANIC_SOCIAL]: '#B5A510',
  [CHANNEL_ID.ORGANIC_VIDEO]: '#AF5692',
  [CHANNEL_ID.REFERRAL]: '#024895',
  [CHANNEL_ID.SALES]: '#BFBFBF',
  [CHANNEL_ID.DIRECT]: '#808080',
  [CHANNEL_ID.OTHER]: '#BFBFBF',
};

export const getChannelColor = (channelId?: CHANNEL_ID) => {
  const color = channelId ? CHANNEL_COLOR_SCHEME[channelId] : GRAY_400;

  if (!color) {
    return CHANNEL_COLOR_SCHEME[CHANNEL_ID.OTHER];
  }
  return color;
};
