import { Trans } from '@lingui/macro';
import { Legend, LegendItem } from '@shared/Legend';
import { Panel } from '@shared/panels';
import { CompanyDetailType, useCompanySummaryById } from 'api/activity';
import { getChannelColor } from 'constants/colors/channel';
import moment from 'moment';

import styles from './CompanyTotalVisitsPanel.module.scss';
import CompanyVisitChartCaption from './CompanyVisitChartCaption';
import CompanyVisitDonutChart from './CompanyVisitDonutChart';

type Props = {
  data: CompanyDetailType | null;
  startDate?: string;
  endDate?: string;
};

const CompanyTotalVisitsPanel = ({ data, startDate, endDate }: Props) => {
  const { companySummary, isLoading, error } = useCompanySummaryById(data?.id, startDate, endDate);

  const numDays = moment(endDate).diff(moment(startDate), 'days');

  return (
    <Panel size="L" title={<Trans>Total Visits ({numDays} Days)</Trans>} noPadding>
      <Legend>
        {companySummary?.trafficVisitBreakdown.map((d) => (
          <LegendItem
            key={d.vendor.id}
            color={getChannelColor(d.vendor.channel?.id)}
            name={d.vendor.displayName}
          />
        ))}
      </Legend>
      <div className={styles.content}>
        <CompanyVisitDonutChart
          data={companySummary?.trafficVisitBreakdown}
          isLoading={isLoading}
          error={error}
        />
        <CompanyVisitChartCaption data={companySummary?.companySummary} />
      </div>
    </Panel>
  );
};

export default CompanyTotalVisitsPanel;
