import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import { Option, Select, SelectProps } from '@shared/Form';
import { DefaultOptionType } from 'antd/es/select';
import { useVendorList } from 'api/vendor';

interface Props extends SelectProps {
  value?: string | string[];
  disabledVendorIds?: string[];
}

/**
 * Presents the user with a list of Vendor names. Several Vendors (e.g. Google) have the same name
 * across multiple channels. Because of this we don't return ids in the `onChange` handler, just
 * the raw names unlike ChannelSelect or VendorByChannelSelect.
 */
const VendorSelect = ({ value, onChange, ...rest }: Props) => {
  const { vendors, isLoading } = useVendorList();
  const uniqueVendors = useMemo(() => {
    const vendorSet = new Set(vendors?.map((vendor) => vendor.name));
    return [...vendorSet];
  }, [vendors]);

  const handleChange = (
    { value }: DefaultOptionType,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (onChange) {
      if (Array.isArray(option)) {
        onChange(
          option.map((o) => o.value),
          option,
        );
      } else {
        onChange(value, option);
      }
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={value}
      onChange={handleChange}
      placeholder={t`Select Vendor...`}
      notFoundContent={<Trans>No vendors were found...</Trans>}
      showSearch
      {...rest}
    >
      {uniqueVendors?.map((vendor) => (
        <Option key={vendor} value={vendor}>
          {vendor}
        </Option>
      ))}
    </Select>
  );
};

export default VendorSelect;
