export const RED_100 = '#F6C5C0';
export const RED_200 = '#F1A7A1';
export const RED_300 = '#EE9189';
export const RED_400 = '#E86D62';
export const RED_500 = '#BA574E';
export const RED_600 = '#8B413B';
export const RED_700 = '#5D2C27';
export const RED_800 = '#2E1614';

export const ALL_REDS = [RED_100, RED_200, RED_300, RED_400, RED_500, RED_600, RED_700, RED_800];

export const ALL_SEMANTIC_REDS = [RED_300, RED_400, RED_500, RED_600, RED_700, RED_800];
