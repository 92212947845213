import { AxiosError, isAxiosError } from 'axios';

import { SolError } from '../api/solType';
import { getMessageByCode } from './messages';
import { CORE_ERROR_CODES } from './messages/core-standard-errors';

const isSolError = (e: SolError | unknown): e is SolError =>
  (e as SolError)?.extensions?.code != null && (e as SolError)?.extensions.details != null;

const getSolError = (error: SolError): SolError => {
  const {
    message,
    extensions: { code, details },
  } = error;
  const errorMessage = getMessageByCode(code, details);

  if (errorMessage) {
    return { ...error, message: errorMessage };
  }

  console.error(`Unknown error code: ${code}. "${message}"`);
  return { ...error, extensions: { ...error.extensions, code: CORE_ERROR_CODES.UNKNOWN } };
};

const getSolStandardError = (error: SolError | AxiosError | unknown): SolError => {
  if (isSolError(error)) {
    return getSolError(error);
  }

  if (isAxiosError(error) && isSolError(error.response?.data)) {
    return getSolError(error.response?.data as SolError);
  }

  console.error(`Unknown error`, error);
  const code = CORE_ERROR_CODES.UNKNOWN;
  return {
    message: getMessageByCode(code) || '',
    extensions: {
      code,
      details: {},
    },
  };
};

export default getSolStandardError;
