import { useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { DashboardLayout, DashboardType } from '@shared/Dashboard';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import WidgetLibrary from '@shared/Dashboard/WidgetLibrary';
import { Page } from '@shared/page';
import { ROUTES } from 'router';

import WebTrafficKPIMetrics from './WebTrafficKPIMetrics';

const WebTraffic = () => {
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFiltersType>({});
  const { audience } = dashboardFilters;

  const dashboard = useMemo<DashboardType>(
    () => ({
      id: 'webTraffic',
      widgets: [
        {
          id: '0',
          type: 'cell',
          title: t`Traffic Summary`,
          library: WidgetLibrary.TRAFFIC_TRENDS_WIDGET,
          showNotes: true,
          gridPos: { x: 0, y: 0, w: 24, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '1',
          type: 'cell',
          title: t`Total Visits`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_TYPE_WIDGET,
          gridPos: { x: 0, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '2',
          type: 'cell',
          title: audience?.name ? t`${audience?.name} Industries` : t`Industries`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_INDUSTRY_WIDGET,
          gridPos: { x: 8, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '3',
          type: 'cell',
          title: audience?.name ? t`${audience?.name} Company Size` : t`Company Size`,
          library: WidgetLibrary.TRAFFIC_BREAKDOWN_BY_COMPANY_SIZE_WIDGET,
          gridPos: { x: 16, y: 6, w: 8, h: 6 },
          verifyC99Tag: true,
        },
        {
          id: '4',
          type: 'cell',
          title: t`Top Companies`,
          library: WidgetLibrary.COMPANY_ACTIVITY_TOP_VISITS_WIDGET,
          link: {
            url: ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              sortBy: 'metrics.visits',
              sortOrder: 'desc',
            }),
            title: t`Company Activity`,
          },
          gridPos: { x: 0, y: 12, w: 24, h: 10 },
          verifyC99Tag: true,
        },
      ],
    }),
    [audience],
  );

  return (
    <Page title={t`Analytics`} pageName={t`Web Traffic`} style={{ gap: 24 }}>
      <DashboardFilterProvider
        dashboardFilters={dashboardFilters}
        onDashboardFiltersChange={setDashboardFilters}
      >
        <DashboardDateRangeFilter />
        <DashboardAudienceFilter />
      </DashboardFilterProvider>
      <WebTrafficKPIMetrics
        startDate={dashboardFilters.start_date}
        endDate={dashboardFilters.end_date}
        audience={dashboardFilters.audience}
      />
      <DashboardLayout
        dashboard={dashboard}
        dashboardFilters={dashboardFilters}
        isEditable={false}
      />
    </Page>
  );
};

export default WebTraffic;
