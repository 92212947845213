import { Trans } from '@lingui/macro';
import TableActions from '@shared/TableActions';
import { Button } from '@shared/buttons';
import { useMe } from 'providers/User';
import { ROUTES } from 'router';

const PixelsListHeader = () => {
  const user = useMe();

  return (
    <TableActions
      actions={
        user?.isAdmin && (
          <Button color="green" variant="primary" to={ROUTES.pixelCreate.path}>
            <Trans>Create Pixel</Trans>
          </Button>
        )
      }
    />
  );
};

export default PixelsListHeader;
