import {
  SolQueryParams,
  SolQueryParamsNew,
  SolQueryParamsVariables,
  SolQueryParamsVariablesNew,
} from '@shared/InstantSearch';

export const getVariablesFromTableParamsNew = (
  tableParams?: SolQueryParamsNew,
): SolQueryParamsVariablesNew | undefined => {
  if (!tableParams) {
    return undefined;
  }

  return {
    page: tableParams?.page,
    sort: tableParams?.sort ? [tableParams?.sort] : [],
    filter: tableParams?.filter,
    searchQuery: tableParams?.searchQuery,
  };
};

/**
 * @deprecated this uses old Sol DB and `search` pattern. Use `getVariablesFromTableParamsNew`
 * until we can deprecate it fully.
 */
const getVariablesFromTableParams = (
  searchFields: string[],
  tableParams?: SolQueryParams,
): SolQueryParamsVariables | undefined => {
  if (!tableParams) {
    return undefined;
  }

  return {
    page: tableParams?.page,
    sort: tableParams?.sort ? [tableParams?.sort] : [],
    filter: tableParams?.filter
      ? [
          {
            field: searchFields.join(','),
            operator: 'iLike',
            operand: `%${tableParams?.filter}%`,
          },
        ]
      : [],
  };
};

export default getVariablesFromTableParams;
