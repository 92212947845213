export enum THEMES {
  LIGHT = 'light',
  DARK = 'dark',
}

export type ThemeState = {
  theme: THEMES;
  setTheme: (theme: THEMES) => void;
  toggleTheme: () => void;
};
