import { forwardRef } from 'react';

import classNames from 'classnames';
import { BaseSelectRef } from 'rc-select';

import { Select, SelectProps } from '../Select';
import styles from './TagTextArea.module.scss';

export type TagTextAreaProps = Omit<SelectProps, 'dropdownStyle' | 'mode'> & {
  className?: string;
  tokenSeparators?: string[];
};

const TOKEN_SEPARATORS = [',', ' ', '\n'];

const TagTextArea = forwardRef<BaseSelectRef, TagTextAreaProps>(
  ({ className, tokenSeparators = TOKEN_SEPARATORS, ...props }: TagTextAreaProps, ref) => {
    return (
      <Select
        {...props}
        className={classNames(styles.tagTextArea, className)}
        ref={ref}
        mode="tags"
        tokenSeparators={tokenSeparators}
        dropdownStyle={{ display: 'none' }}
        suffixIcon={null}
      />
    );
  },
);

export default TagTextArea;
