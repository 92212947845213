import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import DashboardAudienceFilter from '@shared/Dashboard/Filters/DashboardAudienceFilter';
import DashboardDateRangeFilter from '@shared/Dashboard/Filters/DashboardDateRangeFilter';
import DashboardFilterProvider from '@shared/Dashboard/Filters/DashboardFilterProvider';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { ReturnToLink } from '@shared/ReturnToLink';
import Error404 from '@shared/errors/Error404';
import { Page } from '@shared/page';
import { Space } from 'antd';
import { useChannelById } from 'api/channel';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'router';

import ChannelDetailKPIMetrics from './ChannelDetailKPIMetrics';
import ChannelVisitsOverviewTable from './ChannelVisitsOverviewTable';
import ChannelWeeklyVisitsChart from './ChannelWeeklyVisitsChart';

const ChannelDetail = () => {
  const { channel: channelId } = useParams();
  const { channel, isLoading } = useChannelById(channelId);
  const [filters, setDashboardFilters] = useState<DashboardFiltersType>({});

  return (
    <Page title={t`Channels`} pageName={channel?.name}>
      <Space direction="vertical" size="large">
        <ReturnToLink route={ROUTES.channels.path} routeName={<Trans>Channels</Trans>} />
        {!isLoading && channel == null ? (
          <Error404 message={<Trans>The channel you're looking for does not exist</Trans>} />
        ) : (
          <>
            <DashboardFilterProvider
              dashboardFilters={filters}
              onDashboardFiltersChange={setDashboardFilters}
            >
              <DashboardDateRangeFilter />
              <DashboardAudienceFilter />
            </DashboardFilterProvider>
            <ChannelDetailKPIMetrics
              channelId={channel?.id}
              audience={filters.audience}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <ChannelWeeklyVisitsChart
              channelId={channel?.id}
              audience={filters.audience}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
            <ChannelVisitsOverviewTable
              channelId={channel?.id}
              audience={filters.audience}
              startDate={filters.start_date}
              endDate={filters.end_date}
            />
          </>
        )}
      </Space>
    </Page>
  );
};

export default ChannelDetail;
