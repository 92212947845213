import { ReactNode, useLayoutEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './ExpandedRow.module.scss';

type Props<T> = {
  record: T;
  index: number;
  indent: number;
  isExpanded: boolean;
  durationMs?: number;
  expandedRowRender?: (record: T, index: number, indent: number, expanded: boolean) => ReactNode;
};

const ExpandedRow = <T extends object>({
  record,
  index,
  indent,
  isExpanded,
  durationMs = 200,
  expandedRowRender,
}: Props<T>) => {
  const [isEntered, setIsEntered] = useState(false);

  useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isExpanded) {
      timeoutId = setTimeout(() => {
        setIsEntered(true);
      }, 0);
    } else {
      timeoutId = setTimeout(() => {
        setIsEntered(false);
      }, 0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isExpanded]);

  return (
    <div
      style={{ transitionDuration: `${durationMs}ms` }}
      className={classNames(styles.container, { [styles.expanded]: isEntered })}
    >
      <div className={styles.content}>{expandedRowRender?.(record, index, indent, isExpanded)}</div>
    </div>
  );
};

export default ExpandedRow;
