import { t } from '@lingui/macro';
import TooltipRow from '@shared/Flare/guides/TooltipRow';
import MultiSeriesTooltip from '@shared/MultiSeriesTooltip';
import { AudienceOptionType } from 'api/audience';
import { VendorActivityDataType } from 'api/vendor';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  data: VendorActivityDataType;
  audience?: AudienceOptionType;
  sticky: boolean;
  onClose?: () => void;
};

const VendorTableCellTooltip = ({ data, audience, sticky, onClose }: Props) => {
  return (
    <MultiSeriesTooltip
      data-testid={sticky && 'vendor-tooltip-sticky'}
      title={data.vendor.name}
      sticky={sticky}
      onClose={onClose}
    >
      {audience?.name && (
        <TooltipRow
          color={AUDIENCE_PRIMARY}
          name={audience.name}
          secondaryValue={`(${numberFormat(data.audienceVisits / data.visits, {
            isPercent: true,
          })})`}
          value={t`${numberFormat(data.audienceVisits)} Visits`}
          valueLink={
            data.audienceVisits > 0 &&
            ROUTES.webTrafficActivityWithParams({
              vendorIds: data.vendor.id,
              audienceIds: audience.id,
              'metrics.visits': '[1,]',
            })
          }
        />
      )}
      <TooltipRow
        color={TAM_PRIMARY}
        name={t`TAM`}
        secondaryValue={`(${numberFormat(data.tamVisits / data.visits, { isPercent: true })})`}
        value={t`${numberFormat(data.tamVisits)} Visits`}
        valueLink={
          data.visits > 0 &&
          ROUTES.webTrafficActivityWithParams({
            vendorIds: data.vendor.id,
            inTam: 'true',
            'metrics.visits': '[1,]',
          })
        }
      />
      <TooltipRow
        color={VISITS_PRIMARY}
        name={t`Visits`}
        value={t`${numberFormat(data.visits)} Visits`}
        valueLink={
          data.visits > 0 &&
          ROUTES.webTrafficActivityWithParams({
            vendorIds: data.vendor.id,
            'metrics.visits': '[1,]',
          })
        }
      />
    </MultiSeriesTooltip>
  );
};

export default VendorTableCellTooltip;
