import { t } from '@lingui/macro';
import { FormInstance } from 'antd';
import { RuleObject } from 'antd/es/form';
import { isPasswordValid } from 'helper/validator';

type Props = {
  form: FormInstance;
  newPasswordFieldName: string;
  confirmPasswordFieldName: string;
};

const useUpdatePassword = ({ form, newPasswordFieldName, confirmPasswordFieldName }: Props) => {
  const validatePassword = async (_: RuleObject, password: string) => {
    if (password === undefined || password.trim() === '') {
      throw Error(t`Please enter password`);
    }

    if (password.length < 8) {
      throw Error(t`Please enter a password with a minimum of 8 characters`);
    }

    if (!isPasswordValid(password)) {
      throw Error(
        t`The password should have a lowercase letter, an uppercase letter, a digit and a special symbol`,
      );
    }

    if (form.getFieldError(confirmPasswordFieldName).length) {
      form.validateFields([confirmPasswordFieldName]);
    }
  };

  const validateConfirmPassword = async (_: RuleObject, confirmPassword: string) => {
    if (confirmPassword !== form.getFieldValue(newPasswordFieldName)) {
      throw Error(t`Passwords do not match`);
    }
  };

  return {
    validatePassword,
    validateConfirmPassword,
  };
};

export default useUpdatePassword;
