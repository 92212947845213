export const signIn = {
  path: '/sign-in',
  description: 'The sign in page.',
  navigable: true,
};

export const signInDiscovery = {
  path: '/sign-in/discovery',
  navigable: false,
};

export const tenantSignIn = {
  path: '/sign-in/:orgId',
  navigable: false,
};

export const tenantSignInMagicLink = {
  path: '/sign-in/:orgId/magic-link',
  navigable: false,
};

export const tenantForgotPassword = {
  path: '/sign-in/:orgId/forgot',
  navigable: false,
};

export const signUp = {
  path: '/sign-up',
  description: 'The sign up page.',
  navigable: true,
};

export const configureOrg = {
  path: '/sign-up/configure-org',
  navigable: false,
};

export const tenantForgotPasswordByOrgSlug = (orgSlug: string) =>
  `${tenantSignInByOrgSlug(orgSlug)}/forgot`;

export const tenantSignInByOrgSlug = (orgSlug: string) => `${signIn.path}/${orgSlug}`;

export const tenantSignInMagicLinkByOrgSlug = (orgSlug: string) =>
  `${tenantSignInByOrgSlug(orgSlug)}/magic-link`;
