import { Trans } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { AudienceOptionType } from 'api/audience';
import { useChannelTrafficOverviewKPIs } from 'api/channel';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  channelId?: string;
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
  isLoading?: boolean;
};

const ChannelDetailKPIMetrics = ({ channelId, startDate, endDate, audience }: Props) => {
  const {
    channelTrafficOverviewKPIs: data,
    isLoading,
    error,
  } = useChannelTrafficOverviewKPIs(channelId, startDate, endDate, audience?.id);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>All Visits</Trans>}
        value={data && numberFormat(data.visits, { precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Total Spend</Trans>}
        value={data && numberFormat(data.spend, { isCurrency: true, precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>All $/Visit</Trans>}
        value={data && numberFormat(data.spendPerVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM Visits</Trans>}
        value={data && numberFormat(data.tamVisits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM $/Visit</Trans>}
        value={data && numberFormat(data.spendPerTamVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default ChannelDetailKPIMetrics;
