import { CSSProperties, PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './Legend.module.scss';

type Props = {
  className?: string;
  style?: CSSProperties;
};

const Legend = ({ className = '', style = {}, ...rest }: PropsWithChildren<Props>) => (
  <div className={classNames(styles.legend, className)} style={style} {...rest} />
);

export default Legend;
