import { Trans, t } from '@lingui/macro';
import { Dropdown } from '@shared/Dropdown';
import { Flex } from '@shared/Flex';
import Tooltip from '@shared/Tooltip';
import { ActionIcon } from '@shared/buttons';
import { MenuProps } from 'antd';
import MoreIcon from 'assets/svg/bx-dots-vertical-rounded.svg?react';
import ImportIcon from 'assets/svg/bx-import.svg?react';
import { csvDownloadRowLimit } from 'constants/numbers';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  totalResults?: number;
  isLoading?: boolean;
  isDownloadingCSV?: boolean;
  onDownloadCSV?: () => void;
};

const ActivityActionMenu = ({
  totalResults,
  isLoading,
  isDownloadingCSV,
  onDownloadCSV,
}: Props) => {
  const downloadRowLimitReached = totalResults && totalResults > csvDownloadRowLimit;

  const menuProps: MenuProps = {
    items: [
      {
        key: 'export',
        label: downloadRowLimitReached ? (
          <Tooltip
            title={t`Record Limit`}
            body={t`Export limited to ${numberFormat(csvDownloadRowLimit)} records`}
          >
            <Flex gap="small">
              <ImportIcon />
              <Trans>Export CSV</Trans>
            </Flex>
          </Tooltip>
        ) : (
          <Flex gap="small">
            <ImportIcon />
            <Trans>Export CSV</Trans>
          </Flex>
        ),
        disabled: downloadRowLimitReached || isLoading || isDownloadingCSV,
      },
    ],
    onClick: ({ key }) => {
      if (key === 'export') {
        onDownloadCSV?.();
      }
    },
  };

  return (
    <Dropdown menu={menuProps} placement="bottomRight" trigger={['click']}>
      <ActionIcon size="large" color="black" icon={<MoreIcon />} />
    </Dropdown>
  );
};

export default ActivityActionMenu;
