import { useEffect } from 'react';

/**
 * A hook which listens for the "paste" event on the entire page and passes it to the callback arg.
 * If the paste event comes while the user is focused on any other input on the page, we ignore it.
 */
const useGlobalPaste = (onPasteAccept: (pastedText: string) => void) => {
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const pastedText = e.clipboardData?.getData('text');

      if (
        pastedText &&
        // if the user is pasting into another input, ignore this.
        !(e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)
      ) {
        onPasteAccept(pastedText);
      }
    };

    document.addEventListener('paste', handlePaste);
    return () => document.removeEventListener('paste', handlePaste);
  }, []);
};

export default useGlobalPaste;
