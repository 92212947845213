import {
  CSSProperties,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  createElement,
} from 'react';

import SemanticColor from '@shared/SemanticColor';
import { Text, Title } from '@shared/typography';
import Success from 'assets/svg/bx-check-circle.svg?react';
import Warning from 'assets/svg/bx-error.svg?react';
import Error from 'assets/svg/bx-x-circle.svg?react';
import classNames from 'classnames';

import styles from './Results.module.scss';

const IconMap: Record<string, FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  error: Error,
  success: Success,
  warning: Warning,
};

const colorMap: Record<string, 'red' | 'green' | 'yellow'> = {
  error: 'red',
  success: 'green',
  warning: 'yellow',
};

export type ResultsStatusType = keyof typeof IconMap;

type Props = {
  className?: string;
  style?: CSSProperties;
  status?: ResultsStatusType;
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  extra?: ReactNode;
};

const getStatusIcon = (status?: ResultsStatusType) => {
  const statusIcon = status && IconMap[status] ? createElement(IconMap[status]) : undefined;

  if (status && statusIcon) {
    return <SemanticColor type={colorMap[status]}>{statusIcon}</SemanticColor>;
  }
};

const Results = ({
  className,
  style,
  children,
  status,
  icon,
  title,
  subtitle,
  extra,
}: PropsWithChildren<Props>) => {
  const statusIcon = getStatusIcon(status);

  return (
    <div className={classNames(styles.container, className)} style={style}>
      {(icon ?? statusIcon) && <div className={styles.icon}>{icon ?? statusIcon}</div>}
      {title && (
        <Title className={styles.title} level="2">
          {title}
        </Title>
      )}
      {subtitle && (
        <Text className={styles.subtitle} variant="body1" color="grey">
          {subtitle}
        </Text>
      )}
      {extra && <div className={styles.extra}>{extra}</div>}
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Results;
