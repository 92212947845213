import { t } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { useMultiplePixelTargetingEfficiency } from 'api/pixel';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  pixelIds?: string[];
  startDate?: string;
  endDate?: string;
};

const PixelMultiKPIMetrics = ({ pixelIds, startDate, endDate }: Props) => {
  const { pixelTargetingEfficiencyData, isLoading, error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    startDate,
    endDate,
  );

  return (
    <KpiMetricGroup>
      {pixelIds?.map((id) => {
        const item = pixelTargetingEfficiencyData?.find((d) => d.pixel.id === id);

        return (
          <KpiMetric
            key={id}
            label={item ? t`${item.pixel.name} - Impressions` : t`Impressions`}
            value={item && numberFormat(item.impressions.all)}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default PixelMultiKPIMetrics;
