import { MessageDescriptor, i18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { WEB_TRAFFIC_SEARCH_PARAM_KEYS } from 'router/searchParams/webTrafficSearchParams';

export enum NON_QUERY_PARAM_FIELDS {
  'name' = 'name',
  'metrics.lastActivityDate' = 'metrics.lastActivityDate',
}

export const ALL_WEB_TRAFFIC_FIELDS = {
  ...WEB_TRAFFIC_SEARCH_PARAM_KEYS,
  ...NON_QUERY_PARAM_FIELDS,
};

const WEB_TRAFFIC_FIELD_MESSAGES: Record<keyof typeof ALL_WEB_TRAFFIC_FIELDS, MessageDescriptor> = {
  audienceIds: msg`Audience`,
  vendorIds: msg`Vendor`,
  channelIds: msg`Channel`,
  'sector.id': msg`Industry`,
  'revRange.id': msg`Revenue Range`,
  'region.id': msg`Region`,
  'metrics.impressions': msg`Impressions`,
  'metrics.visits': msg`Visits`,
  inTam: msg`In TAM`,
  pixelIds: msg`Pixel`,
  name: msg`Company`,
  'metrics.lastActivityDate': msg`Last Visit`,
  isEngaged: msg`Is Engaged`,
};

export const getWebTrafficFieldName = (fieldId: keyof typeof ALL_WEB_TRAFFIC_FIELDS) =>
  i18n._(WEB_TRAFFIC_FIELD_MESSAGES[fieldId]);
