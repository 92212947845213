import { ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import { USER_FORM_FIELD } from '@pages/users/:user/UserFormType';
import Form, { Radio } from '@shared/Form';
import { Text } from '@shared/typography';
import { Space } from 'antd';
import { UserRoleType } from 'api/user';
import CheckmarkIcon from 'assets/svg/bx-check.svg?react';
import classNames from 'classnames';

import styles from './PermissionSettingInfo.module.scss';

type Props = {
  isEditing: boolean;
  role: UserRoleType;
};

type OptionProps = {
  type: UserRoleType;
  isEditing: boolean;
  role: UserRoleType;
  title: ReactNode;
  description: ReactNode;
};

const PermissionSettingOption = ({ type, isEditing, role, title, description }: OptionProps) => {
  const content = (
    <Space align="start">
      {!isEditing && (
        <CheckmarkIcon
          className={classNames(styles.checkmark, { [styles.hidden]: type !== role })}
        />
      )}
      <Space direction="vertical" size={4}>
        <Text variant="body1" weight="semi-bold">
          {title}
        </Text>
        <Text variant="body1">{description}</Text>
      </Space>
    </Space>
  );

  if (isEditing) {
    return <Radio value={type}>{content}</Radio>;
  }

  return <>{content}</>;
};

const PermissionSettingInfo = ({ isEditing, role }: Props) => {
  return (
    <Space direction="vertical" size={24}>
      <Form.Item name={USER_FORM_FIELD.ROLE} initialValue={role}>
        <Radio.Group size="large">
          <Space direction="vertical" size={24}>
            <PermissionSettingOption
              type="user"
              isEditing={isEditing}
              role={role}
              title={<Trans>User Permission</Trans>}
              description={
                <Trans>
                  Users have permissions to view all data, save filters and edit spend information
                </Trans>
              }
            />
            <PermissionSettingOption
              type="admin"
              isEditing={isEditing}
              role={role}
              title={<Trans>Admin Permission</Trans>}
              description={
                <Trans>
                  Admins have all user permissions and the ability to map vendors, add users, manage
                  users and edit TAM
                </Trans>
              }
            />
          </Space>
        </Radio.Group>
      </Form.Item>
    </Space>
  );
};

export default PermissionSettingInfo;
