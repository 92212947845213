import { useState } from 'react';

import { t } from '@lingui/macro';
import { InstantSearchSortOrder, QueryState, SolQueryParamsNew } from '@shared/InstantSearch';
import InstantSearch from '@shared/InstantSearch/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@shared/InstantSearch/util/search-util';
import { ReturnToLink } from '@shared/ReturnToLink';
import { Page } from '@shared/page';
import { Space } from 'antd';
import { useLoginAsInstanceList } from 'api/organization';
import { setForeignSuperuserInstance } from 'helper/common';
import { useLogOut } from 'hooks/useLogOut';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';

import LoginAsHeader from './LoginAsHeader';
import LoginAsTable from './LoginAsTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'createdAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const LoginAs = () => {
  const navigate = useNavigate();
  const { clearCacheOnly } = useLogOut();
  const [tableParams, setTableParams] = useState<SolQueryParamsNew>();
  const { instanceListData, totalResults, isLoading, error } = useLoginAsInstanceList(tableParams);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsNewFromQueryState(queryState);
    setTableParams(tableParams);
  };

  const handleLoginAs = () => {
    if (selectedRow.length) {
      setForeignSuperuserInstance(selectedRow[0]);
      clearCacheOnly();
      navigate(ROUTES.app.path);
    }
  };

  return (
    <Page title={t`Your Profile`} pageName={t`Login As`}>
      <ReturnToLink route={ROUTES.profile.path} routeName={t`Your Profile`} />
      <InstantSearch
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={handleQueryStateChange}
      >
        <Space direction="vertical" size="large">
          <LoginAsHeader isInstanceSelected={!!selectedRow.length} onLoginAs={handleLoginAs} />
          <LoginAsTable
            selectedRow={selectedRow}
            data={instanceListData}
            totalResults={totalResults}
            isLoading={isLoading}
            error={error}
            onSelectedRowChange={setSelectedRow}
          />
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default LoginAs;
