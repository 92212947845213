import { useEffect } from 'react';

import { Trans, t } from '@lingui/macro';
import { Flex } from '@shared/Flex';
import Form, { NumberInput, Select, TextInput, setFieldErrors } from '@shared/Form';
import { ReportName } from '@shared/ReportName';
import { Results } from '@shared/Results';
import { Button } from '@shared/buttons';
import { Panel } from '@shared/panels';
import { Link, Text } from '@shared/typography';
import { Col, Row } from 'antd';
import { RuleObject } from 'antd/es/form';
import { INTEGRATION_PROVIDER_ID } from 'api/integration';
import { PipelineReportType, useSalesforceReportMetadata } from 'api/organization';
import { isSalesforceReportUrlValid } from 'helper/validator';
import useDebounce from 'hooks/useDebounce';
import { ROUTES } from 'router';

import styles from './CompanyOpportunitySettings.module.scss';
import { FORM_FIELD, FormType } from './CompanySettings';

type Props = {
  isEditMode: boolean;
  isSaving: boolean;
  data?: PipelineReportType;
};

const CompanyOpportunitySettings = ({ isEditMode, isSaving, data }: Props) => {
  const form = Form.useFormInstance<FormType>();
  const salesforceReportURL = Form.useWatch([FORM_FIELD.PIPELINE_REPORT_LINK], form);
  const debouncedURL = useDebounce(salesforceReportURL, 1000);
  const { reportMetadata, isLoading, error } = useSalesforceReportMetadata(
    debouncedURL ?? salesforceReportURL,
  );
  const pipelineReportURL = form.getFieldValue(FORM_FIELD.PIPELINE_REPORT_LINK);
  const pipelineReportAmountField = form.getFieldValue(FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD);

  useEffect(() => {
    if (reportMetadata && reportMetadata.currencyFieldNames?.length) {
      const fieldExistsInReport = reportMetadata.currencyFieldNames.some(
        (val) => pipelineReportAmountField === val,
      );
      if (!fieldExistsInReport) {
        form.setFieldValue(
          FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD,
          reportMetadata.currencyFieldNames?.[0],
        );
      }
    } else if (error) {
      form.setFieldValue(FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD, '');
    }
  }, [reportMetadata, error]);

  useEffect(() => {
    if (!debouncedURL) {
      form.setFieldValue(FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD, '');
    }
  }, [debouncedURL]);

  useEffect(() => {
    setFieldErrors(
      form,
      FORM_FIELD.PIPELINE_REPORT_LINK,
      error?.getFirstError ? [error.getFirstError().message] : [],
    );
  }, [error]);

  return (
    <Panel title={t`Custom Opportunity Settings`} data-testid="opp-settings-panel">
      {data === null ? (
        <Results
          subtitle={
            <Flex vertical align="center">
              <span>
                <Trans>A CRM connection is required for Pipeline and ROI reporting.</Trans>
              </span>
              <span>
                <Trans>Please connect your CRM to activate this feature.</Trans>
              </span>
            </Flex>
          }
          extra={
            <Button color="green" to={ROUTES.integrationById(INTEGRATION_PROVIDER_ID.SALESFORCE)}>
              <Trans>Connect CRM</Trans>
            </Button>
          }
        />
      ) : (
        <Row gutter={[40, 0]}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={<Trans>Pipeline Report Link</Trans>}
              name={FORM_FIELD.PIPELINE_REPORT_LINK}
              initialValue={data?.url}
              infoPopover={{
                title: t`Pipeline Report Link`,
                body: (
                  <Trans>
                    Custom pipeline reporting is available to define which opportunities and amounts
                    the Channel99 platform uses. This report only requires Opportunity ID and Amount
                    fields.{' '}
                    <Link
                      variant="body2"
                      color="static-green"
                      to="https://support.channel99.com/hc/en-us/articles/27231457541403"
                      target="_blank"
                    >
                      {t`More information here`}
                    </Link>
                  </Trans>
                ),
              }}
              rules={[
                {
                  message: t`The Salesforce link is incorrect. Valid URLs should resemble the following pattern: 'https://<your-subdomain>.lightning.force.com/lightning/r/Report/<report-id>/view'.`,
                  validator: (_: RuleObject, value: string) =>
                    isSalesforceReportUrlValid(value) ? Promise.resolve() : Promise.reject(),
                },
                {
                  validator: () =>
                    error ? Promise.reject(error.getFirstError().message) : Promise.resolve(),
                },
              ]}
            >
              {isEditMode ? (
                <TextInput
                  placeholder={t`Enter Salesforce Report Link`}
                  isDisabled={isSaving}
                  size="medium"
                />
              ) : pipelineReportURL ? (
                <Text className={styles.text} variant="body1" />
              ) : (
                <Text className={styles.text} variant="body1" italic weight="extra-light">
                  <Trans>no custom opportunity report</Trans>
                </Text>
              )}
            </Form.Item>
            <ReportName reportName={reportMetadata?.name} isLoading={isLoading} />
            <Form.Item
              label={<Trans>Opportunity Amount Field</Trans>}
              name={FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD}
              initialValue={data?.fieldName}
              infoPopover={{
                title: 'Opportunity Amount Field',
                body: (
                  <Trans>
                    This field is used with a custom opportunity report to identify which amount
                    field should be used in platform reporting, Choose your preferred amount field
                    to be used from the provided report.
                  </Trans>
                ),
              }}
            >
              {isEditMode ? (
                <Select
                  size="medium"
                  width={250}
                  placeholder={t`Select Field`}
                  options={reportMetadata?.currencyFieldNames?.map((val) => ({
                    index: val,
                    value: val,
                  }))}
                  notFoundContent={t`No fields found`}
                  isDisabled={
                    isSaving ||
                    !debouncedURL ||
                    reportMetadata?.currencyFieldNames?.length === 0 ||
                    !!error
                  }
                  isLoading={isLoading}
                />
              ) : (
                <Text className={styles.text} variant="body1" />
              )}
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={<Trans>Opportunity Influence Window</Trans>}
              infoPopover={{
                title: t`Opportunity Influence Window`,
                body: (
                  <Trans>
                    Customizing the influence window will increase or reduce the number of days a
                    visit can influence an opportunity in regards to the opportunity create date.{' '}
                    <Link
                      variant="body2"
                      color="static-green"
                      to="https://support.channel99.com/hc/en-us/articles/21697895853083#h_01HXYZW5XMGC80YRAP99619NY3"
                      target="_blank"
                    >
                      {t`Learn more here`}
                    </Link>
                  </Trans>
                ),
              }}
            >
              <div className={styles.fieldContainer}>
                <Form.Item
                  name={FORM_FIELD.OPPORTUNITY_INFLUENCE_WINDOW}
                  initialValue={data?.influenceWindow ?? 90}
                  noStyle
                >
                  {isEditMode ? (
                    <NumberInput isDisabled={isSaving} size="medium" width={90} max={365} min={1} />
                  ) : (
                    <Text variant="body1" />
                  )}
                </Form.Item>
                <Text variant="body1">
                  <Trans>Days</Trans>
                </Text>
              </div>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Panel>
  );
};

export default CompanyOpportunitySettings;
