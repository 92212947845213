import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const everythingExists = (dependencies: DependencyList) => dependencies.every((dep) => dep != null);

const useEffectOnceWhenDepsExist = (callback: EffectCallback, dependencies: DependencyList) => {
  const hasExecuted = useRef(false);

  useEffect(() => {
    if (everythingExists(dependencies) && !hasExecuted.current) {
      callback();
      hasExecuted.current = true;
    }
  }, dependencies);
};

export default useEffectOnceWhenDepsExist;
