import { Trans } from '@lingui/macro';
import { Tag } from '@shared/typography';
import { Link } from 'react-router-dom';

const STATUS_COMPLETE = 0;
const STATUS_INCOMPLETE = 1;
const STATUS_COMING_SOON = 2;
const STATUS_NOT_IN_PLAN = 3;

type Props = {
  isAvailable?: boolean;
  notIncludedInPlan?: boolean;
  isComplete?: boolean;
  isDisabled?: boolean;
  link?: string;
};

const getStatus = (isAvailable?: boolean, notIncludedInPlan?: boolean, isComplete?: boolean) => {
  if (!isAvailable) {
    return STATUS_COMING_SOON;
  }

  if (notIncludedInPlan) {
    return STATUS_NOT_IN_PLAN;
  }

  if (!isComplete) {
    return STATUS_INCOMPLETE;
  }

  return STATUS_COMPLETE;
};

const getStatusTag = (status: number) => {
  switch (status) {
    case STATUS_COMING_SOON:
      return (
        <Tag size="large" color="info" noMargin>
          <Trans>Coming soon</Trans>
        </Tag>
      );
    case STATUS_NOT_IN_PLAN:
      return (
        <Tag size="large" color="error" noMargin>
          <Trans>Not in plan</Trans>
        </Tag>
      );
    case STATUS_INCOMPLETE:
      return (
        <Tag size="large" color="error" noMargin>
          <Trans>Incomplete</Trans>
        </Tag>
      );
    case STATUS_COMPLETE:
    default:
      return (
        <Tag size="large" color="success" noMargin>
          <Trans>Complete</Trans>
        </Tag>
      );
  }
};

const HealthPodStatusTag = ({
  isAvailable,
  notIncludedInPlan,
  isComplete,
  isDisabled,
  link,
}: Props) => {
  const status = getStatus(isAvailable, notIncludedInPlan, isComplete);
  const tag = getStatusTag(status);

  if (isDisabled) {
    return tag;
  }

  return link ? <Link to={link}>{tag}</Link> : tag;
};

export default HealthPodStatusTag;
