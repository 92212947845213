import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import InstantSearch, { InstantSearchSortOrder, QueryState } from '@shared/InstantSearch';
import { ReturnToLink } from '@shared/ReturnToLink';
import TableActions from '@shared/TableActions/TableActions';
import Error404 from '@shared/errors/Error404';
import { Page } from '@shared/page';
import { Panel } from '@shared/panels';
import { Space } from 'antd';
import { useAudienceById, useAudienceMembership } from 'api/audience';
import SolGraphQLError from 'error/SolGraphQLError';
import { numberFormat } from 'helper/numberFormatter';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'router';

import AudienceMembershipListTable from './AudienceMembershipListTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'name',
  sortOrder: InstantSearchSortOrder.ASC,
};

const AudienceDetail = () => {
  const [queryState, setQueryState] = useState<QueryState>();
  const { audience: audienceId } = useParams();
  const {
    audience,
    isLoading: isAudienceLoading,
    error: audienceError,
  } = useAudienceById(audienceId);
  const {
    audienceMembership,
    totalResults,
    isLoading: isMembershipLoading,
    error: membershipError,
  } = useAudienceMembership(audienceId, queryState);

  return (
    <Page title={t`Audience`} pageName={audience?.name}>
      <ReturnToLink route={ROUTES.audiences.path} routeName={<Trans>Audiences</Trans>} />
      <InstantSearch defaultQueryState={DEFAULT_QUERY_STATE} onQueryStateChange={setQueryState}>
        {audienceError instanceof SolGraphQLError && audienceError.is404() ? (
          <Error404 message={<Trans>The audience you're looking for does not exist</Trans>} />
        ) : (
          <Space direction="vertical" size="large">
            <TableActions />
            <Panel
              size="L"
              title={
                audience ? (
                  <Trans>
                    {audience?.name} ({numberFormat(audience?.metrics.size)} companies)
                  </Trans>
                ) : (
                  <Trans>Audience Details</Trans>
                )
              }
            >
              <AudienceMembershipListTable
                data={audienceMembership}
                totalResults={totalResults}
                isLoading={isAudienceLoading || isMembershipLoading}
                error={membershipError}
              />
            </Panel>
          </Space>
        )}
      </InstantSearch>
    </Page>
  );
};

export default AudienceDetail;
