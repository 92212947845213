import { ReactNode, forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react';

import { AbstractCheckboxGroupProps } from 'antd/es/checkbox/Group';
import { ConfigContext } from 'antd/es/config-provider';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';

import Switch from './Switch';
import SwitchGroupContext from './SwitchGroupContext';

export type SwitchValueType = string | number | boolean;

export type SwitchOptionType = {
  value: SwitchValueType;
  style?: React.CSSProperties;
  disabled?: boolean;
  title?: string;
  id?: string;
  onChange?: SwitchChangeEventHandler;
  required?: boolean;
};

export type SwitchGroupProps = Omit<AbstractCheckboxGroupProps, 'options'> & {
  options?: (SwitchOptionType | string | number)[];
  name?: string;
  defaultValue?: SwitchValueType[];
  value?: SwitchValueType[];
  onChange?: (checkedValue: SwitchValueType[]) => void;
  children?: ReactNode;
};

const InternalGroup: React.ForwardRefRenderFunction<HTMLDivElement, SwitchGroupProps> = (
  props,
  ref,
) => {
  const {
    defaultValue,
    children,
    options = [],
    prefixCls: customizePrefixCls,
    className,
    style,
    onChange,
    ...restProps
  } = props;
  const { getPrefixCls, direction } = useContext(ConfigContext);

  const [value, setValue] = useState<SwitchValueType[]>(restProps.value || defaultValue || []);
  const [registeredValues, setRegisteredValues] = useState<SwitchValueType[]>([]);

  useEffect(() => {
    if ('value' in restProps) {
      setValue(restProps.value || []);
    }
  }, [restProps.value]);

  const memoOptions = useMemo(
    () =>
      options.map<SwitchOptionType>((option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return { label: option, value: option };
        }
        return option;
      }),
    [options],
  );

  const cancelValue = (val: string) => {
    setRegisteredValues((prevValues) => prevValues.filter((v) => v !== val));
  };

  const registerValue = (val: string) => {
    setRegisteredValues((prevValues) => [...prevValues, val]);
  };

  const toggleOption = (option: SwitchOptionType) => {
    const optionIndex = value.indexOf(option.value);
    const newValue = [...value];
    if (optionIndex === -1) {
      newValue.push(option.value);
    } else {
      newValue.splice(optionIndex, 1);
    }
    if (!('value' in restProps)) {
      setValue(newValue);
    }
    onChange?.(
      newValue
        .filter((val) => registeredValues.includes(val))
        .sort((a, b) => {
          const indexA = memoOptions.findIndex((opt) => opt.value === a);
          const indexB = memoOptions.findIndex((opt) => opt.value === b);
          return indexA - indexB;
        }),
    );
  };

  const prefixCls = getPrefixCls('switch', customizePrefixCls);
  const groupPrefixCls = `${prefixCls}-group`;

  const domProps = omit(restProps, ['value', 'disabled']);

  const childrenNode = options.length
    ? memoOptions.map<React.ReactNode>((option) => (
        <Switch
          prefixCls={prefixCls}
          key={option.value.toString()}
          disabled={'disabled' in option ? option.disabled : restProps.disabled}
          value={option.value}
          checked={value.includes(option.value)}
          onChange={option.onChange}
          className={`${groupPrefixCls}-item`}
          style={option.style}
          title={option.title}
          id={option.id}
          required={option.required}
        />
      ))
    : children;

  const context = {
    toggleOption,
    value,
    disabled: restProps.disabled,
    name: restProps.name,
    registerValue,
    cancelValue,
  };
  const classString = classNames(
    groupPrefixCls,
    {
      [`${groupPrefixCls}-rtl`]: direction === 'rtl',
    },
    className,
  );
  return (
    <div className={classString} style={style} {...domProps} ref={ref}>
      <SwitchGroupContext.Provider value={context}>{childrenNode}</SwitchGroupContext.Provider>
    </div>
  );
};

export type { SwitchGroupContextType } from './SwitchGroupContext';
export { SwitchGroupContext };

const SwitchGroup = forwardRef<HTMLDivElement, SwitchGroupProps>(InternalGroup);

export default memo(SwitchGroup);
