import { t } from '@lingui/macro';

// TODO: Swap this out for dynamic local set at the top of the application, associated with org.
const LOCALE = 'en-US';
const CURRENCY = 'USD';

export type NumberFormatOptionsType = {
  precision?: number;
  isCurrency?: boolean;
  isPercent?: boolean;
  isMultiplier?: boolean;
  compact?: boolean;
};

const getNumberFormatOptions = (options: NumberFormatOptionsType): Intl.NumberFormatOptions => {
  const intlOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
  };

  if (options.isCurrency && options.isPercent) {
    throw new Error('Only currency or percent is allowed, not both');
  }

  if (options.isCurrency) {
    intlOptions.style = 'currency';
    intlOptions.currency = CURRENCY;

    if (options.precision == null) {
      options.precision = 2;
    }
  }

  if (options.isPercent) {
    intlOptions.style = 'percent';
    intlOptions.maximumFractionDigits = 1;
  }

  if (options.precision != null) {
    intlOptions.minimumFractionDigits = options.precision;
    intlOptions.maximumFractionDigits = options.precision;
  }

  if (options.compact) {
    intlOptions.notation = 'compact';

    if (options.precision == null) {
      intlOptions.maximumFractionDigits = 2;
    }
  }

  return intlOptions;
};

export const numberFormat = (
  val: number | string | null | undefined,
  options: NumberFormatOptionsType = {},
) => {
  if (!val) {
    val = 0;
  }

  const nf = new Intl.NumberFormat(LOCALE, getNumberFormatOptions(options));
  const result = nf.format(+val);

  if (options.isMultiplier) {
    return t`${result}x`;
  }
  return result;
};

export const numberFormatBytes = (val: number | string | null | undefined) => {
  if (!val) {
    val = 0;
  }

  const byteFormatter = Intl.NumberFormat(LOCALE, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });
  return byteFormatter.format(+val);
};
