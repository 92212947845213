import { Trans, t } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { Text } from '@shared/typography';
import { AudienceOptionType } from 'api/audience';
import { useWebTrafficKPIs } from 'api/webTraffic';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  startDate?: string;
  endDate?: string;
  audience?: AudienceOptionType;
};

const WebTrafficKPIMetrics = ({ startDate, endDate, audience }: Props) => {
  const { webTrafficKPIs, isLoading, error } = useWebTrafficKPIs(startDate, endDate, audience);

  return (
    <KpiMetricGroup>
      {webTrafficKPIs?.map((kpi) => {
        const captionVal =
          kpi.lastPeriod === null ? (
            <Text variant="caption1" color="grey">{t`Partial Data`}</Text>
          ) : (
            numberFormat(kpi.lastPeriod, kpi.valueFormat)
          );

        return (
          <KpiMetric
            key={kpi.id}
            label={kpi.label}
            value={kpi.value && numberFormat(kpi.value, kpi.valueFormat)}
            valueLink={kpi.valueLink}
            caption={<Trans>Previous Period: {captionVal}</Trans>}
            percentChange={kpi.lastPeriod == null ? null : kpi.percentChange}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default WebTrafficKPIMetrics;
