import { useCallback } from 'react';

import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { clearForeignSuperuserInstance } from 'helper/common';
import { useSWRConfig } from 'swr';

export const useLogOut = () => {
  const stytch = useStytchB2BClient();
  const { mutate } = useSWRConfig();

  const clearCache = useCallback(async () => {
    // Clear the SWR cache. See docs for info on how this works.
    mutate(() => true, undefined, { revalidate: false });
  }, [stytch]);

  const logOut = useCallback(async () => {
    // Revoke the session is the user is authenticated
    if (stytch.session.getSync()) {
      await stytch.session.revoke();
    }
    clearForeignSuperuserInstance();

    clearCache();
  }, [stytch, clearCache]);

  return {
    /**
     * Use only for cases where we want to wipe the local cache. This does NOT revoke the user's
     * token and SHOULD NOT be used for log out purposes. Use the `logOut` export for that. This
     * `clearCacheOnly` function serves as a soft-logout for C99 Superusers who need to keep their
     * token, but reset their cached data in preparation for logging into another organization they
     * don't formally belong to (Login-As).
     */
    clearCacheOnly: clearCache,

    /**
     * Clears the cache and revokes the user's session, causing them to need to log in again to
     * re-enter the app.
     */
    logOut,
  };
};
