import { PAGINATION_PARAM_KEYS, PaginationSearchParamType } from './defaultPaginationParams';

export enum WEB_TRAFFIC_SEARCH_PARAM_KEYS {
  'audienceIds' = 'audienceIds',
  'vendorIds' = 'vendorIds',
  'channelIds' = 'channelIds',
  'sector.id' = 'sector.id',
  'revRange.id' = 'revRange.id',
  'region.id' = 'region.id',
  'metrics.impressions' = 'metrics.impressions',
  'metrics.visits' = 'metrics.visits',
  'inTam' = 'inTam',
  'pixelIds' = 'pixelIds',
  'isEngaged' = 'isEngaged',
}

export type WEB_TRAFFIC_ACTIVITY_SORT_KEYS =
  | 'name'
  | 'region.id'
  | 'sector.id'
  | 'revRange.id'
  | 'metrics.impressions'
  | 'metrics.visits'
  | 'metrics.lastActivityDate';

export type ALL_WEB_TRAFFIC_SEARCH_PARAM_KEYS =
  | WEB_TRAFFIC_SEARCH_PARAM_KEYS
  | PAGINATION_PARAM_KEYS;

export type WebTrafficSearchParamType = Record<
  ALL_WEB_TRAFFIC_SEARCH_PARAM_KEYS,
  string | string[]
> &
  PaginationSearchParamType<WEB_TRAFFIC_ACTIVITY_SORT_KEYS> & {
    inTam?: 'true' | 'false';
    isEngaged?: 'true' | 'false';
  };
