import { UserType } from '../user/userType';

export interface BaseMetadataType {
  id: string;
  name: string;
  description?: string;
  created_at?: string;
  created_by?: UserType;
  updated_at?: string;
  updated_by?: UserType;
}

export type OptionType = {
  id: string;
  name: string;
};

export type PagingOptionType = {
  results: OptionType[];
  hasNext: boolean;
};

export type CompanyType = {
  id: string;
  name: string;
  tld: string;
};

export type SectorType = OptionType;

export type RevenueRangeType = OptionType & {
  id: REVENUE_RANGE_ID;
  shortName?: string;
  name: string;
};

export type RegionType = OptionType;

export enum TRAFFIC_TYPE {
  ALL = 'ALL_TRAFFIC_TYPES',
  TAM = 'TAM',
  NON_TAM = 'NON_TAM',
  ISP = 'ISP',
  BOT = 'BOT',
}

export enum WEB_TRAFFIC_TYPES {
  AUDIENCE = 'audience',
  TAM = 'tam',
  OTHER = 'other',
  BOT = 'bot',
}

export enum CHANNEL_ID {
  DISPLAY = 'chnl_display',
  PAID_SOCIAL = 'chnl_paid_social',
  PAID_SEARCH = 'chnl_paid_search',
  PAID_VIDEO = 'chnl_paid_video',
  AR_INFLUENCER = 'chnl_ar_influencer',
  PR = 'chnl_pr',
  EMAIL = 'chnl_email',
  ORGANIC_SEARCH = 'chnl_organic_search',
  ORGANIC_SOCIAL = 'chnl_organic_social',
  ORGANIC_VIDEO = 'chnl_organic_video',
  REFERRAL = 'chnl_referral',
  SALES = 'chnl_sales',
  DIRECT = 'chnl_direct',
  OTHER = 'other',
}

export enum REVENUE_RANGE_ID {
  ENTERPRISE = 'rrng_enterprise',
  MID_MARKET = 'rrng_mid_market',
  SMALL_MEDIUM = 'rrng_small_medium',
  VERY_SMALL = 'rrng_very_small',
}

export type DisplayNamesGQLResponse = {
  displayNames: {
    get: {
      id: string;
      name: string;
    }[];
  };
};
