import { Axis } from 'highcharts';

const linkifyAxisLabels = (axis: Axis, onClick: (url: string) => void) => {
  // Any children with `id` attributes should become links
  Object.keys(axis.ticks).forEach((key: string) => {
    const labelEl = axis.ticks[key]?.label?.element;
    const linkEl = labelEl?.querySelector<HTMLSpanElement>('[id]');
    const url = linkEl?.getAttribute('id');

    if (linkEl && url) {
      // turn it into a link
      linkEl.onclick = () => onClick(url);
    }
  });
};

export default linkifyAxisLabels;
