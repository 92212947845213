import { msg } from '@lingui/macro';

import { CORE_ERROR_CODES, CORE_ERROR_MESSAGES_BY_CODE } from './core-standard-errors';
import { ErrorByCodeType } from './types';

export enum AUDIENCE_ERROR_CODES {
  NOT_SPREADSHEET = 'err:sol.audiences.not-spreadsheet',
  NO_DOMAIN_DATA = 'err:sol.audiences.no-domain-data',
  NO_AUDIENCE_DATA = 'err:sol.audiences.no-audience-data',
  UPLOAD_PROBLEM = 'err:sol.audiences.upload-problem',
  INVALID_AUDIENCE_FIELD = 'err:sol.audiences.invalid-audience-type',
  MISSING_AUDIENCE_FIELD = 'err:sol.audiences.missing-audience-field',
  MISSING_SF_CONNECTION = 'err:sol.audiences.missing-sf-connection',
  DATA_WRITE_FAIL = 'err:sol.audiences.data-write-fail',
  AUDIENCE_IN_USE = 'err:sol.audiences.audience-in-use',
}

const INTERNAL_AUDIENCE_ERROR = msg`An error occurred with this Audience. Please contact support for assistance.`;

export const AUDIENCE_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [AUDIENCE_ERROR_CODES.NOT_SPREADSHEET]: msg`Data uploads must only be Excel or CSV files (.csv, .xls, or .xlsx).`,
  [AUDIENCE_ERROR_CODES.NO_DOMAIN_DATA]: msg`No “domain” or “website” column was detected in your file, please ensure a column is titled “domain” or “website” and retry.`,
  [AUDIENCE_ERROR_CODES.NO_AUDIENCE_DATA]: msg`The uploaded data does not seem to contain any rows of data.`,
  [AUDIENCE_ERROR_CODES.UPLOAD_PROBLEM]: msg`There was an unexpected problem uploading and parsing the audience data. Please make sure the uploaded file is an Excel or CSV file (.csv, .xls, or .xlsx).`,
  [AUDIENCE_ERROR_CODES.INVALID_AUDIENCE_FIELD]: INTERNAL_AUDIENCE_ERROR,
  [AUDIENCE_ERROR_CODES.MISSING_AUDIENCE_FIELD]:
    CORE_ERROR_MESSAGES_BY_CODE[CORE_ERROR_CODES.MISSING_REQUIRED_FIELD],
  [AUDIENCE_ERROR_CODES.MISSING_SF_CONNECTION]: msg`A Salesforce connection has not been made under this account. Please add the Salesforce integration and then return to import an audience.`,
  [AUDIENCE_ERROR_CODES.DATA_WRITE_FAIL]: msg`Unable to write audience data. Please try again or contact support for assistance.`,
  [AUDIENCE_ERROR_CODES.AUDIENCE_IN_USE]: msg`The audience you're attempting to delete is currently assigned to a Pixel and cannot be deleted. You may update your Pixel Audiences in the Pixel Overview Screen.`,
};
