import { FilterOperatorMulti, FilterOperatorValue } from '@shared/InstantSearch';
import { CategoryFilter } from '@shared/filters';
import { useRevenueRanges } from 'api/settings';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const RevenueRangeFilter = ({ onChange, ...rest }: Props) => {
  const { revRanges, isLoading, error } = useRevenueRanges({ includeBlanks: true });

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={revRanges}
      onChange={onChange}
    />
  );
};

export default RevenueRangeFilter;
