import { forwardRef, useState } from 'react';

import { Trans } from '@lingui/macro';
import { Button } from '@shared/buttons';
import { Space } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';
import PrevIcon from 'assets/svg/bx-chevron-left.svg?react';
import NextIcon from 'assets/svg/bx-chevron-right.svg?react';
import SuperPrevIcon from 'assets/svg/bx-chevrons-left.svg?react';
import SuperNextIcon from 'assets/svg/bx-chevrons-right.svg?react';
import Chevron from 'assets/svg/chevron-left.svg?react';
import classNames from 'classnames';
import { DateBEFormat, USDateViewFormat } from 'constants/formats';
import { dataRetentionPolicy } from 'constants/numbers';
import moment, { Moment } from 'moment';
import { PickerRef } from 'rc-picker';

import { InternalMomentDatePicker } from '../InternalMomentDatePicker';
import commonStyles from '../InternalMomentDatePicker/InternalMomentDatePicker.module.scss';
import styles from './DateRangePicker.module.scss';

const AntdMomentDateRangePicker = InternalMomentDatePicker.RangePicker;

type EventValue<DateType> = DateType | null;
type TypedRangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>];

export type RangeValue = TypedRangeValue<Moment>;

export type DateRangePickerProps = Omit<RangePickerProps<Moment>, 'disabled' | 'size'> & {
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const DateRangePicker = forwardRef<PickerRef, DateRangePickerProps>(
  (
    {
      className,
      popupClassName,
      size,
      format = USDateViewFormat,
      isDisabled = false,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const handleChange = (value: RangeValue | null) => {
      if (onChange && value) {
        const [start, end] = value;

        onChange(value, [
          start ? start.format(DateBEFormat) : '',
          end ? end.format(DateBEFormat) : '',
        ]);
      }
    };

    const handlePresetClick = (days: number) => () => {
      if (onChange) {
        const start = moment().subtract(days, 'd').startOf('d');
        const end = moment().endOf('d');

        setOpen(false);
        onChange([start, end], [start.format(DateBEFormat), end.format(DateBEFormat)]);
      }
    };

    const handleArrowClick = () => {
      if (open) {
        // let clicking the arrow close the picker if it's open
        setOpen(false);
      }
    };

    return (
      <AntdMomentDateRangePicker
        ref={ref}
        className={classNames(
          commonStyles.picker,
          styles.dateRangePicker,
          commonStyles[`size-${size}`],
          className,
        )}
        popupClassName={classNames(commonStyles.pickerPopup, popupClassName)}
        format={format}
        {...rest}
        size={size === 'medium' ? 'middle' : size}
        disabled={isDisabled}
        open={open}
        allowEmpty={[false, false]}
        prevIcon={<PrevIcon />}
        nextIcon={<NextIcon />}
        superPrevIcon={<SuperPrevIcon />}
        superNextIcon={<SuperNextIcon />}
        suffixIcon={
          <Chevron
            className={classNames(commonStyles.suffixIcon, { [commonStyles.open]: open })}
            onClick={handleArrowClick}
          />
        }
        getPopupContainer={() => document.getElementById('pageRoot') as HTMLElement}
        separator={<span className={commonStyles.separator}>-</span>}
        minDate={moment().subtract(dataRetentionPolicy, 'd').startOf('d')}
        renderExtraFooter={() => (
          <Space className={commonStyles.presetContainer} direction="horizontal">
            <Button color="black" variant="secondary" onClick={handlePresetClick(30)}>
              <Trans>Last 30 Days</Trans>
            </Button>
            <Button color="black" variant="secondary" onClick={handlePresetClick(90)}>
              <Trans>Last 90 Days</Trans>
            </Button>
            <Button color="black" variant="secondary" onClick={handlePresetClick(180)}>
              <Trans>Last 180 Days</Trans>
            </Button>
          </Space>
        )}
        onOpenChange={setOpen}
        onChange={handleChange}
      />
    );
  },
);

export default DateRangePicker;
