import {
  ALL_EVENT_LOG_SEARCH_PARAM_KEYS,
  EVENT_LOG_SORT_KEYS,
} from 'router/searchParams/eventLogSearchParams';
import { NavigableRouteType, RouteSearchParamEnumType } from 'router/types';

export const healthCenter = {
  path: '/app/health-center',
  description:
    "A place where a user can see the overall health of their company's Channel99 setup. The user can see if the C99 tag is placed on their website, TAM has been defined, Salesforce has been connected, vendor spend has been updated, users have been invited, audiences have been created, and pixels have been created.",
  navigable: true,
};

export const eventLog = {
  path: '/app/event-log',
  description:
    'A list of events that have occurred in the company. These events include administrative actions such as creating, updating, or deleting any object in the system.',
  navigable: true,
  searchParams: {
    typeString: {
      type: 'string[]',
      description:
        'A filter for the type of event. Possible values include: "Note", "Campaign", "Admin", "Spend", "Activity", or "Pipeline"',
    },
    sortBy: {
      type: 'enum',
      values: ['sourceString', 'typeString', 'occurredAt'],
      default: 'occurredAt',
      description: 'The field to sort by. Only one field can be sorted at a time.',
    } satisfies RouteSearchParamEnumType<EVENT_LOG_SORT_KEYS>,
    sortOrder: {
      type: 'enum',
      values: ['asc', 'desc'],
      default: 'desc',
      description:
        'The sort order. Fields with type `number` or `date` are usually best sorted in descending order. While string fields are best sorted in ascending order.',
    },
    page: {
      type: 'string',
      description: 'The page number to display',
    },
    size: {
      type: 'string',
      description: 'The number of items to display per page',
    },
    search: {
      type: 'string',
      description: 'A string to search for in the event details or source',
    },
  },
} satisfies NavigableRouteType<ALL_EVENT_LOG_SEARCH_PARAM_KEYS>;

export const integrations = {
  path: '/app/integrations',
  description:
    'A page where a user can see all the integrations that are connected to Channel99. The user can see the status of each integration.',
  navigable: true,
};

export const integrationDetail = {
  path: '/app/integrations/:provider',
  description:
    'A detail page for a single integration. The user can see the status of the integration, the last time a sync occurred, and the number of connections that have been made. Users can also make new connections or delete existing ones.',
  navigable: true,
};

export const verifyIntegration = {
  path: '/app/verify-integration',
  navigable: false,
};

export const companySettings = {
  path: '/app/company-settings',
  description:
    'A page where an admin can edit general information for their C99 organization, specify their Total Addressable Market (TAM) by industries, regions, or company sizes. The C99 website tag can also be found and copied here.',
  navigable: true,
};

export const configureVendors = {
  path: '/app/configure-vendors',
  description:
    'A form showing each vendor in the system and the spend for each vendor. The user can manually edit spend by month for each vendor. Users can also see spend numbers synced from integrations here (but not edit them).',
  navigable: true,
};

export const configureChannels = {
  path: '/app/configure-channels',
  description:
    'A form where users can add channel mapping rules to the system. The user can specify which channels are mapped to vendors via medium, source, referral domain, or custom parameters.',
  navigable: true,
};

export const integrationById = (providerId?: string) =>
  providerId && `${integrations.path}/${providerId}`;
