export const YELLOW_100 = '#F3EBA1';
export const YELLOW_200 = '#EEE272';
export const YELLOW_300 = '#E9DA4F';
export const YELLOW_400 = '#E2CE14';
export const YELLOW_500 = '#B5A510';
export const YELLOW_600 = '#887C0C';
export const YELLOW_700 = '#5A5208';
export const YELLOW_800 = '#2D2904';

export const ALL_YELLOWS = [
  YELLOW_100,
  YELLOW_200,
  YELLOW_300,
  YELLOW_400,
  YELLOW_500,
  YELLOW_600,
  YELLOW_700,
  YELLOW_800,
];

export const ALL_SEMANTIC_YELLOWS = [
  YELLOW_300,
  YELLOW_400,
  YELLOW_500,
  YELLOW_600,
  YELLOW_700,
  YELLOW_800,
];
