import { Trans } from '@lingui/macro';
import { DashboardFiltersType } from '@shared/Dashboard/Filters/types';
import { BenchmarksWidget } from '@shared/Dashboard/WidgetLibrary';
import { Panel } from '@shared/panels';

type Props = {
  dashboardFilters: DashboardFiltersType;
};

const BenchmarksByChannel = ({ dashboardFilters }: Props) => {
  return (
    <Panel title={<Trans>Total Visits</Trans>} size="L" noPadding style={{ minHeight: 500 }}>
      <BenchmarksWidget dashboardFilters={dashboardFilters} />
    </Panel>
  );
};

export default BenchmarksByChannel;
