import { CSSProperties } from 'react';

import SkeletonLoader from '@shared/SkeletonLoader';

type Props = {
  style: CSSProperties;
};

const LineLoader = ({ style }: Props) => (
  <div style={{ margin: '6%', display: 'flex', flex: 1, overflow: 'hidden' }}>
    <SkeletonLoader style={style} viewBox="0 0 902 206" preserveAspectRatio="none">
      <path d="M898.309 2.245c-.445.684-14.621 28.013-31.502 60.731-16.881 32.717-31.081 59.615-31.554 59.773-.473.158-21.086-9.694-45.807-21.893-24.72-12.199-45.621-22.125-46.446-22.057-.825.067-19.756 5.899-42.069 12.959l-40.569 12.837-40.264 33.453c-22.145 18.398-40.66 33.596-41.144 33.773-.484.177-19.427-17.239-42.096-38.702l-41.217-39.023-41.57-18.603c-33.048-14.79-41.923-18.39-43.289-17.56-.945.574-19.845 16.073-42 34.442-32.264 26.75-48.201 40.899-80.067 71.083-21.882 20.727-40.176 37.54-40.653 37.364-.477-.177-18.123-15.194-39.214-33.371-21.092-18.177-39.698-33.982-41.348-35.123-1.988-1.375-16.687-5.35-43.592-11.788l-40.593-9.713-40.907 24.76C-5.201 164.402.709 160.628 1.146 161.937c.195.583 18.858-10.149 41.473-23.851l41.12-24.912 40.814 9.924c22.448 5.459 41.519 10.524 42.381 11.256.861.733 19.441 16.703 41.288 35.489C230.07 188.629 248.199 204 248.509 204c.311 0 19.336-17.742 42.278-39.428 33.829-31.975 49.243-45.654 81.549-72.371l39.837-32.944 41.051 18.295c35.396 15.774 41.687 18.902 45.664 22.707C523.865 124.154 577.987 175 578.445 175c.321 0 19.109-15.395 41.75-34.21l41.165-34.211 40.57-12.789c22.314-7.033 40.928-12.789 41.365-12.789.437-.001 20.827 9.877 45.311 21.951 24.484 12.074 45.332 22.211 46.328 22.527 1.534.487 6.504-8.543 32.58-59.202C884.437 33.4 898.906 5.263 899.667 3.75c1.359-2.699.23-3.949-1.358-1.505" />
    </SkeletonLoader>
  </div>
);

export default LineLoader;
