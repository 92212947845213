import { useMemo } from 'react';

import SolGraphQLError from 'error/SolGraphQLError';
import gql from 'graphql-tag';
import useSWR from 'swr';

import { fetcherSol } from '../swr-fetcher';
import {
  PipelineKPIsGQLResponse,
  PipelineReturnOnSpendGQLResponse,
  PipelineVendorInfluenceGQLResponse,
  PipelineWeeklyTrendsGQLResponse,
} from './pipelineType';

export const usePipelineKPIs = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<PipelineKPIsGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query PipelineKPIs($startDate: DateTime, $endDate: DateTime) {
            visualization {
              pipelineKpis(startDate: $startDate, endDate: $endDate) {
                amount
                totalSpend
                returnOnSpend
                opportunities
                costPerOpportunity
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
      },
    },
    fetcherSol,
    {},
  );

  return {
    pipelineKPIs: data?.visualization.pipelineKpis,
    isLoading,
    error,
  };
};

export const usePipelineWeeklyTrends = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<PipelineWeeklyTrendsGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query PipelineWeeklyTrends($startDate: DateTime, $endDate: DateTime) {
            visualization {
              pipelineTrends(startDate: $startDate, endDate: $endDate) {
                sundayOfWeek
                returnOnSpend
                costPerOpportunity
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
      },
    },
    fetcherSol,
    {},
  );

  return {
    pipelineWeeklyTrends: data?.visualization.pipelineTrends,
    isLoading,
    error,
  };
};

export const usePipelineReturnOnSpend = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<PipelineReturnOnSpendGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query PipelineReturnOnSpend($startDate: DateTime, $endDate: DateTime) {
            visualization {
              pipelineReturnOnSpend(startDate: $startDate, endDate: $endDate) {
                channel {
                  id
                  name
                }
                pipelineAmount
                spend
                returnOnSpend
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
      },
    },
    fetcherSol,
    {},
  );

  return {
    pipelineReturnOnSpend: data?.visualization.pipelineReturnOnSpend,
    isLoading,
    error,
  };
};

export const usePipelineVendorInfluence = (startDate?: string, endDate?: string) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<PipelineVendorInfluenceGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query PipelineVendorInfluence($startDate: DateTime, $endDate: DateTime) {
            visualization {
              pipelineVendorInfluence(startDate: $startDate, endDate: $endDate) {
                channel {
                  id
                  name
                }
                vendor {
                  id
                  name
                }
                opportunitiesInfluenced
                opportunitiesInfluencedPercent
                pipelineRevenueInfluence
                pipelineRevenueInfluencePercent
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
      },
    },
    fetcherSol,
    {},
  );

  const sortedData = useMemo(
    () =>
      data?.visualization.pipelineVendorInfluence.sort(
        (a, b) => b.opportunitiesInfluencedPercent - a.opportunitiesInfluencedPercent,
      ),
    [data],
  );

  return {
    pipelineVendorInfluence: sortedData,
    isLoading,
    error,
  };
};
