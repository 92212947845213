import { useState } from 'react';

import { Trans, t } from '@lingui/macro';
import {
  DashboardDateRangeFilter,
  DashboardFilterProvider,
  DashboardFiltersType,
} from '@shared/Dashboard';
import { InstantSearchSortOrder, QueryState } from '@shared/InstantSearch';
import InstantSearch from '@shared/InstantSearch/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@shared/InstantSearch/util/search-util';
import TableActions from '@shared/TableActions/TableActions';
import { Page } from '@shared/page';
import { Panel } from '@shared/panels';
import { Space } from 'antd';
import {
  CompanyDetailType,
  downloadWebTrafficActivityList,
  useWebTrafficActivityList,
} from 'api/activity';
import axios, { CancelTokenSource } from 'axios';
import { csvDownloadRowLimit } from 'constants/numbers';
import { numberFormat } from 'helper/numberFormatter';
import moment from 'moment';
import { useNotification } from 'providers/Notification';

import ActivityActionMenu from './ActivityActionMenu';
import FileDownloadModal from './FileDownloadModal';
import WebTrafficActivityDetailDrawer from './WebTrafficActivityDetailDrawer';
import WebTrafficActivityTable from './WebTrafficActivityTable';
import { ALL_WEB_TRAFFIC_FIELDS } from './webTrafficFields';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: ALL_WEB_TRAFFIC_FIELDS['metrics.lastActivityDate'],
  sortOrder: InstantSearchSortOrder.DESC,
};

const ActivityOverview = () => {
  const { pushNotification, removeAllNotifications } = useNotification();
  const [dashboardFilters, setDashboardFilters] = useState<DashboardFiltersType>({});
  const [selectedRow, setSelectedRow] = useState<CompanyDetailType | null>(null);
  const [queryState, setQueryState] = useState<QueryState>();
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>(
    undefined,
  );
  const { companies, totalResults, isLoading, error } = useWebTrafficActivityList(
    getSolQueryParamsNewFromQueryState(queryState),
    dashboardFilters.start_date,
    dashboardFilters.end_date,
  );
  const isDownloadingFile = cancelTokenSource != null;

  const handleDownloadCSV = async () => {
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);

    try {
      const response = await downloadWebTrafficActivityList(
        getSolQueryParamsNewFromQueryState(queryState),
        dashboardFilters.start_date,
        dashboardFilters.end_date,
        source.token,
      );
      const data = response.data as string;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const todayStr = moment().format('MM_DD_YYYY');
      const filename = `C99_companyactivity_${todayStr}`;
      const pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', filename);
      pom.click();

      removeAllNotifications();
      pushNotification({
        type: 'success',
        message: t`Download Complete!`,
      });
    } catch (e) {
      removeAllNotifications();
      if (axios.isCancel(e)) {
        pushNotification({
          type: 'error',
          message: t`Download canceled by user`,
        });
      } else {
        pushNotification({
          type: 'error',
          message: t`Failed to download CSV. Only a maximum of ${numberFormat(csvDownloadRowLimit)} records can be downloaded, try applying some filters to limit download size`,
        });
      }
    }

    setCancelTokenSource(undefined);
  };

  const handleAbortDownload = () => {
    if (cancelTokenSource) {
      cancelTokenSource?.cancel();
      setCancelTokenSource(undefined);
    }
  };

  return (
    <Page title={t`Web Traffic`} pageName={t`Activity`}>
      <InstantSearch defaultQueryState={DEFAULT_QUERY_STATE} onQueryStateChange={setQueryState}>
        <Space direction="vertical" size="large">
          <DashboardFilterProvider
            dashboardFilters={dashboardFilters}
            onDashboardFiltersChange={setDashboardFilters}
          >
            <DashboardDateRangeFilter />
            <TableActions />
          </DashboardFilterProvider>
          <Panel
            title={<Trans>Company Activity</Trans>}
            actions={
              <ActivityActionMenu
                totalResults={totalResults}
                isLoading={isLoading}
                isDownloadingCSV={isDownloadingFile}
                onDownloadCSV={handleDownloadCSV}
              />
            }
            size="L"
            isFullHeight
          >
            <WebTrafficActivityTable
              data={companies}
              isLoading={isLoading}
              error={error}
              totalResults={totalResults}
              onSelectedRowChange={setSelectedRow}
            />
            <WebTrafficActivityDetailDrawer
              data={selectedRow}
              startDate={dashboardFilters.start_date}
              endDate={dashboardFilters.end_date}
              onClose={() => setSelectedRow(null)}
            />
          </Panel>
        </Space>
      </InstantSearch>
      <FileDownloadModal open={isDownloadingFile} onAbortDownload={handleAbortDownload} />
    </Page>
  );
};

export default ActivityOverview;
