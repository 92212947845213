import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Flare, {
  Axis,
  Column,
  FlareSeriesOptions,
  Legend,
  Tooltip,
  linkifyAxisLabels,
} from '@shared/Flare';
import AxisLabel from '@shared/Flare/guides/AxisLabel';
import { Panel } from '@shared/panels';
import { useMultiplePixelTargetingEfficiency } from 'api/pixel';
import { AUDIENCE_PRIMARY, TAM_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';

type Props = {
  pixelIds?: string[];
  startDate?: string;
  endDate?: string;
};

const PixelMultiTargetingEfficiencyChart = ({ pixelIds, startDate, endDate }: Props) => {
  const navigate = useNavigate();
  const { pixelTargetingEfficiencyData, isLoading, error } = useMultiplePixelTargetingEfficiency(
    pixelIds,
    startDate,
    endDate,
  );

  const sanitizedData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!pixelTargetingEfficiencyData) {
      return undefined;
    }

    return [
      {
        id: 'tam',
        name: t`TAM`,
        zIndex: 2,
        data: pixelTargetingEfficiencyData.map((d) => ({
          amount: d.impressions.tam / d.impressions.all,
          custom: { count: d.impressions.tam },
        })),
      },
      {
        id: 'audience',
        name: t`Target Audience`,
        zIndex: 3,
        data: pixelTargetingEfficiencyData.map((d) => ({
          amount: d.impressions.audience / d.impressions.all,
          custom: { count: d.impressions.audience },
        })),
      },
    ];
  }, [pixelTargetingEfficiencyData]);

  return (
    <Panel
      size="L"
      title={<Trans>Targeting Efficiency</Trans>}
      infoPopover={{
        body: <Trans>% of Impressions served to your audience.</Trans>,
      }}
      noPadding
      style={{ height: 462 }}
    >
      <Flare
        data={sanitizedData}
        description={t`A percent-based comparison between multiple pixels by Target Audience, Total Addressable Market (TAM), and everything else.`}
        colors={[TAM_PRIMARY, AUDIENCE_PRIMARY]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend reversed />
        <Column
          y="amount"
          position="overlap"
          skeletonLoaderOptions={{ numSeries: 3, numCategories: pixelIds?.length || 3 }}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value, { isPercent: true })}
        />
        <Axis
          position="bottom"
          categories={pixelTargetingEfficiencyData?.map((d) => d.pixel.name || '')}
          labelFormat={(item) => {
            const { pixel } = pixelTargetingEfficiencyData?.[item.pos] || {};
            if (!pixel?.id || !pixel?.name) {
              return '';
            }
            return renderToStaticMarkup(
              <AxisLabel
                label={pixel?.name}
                linkUrl={ROUTES.pixelById(pixel.id)}
                secondaryLabel={pixel?.audience?.name}
              />,
            );
          }}
          useHTML
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => item?.key}
          rowValueFormat={(item) => numberFormat(item?.point.custom?.count)}
          rowSecondaryValueFormat={(item) =>
            `(${numberFormat(item?.y ?? 0, {
              isPercent: true,
              precision: 1,
            })})`
          }
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PixelMultiTargetingEfficiencyChart;
