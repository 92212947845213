import { ChannelType } from '../channel/channelType';
import { CompanyType } from '../common';

export enum OPPORTUNITY_TYPE {
  CLOSED_WON = 'RECENTDEAL',
  PIPELINE = 'PIPELINE',
}

export type AccountType = {
  id: string;
  company: CompanyType;
  visits: number;
  people: number;
  vendors: number;
};

export type OpportunityType = {
  id: string;
  name: string;
  account?: AccountType;
  openDate: string;
  closeDate: string;
  daysToClose: number;
  amount: number;
  metrics?: {
    impressions: number;
    visits: number;
  };
};

export type RecentDealsGQLResponse = {
  opportunities: {
    recentDeals: OpportunityType[];
  };
};

export type RecentDealVisitSeriesDataType = {
  channel: ChannelType;
  data: {
    sundayOfWeek: string;
    runningTotalVisits: number;
  }[];
};

export type RecentDealVisitTrendsGQLResponse = {
  visualization: {
    recentDealsTrends: {
      opportunity: OpportunityType;
      channelData: RecentDealVisitSeriesDataType[];
    };
  };
};
