import { forwardRef } from 'react';

import { Input, InputProps, InputRef } from 'antd';
import ClearIcon from 'assets/svg/bx-x.svg?react';
import classNames from 'classnames';
import { maxDefaultLength } from 'constants/numbers';

import styles from './TextInput.module.scss';

export type TextInputProps = Omit<
  InputProps,
  'disabled' | 'variant' | 'allowClear' | 'size' | 'status' | 'showCount' | 'onChange'
> & {
  allowClear?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium' | 'large';
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput = forwardRef<InputRef, TextInputProps>(
  (
    {
      className,
      style,
      allowClear = false,
      error,
      isDisabled,
      size = 'large',
      maxLength = maxDefaultLength,
      width,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <Input
        {...rest}
        className={classNames(styles.input, className)}
        style={{ ...style, width: width ? width : undefined }}
        ref={ref}
        size={size === 'medium' ? 'middle' : size}
        variant="outlined"
        disabled={isDisabled}
        maxLength={maxLength}
        status={error ? 'error' : undefined}
        allowClear={allowClear && { clearIcon: <ClearIcon data-testid="search-clear" /> }}
        showCount={false}
        onChange={(e) => onChange?.(e.target.value, e)}
      />
    );
  },
);

export default TextInput;
