import { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { AudienceSelect } from '@shared/AudienceSelect';
import { Option } from '@shared/Form';
import { Text } from '@shared/typography';
import { AudienceOptionType } from 'api/audience';
import { useDisplayName } from 'api/common';
import { numberFormat } from 'helper/numberFormatter';
import { useSearchParams } from 'react-router-dom';

import { useDashboardFilterContext } from './DashboardFilterProvider';

type Props = {
  className?: string;
  audience?: AudienceOptionType;
  isDisabled?: boolean;
  prependedOptions?: AudienceOptionType[];
};

const DashboardAudienceFilter = ({
  className = '',
  audience: defaultAudience,
  isDisabled = false,
  prependedOptions,
}: Props) => {
  const [audience, setAudience] = useState<AudienceOptionType | undefined>(defaultAudience);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setDashboardFilters } = useDashboardFilterContext();
  const audienceId = searchParams.get('audience') ?? undefined;
  const prependedAudienceOption = prependedOptions?.find((option) => option.id === audienceId);
  const { displayName, isLoading } = useDisplayName(
    audience?.id !== audienceId && !prependedAudienceOption && audienceId,
  );

  const handleAudienceChange = (selectedAudience?: AudienceOptionType) => {
    if (selectedAudience) {
      searchParams.set('audience', selectedAudience.id);
    } else {
      searchParams.delete('audience');
    }

    setAudience(selectedAudience);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (prependedAudienceOption) {
      setDashboardFilters({ audience: prependedAudienceOption });
      setAudience(prependedAudienceOption);
    } else if (audience?.id === audienceId) {
      setDashboardFilters({ audience });
    } else if (!audienceId) {
      setDashboardFilters({ audience: undefined });
      setAudience(undefined);
    } else if (audienceId && displayName) {
      const composedAudience = { id: audienceId, name: displayName };
      setAudience(composedAudience);
    }
  }, [audience, audienceId, displayName, prependedAudienceOption]);

  return (
    <AudienceSelect
      className={className}
      width={230}
      value={audience}
      isLoading={isLoading}
      placeholder={t`+ Add Audience`}
      renderOption={({ id, name, metrics = {} }) => (
        <Option key={id} value={id} label={name} metrics={metrics}>
          <div className={metrics.size === 0 ? 'audience-select-container__disabled-row' : ''}>
            <Text variant="body1" color="grey" weight="bold">
              {name}
            </Text>
            <br />
            <Text variant="caption1" color="grey">
              <Trans>Count: {numberFormat(metrics.size)}</Trans>
            </Text>
          </div>
        </Option>
      )}
      allowClear
      isDisabled={isDisabled}
      prependedOptions={prependedOptions}
      onChange={handleAudienceChange}
    />
  );
};

export default DashboardAudienceFilter;
