import { t } from '@lingui/macro';
import { KpiMetric, KpiMetricGroup } from '@shared/kpi';
import { AudienceOptionType } from 'api/audience';
import { useVendorActivityKPIs } from 'api/vendor';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
};

const VendorActivityKPIs = ({ startDate, endDate, audience }: Props) => {
  const { vendorActivityKpis, isLoading, error } = useVendorActivityKPIs(
    startDate,
    endDate,
    audience?.id,
  );

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={t`Total Visits`}
        value={vendorActivityKpis && numberFormat(vendorActivityKpis.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={audience?.id ? t`${audience?.name} Visits` : t`TAM Visits`}
        value={
          vendorActivityKpis &&
          numberFormat(
            audience?.id ? vendorActivityKpis.audienceVisits : vendorActivityKpis.tamVisits,
          )
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Engaged Companies`}
        value={vendorActivityKpis && numberFormat(vendorActivityKpis.engagedCompanies)}
        valueLink={
          vendorActivityKpis?.engagedCompanies &&
          ROUTES.webTrafficActivityWithParams({
            audienceIds: audience?.id,
            'metrics.visits': '[1,]',
          })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Pipeline Influenced`}
        value={
          vendorActivityKpis &&
          numberFormat(vendorActivityKpis.pipelineInfluenced, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Closed Won Influenced`}
        value={
          vendorActivityKpis &&
          numberFormat(vendorActivityKpis.closedWonInfluenced, { isCurrency: true, precision: 0 })
        }
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default VendorActivityKPIs;
