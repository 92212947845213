import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

import classNames from 'classnames';

import styles from './DashboardFilterProvider.module.scss';
import {
  DashboardFilterContextProps,
  DashboardFiltersChangeFunction,
  DashboardFiltersType,
} from './types';

type Props = {
  className?: string;
  dashboardFilters: DashboardFiltersType;
  onDashboardFiltersChange: DashboardFiltersChangeFunction;
};

const DashboardFilterContext = createContext<DashboardFilterContextProps | undefined>(undefined);

export const useDashboardFilterContext = () => {
  const context = useContext(DashboardFilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a DashboardFilterProvider');
  }
  return context;
};

const DashboardFilterProvider = ({
  className,
  children,
  dashboardFilters,
  onDashboardFiltersChange,
  ...rest
}: PropsWithChildren<Props>) => {
  const [, setFilters] = useState<DashboardFiltersType>({});

  const handleDashboardFiltersChange = useCallback(
    (newFilters: Partial<DashboardFiltersType>) => {
      setFilters((previousFilters) => {
        const combinedFilters = { ...previousFilters, ...newFilters };
        onDashboardFiltersChange(combinedFilters);
        return combinedFilters;
      });
    },
    [setFilters],
  );

  return (
    <div className={classNames(styles.header, className)} {...rest}>
      <DashboardFilterContext.Provider
        value={{ dashboardFilters, setDashboardFilters: handleDashboardFiltersChange }}
      >
        {children}
      </DashboardFilterContext.Provider>
    </div>
  );
};

export default DashboardFilterProvider;
