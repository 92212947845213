import { useEffect } from 'react';

import { DateRangePicker, RangeValue } from '@shared/Form';
import classNames from 'classnames';
import { DateBEFormat } from 'constants/formats';
import { getDefaultDateRange, setDefaultDateRange } from 'helper/common';
import moment from 'moment';

import styles from './DashboardDateRangeFilter.module.scss';
import { useDashboardFilterContext } from './DashboardFilterProvider';
import { DashboardFiltersType } from './types';

type Props = {
  className?: string;
};

const getLast90Days = () => {
  return {
    start_date: moment().subtract(90, 'd').startOf('d').format(DateBEFormat),
    end_date: moment().endOf('d').format(DateBEFormat),
  };
};

const addDefaultDatesIntoFiltersIfNecessary = (dashboardFilters: DashboardFiltersType) => {
  // We may not have a `start_date` and `end_date` in the dashboardFilters until the filters get
  // pushed down into the component. This is a workaround to ensure that the date range is set to
  // what the filters will eventually contain.
  if (!dashboardFilters.start_date && !dashboardFilters.end_date) {
    const defaultRange = getDefaultDateRange() || getLast90Days();
    return {
      ...dashboardFilters,
      ...defaultRange,
    };
  } else {
    return dashboardFilters;
  }
};

const DashboardDateRangeFilter = ({ className = '' }: Props) => {
  const { dashboardFilters, setDashboardFilters } = useDashboardFilterContext();

  // Push the default start/end dates into the filters context so other components can use them.
  useEffect(() => {
    const defaultRange = getDefaultDateRange();

    if (defaultRange) {
      setDashboardFilters(defaultRange);
    } else {
      const newDefaultRange = getLast90Days();
      setDefaultDateRange(newDefaultRange);
      setDashboardFilters(newDefaultRange);
    }
  }, []);

  const handleDateRangeChange = (dates: RangeValue | null, dateStrings: [string, string]) => {
    const newDateRange = { start_date: dateStrings[0], end_date: dateStrings[1] };
    setDefaultDateRange(newDateRange);
    setDashboardFilters(newDateRange);
  };

  const filtersWithDateRange = addDefaultDatesIntoFiltersIfNecessary(dashboardFilters);

  return (
    <div className={classNames(styles.dateRange, className)} data-testid="date-range-picker">
      <DateRangePicker
        size="large"
        value={[moment(filtersWithDateRange.start_date), moment(filtersWithDateRange.end_date)]}
        allowClear={false}
        maxDate={moment().endOf('d')}
        onChange={handleDateRangeChange}
      />
    </div>
  );
};

export default DashboardDateRangeFilter;
