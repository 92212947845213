import { useEffect, useRef } from 'react';

import { t } from '@lingui/macro';
import SolGraphQLError from 'error/SolGraphQLError';
import { useNotification } from 'providers/Notification';

type Props = {
  startSession: () => Promise<{ success: boolean }>;
  refreshSession: () => Promise<{ success: boolean }>;
  endSession: () => Promise<{ success: boolean }>;
  keepAlivePingInterval: number;
};

const useSessionLock = ({
  startSession,
  refreshSession,
  endSession,
  keepAlivePingInterval,
}: Props) => {
  const { pushNotification } = useNotification();
  const sessionIntervalId: { current: NodeJS.Timeout | null } = useRef(null);

  const startSessionLock = async (): Promise<boolean> => {
    try {
      const response = await startSession();
      if (!response.success) {
        throw new Error();
      }
      clearInterval(sessionIntervalId.current as NodeJS.Timeout);
      sessionIntervalId.current = setInterval(refreshSession, keepAlivePingInterval);

      return true;
    } catch (e) {
      let errMessage = t`Apologies, but something went wrong. Please refresh the page and try to edit again.`;
      if (e instanceof SolGraphQLError) {
        errMessage = e.getFirstError().message;
      }

      pushNotification({ type: 'error', message: errMessage });
    }

    return false;
  };

  const endSessionLock = async (): Promise<boolean> => {
    clearInterval(sessionIntervalId.current as NodeJS.Timeout);
    const response = await endSession();
    return response.success;
  };

  useEffect(() => {
    return () => {
      // End session on unmount
      endSessionLock();
    };
  }, []);

  return {
    startSessionLock,
    endSessionLock,
  };
};

export default useSessionLock;
