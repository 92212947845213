import { Trans } from '@lingui/macro';
import { Link } from '@shared/typography';

const TermsOfUseLink = () => {
  return (
    <Link
      variant="caption1"
      color="aqua"
      to="https://www.channel99.com/terms-of-use"
      target="_blank"
    >
      <Trans>Terms of Use</Trans>
    </Link>
  );
};

export default TermsOfUseLink;
