import { createContext, useContext, useId } from 'react';

import { Options } from 'highcharts';

import { BuildOptionsFunction, FlareChart, FlareProps } from './types';

export type FlareContextType = {
  registerChild: (id: string, buildOptionsFunction: BuildOptionsFunction) => void;
  options: Options | null | undefined;
  parentProps: Omit<FlareProps, 'error'>;
  isLoading: boolean;
  chart?: FlareChart;
};

const FlareContext = createContext<FlareContextType | null>(null);

export const FlareContextProvider = FlareContext.Provider;

export const useFlareContext = () => {
  const id = useId();
  const context = useContext(FlareContext);

  if (!context) {
    throw new Error('This component must be used within a `<Flare />` component');
  }

  const { registerChild, parentProps, options, isLoading, chart } = context;

  return { id, registerChild, parentProps, options, isLoading, chart };
};
