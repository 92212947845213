import { useEffect, useMemo, useRef, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import Table, { ColumnsType } from '@shared/Table';
import { Link } from '@shared/typography';
import { TableRef } from 'antd/es/table';
import { AudienceOptionType } from 'api/audience';
import { VendorActivityDataType, VendorAndChannelType } from 'api/vendor';
import didClickOnAntTooltip from 'helper/didClickOnAntTooltip';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

import VendorActivityTableBarCell from './VendorActivityTableBarCell';
import styles from './VendorTop5CampaignTable.module.scss';
import { SERIES_TYPE } from './types';

const getDefaultCampaigns = (vendor: VendorAndChannelType) => {
  return [
    {
      id: `${vendor.channel?.id}-${vendor.id}-1`,
      name: 'Q1 2020 SF Camp',
      spend: 5000,
      adImpressions: 500000,
      adClicks: 10,
      audienceVisits: 2421,
      tamVisits: 4843,
      visits: 12107,
      engagedCompanies: 800,
      pipelineInfluenced: 300000,
      closedWonInfluenced: 50000,
    },
    {
      id: `${vendor.channel?.id}-${vendor.id}-2`,
      name: 'Campaign 2',
      spend: 5000,
      adImpressions: 500000,
      adClicks: 20,
      audienceVisits: 2740,
      tamVisits: 5480,
      visits: 8565,
      engagedCompanies: 1053,
      pipelineInfluenced: 100000,
      closedWonInfluenced: 50000,
    },
    {
      id: `${vendor.channel?.id}-${vendor.id}-3`,
      name: 'Campaign 2025',
      spend: 10000,
      adImpressions: 600000,
      adClicks: 20,
      audienceVisits: 1231,
      tamVisits: 2853,
      visits: 5915,
      engagedCompanies: 150,
      pipelineInfluenced: 100000,
      closedWonInfluenced: 50000,
    },
    {
      id: `${vendor.channel?.id}-${vendor.id}-4`,
      name: 'Q1 Campaign',
      spend: 5456,
      adImpressions: 633000,
      adClicks: 6,
      audienceVisits: 582,
      tamVisits: 1365,
      visits: 5883,
      engagedCompanies: 350,
      pipelineInfluenced: 34445,
      closedWonInfluenced: 6485,
    },
    {
      id: `${vendor.channel?.id}-${vendor.id}-5`,
      name: 'AlloppsQ2 PS',
      spend: 3145,
      adImpressions: 332456,
      adClicks: 3,
      audienceVisits: 123,
      tamVisits: 234,
      visits: 2752,
      engagedCompanies: 253,
      pipelineInfluenced: 345786,
      closedWonInfluenced: 124456,
    },
  ];
};

type CampaignType = {
  id: string;
  name: string;
  spend: number;
  adImpressions: number;
  adClicks: number;
  audienceVisits: number;
  tamVisits: number;
  visits: number;
  engagedCompanies: number;
  pipelineInfluenced: number;
  closedWonInfluenced: number;
};

type Props = {
  campaigns?: CampaignType[];
  vendor: VendorAndChannelType;
  audience?: AudienceOptionType;
  enabledSeries: SERIES_TYPE[];
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
};

const VendorTop5CampaignTable = ({
  vendor,
  campaigns = getDefaultCampaigns(vendor),
  audience,
  enabledSeries,
  onScroll,
}: Props) => {
  const tableRef = useRef<TableRef>(null);
  const [hoveredRow, setHoveredRow] = useState<VendorActivityDataType | null>(null);
  const [stickyRow, setStickyRow] = useState<{
    element: HTMLElement;
    record: VendorActivityDataType;
  } | null>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.nativeElement
        .querySelector<HTMLDivElement>('.ant-table-content')
        ?.addEventListener('scroll', (e) =>
          onScroll?.(e as unknown as React.UIEvent<HTMLDivElement>),
        );
    }

    return () => {
      if (tableRef.current) {
        tableRef.current.nativeElement
          .querySelector<HTMLDivElement>('.ant-table-content')
          ?.removeEventListener('scroll', (e) =>
            onScroll?.(e as unknown as React.UIEvent<HTMLDivElement>),
          );
      }
    };
  }, [tableRef]);

  // We use this to calculate bar percentage for each row.
  const maxVisits = useMemo(
    () =>
      campaigns?.reduce((max, d) => {
        if (enabledSeries.indexOf(SERIES_TYPE.ALL) >= 0) {
          return Math.max(max, d.visits);
        }
        if (enabledSeries.indexOf(SERIES_TYPE.TAM) >= 0) {
          return Math.max(max, d.tamVisits);
        }
        if (enabledSeries.indexOf(SERIES_TYPE.AUDIENCE) >= 0) {
          return Math.max(max, d.audienceVisits);
        }
        return 0;
      }, 0),
    [campaigns, enabledSeries],
  );

  const columns: ColumnsType<CampaignType> = useMemo(
    () => [
      {
        title: '',
        key: 'group',
        fixed: 'left',
        className: styles.rowSpanColumn,
        width: 152,
        render: () => <Trans>Campaigns</Trans>,
        onCell: (_, index) => (index === 0 ? { rowSpan: campaigns.length } : { rowSpan: 0 }),
      },
      {
        title: t`Campaign Name`,
        key: 'name',
        fixed: 'left',
        width: 120,
        render: (text, field) => (
          <Link variant="caption1" weight="bold" to={ROUTES.campaignById(field.id)}>
            {field.name}
          </Link>
        ),
      },
      {
        title: 'Spend',
        key: 'spend',
        width: 110,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: 'Impressions',
        key: 'impressions',
        width: 100,
        align: 'right',
        render: (text) => numberFormat(text),
      },
      {
        title: 'Clicks',
        key: 'clicks',
        width: 90,
        align: 'right',
        render: (text) => numberFormat(text),
      },
      {
        title: t`Visits`,
        key: 'visits',
        width: 300,
        render: (text, field) => (
          <VendorActivityTableBarCell
            data={{
              vendor,
              ...field,
            }}
            audience={audience}
            maxVisits={maxVisits}
            hoveredRow={hoveredRow}
            stickyRow={stickyRow?.record}
            enabledSeries={enabledSeries}
            onStickyTooltipClose={() => setStickyRow(null)}
          />
        ),
        onCell: (record) => ({
          style: { cursor: 'pointer' },
          onMouseEnter: () => setHoveredRow({ ...record, vendor }),
          onMouseLeave: () => setHoveredRow(null),
          onClick: (e) => {
            if (didClickOnAntTooltip(e.target)) {
              return;
            }

            // clicking on the same row again will close the tooltip
            if (stickyRow?.record.id === record.id) {
              setStickyRow(null);
            } else {
              setStickyRow({
                element: e.currentTarget as HTMLElement,
                record: { ...record, vendor },
              });
            }
          },
        }),
      },
      {
        title: 'Engaged Companies',
        key: 'engagedCompanies',
        width: 110,
        align: 'right',
        render: (text) => numberFormat(text),
      },
      {
        title: 'Pipeline Influenced',
        key: 'pipelineInfluenced',
        width: 120,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: 'Closed Won Influenced',
        key: 'closedWonInfluenced',
        width: 120,
        align: 'right',
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [campaigns],
  );

  return (
    <Table<CampaignType>
      ref={tableRef}
      emptyMessage={<Trans>No campaigns found</Trans>}
      dataSource={campaigns}
      columns={columns}
      pagination={false}
      error={null}
      showHeader={false}
      rowKey={(record) => record.id}
      scroll={{ x: 1200 }}
    />
  );
};

export default VendorTop5CampaignTable;
