import { CategoryAxisType } from '../guides/Axis';
import { TooltipPositionerCallbackFunction } from '../types';

export const tooltipPositionBelow: TooltipPositionerCallbackFunction = (
  tooltip,
  labelWidth,
  labelHeight,
  point,
) => {
  const { plotLeft, plotWidth } = tooltip.chart;
  const plotRight = plotLeft + plotWidth;
  const xAxis = tooltip.chart.xAxis[0];
  const yAxis = tooltip.chart.yAxis[0];

  const maxYPos =
    tooltip.chart.hoverPoints?.reduce(
      (max, point) => Math.max(max, yAxis.toPixels(point.y ?? 0, true)),
      0,
    ) ?? point.plotY;

  const x = Math.max(plotLeft + 5, plotLeft + maxYPos / 2 - labelWidth / 2);
  const y = Math.min(plotRight - 5, point.plotY + (xAxis as CategoryAxisType).transA);

  return { x, y };
};
