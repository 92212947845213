import { ActionIcon } from '@shared/buttons';
import MicrophoneIcon from 'assets/svg/microphone.svg?react';
import classNames from 'classnames';

import styles from './MicrophoneButton.module.scss';

type Props = {
  className?: string;
  isRecording: boolean;
  onRecordingChange: (isRecording: boolean) => void;
  size?: 'small' | 'medium' | 'large';
};

const MicrophoneButton = ({ className = '', isRecording, onRecordingChange, size }: Props) => {
  return (
    <ActionIcon
      className={classNames(styles.microphone, className, { [styles.recording]: isRecording })}
      size={size}
      color="black"
      icon={<MicrophoneIcon />}
      onClick={() => onRecordingChange(!isRecording)}
    />
  );
};

export default MicrophoneButton;
