import { Pagination as AntdPagination, PaginationProps } from 'antd';
import classNames from 'classnames';

import styles from './Pagination.module.scss';

const Pagination = ({ className, ...rest }: PaginationProps) => {
  return <AntdPagination {...rest} className={classNames(styles.pagination, className)} />;
};

export default Pagination;
