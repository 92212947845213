import { AnchorHTMLAttributes, HTMLAttributes, MouseEvent } from 'react';

import classNames from 'classnames';
import { Link as RRLink, To } from 'react-router-dom';

import { Text } from '../Text';
import { LinkColorType, TextVariantType } from '../types';
import styles from './Link.module.scss';

type LinkHTMLProps = HTMLAttributes<HTMLElement> & AnchorHTMLAttributes<HTMLElement>;

type BaseLinkProps = Omit<LinkHTMLProps, 'href'> & {
  className?: string;
  to?: To;
  variant?: TextVariantType;
  color?: LinkColorType;
  weight?: 'regular' | 'semi-bold' | 'bold';
  isDisabled?: boolean;
  onClick?: (e?: MouseEvent) => void;
};

export type LinkProps = BaseLinkProps &
  (
    | {
        to?: To;
        previousPage?: never;
      }
    | {
        to?: never;
        previousPage?: boolean;
      }
  );

const getDefaultWeight = (color: LinkColorType): 'regular' | 'semi-bold' => {
  switch (color) {
    case 'aqua':
    case 'static-white':
      return 'semi-bold';
    default:
      return 'regular';
  }
};

const Link = ({
  className,
  variant = 'body1',
  color = 'black',
  weight,
  to,
  target,
  rel,
  previousPage,
  children,
  isDisabled = false,
  onClick,
  ...rest
}: LinkProps) => {
  const linkStyles = classNames(
    styles.link,
    styles.text,
    className,
    styles[`color-${color}`],
    styles[`weight-${weight ?? getDefaultWeight(color)}`],
    {
      [styles.disabled]: isDisabled,
    },
  );

  if (to) {
    return (
      <RRLink
        {...rest}
        to={to}
        target={target}
        rel={!rel && target === '_blank' ? 'noreferrer' : rel}
        onClick={(e) => onClick?.(e)}
      >
        <Text className={linkStyles} variant={variant}>
          {children}
        </Text>
      </RRLink>
    );
  }

  return (
    <Text
      {...rest}
      className={linkStyles}
      onClick={previousPage ? () => history.back() : (e) => onClick?.(e)}
      onKeyDown={(event) => {
        // Allow the pseudo-link to be activated by pressing Enter or Space
        if (event.key === 'Enter' || event.key === ' ') {
          onClick?.();

          if (previousPage) {
            history.back();
          }
        }
      }}
      variant={variant}
      role="link"
      tabIndex={isDisabled ? undefined : 0}
    >
      {children}
    </Text>
  );
};

export default Link;
