import { DashboardWidgetComponentProps } from '@shared/Dashboard/types';
import WebTrafficVisitByTypeTrendChart from '@shared/charts/WebTrafficVisitByTypeTrendChart';
import { useWebTrafficVisitTrendByType } from 'api/webTraffic';

const WebTrafficVisitByTypeTrendWidget = ({ dashboardFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date, audience } = dashboardFilters;
  const { trafficTrendData, isLoading, error } = useWebTrafficVisitTrendByType(
    start_date,
    end_date,
    audience?.id,
  );

  return (
    <WebTrafficVisitByTypeTrendChart
      data={trafficTrendData}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficVisitByTypeTrendWidget;
