import { Trans, t } from '@lingui/macro';
import { Legend, LegendItem } from '@shared/Legend';
import DonutContainer from '@shared/charts/DonutContainer';
import DonutGauge from '@shared/charts/DonutGauge';
import { Panel } from '@shared/panels';
import { Link, Text } from '@shared/typography';
import { useMultiplePixelReachEngagementData } from 'api/pixel';
import { AUDIENCE_PRIMARY, AUDIENCE_SECONDARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

type Props = {
  pixelIds?: string[];
  startDate?: string;
  endDate?: string;
};

const PixelMultiReachEngagementChart = ({ pixelIds, startDate, endDate }: Props) => {
  const { pixelReachEngagementData, isLoading, error } = useMultiplePixelReachEngagementData(
    pixelIds,
    startDate,
    endDate,
  );

  return (
    <Panel
      data-testid="pixel-compare-reach-panel"
      size="L"
      title={<Trans>Reach & Engagement Compare</Trans>}
      noPadding
    >
      <Legend>
        <LegendItem color={AUDIENCE_PRIMARY} name={t`Reach`} />
        <LegendItem color={AUDIENCE_SECONDARY} name={t`Engagement`} />
      </Legend>
      <DonutContainer error={error}>
        {pixelIds?.map((pixelId) => {
          const data = pixelReachEngagementData?.find(
            (pixelData) => pixelData.pixel.id === pixelId,
          );

          const size = data?.audienceSize || 0;
          const outerPercent = size > 0 ? (data?.reach || 0) / size : 0;
          const innerPercent = size > 0 ? (data?.engagement || 0) / size : 0;

          return (
            <DonutContainer.Item
              key={pixelId}
              minWidth={275}
              height={316}
              caption={
                data && (
                  <>
                    <Link
                      variant="caption1"
                      color="black"
                      weight="semi-bold"
                      to={ROUTES.pixelById(data.pixel.id)}
                    >
                      {data.pixel?.name}
                    </Link>
                    <Text variant="caption2">{data.pixel?.audience?.name}</Text>
                  </>
                )
              }
            >
              <DonutGauge
                title={t`${data?.pixel.audience?.name} (${numberFormat(size)} Companies)`}
                outerSeriesName={t`Reach`}
                innerSeriesName={t`Engagement`}
                unitLabel={t`Companies`}
                outerValue={outerPercent * 100}
                outerCount={data?.reach}
                innerValue={innerPercent * 100}
                innerCount={data?.engagement}
                hideLegend
                error={null}
                isLoading={isLoading}
              />
            </DonutContainer.Item>
          );
        })}
      </DonutContainer>
    </Panel>
  );
};

export default PixelMultiReachEngagementChart;
