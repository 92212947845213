import { Text, Title } from '@shared/typography';
import classNames from 'classnames';
import { ALL_SEMANTIC_COLORS } from 'constants/colors';
import { GRAY_700 } from 'constants/colors/semantic/gray';

import styles from './LetteredAvatar.module.scss';

/**
 * Generates a consistent, random background color for a given name.
 */
const stringToColor = (string?: string) => {
  // if no value is passed, always return transparent color otherwise a rerender would show a new
  // color which would will give strange effects when an interface is loading and gets rerendered a
  // few consecutive times
  if (!string) {
    return GRAY_700;
  }

  // Rather than use a random number, we want to make sure we'll get the same color assigned to the
  // same name each time.
  // https://en.wikipedia.org/wiki/Linear_congruential_generator
  const numColors = ALL_SEMANTIC_COLORS.length;
  const charCodes = [...string].map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (numColors - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % numColors;

  let random = charCodes[0] % numColors;
  for (let i = 0; i < len; i++) {
    random = (a * random + c) % numColors;
  }

  return ALL_SEMANTIC_COLORS[random];
};

const stringInitials = (string?: string | null) => {
  const parts = (string || '').split(' ');
  const firstInitial = parts[0].toLocaleUpperCase()[0];

  if (parts.length > 1) {
    const secondWord = parts[1].toLocaleUpperCase();
    const secondInitial = secondWord[0];
    // Don't show an initial for some 2nd words.
    const dropSecondInitial =
      secondWord === 'INC' ||
      secondWord === 'INC.' ||
      secondWord === 'LLC' ||
      secondWord === 'LLC.';
    return `${firstInitial}${dropSecondInitial ? '' : secondInitial}`;
  }
  return firstInitial;
};

type Props = {
  name?: string;
  size?: 'small' | 'medium';
  shape?: 'circle' | 'square';
};

const LetteredAvatar = ({ name, size = 'small', shape = 'circle' }: Props) => {
  const props = {
    className: classNames(styles.avatar, styles[`size-${size}`], styles[`shape-${shape}`]),
    style: { backgroundColor: stringToColor(name) },
    children: stringInitials(name),
  };

  if (size === 'small') {
    return <Text variant="caption2" {...props} />;
  }

  return <Title level={4} {...props} />;
};

export default LetteredAvatar;
