import { useCallback, useMemo, useState } from 'react';

type Props = {
  lang: string;
  continuous: boolean;
};

const punctuationMap: Record<string, string> = {
  comma: ',',
  period: '.',
  'question mark': '?',
  'exclamation mark': '!',
  'exclamation point': '!',
  semicolon: ';',
  colon: ':',
  dash: '-',
  hyphen: '-',
  'open parenthesis': '(',
  'close parenthesis': ')',
  'open bracket': '[',
  'close bracket': ']',
  'open brace': '{',
  'close brace': '}',
  'single quote': "'",
  'double quote': '"',
};

// Function to replace punctuation words with symbols, remove whitespace before punctuation,
// and capitalize the first letter after certain punctuation marks.
const replacePunctuationWords = (text = '') => {
  // Replace punctuation words with symbols
  let result = text.replace(
    /\b(?:comma|period|question mark|exclamation mark|exclamation point|semicolon|colon|dash|hyphen|open parenthesis|close parenthesis|open bracket|close bracket|open brace|close brace|single quote|double quote)\b/g,
    function (match) {
      return punctuationMap[match.toLowerCase()] || match;
    },
  );

  // Remove whitespace before punctuation
  result = result.replace(/\s([,.!?;:)\]}\-'"])/g, '$1');

  // Capitalize the first letter after certain punctuation marks
  result = result.replace(/([.?!]\s*)(\w)/g, function (match, p1, p2) {
    return p1 + p2.toUpperCase();
  });

  // Capitalize the first letter of the entire transcript
  if (result.length > 0) {
    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
};

const useSpeechRecognition = ({ lang = 'en-US', continuous = true }: Props) => {
  const [value, setValue] = useState('');
  const [isRecording, setRecording] = useState(false);
  const [interimTranscript, setInterimTranscript] = useState('');

  const handleSpeechResult = useCallback((event: SpeechRecognitionEvent) => {
    let interimTranscript = '';
    let finalTranscript = '';

    for (let i = event.resultIndex; i < event.results.length; i++) {
      const transcript = event.results[i][0].transcript;
      if (event.results[i].isFinal) {
        finalTranscript += transcript;
      } else {
        interimTranscript += transcript;
      }
    }

    if (finalTranscript) {
      setValue((val) => val + finalTranscript);
      setInterimTranscript('');
    } else {
      setInterimTranscript(interimTranscript);
    }
  }, []);

  const recognition = useMemo(() => {
    const SpeechRecognitionService = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognitionService) {
      return null;
    }

    const recognition = new SpeechRecognitionService();
    recognition.continuous = continuous;
    recognition.interimResults = true;
    recognition.lang = lang;
    recognition.addEventListener('result', handleSpeechResult);

    recognition.addEventListener('end', () => {
      setRecording(false);
    });

    recognition.addEventListener('start', () => {
      setRecording(true);
      setValue('');
    });

    return recognition;
  }, [handleSpeechResult, lang, continuous]);

  const startRecording = useCallback(() => {
    recognition?.start();
  }, [recognition]);

  const stopRecording = useCallback(() => {
    recognition?.stop();
  }, [recognition]);

  return {
    value: replacePunctuationWords(value + interimTranscript),
    isRecording,
    setValue,
    startRecording,
    stopRecording,
  };
};

export default useSpeechRecognition;
