import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';

import {
  useStytchB2BClient,
  useStytchMember,
  useStytchMemberSession,
  useStytchOrganization,
} from '@stytch/react/dist/b2b';
import { Member, Organization } from '@stytch/vanilla-js';
import { UserProfileType } from 'api/user';
import { sessionDurationMinutes } from 'constants/numbers';
import { setLastVisitedOrgSlug } from 'helper/common';
import { updateUserContext } from 'helper/launchdarkly';
import { updatePendoUser } from 'helper/pendo';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { EnhancedMember, UserContextType } from './types';

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const createUserContext = (member: Member, organization: Organization) => {
  const instanceId = organization.organization_slug.replace(/^inst~/, 'inst:');

  const currentOrg = {
    id: organization.organization_id,
    name: organization.organization_name,
    slug: organization.organization_slug,
    instanceId,
  };
  const roles = member.roles.map((role) => role.role_id);

  let pictureUrl = null;
  const registrationWithPicture = ((member as EnhancedMember)?.oauth_registrations ?? []).find(
    (registration) => registration.profile_picture_url != null,
  );
  if (registrationWithPicture) {
    pictureUrl = registrationWithPicture?.profile_picture_url;
  }

  return {
    id: `user:${member.email_address.replace('.', '~')}`,
    stytchId: member.member_id,
    email: member.email_address,
    name: member.name,
    pictureUrl,
    roles,
    currentOrg,
    isC99Superuser: roles.some((role: string) => role === 'c99.role.superuser'),
    isAdmin: roles.some((role: string) => role === 'c99.role.admin'),
    // TODO: Setting `isPaid` to true for now. We intend to eventually rework roles/permissions
    isPaid: true,
    status: member.status,
  } as UserProfileType;
};

export const UserProvider = ({ children }: PropsWithChildren) => {
  const stytch = useStytchB2BClient();
  const { member } = useStytchMember();
  const { session } = useStytchMemberSession();
  const { organization } = useStytchOrganization();
  const ldClient = useLDClient();

  const user = useMemo(() => {
    if (member && organization && session) {
      return createUserContext(member, organization);
    } else {
      return undefined;
    }
  }, [member, session, organization]);

  useEffect(() => {
    if (user) {
      // if user changes, update LD and Pendo contexts
      updateUserContext(user, ldClient);
      updatePendoUser(user);

      // cache the logged-in org so the user jumps straight there from /sign-in next time.
      setLastVisitedOrgSlug(user.currentOrg.slug);

      // Extend the session
      stytch.session.authenticate({
        session_duration_minutes: sessionDurationMinutes,
      });
    }
  }, [stytch, ldClient, user?.id]);

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};
