import { useCallback, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { Legend, LegendItem } from '@shared/Legend';
import Table, { ColumnsType } from '@shared/Table';
import { KpiMetric } from '@shared/kpi';
import { Panel } from '@shared/panels';
import { Link, Text } from '@shared/typography';
import { AudienceOptionType } from 'api/audience';
import { VendorFitScoringDataType, useVendorFitScoring } from 'api/vendor';
import Remove from 'assets/svg/remove.svg?react';
import classNames from 'classnames';
import { GREEN_100 } from 'constants/colors/semantic/green';
import { RED_300 } from 'constants/colors/semantic/red';
import { numberFormat } from 'helper/numberFormatter';
import { ROUTES } from 'router';

import styles from './VendorFitScoringTable.module.scss';
import VendorFitScoringTableActions from './VendorFitScoringTableActions';

const TITLE_WIDTH = 160;

type Props = {
  audience?: AudienceOptionType;
  startDate?: string;
  endDate?: string;
};

const getColor = ({ best, worst }: { best: boolean; worst: boolean }) => {
  if (best) {
    return GREEN_100;
  }
  if (worst) {
    return RED_300;
  }
  return undefined;
};

const VendorFitScoringTable = ({ startDate, endDate, audience }: Props) => {
  const { vendorFitScores, isLoading, error } = useVendorFitScoring(
    startDate,
    endDate,
    audience?.id,
  );
  const [removedVendors, setRemovedVendors] = useState<VendorFitScoringDataType[]>([]);

  const handleRemoveVendor = useCallback(
    (vendorToRemove: VendorFitScoringDataType) => {
      setRemovedVendors([...removedVendors, vendorToRemove]);
    },
    [removedVendors, setRemovedVendors],
  );

  const handleAddVendor = useCallback(
    (vendorToAdd: VendorFitScoringDataType) => {
      setRemovedVendors(removedVendors.filter((vendor) => vendor !== vendorToAdd));
    },
    [removedVendors, setRemovedVendors],
  );

  const visibleVendorFitScores = useMemo(() => {
    return vendorFitScores?.filter((vendor) => !removedVendors.includes(vendor));
  }, [removedVendors, vendorFitScores]);

  const columns: ColumnsType<VendorFitScoringDataType> = useMemo(
    () => [
      {
        title: t`Vendor`,
        key: 'vendor.id',
        fixed: 'left',
        width: TITLE_WIDTH,
        render: (text, field) => (
          <div className={styles.titleContainer}>
            <div
              className={classNames(styles.removeTab)}
              onClick={() => handleRemoveVendor(field)}
              role="button"
            >
              <Remove className={styles.icon} width={20} height={20} />
            </div>
            <Link variant="caption1" weight="bold" to={ROUTES.vendorById(field.vendor.id)}>
              {field.vendor.name}
            </Link>
            <Text variant="caption1">{field.vendor.channel?.name}</Text>
          </div>
        ),
      },
      {
        title: t`Visits`,
        key: 'visits.count',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.visits)}
            label={<Trans>Visits</Trans>}
            value={numberFormat(field.visits.count)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies.count',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.engagedCompanies)}
            label={<Trans>Engaged Companies</Trans>}
            value={numberFormat(field.engagedCompanies.percent, { isPercent: true, precision: 1 })}
            caption={numberFormat(field.engagedCompanies.count)}
            captionLink={ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              vendorIds: field.vendor.id,
              'metrics.visits': '[1,]',
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Visit`,
        key: 'spendPerVisit.total',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.spendPerVisit)}
            label={<Trans>$/Visit</Trans>}
            value={numberFormat(field.spendPerVisit.total, { isCurrency: true, precision: 2 })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Pipeline Influenced`,
        key: 'pipelineInfluenced.perSpend',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.pipelineInfluenced)}
            label={<Trans>$/Pipeline Influenced</Trans>}
            value={numberFormat(field.pipelineInfluenced.perSpend, {
              isMultiplier: true,
              precision: 1,
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`% Opps Influenced`,
        key: 'opportunityInfluenced.percent',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.opportunityInfluenced)}
            label={<Trans>% Opps Influenced</Trans>}
            value={numberFormat(field.opportunityInfluenced.percent, {
              isPercent: true,
              precision: 0,
            })}
            caption={numberFormat(field.opportunityInfluenced.total)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Fit Score`,
        key: 'fitScore.score',
        fixed: 'right',
        sorter: true,
        sortOrder: 'descend',
        render: (text, field) => (
          <KpiMetric
            color={getColor(field.fitScore)}
            label={<Trans>Fit Score</Trans>}
            value={numberFormat(field.fitScore.score, { precision: 1 })}
            error={error}
            isLoading={false}
          />
        ),
      },
    ],
    [audience, handleRemoveVendor],
  );

  const countBaseAccounts = vendorFitScores?.length && vendorFitScores[0].countBaseAccounts;

  return (
    <Panel
      title={
        <Trans>
          Fit Score - {audience ? audience.name : t`TAM`}
          {countBaseAccounts != null ? ' ' + t`(${numberFormat(countBaseAccounts)} Companies)` : ''}
        </Trans>
      }
      infoPopover={{
        title: t`Fit Score`,
        body: (
          <Trans>
            The Vendor Fit Score is an analysis of 5 major metrics used to calculate vendor
            performance from 1-100.{' '}
            <Link
              variant="body2"
              color="static-white"
              to="https://support.channel99.com/hc/en-us/articles/21697895853083-C99-Platform-Definitions#h_01HXYZVHZYTEZ1HTMG79PCB1DJ"
              target="_blank"
            >
              Learn More
            </Link>
          </Trans>
        ),
      }}
      noPadding
    >
      <Legend>
        <LegendItem color={GREEN_100} name={t`Highest Performer`} />
        <LegendItem color={RED_300} name={t`Lowest Performer`} />
      </Legend>
      <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            columns={columns}
            dataSource={visibleVendorFitScores}
            emptyMessage={t`No scores found. Please add vendor spend data to see fit scores.`}
            loading={isLoading}
            error={error}
            showHeader={false}
            pagination={false}
            rowKey={(record) => `${record.vendor.channel?.id}-${record.vendor.id}`}
            scroll={{ x: 1200 }}
            actionContent={
              removedVendors.length > 0 && (
                <VendorFitScoringTableActions
                  titleWidth={TITLE_WIDTH}
                  removedVendors={removedVendors}
                  onAddVendor={handleAddVendor}
                />
              )
            }
          />
        </div>
      </div>
    </Panel>
  );
};

export default VendorFitScoringTable;
