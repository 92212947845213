import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Flare, {
  Axis,
  FlareSeriesOptions,
  Legend,
  Tooltip,
  linkifyAxisLabels,
  tooltipPositionBelow,
} from '@shared/Flare';
import AxisLabel from '@shared/Flare/guides/AxisLabel';
import Bar from '@shared/Flare/marks/Bar';
import { Panel } from '@shared/panels';
import { OPPORTUNITY_TYPE } from 'api/opportunity';
import { useMultiplePixelOpportunitiesInfluenced } from 'api/pixel';
import { AQUA_200, AQUA_600 } from 'constants/colors/semantic/aqua';
import { numberFormat } from 'helper/numberFormatter';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';

type Props = {
  pixelIds?: string[];
  startDate?: string;
  endDate?: string;
};

const PixelMultiOpportunityInfluenceChart = ({ pixelIds, startDate, endDate }: Props) => {
  const navigate = useNavigate();
  const {
    pixelOpportunitiesInfluenced: data,
    isLoading,
    error,
  } = useMultiplePixelOpportunitiesInfluenced(pixelIds, startDate, endDate);

  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: OPPORTUNITY_TYPE.PIPELINE,
        name: t`Pipeline Influence`,
        zIndex: 1,
        data: data.map((d) => ({
          amount: d.pipeline.totalRevenue,
          custom: { count: d.pipeline.totalOpportunities },
        })),
      },
      {
        id: OPPORTUNITY_TYPE.CLOSED_WON,
        name: t`Closed Won Influence`,
        zIndex: 1,
        data: data.map((d) => ({
          amount: d.recentDeals.totalRevenue,
          custom: { count: d.recentDeals.totalOpportunities },
        })),
      },
    ];
  }, [data]);

  return (
    <Panel size="L" title={<Trans>Opportunity Influence</Trans>} noPadding style={{ height: 477 }}>
      <Flare
        data={seriesData}
        description={t`A breakdown of opportunity Pipeline and Closed Won revenue amounts for each pixel being compared`}
        colors={[AQUA_600, AQUA_200]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend />
        <Bar
          y="amount"
          position="dodge"
          showDataLabels
          dataLabelFormat={(label) =>
            label.y === 0 ? '' : numberFormat(label.y, { isCurrency: true, precision: 0 })
          }
          skeletonLoaderOptions={{ numSeries: 3, numCategories: pixelIds?.length || 3 }}
        />
        <Axis
          position="right"
          labelFormat={(item) => numberFormat(item.value, { isCurrency: true, precision: 0 })}
          gridlineWidth={0}
          baselineWidth={1}
          tickAmount={5}
        />
        <Axis
          position="bottom"
          categories={data?.map((d) => d.pixel.name) || []}
          labelFormat={(item) => {
            const { pixel } = data?.[item.pos] || {};
            if (!pixel?.id || !pixel?.name) {
              return '';
            }
            return renderToStaticMarkup(
              <AxisLabel
                align="right"
                label={pixel?.name}
                linkUrl={ROUTES.pixelById(pixel.id)}
                secondaryLabel={pixel?.audience?.name}
              />,
            );
          }}
          useHTML
          crosshair="rect"
          baselineWidth={0}
        />
        <Tooltip
          titleFormat={(item) => item?.key}
          rowValueFormat={(item) =>
            t`${numberFormat(item?.y, { isCurrency: true, precision: 0 })} (${numberFormat(
              item?.point.custom?.count,
            )} Companies)`
          }
          positioner={tooltipPositionBelow}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PixelMultiOpportunityInfluenceChart;
