import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import Flare, { Axis, Column, Tooltip } from '@shared/Flare';
import { Panel } from '@shared/panels';
import { usePipelineReturnOnSpend } from 'api/pipeline';
import { TAM_PRIMARY } from 'constants/colors';
import { numberFormat } from 'helper/numberFormatter';

type Props = {
  startDate?: string;
  endDate?: string;
};

const PipelineReturnOnSpendChart = ({ startDate, endDate }: Props) => {
  const {
    pipelineReturnOnSpend: data,
    isLoading,
    error,
  } = usePipelineReturnOnSpend(startDate, endDate);

  const filteredData = useMemo(
    () =>
      data?.filter((d) => d.returnOnSpend > 0)?.sort((a, b) => b.returnOnSpend - a.returnOnSpend),
    [data],
  );

  const seriesData = useMemo(() => {
    if (!filteredData || filteredData.length === 0) {
      return undefined;
    }

    return [
      {
        id: 'ros',
        name: t`Return on Spend`,
        data: filteredData.map((d) => ({ amount: d.returnOnSpend })),
      },
    ];
  }, [filteredData]);

  return (
    <Panel
      title={<Trans>Channel Return on Spend</Trans>}
      size="L"
      noPadding
      style={{ height: 390 }}
    >
      <Flare
        data={seriesData}
        description={t`Return on spend broken down by channel`}
        colors={[TAM_PRIMARY]}
        isLoading={isLoading}
        error={error}
        defaultOptions={{ legend: { enabled: false } }}
      >
        <Column
          y="amount"
          position="overlap"
          showDataLabels
          dataLabelFormat={(label) => t`${numberFormat(label.y, { precision: 0 })}x`}
          skeletonLoaderOptions={{ numCategories: 4, numSeries: 1, sortDescending: true }}
        />
        <Axis
          position="left"
          labelFormat={(item) => t`${numberFormat(item.value, { precision: 0 })}x`}
        />
        <Axis
          position="bottom"
          categories={filteredData?.map((d) => d.channel.name)}
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => item?.x}
          rowValueFormat={(item) => t`${numberFormat(item?.y, { precision: 0 })}x`}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PipelineReturnOnSpendChart;
