import { Trans, t } from '@lingui/macro';
import { Option, Select, SelectProps } from '@shared/Form';
import { DefaultOptionType } from 'antd/es/select';
import { OptionType } from 'api/common';
import { useVendorListByChannelId } from 'api/vendor';

interface Props extends SelectProps {
  channelId?: string;
  value?: OptionType;
  disabledVendorIds?: string[];
}

const VendorByChannelSelect = ({
  channelId,
  value,
  isDisabled,
  disabledVendorIds,
  onChange,
  ...rest
}: Props) => {
  const { vendors, isLoading } = useVendorListByChannelId(channelId);

  const handleChange = (
    { value, label }: DefaultOptionType,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (onChange) {
      onChange(
        {
          id: value,
          name: label,
        },
        option,
      );
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={{ value: value?.id, label: value?.name }}
      onChange={handleChange}
      placeholder={t`Select Vendor...`}
      notFoundContent={<Trans>No vendors were found...</Trans>}
      labelInValue
      showSearch
      {...rest}
      isDisabled={isDisabled || !channelId}
    >
      {vendors?.map((vendor) => (
        <Option
          key={vendor.id}
          value={vendor.id}
          disabled={(disabledVendorIds || []).indexOf(vendor.id) >= 0}
        >
          {vendor.name}
        </Option>
      ))}
    </Select>
  );
};

export default VendorByChannelSelect;
