import { Flex } from '@shared/Flex';
import { Tag } from '@shared/typography';
import { CHANNEL_ID } from 'api/common';
import { PixelType } from 'api/pixel';
import { getChannelColor } from 'constants/colors/channel';

// 50% opacity (hex)
const OPACITY = '80';

type Props = {
  pixels?: PixelType[];
};

const PixelTagList = ({ pixels }: Props) => {
  return (
    <Flex justify="end" gap="small" wrap>
      {pixels?.map((pixel) => (
        <Tag
          key={pixel.id}
          size="medium"
          variant="solid"
          color={`${getChannelColor(pixel.channel.id as CHANNEL_ID)}${OPACITY}`}
          noMargin
        >
          {pixel.name} - {pixel.vendor.name} {pixel.channel.name}
        </Tag>
      ))}
    </Flex>
  );
};

export default PixelTagList;
