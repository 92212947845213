import { UIEvent, useMemo, useState } from 'react';

import { t } from '@lingui/macro';
import { Text } from '@shared/typography';
import { List } from 'antd';
import { ListItemLayout } from 'antd/es/list';
import { OptionType } from 'api/common';
import classNames from 'classnames';

import { Checkbox, CheckboxValueType } from '../switches';
import styles from './ListBox.module.scss';

const LOADING_ID = 'LOADING';

type Props = {
  options: OptionType[] | undefined;
  selectionMode: 'none' | 'single' | 'multiple';
  defaultValue?: CheckboxValueType[];
  value?: CheckboxValueType[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  error?: Error | null;
  onChange?: (value: CheckboxValueType[]) => void;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
};

const ListBox = ({
  options,
  selectionMode = 'none',
  defaultValue,
  value: valueFromProps,
  isLoading,
  isLoadingMore = false,
  error,
  onChange,
  onScroll,
}: Props) => {
  const isControlled = typeof valueFromProps !== 'undefined';
  const hasDefaultValue = typeof defaultValue !== 'undefined';

  const [internalValue, setInternalValue] = useState<CheckboxValueType[] | undefined>(
    hasDefaultValue ? defaultValue : undefined,
  );
  const value = isControlled ? valueFromProps : internalValue;

  const isSelectable = selectionMode !== 'none';
  const baseListProps = {
    className: styles.list,
    itemLayout: 'horizontal' as ListItemLayout,
  };

  const listOptions = useMemo(() => {
    if (error) {
      return [{ id: 'error', name: t`Unable to load items, please refresh and try again` }];
    }

    if (isLoading) {
      return [{ id: LOADING_ID, name: t`Loading...` }];
    }

    if (isLoadingMore) {
      return options?.concat({ id: LOADING_ID, name: t`Loading...` });
    }

    return options;
  }, [options, isLoadingMore]);

  const handleChange = (val: CheckboxValueType[]) => {
    onChange?.(val);

    if (!isControlled) {
      setInternalValue(val);
    }
  };

  const renderStatusItem = (item: OptionType) => {
    return (
      <List.Item className={classNames(styles.listItem, styles.isLoading)}>
        <Text variant="body2" color="grey" italic>
          {item.name}
        </Text>
      </List.Item>
    );
  };

  const renderChildItem = (item: OptionType) => {
    if (error || isLoading || item.id === LOADING_ID) {
      return renderStatusItem(item);
    }

    const val = value ?? [];
    const isChecked = val.indexOf(item.id) >= 0;

    return (
      <List.Item
        className={classNames(styles.listItem, { [styles.isSelectable]: isSelectable })}
        onClick={() => {
          if (!isSelectable) return;

          if (selectionMode === 'single') {
            handleChange(isChecked ? [] : [item.id]);
          } else {
            if (isChecked) {
              handleChange(val.filter((d) => d !== item.id));
            } else {
              handleChange([...val, item.id]);
            }
          }
        }}
      >
        <List.Item.Meta
          avatar={isSelectable && <Checkbox value={item.id} checked={isChecked} />}
          title={<Text variant="body1">{item.name}</Text>}
        />
      </List.Item>
    );
  };

  return (
    <div className={styles.listBox} onScroll={onScroll}>
      <List {...baseListProps} dataSource={listOptions} renderItem={renderChildItem} />
    </div>
  );
};

export default ListBox;
