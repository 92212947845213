export const TEAL_100 = '#B3ECDC';
export const TEAL_200 = '#8DE2CA';
export const TEAL_300 = '#71DBBD';
export const TEAL_400 = '#4EDDB4';
export const TEAL_500 = '#34A686';
export const TEAL_600 = '#277C64';
export const TEAL_700 = '#1A5343';
export const TEAL_800 = '#0D2921';

export const ALL_TEALS = [
  TEAL_100,
  TEAL_200,
  TEAL_300,
  TEAL_400,
  TEAL_500,
  TEAL_600,
  TEAL_700,
  TEAL_800,
];

export const ALL_SEMANTIC_TEALS = [TEAL_300, TEAL_400, TEAL_500, TEAL_600, TEAL_700, TEAL_800];
