import { Trans, t } from '@lingui/macro';
import LoginForm from '@pages/sign-in/LoginForm';
import SignInPanel from '@pages/sign-in/SignInPanel';
import TermsOfUseLink from '@pages/sign-in/TermsOfUseLink';
import { FullPage } from '@shared/page';
import { Link, Text } from '@shared/typography';
import { ROUTES } from 'router';

const SignUp = () => {
  return (
    <FullPage title={t`Sign Up`}>
      <SignInPanel
        title={t`Create An Account with Channel99`}
        footer={
          <>
            <Text variant="caption1" color="grey">
              <Trans>
                Already have an account?{' '}
                <Link
                  variant="caption1"
                  color="aqua"
                  to={ROUTES.signIn.path}
                >{t`Sign In Here`}</Link>
              </Trans>
            </Text>
            <Text variant="caption1" color="grey">
              <Trans>
                By continuing to sign up I agree to the <TermsOfUseLink />
              </Trans>
            </Text>
          </>
        }
        showGap
      >
        <LoginForm type="sign-up" />
      </SignInPanel>
    </FullPage>
  );
};

export default SignUp;
